import { DEMO_TARIFFS } from "@constants";

export const isDemoPath = () => {
  if (window?.location?.pathname) {
    const splittedPath = window.location.pathname.split("/");
    return splittedPath[1] === "demo";
  } else return false;
};

export const stateAuthOrDemo = (state) =>
  state.demo?.isDemo ? state.demo : state.auth;

export const demoGenId = (n) =>
  Array(n)
    .fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
    .map((x) => x[Math.floor(Math.random() * x.length)])
    .join("");

export const randomIP = () => {
  const random = () => Math.floor(Math.random() * 256);
  return `${random()}.${random()}.${random()}.${random()}`;
};

export const demoModemChangeStatus = (status, newStatus) => {
  if (status === "DOWN") {
    return status;
  }
  if (newStatus === "REBOOT") {
    return newStatus;
  }
  if (newStatus === "ONLINE" && status === "ONLINE") {
    return newStatus;
  }
  return status;
};

export const demoCreateCheckOut = ({ ids, modems }) => {
  const temp = modems.filter((item) => ids.includes(item.id));
  const tempMap = temp.map((item) => ({
    id: demoGenId(9),
    modemId: item.id,
    number: item.number,
    model: item.model,
    rateId: item.tariffInfo?.id,
    ratePublicName: DEMO_TARIFFS.find(
      (tariff) => tariff.id === item.tariffInfo.id
    )?.publicName,
    monthCount: 0,
    rawPrice: 0,
    actualPrice: 0,
    finalPrice: 0,
    isRateChangeable: true
  }));
  const key = demoGenId(10);
  return {
    key,
    data: {
      id: demoGenId(10),
      modemOrderInfoList: tempMap,
      clientId: "123456789",
      status: "PENDING",
      isAppliable: false,
      readonly: false,
      rawAmount: 0,
      actualAmount: 0,
      payment: "Balanse"
    }
  };
};

export const demoChangeCheckOut = ({
  checkoutModemId,
  tariffId,
  monthCount,
  checkOut
}) => {
  const tempTariff = DEMO_TARIFFS.find((tariff) => tariff.id === tariffId);
  const temp = checkOut.modemOrderInfoList.map((item) => {
    if (item.id === checkoutModemId) {
      return {
        ...item,
        rateId: tempTariff.id,
        ratePublicName: tempTariff.publicName,
        monthCount,
        rawPrice: tempTariff.price * monthCount,
        actualPrice: tempTariff.price * monthCount,
        finalPrice: tempTariff.price * monthCount,
        isRateChangeable: true
      };
    } else return item;
  });
  return { ...checkOut, modemOrderInfoList: temp, isAppliable: true };
};

export const demoActionsAllModems = (
  activeHostModems,
  selectedModemsIds,
  modems,
  activeHostId,
  action
) => {
  let newModems = {};
  const errors = [];
  const success = [];
  if (action === "DELETE") {
    newModems = {
      ...modems,
      [activeHostId]: activeHostModems.filter((item) => {
        if (!selectedModemsIds.includes(item.id)) {
          success.push(item.number);
          return item;
        }
      })
    };
  }

  if (action === "CONTINUE") {
    newModems = {
      ...modems,
      [activeHostId]: activeHostModems.map((item) => {
        if (selectedModemsIds.includes(item.id)) {
          if (item.paid) {
            success.push(item.number);
            return {
              ...item,
              lastUpdate: new Date().getTime(),
              isPauseable: true,
              isContinueable: false,
              use: true
            };
          } else {
            errors.push({ number: item.number, reason: "Is not paid" });
            return item;
          }
        } else {
          return item;
        }
      })
    };
  }

  if (action === "PAUSE") {
    newModems = {
      ...modems,
      [activeHostId]: activeHostModems.map((item) => {
        if (selectedModemsIds.includes(item.id)) {
          if (item.paid) {
            success.push(item.number);
            return {
              ...item,
              lastUpdate: new Date().getTime(),
              isPauseable: false,
              isContinueable: true,
              use: false
            };
          } else {
            errors.push({ number: item.number, reason: "Is not paid" });
            return item;
          }
        } else {
          return item;
        }
      })
    };
  }
  return { newModems, errors, success };
};
