import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// ** Custom Components
import { AdminBreadcrumbs } from "@components/app";
import { MetaDate } from "@components/Metadate";

export const AdminLayout = () => {
  const { pathname } = useLocation();
  const { userProfile } = useSelector((state) => state.auth);

  if (!userProfile?.data?.isSuperUser && !userProfile?.data?.isAdmin) {
    return <Navigate to={"/app/home/"} />;
  }

  const isMain = pathname === "/app/admin" || pathname === "/app/admin/";

  return (
    <>
      <MetaDate app={true} title="Admin Panel" description="" />
      {!isMain && <AdminBreadcrumbs />}
      <Outlet context={{ isDemo: false }} />
    </>
  );
};
