export const PageSpinner = ({ page = null }) => (
    <div className="fallback-spinner">
      <div className="loading component-loader">
        <div
          className={`effect-1 effects ${
            page === "LANDING" ? "effect-1-SSR" : ""
          }`}
        ></div>
        <div
          className={`effect-2 effects ${
            page === "LANDING" ? "effect-2-SSR" : ""
          }`}
        ></div>
        <div
          className={`effect-3 effects ${
            page === "LANDING" ? "effect-3-SSR" : ""
          }`}
        ></div>
      </div>
    </div>
  );
