import { Navigate } from "react-router-dom";

export const AdminWarehouseRoutes = [
  {
    path: "warehouse/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminWarehouse } = await import("@views/app/admin/warehouse");
          return {
            Component: AdminWarehouse
          };
        }
      },
      {
        path: "add/",
        async lazy() {
          const { AdminWarehouseAddEdit } = await import(
            "@views/app/admin/warehouse/item"
          );
          return {
            Component: AdminWarehouseAddEdit
          };
        }
      },
      {
        path: ":warehouseId/edit/",
        async lazy() {
          const { AdminWarehouseAddEdit } = await import(
            "@views/app/admin/warehouse/item"
          );
          return {
            Component: AdminWarehouseAddEdit
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
