import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
// ** Services
import { apiService } from "@services/ApiService";
// ** Custom Hooks & Utils
import { useDispatchedActions, useLockBackground } from "@hooks";
// ** Components
import { FormInput } from "@components/app";

export const ModalSimNumberEdit = () => {
  // ** Hooks
  const { isDemo } = useOutletContext();
  const methods = useForm();
  // ** Dispatched Actions
  const { setModalSimNumberEditOpen, fetchSaveSimNumber } =
    useDispatchedActions();
  // ** Redux state
  const { content } = useSelector((state) => state.demo);
  const { selectedModemId, modalSimNumberEditOpen } = useSelector(
    (state) => state.modals
  );
  const { activeHostId } = useSelector((state) => state.content.hostModems);

  // ** State
  const [loaded, setLoaded] = useState(false);

  // ** Functions
  const toggle = () => setModalSimNumberEditOpen(!modalSimNumberEditOpen);

  const handleSave = (data) => {
    const params = {
      id: selectedModemId,
      simNumberCustom: data.simNumberCustom
    };
    fetchSaveSimNumber({ params, isDemo });
  };

  // ** Effects
  useEffect(() => {
    if (!isDemo) {
      apiService.modems.getById({ id: selectedModemId }).then((req) => {
        if (req?.status === 200 && req?.data) {
          methods.setValue("simNumberCustom", req.data.simNumberCustom);
          setLoaded(true);
        }
      });
    } else {
      const activeHostModems = content.modems[activeHostId];
      const tempModem = activeHostModems.find(
        (item) => item.id === selectedModemId
      );
      methods.setValue("simNumberCustom", tempModem.simNumberCustom);
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLockBackground(!!modalSimNumberEditOpen);

  return loaded === true ? (
    <Modal
      isOpen={modalSimNumberEditOpen}
      className="modal-dialog-centered"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Edit sim number</ModalHeader>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(handleSave)}>
          <ModalBody>
            <FormGroup noMargin className="m-0">
              <Label for="simCustomerNumber">Sim number:</Label>
              <FormInput type="tel" name="simNumberCustom" required />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button outline color="success" type="submit">
              Save
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  ) : null;
};
