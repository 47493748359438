import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

import "./skeleton.styles";

export const LoadingDataTable = ({ rowNumber = 1 }) => {
  // ** Redux State
  const { skin } = useSelector((state) => state.theme);

  // ** Conctants
  const darkColors =
    skin === "dark" ? { baseColor: "#242b3d", highlightColor: "#343d55" } : {};

  return (
    <div className="d-flex flex-column w-100 loading-data-body">
      {new Array(rowNumber).fill(1).map((_, index) => (
        <div className="d-flex  border-bottom" key={index}>
          <div className="px-1 py-75 d-flex align-items-center ">
            <Skeleton width={20} height={20} {...darkColors} />
          </div>
          <div className="px-1 py-75 d-flex align-items-center">
            <Skeleton width={20} height={20} {...darkColors} />
          </div>
          <div className="px-1 py-75 d-flex flex-column justify-content-center">
            <Skeleton
              width={110}
              height={16}
              containerClassName="mb-50"
              {...darkColors}
            />
            <Skeleton width={70} height={20} {...darkColors} />
          </div>
          <div className="px-1 py-75 d-flex  align-items-center">
            <Skeleton
              circle
              width={10}
              height={10}
              containerClassName="me-50"
              {...darkColors}
            />
            <Skeleton width={70} height={20} {...darkColors} />
          </div>
          <div className="px-1 py-75 d-flex flex-column justify-content-center">
            <Skeleton width={150} height={16} {...darkColors} />
            <Skeleton
              width={45}
              height={20}
              containerClassName="my-25"
              {...darkColors}
            />
            <Skeleton width={70} height={16} {...darkColors} />
          </div>
          <div className="px-1 py-75 d-flex flex-column justify-content-center">
            <Skeleton
              width={200}
              height={16}
              containerClassName="mb-25"
              {...darkColors}
            />
            <Skeleton width={150} height={16} {...darkColors} />
          </div>
          <div className="px-1 py-75 d-flex align-items-center ">
            <Skeleton width={100} height={18} {...darkColors} />
          </div>
          <div className="px-1 py-75 d-flex align-items-center">
            <Skeleton width={95} height={50} {...darkColors} />
          </div>
          <div className="px-1 py-75 d-flex flex-column justify-content-center">
            <Skeleton
              width={75}
              height={12}
              containerClassName="mb-25"
              {...darkColors}
            />
            <Skeleton width={40} height={12} {...darkColors} />
          </div>
          <div className="px-1 py-75 d-flex flex-column justify-content-center">
            <Skeleton
              width={75}
              height={12}
              containerClassName="mb-25"
              {...darkColors}
            />
            <Skeleton width={40} height={12} {...darkColors} />
          </div>
          <div className="px-1 py-75 d-flex align-items-center">
            <Skeleton circle width={40} height={40} {...darkColors} />
          </div>
          <div className="px-1 py-75 d-flex align-items-center ">
            <Skeleton width={220} height={45} {...darkColors} />
          </div>
          <div className="px-1 py-75 d-flex align-items-center">
            <Skeleton circle width={40} height={40} {...darkColors} />
          </div>
        </div>
      ))}
    </div>
  );
};
