import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
// ** Custom Hooks & Utils
import { useTranslation } from "@hooks";
import { IconSvg } from "@constants";
// ** Styles
import "./input.scss";

export const Input = (props) => {
  // ** Props
  const {
    type = "text",
    name,
    placeholder = "",
    required = false,
    messageOff = false,
    resizeEvent = false,
    ...rest
  } = props;

  // ** Hooks
  const { control } = useFormContext();
  const { mainPage } = useTranslation();

  // **Local State
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  // ** Functions
  const currentChangeHandler = (event, handlerChange) => {
    const { type } = event.target;
    let { value } = event.target;
    if (type === "tel") {
      value = value.replace(/[^0-9]/g, "");
      if (value.length > 18) {
        value = value.slice(0, 18);
      }
    }
    event.target.value = value;
    handlerChange?.(value);
  };

  const handlerTextareaChange = (event, handlerChange) => {
   if (resizeEvent) {
    const { scrollHeight } = event.target;
    const { height } = event.target.style;
    const numberHeight = Number(height?.slice(0, -2));

    if (numberHeight !== scrollHeight) {
      const currentHeight = (numberHeight - scrollHeight) < 2 ? scrollHeight : scrollHeight - 16;
      event.target.style.height = `${currentHeight}px`;
    }
    if (event.target.value === "") {
      event.target.style.height = "auto";
    }
  }
    handlerChange?.(event.target.value);
  };

  const currentBlurHandler = (event, handlerChange) => {
    const { value } = event.target;
    handlerChange?.(value.trim());
  };

  // Password handler
  const showPasswordHandler = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const currentErrorMessage = (error) => {

    if (messageOff) return "";

    if (error?.type === "required") {
      return mainPage?.errors?.requiredField;
    }
    if (error?.type === "pattern") {
      if (type === "email") {
        return mainPage?.errors?.wrongFormatEmail;
      }
      if (type === "password") {
        return mainPage?.errors?.wrongPass;
      }
      if (type === "tel") {
        return mainPage?.errors?.wrongFormatPhone;
      }
    }
    return error?.message;
  };

  return name && control ? (
    <Controller
      name={name}
      control={control}
      defaultValue={""}
      rules={{
        required: {
          value: required,
          message: mainPage?.errors?.requiredField
        },
        ...(type === "email" && {
          pattern: {
            value:
              /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,4}$/i,
            message: mainPage?.errors?.wrongFormatEmail
          }
        }),
        ...(type === "password" && {
          pattern: {
            value: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,32}$/,
            message: mainPage?.errors?.wrongPass
          }
        }),
        ...(type === "tel" && {
          pattern: {
            value: /^([0-9]){1,18}$/,
            message: mainPage?.errors?.wrongFormatPhone
          }
        })
      }}
      render={({ field, fieldState: { error } }) => (
        <div
          className={`landing-form__item-input ${type === "textarea" ? " landing-form__item-textarea" : ""
            } ${error?.type ? " landing-form__item-input-error" : ""}`}
        >
          <label className="landing-form__item-input-label">
            {type === "textarea" ? (
              <textarea
                {...field}
                id={name}
                value={field.value || ""}
                placeholder={placeholder}
                rows={1}
                aria-invalid={!!error?.type}
                onChange={(event) => handlerTextareaChange(event, field.onChange)}
                {...rest}
              />
            ) : (
              <input
                {...field}
                id={name}
                type={type === "password" && isPasswordShown ? "text" : type}
                placeholder={placeholder}
                aria-invalid={!!error?.type}
                value={field.value || ""}
                onClick={(event) => currentChangeHandler(event, field.onChange)}
                onChange={(event) =>
                  currentChangeHandler(event, field.onChange)
                }
                onBlur={(event) => currentBlurHandler(event, field.onChange)}
                {...rest}
              />
            )}
            {placeholder && <span className="landing-form__item-input-label-name">
              {placeholder}{required && <span className="landing-form__item-input-label-name-required">*</span>}
            </span>}
            {type === "password" && (
              <span
                className="landing-form__item-input-icon"
                onClick={showPasswordHandler}
              >
                {isPasswordShown ? (
                  <IconSvg tag="hiddenPas" />
                ) : (
                  <IconSvg tag="showPas" />
                )}
              </span>
            )}
          </label>
          {!messageOff && error?.type && (
            <p className="landing-form__item-error">{currentErrorMessage(error)}</p>
          )}
        </div>
      )}
    />
  ) : null;
};
