import { isSSR } from "@helpers";
import chalk from "chalk";
(async () => {
  try {
    await import("react-loading-skeleton/dist/skeleton.css");
  } catch (ignored) {
    const isServer = isSSR();
    // eslint-disable-next-line no-console
    console.log(
      chalk.yellow(
        `Error: Skeleton CSS import failed on ${isServer ? "server" : "client"} "skeleton.styles.js"`
      )
    );
  }
})();
