import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatchedActions } from "@hooks";

import "./burger.scss";

export const Burger = () => {
  // **Dispatch
  const { toggleMobileMenu } = useDispatchedActions();

  // **Redux State
  const { isOpen } = useSelector((state) => state.landing.mobileMenu);

  // **Handlers
  const menuHandler = () => toggleMobileMenu(!isOpen);

  // **Effects
  useEffect(() => {
    if (isOpen) {
      menuHandler();
    }
    document
      .querySelector(".landing-burger__btn")
      .classList.remove("landing-burger__btn--active");
    return () => {
      document.body.classList.remove("lock");
      document.documentElement.style.overflow = "";
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <button
        className={`landing-burger__btn ${isOpen ? "landing-burger__btn--active" : ""}`}
        aria-label="menu"
        onClick={menuHandler}
      >
        <span></span>
      </button>
    </>
  );
};
