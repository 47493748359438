import { useEffect, useState } from "react";
import { Button, Form } from "reactstrap";
import { X, Users, Server, Check } from "react-feather";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FormProvider, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";

// ** Services
import { apiService } from "@services/ApiService";
// ** Custom Hooks & Utils
import { useDispatchedActions } from "@hooks";
// ** Components
import { FormInput } from "@components/app";

// ** Constants
const AUTH_TYPES = {
  LOGIN: "LOGIN",
  IP: "IP"
};

export const EditUser = (props) => {
  // ** Props
  const { user, refreshUsers, setEdit } = props;
  // ** Dispatched Actions
  const { setUpdateModems } = useDispatchedActions();
  // ** Hooks
  const { isDemo } = useOutletContext();
  const methods = useForm({
    defaultValues: { login: "", password: "", ip: "" }
  });
  // ** Redux state
  const { activeHostId } = useSelector((state) => state.content.hostModems);
  const { content } = useSelector((state) => state.demo);
  const [loading, setLoading] = useState(false);
  // ** State
  const [currentAuthType, setCurrentAuthType] = useState("");

  // ** Handlers
  const handlerSave = (data) => {
    setLoading(true);
    const currentData = {
      ip: currentAuthType === AUTH_TYPES.IP ? data.ip : "",
      authType:
        currentAuthType === AUTH_TYPES.IP ? AUTH_TYPES.IP : AUTH_TYPES.LOGIN,
      login: currentAuthType === AUTH_TYPES.LOGIN ? data.login : "",
      password: currentAuthType === AUTH_TYPES.LOGIN ? data.password : ""
    };
    if (!isDemo) {
      const params = {
        id: user?.id,
        ...currentData
      };
      apiService.modemAuth
        .updateUser(params)
        .then((req) => {
          if (
            req?.response?.status === 400 &&
            req?.response?.data === "INVALID_SYMBOLS_IN_INPUT"
          ) {
            toast.error(
              "Login and password must contain only latin letters, numbers and any special characters"
            );
          }
          if (req.status === 200) {
            refreshUsers();
            setEdit(false);
            methods.reset();
            toast.success("Saved");
          }
        })
        .finally(() => setLoading(false));
    } else {
      // demo logik start
      const activeHostModems = content.modems[activeHostId];
      const newModems = {
        ...content.modems,
        [activeHostId]: activeHostModems.map((item) => {
          if (item.limits.modemAuths.find((auth) => user?.id === auth.id)) {
            const tempLogins = [];
            const tempModemAuths = item.limits.modemAuths.map((auth) => {
              if (user?.id === auth.id) {
                if (auth.speedLimit > 0 || auth.trafficLimit > 0) {
                  tempLogins.push(
                    currentAuthType === AUTH_TYPES.IP ? auth.ip : auth.login
                  );
                }
                return {
                  ...auth,
                  ...currentData
                };
              } else return auth;
            });
            return {
              ...item,
              lastUpdate: new Date().getTime(),
              limits: {
                ...item.limits,
                logins: tempLogins,
                modemAuths: tempModemAuths
              }
            };
          } else return item;
        })
      };
      toast.success("Saved");
      setUpdateModems(newModems);
      setEdit(false);
      methods.reset();
      setLoading(false);
      // demo logik end
    }
  };

  const handlerChangeAuthType = () => {
    setCurrentAuthType(
      currentAuthType === AUTH_TYPES.IP ? AUTH_TYPES.LOGIN : AUTH_TYPES.IP
    );
  };

  // ** Effects
  useEffect(() => {
    if (user) {
      methods.reset({
        login: user?.login,
        password: user?.password,
        ip: user?.ip
      });
      setCurrentAuthType(
        AUTH_TYPES.hasOwnProperty(user?.authType)
          ? user?.authType
          : AUTH_TYPES.LOGIN
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <FormProvider {...methods}>
      <Form
        className="d-flex w-100 align-items-center  border-bottom p-25"
        onSubmit={methods.handleSubmit(handlerSave)}
      >
        <div
          className="d-flex align-items-center font-weight-bold text-uppercase me-75"
          style={{ width: "61px" }}
        >
          {user?.isActive === true ? (
            <span className="bullet bullet-sm bullet-success me-25"></span>
          ) : (
            <span className="bullet bullet-sm bullet-danger me-50"></span>
          )}
          {currentAuthType}
        </div>
        <div className="overflow-hidden d-flex align-items-center">
          {currentAuthType === AUTH_TYPES.IP && (
            <FormInput
              style={{ width: "310px" }}
              type="ip"
              name="ip"
              required
            />
          )}

          {currentAuthType === AUTH_TYPES.LOGIN && (
            <>
              <FormInput
                className=" me-50"
                style={{ width: "150px" }}
                type="text"
                name="login"
                required
                onlyLatinLanguage
              />
              <FormInput
                style={{ width: "150px" }}
                type="text"
                name="password"
                required
                onlyLatinLanguage
              />
            </>
          )}
        </div>
        <div className="d-flex ms-auto">
          <Button
            className="btn-icon p-50 me-25 flex-none border-0"
            color="flat-secondary"
            onClick={() => setEdit(false)}
          >
            <X size={16} />
          </Button>
          <Button
            className="btn-icon p-50 me-25 flex-none border-0"
            color="flat-secondary"
            onClick={handlerChangeAuthType}
          >
            {currentAuthType === AUTH_TYPES.LOGIN ? (
              <Users size={16} />
            ) : (
              <Server size={16} />
            )}
          </Button>
        </div>
        <Button
          color="info"
          outline
          type="submit"
          className="px-1 py-50"
          disabled={loading}
        >
          <Check size={18} className="me-25" />
          Save
        </Button>
      </Form>
    </FormProvider>
  );
};
