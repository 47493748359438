import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "@services/ApiService";
import { Coffee } from "react-feather";
import { toast } from "react-toastify";
import { SettingsReducerActions } from "../SettingsReducer/Settings.reducer";
import { ThemeReducerActions } from "../ThemeReducer/Theme.reducer";

export const fetchUserAuth = createAsyncThunk(
  "Auth/fetchUserAuth",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { settings, theme } = getState();
    try {
      const isAuth = await apiService.users.isAuth();
      if (isAuth?.request?.status === 401) {
        return { auth: false, data: {} };
      }
      if (isAuth && isAuth?.status === 200) {
        if (isAuth?.data?.locale && settings?.lang !== isAuth?.data?.locale) {
          dispatch(SettingsReducerActions.setLang(isAuth?.data?.locale));
        }
        if (isAuth?.data?.theme && theme?.skin !== isAuth?.data?.theme) {
          dispatch(ThemeReducerActions.setThemeSkin(isAuth?.data?.theme));
        }
        return {
          auth: true,
          data: {
            ...isAuth?.data,
            isSuperUser: isAuth?.data?.roles.includes("SUPERUSER"),
            isAdmin: isAuth?.data?.roles.includes("ADMIN")
          }
        };
      }
      throw isAuth;
    } catch (error) {
      return rejectWithValue(false);
    }
  }
);

export const fetchUserLogin = createAsyncThunk(
  "Auth/fetchUserLogin",
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await apiService.auth.login(params);
      if (response && response?.status !== 200) {
        throw response;
      }
      const { settings } = getState();
      const userProfile = await apiService.users.getProfile();
      if (userProfile && userProfile?.status !== 200) {
        throw userProfile;
      }
      toast.success("Successfully authorized. We are glad to welcome!", {
        icon: <Coffee />
      });
      if (
        userProfile?.data?.locale &&
        settings?.lang !== userProfile?.data?.locale
      ) {
        dispatch(SettingsReducerActions.setLang(userProfile?.data?.locale));
      }
      if (
        userProfile?.data?.theme &&
        settings?.skin !== userProfile?.data?.theme
      ) {
        dispatch(ThemeReducerActions.setThemeSkin(userProfile?.data?.theme));
      }
      return {
        ...userProfile?.data,
        isSuperUser: userProfile?.data?.roles.includes("SUPERUSER"),
        isAdmin: userProfile?.data?.roles.includes("ADMIN")
      };
    } catch (error) {
      return rejectWithValue(true);
    }
  }
);

export const fetchUserProfile = createAsyncThunk(
  "Auth/fetchUserProfile",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const userProfile = await apiService.users.getProfile();
      if (userProfile && userProfile?.status !== 200) {
        throw userProfile;
      }
      const { settings } = getState();
      if (
        userProfile?.data?.locale &&
        settings?.lang !== userProfile?.data?.locale
      ) {
        dispatch(SettingsReducerActions.setLang(userProfile?.data?.locale));
      }
      if (
        userProfile?.data?.theme &&
        settings?.skin !== userProfile?.data?.theme
      ) {
        dispatch(ThemeReducerActions.setThemeSkin(userProfile?.data?.theme));
      }

      return {
        ...userProfile?.data,
        isSuperUser: userProfile?.data?.roles.includes("SUPERUSER")
      };
    } catch (error) {
      return rejectWithValue(true);
    }
  }
);

export const fetchUserDomains = createAsyncThunk(
  "Auth/fetchUserDomains",
  async (_, { rejectWithValue }) => {
    try {
      const userDomain = await apiService.clientDomains.getAll();
      if (userDomain && userDomain?.status !== 200) {
        throw userDomain;
      }
      return userDomain?.data;
    } catch (error) {
      return rejectWithValue(false);
    }
  }
);

export const updateProfileInterface = createAsyncThunk(
  "Auth/updateProfileInterface",
  async ({ params, isDemo }, { rejectWithValue, getState, signal }) => {
    if (isDemo) {
      rejectWithValue(false);
    }
    const {
      settings,
      theme,
      auth: {
        userProfile: { data }
      }
    } = getState();
    const current = {
      locale: params?.locale || settings?.locale,
      theme: params?.skin || theme?.skin
    };

    if (data?.locale === current?.locale && data?.theme === current?.theme) {
      rejectWithValue(false);
    }

    try {
      const response = await apiService.users.updateProfileInterface(
        current,
        signal
      );
      if (response && response?.status !== 200) {
        throw response;
      }
      return response?.data;
    } catch (error) {
      return rejectWithValue(false);
    }
  }
);
