/* eslint-disable quotes */
import { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Button, Alert, Row } from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Plus } from "react-feather";
import { useNavigate, useOutletContext } from "react-router-dom";
// ** Custom Hooks & Utils
import { useDispatchedActions, useLockBackground } from "@hooks";
import { selectThemeColors } from "@helpers";
// ** Services
import { apiService } from "@services/ApiService";
// ** Components
import { LoadingModemLinks } from "@components/app";
import { LinkDomain } from "./components/LinkDomain";

const SwalAct = withReactContent(Swal);

export const ModalCommandLinks = () => {
  // ** Hooks
  const { isDemo } = useOutletContext();
  const navigate = useNavigate();
  // **Dispatched Actions
  const { setModalCommandLinksOpen } = useDispatchedActions();
  // ** Redux state
  const { selectedModemId, modalCommandLinksOpen } = useSelector(
    (state) => state.modals
  );
  // ** State
  const [modemRateInfo, setModemRateInfo] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [defaultRebootLink, setDefaultRebootLink] = useState(null);
  const [defaultStatusLink, setDefaultStatusLink] = useState(null);
  const [defaultIPLink, setDefaultIPLink] = useState(null);
  const [toggleResetLinks, setToggleResetLinks] = useState(false);
  const [resetLinksLoading, setResetLinksLoading] = useState(false);
  const [rebootLink, setRebootLink] = useState(null);
  const [statusLink, setStatusLink] = useState(null);
  const [ipLink, setIPLink] = useState(null);
  const [priorityDomainId, setPriorityDomainId] = useState("");
  const [isTls, setIsTls] = useState(false);
  const [approvedDomains, setApprovedDomains] = useState([]);

  // ** Functions
  const getDefaultLinks = (signal) => {
    apiService.modems
      .getLinks({ id: selectedModemId }, signal)
      .then((req) => {
        if (req?.data) {
          const { reboot, status, ip } = req.data;
          setDefaultRebootLink(reboot);
          setDefaultStatusLink(status);
          setDefaultIPLink(ip);
          setToggleResetLinks((prevState) => !prevState);
        }
      })
      .catch(() => {
        setResetLinksLoading(false);
      });
  };
  const getModemRateInfo = (signal) => {
    const params = { modemId: selectedModemId };
    apiService.modems.getRateInfo(params, signal).then((req) => {
      if (req.data) {
        setModemRateInfo(req.data);
      }
    });
  };
  const resetLinks = () => {
    setResetLinksLoading(true);
    if (!isDemo) {
      apiService.modems
        .getResetLink({ id: selectedModemId })
        .then((resp) => {
          if (resp?.data) {
            if (resp.data === "OK") {
              setRebootLink(null);
              setStatusLink(null);
              setIPLink(null);
              getDefaultLinks();
            } else {
              setResetLinksLoading(false);
              SwalAct.fire({
                icon: "error",
                title: "Links is not updated!",
                showConfirmButton: false,
                timer: 1500
              });
            }
          }
        })
        .catch(() => {
          setResetLinksLoading(false);
        });
    }
  };

  const setClientLinks = (id) => {
    if (id) {
      apiService.clientDomains.getDomainLinks(id).then((req) => {
        const { status, data } = req;

        if (status === 200) {
          const globalDomainLink =
            data.length > 0 &&
            data.some((el) => el.linkType === "GLOBAL") &&
            data.find((el) => el.linkType === "GLOBAL");

          if (globalDomainLink && globalDomainLink.enabled) {
            const globalPrefix = globalDomainLink.prefix;

            setRebootLink(
              defaultRebootLink.replace(/\/c\/modem\//g, globalPrefix)
            );
            setStatusLink(
              defaultStatusLink.replace(/\/c\/modem\//g, globalPrefix)
            );
            setIPLink(defaultIPLink.replace(/\/c\/modem\//g, globalPrefix));
          } else {
            setRebootLink(defaultRebootLink);
            setStatusLink(defaultStatusLink);
            setIPLink(defaultIPLink);
          }

          setIsLoadingData(false);
        }
      });
    } else {
      setRebootLink(defaultRebootLink);
      setStatusLink(defaultStatusLink);
      setIPLink(defaultIPLink);
      setIsLoadingData(false);
    }
  };

  const getApprovedDomains = (signal) => {
    const appApiUrl = process.env.REACT_APP_URL;
    const params = { approved: true };
    apiService.clientDomains.getApproved(params, signal).then((req) => {
      if (req?.data) {
        const tempData = req?.data.map((item) => ({
          ...item,
          label: item?.domain,
          value: item?.domain
        }));

        setApprovedDomains([
          {
            label: "default",
            value: appApiUrl,
            domain: appApiUrl
          },
          ...tempData
        ]);
      }
    });
  };

  const toggle = () => setModalCommandLinksOpen(false);

  const handlerAdd = () => {
    toggle();
    navigate(`/${isDemo ? "demo" : "app"}/profile/domain-add/`);
  };

  const handlerOpen = () => {
    toggle();
    navigate(`/${isDemo ? "demo" : "app"}/profile/domains/`);
  };

  const handleSelect = ({ id, domain, useTls }) => {
    setIsLoadingData(true);
    setClientLinks(id);
    setPriorityDomainId(domain);
    setIsTls(useTls);
  };

  const checkRateInfo = () =>
    modemRateInfo?.restrictions.length > 0 &&
    modemRateInfo.restrictions.includes("CUSTOM_DOMAINS");

  // ** Effects
  useEffect(() => {
    const controller = new AbortController();
    if (!isDemo) {
      getApprovedDomains(controller.signal);
      getDefaultLinks(controller.signal);
      getModemRateInfo(controller.signal);
    } else {
      setIsLoadingData(false);
    }
    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      approvedDomains.length > 0 &&
      defaultRebootLink &&
      defaultStatusLink &&
      defaultIPLink
    ) {
      const approveDomainPrioritized = approvedDomains.find(
        (el) => el?.prioritized
      );
      const { domain, id, useTls } = approveDomainPrioritized
        ? approveDomainPrioritized
        : approvedDomains[0];
      setPriorityDomainId(domain);
      setClientLinks(id);
      setIsTls(useTls);
      if (resetLinksLoading) {
        SwalAct.fire({
          icon: "success",
          title: "Links is updated!",
          showConfirmButton: false,
          timer: 1500
        });
        setResetLinksLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvedDomains, toggleResetLinks]);

  useLockBackground(!!modalCommandLinksOpen);

  return (
    <div className="vertically-centered-modal">
      <Modal
        isOpen={modalCommandLinksOpen}
        className="modal-dialog-centered"
        toggle={toggle}
        key={`moadal-link-${selectedModemId}`}
      >
        <ModalHeader toggle={toggle}>{"Modem links"}</ModalHeader>
        <ModalBody>
          {isDemo ? (
            <Alert className="mb-1 mt-2" color="primary">
              <h4 className="alert-heading">
                This feature is only available in the full version.
              </h4>
            </Alert>
          ) : (
            <>
              {isLoadingData ? (
                <LoadingModemLinks />
              ) : (
                <>
                  <Row className=" mb-50 justify-content-around">
                    <div className="d-flex flex-wrap justify-content-around px-50">
                      <Button
                        className="d-flex align-items-center m-50"
                        color="primary"
                        onClick={handlerAdd}
                      >
                        <Plus className="me-50" size={14} />
                        Add new
                      </Button>
                      <Button
                        className="d-flex align-items-center m-50"
                        color="primary"
                        onClick={handlerOpen}
                      >
                        Open domains
                      </Button>
                      <Button
                        className="d-flex align-items-center m-50"
                        color="primary"
                        onClick={resetLinks}
                        disabled={resetLinksLoading}
                      >
                        Update links
                      </Button>
                    </div>
                  </Row>
                  {approvedDomains.length ? (
                    <div className="mb-1 ">
                      <Select
                        theme={selectThemeColors}
                        className="react-select"
                        classNamePrefix="select"
                        isDisabled={checkRateInfo()}
                        value={(() => {
                          if (checkRateInfo()) {
                            return approvedDomains[0];
                          }
                          if (approvedDomains.length > 0) {
                            const priorityDomain = approvedDomains.find(
                              (el) => el?.label === priorityDomainId
                            );

                            return priorityDomain
                              ? priorityDomain
                              : approvedDomains[0];
                          }

                          return {};
                        })()}
                        options={approvedDomains}
                        onChange={handleSelect}
                        isClearable={false}
                      />
                    </div>
                  ) : null}
                  {checkRateInfo() && (
                    <p>{`Управление доменом доступно в тарифе "Advanced"`}</p>
                  )}
                  <LinkDomain
                    title="Do reboot:"
                    domain={priorityDomainId}
                    link={rebootLink}
                    isTls={isTls}
                  />
                  <LinkDomain
                    title="Get status:"
                    domain={priorityDomainId}
                    link={statusLink}
                    isTls={isTls}
                  />
                  <LinkDomain
                    title=" Get IP:"
                    domain={priorityDomainId}
                    link={ipLink}
                    isTls={isTls}
                  />
                </>
              )}
            </>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};
