import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "./skeleton.styles";

export const LoadingHistoryIp = ({ rowNumber = 10 }) => {
  // ** Redux State
  const { skin } = useSelector((state) => state.theme);
  // ** Conctants
  const darkColors =
    skin === "dark" ? { baseColor: "#242b3d", highlightColor: "#343d55" } : {};

  return (
    <div className="d-flex flex-column w-100 loading-data-body">
      {new Array(rowNumber).fill(1).map((_, index) => (
        <div className="d-flex justify-content-around" key={index}>
          <Skeleton className="m-2" width={200} height={10} {...darkColors} />
          <Skeleton className="m-2" width={200} height={10} {...darkColors} />
        </div>
      ))}
    </div>
  );
};
