/* eslint-disable no-console */
import axios from "axios";
import { isSSR } from "@helpers";

const isServer = isSSR();

const reloadPage = (text) => {
  try {
    if (!isServer) {
      window.location.reload();
    }
  } catch (e) {
    console.log(e);
  }
};

axios.interceptors.response.use(
  (response) => {
    try {
      if (response.data.slice(0, 15) === "<!doctype html>") {
        // logout
        reloadPage("error: <!doctype html>");
        return null;
      } else {
        return response;
      }
    } catch (e) {
      return response;
    }
  },
  (error) => {
    if (error?.code === "ERR_CANCELED") {
      return error;
    }
    if (!error?.response) {
      reloadPage("error: no response");
      return null;
    }
    if (
      error?.response?.status === 401 &&
      error.config.url !== "/api/users/isAuthenticated" &&
      error.config.url !== "/api/login"
    ) {
      reloadPage("error: 401");
      return null;
    }
    if (typeof AbortController === "undefined") {
      return null;
    }
    return error;
  }
);
const $api = axios.create();

export class ApiSsrService {
  static getLendingContent(baseURL, params) {
    return $api.post(`${baseURL}/api/front`, params);
  }

  static getCustomMetaTags(baseURL) {
    return $api.get(`${baseURL}/api/metaTags`);
  }

  static getSiteSettingsSSR(baseURL) {
    return $api.get(`${baseURL}/api/front/settings`);
  }

  // static cacheSSR(id) {
  //   return $api.get(`/ssr/cache/${id}`);
  // }
}

export const apiService = {
  // ssr API
  ssr: {
    cache: (id) => axios.get(`/ssr/cache/${id}`),
  },
  // Auth API
  auth: {
    login: (params) => axios.post("/api/login", null, { params }),
    logout: () => axios.get("/api/logout"),
  },
  // Robots API
  robots: {
    get: () => axios.get("/api/robots"),
    adminGet: (signal) => axios.get("/api/admin/robots", { signal }),
    adminSetEnabled: (enable) =>
      axios.put(`/api/admin/robots/status?enabled=${enable}`),
    adminUpdate: (params) =>
      axios.post("/api/admin/robots", params, {
        headers: { "Content-Type": "text/plain" },
      }),
  },
  // Error Reports API
  errorReports: {
    adminGetAll: (params, signal) =>
      axios.get("/api/admin/front/error/report", { params, signal }),
    adminGetById: (id, signal) =>
      axios.get(`/api/admin/front/error/report/${id}`, { signal }),
  },
  // Client Domains API
  clientDomains: {
    getAll: () => axios.get("/api/clientDomains"),
    getById: (id, signal) => axios.get(`/api/clientDomains/${id}`, { signal }),
    getDomainLinks: (id, signal) =>
      axios.get(`/api/clientDomains/${id}/links`, { signal }),
    getApproved: (params, signal) =>
      axios.get("/api/clientDomains/byApproved", { params, signal }),
    addDomain: (params) => axios.post("/api/clientDomains", params),
    setPriorityById: (id) => axios.patch(`/api/clientDomains/${id}/prioritize`),
    updateDomain: (params) => axios.put("/api/clientDomains", params),
    approveById: (id) => axios.patch(`/api/clientDomains/${id}/approve`),
    orchestrateSave: (params) =>
      axios.put("/api/clientDomains/orchestratedSave", params),
    deleteById: (id) => axios.delete(`/api/clientDomains/${id}`),
  },
  // Client Domain Links API
  clientDomainLinks: {
    create: (params) => axios.post("/api/clientDomainLinks", params),
    deleteById: (id) => axios.delete(`/api/clientDomainLinks/${id}`),
  },
  // Setup API
  setup: {
    get: (params, signal) => axios.get("/api/setup", { params, signal }),
    adminGetAll: (signal) =>
      axios.get("/api/admin/setup/groupBy/locale", { signal }),
    adminGetById: (id) => axios.get(`/api/admin/setup/${id}`),
    adminCreate: (params) => axios.post("/api/admin/setup", params),
    adminUpdate: (params) => axios.put("/api/admin/setup", params),
    adminDeleteById: (id) => axios.delete(`/api/admin/setup/${id}`),
  },
  // Knowledge Base Collections API
  knowledgeBaseCollections: {
    getAll: (params, signal) =>
      axios.get("/api/knowledgeBaseCollections", { params, signal }),
    adminGetAll: (signal) =>
      axios.get("/api/admin/knowledgeBaseCollections/groupBy/locale", {
        signal,
      }),
    adminGetById: (id, signal) =>
      axios.get(`/api/admin/knowledgeBaseCollections/${id}`, { signal }),
    adminCreate: (params) =>
      axios.post("/api/admin/knowledgeBaseCollections", params),
    adminUpdate: (params) =>
      axios.put("/api/admin/knowledgeBaseCollections", params),
    adminDeleteById: (id) =>
      axios.delete(`/api/admin/knowledgeBaseCollections/${id}`),
    adminChangeIndex: ({ id, option }) =>
      axios.put(
        `/api/admin/knowledgeBaseCollections/${id}/index?operation=${option}`
      ),
  },
  // Knowledge Base Items API
  knowledgeBaseItems: {
    adminGetAll: (signal) =>
      axios.get("/api/admin/knowledgeBases/groupBy/locale", { signal }),
    adminGetById: (id, signal) =>
      axios.get(`/api/admin/knowledgeBases/${id}`, { signal }),
    adminCreate: (params) => axios.post("/api/admin/knowledgeBases", params),
    adminUpdate: (params) => axios.put("/api/admin/knowledgeBases", params),
    adminChangeIndex: ({ id, option }) =>
      axios.put(`/api/admin/knowledgeBases/${id}/index?operation=${option}`),
    adminDeleteById: (id) => axios.delete(`/api/admin/knowledgeBases/${id}`),
  },
  // Aggregators API
  aggregators: {
    getByGeo: (signal) => axios.get("/api/aggregators/by-geo", { signal }),
    getConfigById: (params, signal) =>
      axios.get("/api/aggregators/byId/cfg", { params, signal }),
    getStats: (params, signal) =>
      axios.get("/api/aggregator/stats", { params, signal }),
    adminGetAll: () => axios.get("/api/admin/aggregators/all"), //not used
    adminGetPaginated: (params, signal) =>
      axios.post("/api/admin/aggregators", params, { signal }),
    adminGetById: (params, signal) =>
      axios.get("/api/admin/aggregators/by-id", { params, signal }),
    adminCreate: (params) =>
      axios.post("/api/admin/aggregators/create", params),
    adminUpdate: (params) => axios.put("/api/admin/aggregators/save", params),
    adminGetToChange: (params) =>
      axios.get("/api/admin/aggregators/to-change", { params }),
    adminGetConfigVars: (signal) =>
      axios.get("/api/admin/aggregators/configVars", { signal }),
    adminUpdateConfigVars: (params) =>
      axios.put("/api/admin/aggregators/configVars", params),
  },
  // Aggregator Auth Tokens API
  aggregatorAuthTokens: {
    aggregatorAuthTokens: (params, signal) =>
      axios.get("/api/admin/aggregatorAuthTokens/by-user-id", {
        params,
        signal,
      }),
    adminGetPaginated: (params, signal) =>
      axios.post("/api/admin/aggregatorAuthTokens", params, { signal }),
    adminCreate: (params) =>
      axios.post("/api/admin/aggregatorAuthTokens/create", params),
    adminDeleteById: (params) =>
      axios.delete("/api/admin/aggregatorAuthTokens/by-id", { params }),
  },
  // Tariffs/Rates API
  tariffs: {
    frontGetAll: (signal) => axios.get("/api/front/rate/all", { signal }),
    adminGetAll: (signal) => axios.get("/api/admin/rate/all", { signal }),
    adminGetById: (params, signal) =>
      axios.get("/api/admin/rate/by-id", { params, signal }),
    adminCreate: (params) => axios.post("/api/admin/rate/create", params),
    adminUpdate: (params) => axios.put("/api/admin/rate/save", params),
    adminDeleteById: (params) =>
      axios.delete("/api/admin/rate/by-id", { params }),
  },
  // Tariff Translates API
  tariffTranslates: {
    adminGetByTariffId: (params, signal) =>
      axios.get("/api/admin/rateTranslate/byRateId", { params, signal }),
    adminUpdate: (params) => axios.put("/api/admin/rateTranslate", params),
  },
  // SSH Scripts API
  sshScripts: {
    adminGetPaginated: (params, signal) =>
      axios.post("/api/admin/ssh/scripts/page", params, { signal }),
    adminGetById: (id, signal) =>
      axios.get(`/api/admin/ssh/scripts/${id}`, { signal }),
    adminCreate: (params) => axios.post("/api/admin/ssh/scripts", params),
    adminUpdate: (params) => axios.put("/api/admin/ssh/scripts", params),
    adminDeleteById: (id) => axios.delete(`/api/admin/ssh/scripts/${id}`),
    adminRunScript: (id, params) =>
      axios.put(`/api/admin/ssh/scripts/${id}/run`, params),
    adminGetAllScriptCommands: (id, signal) =>
      axios.get(`/api/admin/ssh/scripts/${id}/commands`, { signal }),
  },
  // SSH Commands API
  sshCommands: {
    adminGetPaginated: (params, signal) =>
      axios.post("/api/admin/ssh/commands/page", params, { signal }),
    adminGetById: (id, signal) =>
      axios.get(`/api/admin/ssh/commands/${id}`, { signal }),
    adminCreate: (params) => axios.post("/api/admin/ssh/commands", params),
    adminUpdate: (params) => axios.put("/api/admin/ssh/commands", params),
    adminUpdateBatch: (params) =>
      axios.put("/api/admin/ssh/commands/batch", params),
    adminUpdateComandById: (id, params) =>
      axios.patch(`/api/admin/ssh/commands/${id}/command`, params),
    adminDeleteById: (id) => axios.delete(`/api/admin/ssh/commands/${id}`),
  },
  // SSH Runs API
  sshRuns: {
    adminGetPaginated: (params, signal) =>
      axios.post("/api/admin/ssh/runs/page", params, { signal }),
    adminGetLogsById: (id, signal) =>
      axios.get(`/api/admin/ssh/runs/${id}/logs`, { signal }),
  },
  // Hosts API
  hosts: {
    changeName: (params) => axios.post("/api/hosts/change/name", params),
    adminGetPaginated: (params, signal) =>
      axios.post("/api/admin/hosts", params, { signal }),
    adminGetById: (params, signal) =>
      axios.get("/api/admin/hosts/by-id", { params, signal }),
    adminUpdate: (params) => axios.put("/api/admin/hosts/save", params),
    adminMoveHostToAnotherAggregator: (params) =>
      axios.put("/api/admin/hosts/move-to-another-agr", null, { params }),
    adminAttachUserToHost: (params) =>
      axios.put("/api/admin/hosts/attach-user", null, { params }),
    adminSwitchHostStatus: (params) =>
      axios.put("/api/admin/hosts/switch-status", null, { params }),
    adminClearUsersAndModems: (params) =>
      axios.delete("/api/admin/hosts/clear", { params }),
  },
  // Host History API
  hostHistory: {
    adminGetPaginated: (params, signal) =>
      axios.post("/api/admin/hostHistory", params, { signal }),
    adminGetPaginatedArchive: (params, signal) =>
      axios.post("/api/admin/hostHistoryArchive", params, { signal }),
    adminRecoverModem: (id) =>
      axios.post(`/api/admin/hostHistory/${id}/recover`),
  },
  // Warehouses API
  warehouses: {
    adminGetAll: (signal) => axios.get("/api/admin/warehouses", { signal }),
    adminGetById: (id, signal) =>
      axios.get(`/api/admin/warehouses/${id}`, { signal }),
    adminCreate: (params) => axios.post("/api/admin/warehouses", params),
    adminUpdate: (params) => axios.put("/api/admin/warehouses", params),
    adminDeleteById: (id) => axios.delete(`/api/admin/warehouses/${id}`),
  },
  // Checkouts API
  checkouts: {
    getById: (id, signal) => axios.get(`/api/checkout/${id}`, { signal }),
    getByModemId: (params) =>
      axios.get("/api/checkout/modem/active/by-modem", { params }),
    create: (modemId) => axios.post("/api/checkout", { modemId }),
    update: (params) => axios.post("/api/checkout/modem/save", params),
    adminGetPaginated: (params, signal) =>
      axios.post("/api/admin/checkouts", params, { signal }),
    adminGetById: (params, signal) =>
      axios.get("/api/admin/checkouts/by-id/expanded", { params, signal }),
    adminUpdate: (params) => axios.put("/api/admin/checkouts/save", params),
    adminRefund: (params) =>
      axios.put("/api/admin/checkouts/refund", null, { params }),
  },
  // Checkout Modems API
  checkoutModems: {
    adminGetPaginated: (params, signal) =>
      axios.post("/api/admin/checkoutModems", params, { signal }),
    adminGetById: (params, signal) =>
      axios.get("/api/admin/checkoutModems/by-id", { params, signal }),
    adminInvalidateById: (params) =>
      axios.patch("/api/admin/checkoutModems/invalidate-by-id", null, {
        params,
      }),
  },
  // Modems API
  modems: {
    getById: (params) => axios.get("/api/modems/by-id", { params }),
    getIpHistory: (params) => axios.get("/api/modems/ip-history", { params }),
    getInfo: (params) => axios.get("/api/modems/info", { params }),
    getRateInfo: (params, signal) =>
      axios.get("/api/modems/rate-info", { params, signal }),
    getLinks: (params, signal) =>
      axios.get("/api/modems/links", { params, signal }),
    getStatus: (signal) => axios.get("/api/modem/modemsStatus", { signal }),
    getModemLimits: (params) => axios.post("/api/modem/limits", params),
    getSmsRead: (params, signal) =>
      axios.get("/api/modems/sms/read", { params, signal }),
    getExportFields: () => axios.get("/api/modems/permittedExportVars"),
    getResetLink: (params) => axios.get("/api/modems/reset/link", { params }),
    setLimits: (params) => axios.post("/api/modem/speedlimit", params),
    setNote: (note, id) =>
      axios.patch(`/api/modems/note?id=${id}`, { value: note }),
    setCustomSimNumber: (params) =>
      axios.patch("/api/modems/sim-number-custom", null, { params }),
    setUsbPort: (id, params) =>
      axios.patch(`/api/modems/usb-port?id=${id}`, params),
    synchronizeIp: (params) =>
      axios.put("/api/modems/sync-ip", null, { params }),
    refreshModem: (params) =>
      axios.put("/api/modems/refresh", null, { params }),
    activateModem: (params) =>
      axios.post("/api/modems/activate", null, { params }),
    export: (params) =>
      axios.post("/api/modems/export", params, { responseType: "blob" }),
    disableModem: (params) =>
      axios.post("/api/modems/disable", null, { params }),
    pauseModem: (params) => axios.post("/api/modems/pause", null, { params }),
    unpauseModem: (params) =>
      axios.post("/api/modems/unpause", null, { params }),
    changeRotationRate: (params) =>
      axios.post("/api/modems/rotation", null, { params }),
    updateModemById: (params) =>
      axios.post("/api/modems/update", null, { params }),
    updateAllByHostId: (params) =>
      axios.post("/api/modems/update/all", null, { params }),
    deleteById: (params) =>
      axios.delete("/api/modems/admin-delete", { params }),
    clearRate: (params) =>
      axios.put("/api/modems/clear-rate", null, { params }),
    processById: (params) => axios.post("/api/modems/process", params),
    deleteSms: (params) => axios.delete("/api/modems/sms/delete", { params }),
    sendSms: (params) => axios.post("/api/modems/sms/send", null, { params }),
  },
  // Modem Auth API
  modemAuth: {
    get: (params) => axios.get("/api/modemAuth", { params }), //not used
    getWithLimits: (params) => axios.get("/api/modemAuth/limits", { params }),
    addNewUser: (params) => axios.post("/api/modemAuth", params),
    updateUser: (params) => axios.post("/api/modemAuthSave", params),
    deleteById: (params) => axios.delete("/api/modemAuth", { params }),
  },
  // Modem History API
  modemHistory: {
    adminGetVars: (signal) =>
      axios.get("/api/scheduler/modemHistory/vars", { signal }),
    adminSetVars: (params) =>
      axios.put("/api/scheduler/modemHistory/vars", params),
    adminGetAllPaginated: (params, signal) =>
      axios.post("/api/admin/modemHistory", params, { signal }),
    adminGetAllArchivePaginated: (params, signal) =>
      axios.post("/api/admin/modemHistoryArchive", params, { signal }),
  },
  // Dongle Kits API
  dongleKits: {
    adminGetAll: (signal) => axios.get("/api/admin/dongleKits/all", { signal }),
    adminGetById: (id, signal) =>
      axios.get(`/api/admin/dongleKits/${id}`, { signal }),
    adminCreate: (params) => axios.post("/api/admin/dongleKits/create", params),
    adminUpdate: (params) => axios.put("/api/admin/dongleKits/save", params),
    adminDeleteById: (id) => axios.delete(`/api/admin/dongleKits/${id}`),
  },
  // Dongle Kit Translates API
  dongleKitTranslates: {
    adminGetByDongleKitId: (params, signal) =>
      axios.get("/api/admin/dongleKitTranslate/byDongleKit", {
        params,
        signal,
      }),
    adminUpdate: (params) => axios.put("/api/admin/dongleKitTranslate", params),
  },
  // Modems Admin API
  modemsAdmin: {
    adminGetAllPaginated: (params, signal) =>
      axios.post("/api/admin/modems", params, { signal }),
    adminGetById: (params, signal) =>
      axios.get("/api/admin/modems/by-id", { params, signal }),
    adminGetInfo: (params, signal) =>
      axios.get("/api/admin/modems/info", { params, signal }),
    adminUpdate: (params) => axios.put("/api/admin/modems/save", params),
    adminInitModems: (params) =>
      axios.post("/api/admin/modems/init-modems", null, { params }),
    adminSetTariffByModemId: (params) =>
      axios.post("/api/admin/modems/buyRate", params),
    adminSetTariffByHostIdForAllModems: (params) =>
      axios.post("/api/admin/modems/buyRateToHost", params),
  },
  // Language Content API
  languageContent: {
    getAgreements: (locale) =>
      axios.get("/api/languageContent/footer/byLocale", { params: { locale } }),
    adminGetAll: () => axios.get("/api/admin/languageContent"),
    adminUpdate: (id, type, params) =>
      axios.put(`/api/admin/languageContent/${id}/${type}`, params),
  },
  // Main Page Get API
  mainPageGet: {
    adminGetAll: (id, signal) =>
      axios.get(`/api/admin/mainPageGet/byLanguageContent/${id}`, { signal }),
    adminGetById: (id, signal) =>
      axios.get(`/api/admin/mainPageGet/${id}`, { signal }),
    adminCreate: (params) => axios.post("/api/admin/mainPageGet", params),
    adminUpdate: (params) => axios.put("/api/admin/mainPageGet", params),
    adminDeleteById: (id) => axios.delete(`/api/admin/mainPageGet/${id}`),
  },
  // Main Page Work API
  mainPageWork: {
    adminGetAll: (id, signal) =>
      axios.get(`/api/admin/mainPageWork/byLanguageContent/${id}`, { signal }),
    adminGetById: (id, signal) =>
      axios.get(`/api/admin/mainPageWork/${id}`, { signal }),
    adminCreate: (params) => axios.post("/api/admin/mainPageWork", params),
    adminUpdate: (params) => axios.put("/api/admin/mainPageWork", params),
    adminDeleteById: (id) => axios.delete(`/api/admin/mainPageWork/${id}`),
  },
  // Proxy Limits API
  proxyLimits: {
    getUserLimits: (ownerId, modemId) =>
      axios.get(`/api/proxyLimits/owner/${ownerId}?modemId=${modemId}`),
    updateLimits: (params) => axios.post("/api/proxyLimits", params), // not used
    updateLimitsBatch: (params) => axios.post("/api/proxyLimits/batch", params),
  },
  // Proxy Servers API
  proxyServers: {
    adminGetAllPaginated: (params, signal) =>
      axios.post("/api/admin/proxyServers", params, { signal }),
    adminGetById: (id, signal) =>
      axios.get(`/api/admin/proxyServers/${id}`, { signal }),
    adminCreate: (params) =>
      axios.post("/api/admin/proxyServers/create", params),
    adminUpdate: (params) => axios.put("/api/admin/proxyServers/save", params),
    adminDeleteById: (id) => axios.delete(`/api/admin/proxyServers/${id}`),
  },
  // Payment Systems API
  paymentSystems: {
    getAll: (params, signal) =>
      axios.get("/api/paymentSystems/all", { params, signal }),
    adminGetAllPaginated: (params, signal) =>
      axios.post("/api/admin/paymentSystems", params, { signal }),
    adminChangeIndex: ({ id, option }) =>
      axios.put(`/api/admin/paymentSystems/${id}/index?operation=${option}`),
    adminGetById: (id, signal) =>
      axios.get(`/api/admin/paymentSystems/${id}`, { signal }),
    adminCreate: (params) =>
      axios.post("/api/admin/paymentSystems/create", params),
    adminUpdate: (params) =>
      axios.put("/api/admin/paymentSystems/save", params),
    adminDeleteById: (id) => axios.delete(`/api/admin/paymentSystems/${id}`),
  },
  // Admin Payments API
  adminPayments: {
    adminGetAllPaginated: (params, signal) =>
      axios.post("/api/admin/payments", params, { signal }),
    adminGetById: (params, signal) =>
      axios.get("/api/admin/payments/by-id", { params, signal }),
    adminConfirm: (params) =>
      axios.post("/api/admin/payments/confirm", null, { params }),
  },
  // User Notifications Settings API
  userNotificationsSettings: {
    get: (signal) => axios.get("/api/settings/notifications", { signal }),
    update: (params) => axios.post("/api/settings/notifications", params),
  },
  // Notifications API
  notifications: {
    get: (signal) => axios.get("/api/notifications/user", { signal }),
    markRead: (params) => axios.post("/api/notifications/mark/read", params),
    sendTestEmail: (params) =>
      axios.post("/api/notifications/bareEmailNotification", params),
  },
  // Notifications Templates API
  notificationTemplates: {
    getAll: (signal) => axios.get("/api/notificationTemplates", { signal }),
    getById: (params, signal) =>
      axios.get("/api/notificationTemplates/byId", { params, signal }),
    getPreview: (params) =>
      axios.post("/api/notificationTemplates/preview", params),
    create: (params) => axios.post("/api/notificationTemplates", params),
    update: (params) => axios.put("/api/notificationTemplates", params),
    deleteById: (params) =>
      axios.delete("/api/notificationTemplates/byId", { params }),
  },
  // Orders API
  orders: {
    create: (params) => axios.post("/api/orders", params),
    adminGetAllPaginated: (params, signal) =>
      axios.post("/api/admin/orders/pageable", params, { signal }),
    adminGetById: (id, signal) =>
      axios.get(`/api/admin/orders/${id}`, { signal }),
    adminUpdate: (params) => axios.put("/api/admin/orders", params),
    adminDeleteById: (id) => axios.delete(`/api/admin/orders/${id}`),
  },
  // Geo Autocomplete API
  geoAutocomplete: {
    getLocation: (signal) =>
      axios.get("/api/geoAutocomplete/currentLocation", { signal }),
    getStates: (params, signal) =>
      axios.get("/api/geoAutocomplete/manual/states", { params, signal }),
    getCity: (params) =>
      axios.get("/api/geoAutocomplete/manual/city", { params }),
  },
  // Users API
  users: {
    isAuth: () => axios.get("/api/users/isAuthenticated"),
    register: (params) => axios.post("/api/users/register", params),
    emailConfirm: (params) => axios.get("/api/users/email/config", { params }),
    checkDefault: (signal) => axios.get("/api/users/adminUser", { signal }),
    createDefault: (params) =>
      axios.post("/api/users/createDefaultUser", params),
    recoveryPass: (email) => {
      const headers = { "Content-Type": "text/html; charset=UTF-8" };
      return axios.post("/api/users/passwordRecovery", email, { headers });
    },
    recoveryPassHash: (params) =>
      axios.post("/api/users/passwordRecovery/update", params),
    changePass: (params) => axios.post("/api/users/changePassword", params),
    changeName: (params) => axios.post("/api/users/changeName", params),
    getToken: (signal) => axios.get("/api/users/auth-token", { signal }),
    updateToken: (params) =>
      axios.post("/api/users/set-auth-token", null, { params }),
    updateTokenByGeo: () => axios.post("/api/users/set-auth-token-by-geo"),
    deleteToken: () => axios.delete("/api/users/delete-auth-token"),
    getProfile: () => axios.get("/api/users/profile"),
    updateProfileInterface: (params, signal) =>
      axios.post("/api/users/locale-theme", params, { signal }),
    updateProfile: (params) => axios.post("/api/users/profile", params),
    resendAuthMail: (params) => axios.post("/api/users/refresh-token", params), // not used
    resendMailConfirmation: () =>
      axios.post("/api/users/resendEmailConfirmation"),
    getOrdersPaginated: (params, signal) =>
      axios.post("/api/users/orders", params, { signal }),
    getPaymentHistoryPaginated: (userId, params, signal) =>
      axios.post(`/api/users/${userId}/paymentHistory`, params, { signal }),
    getBillingHistoryPaginated: (params, signal) =>
      axios.get("/api/users/billing-history/pageable", { params, signal }),
    adminGetModemsHistory: (body, params, signal) =>
      axios.post("/api/admin/users/history", body, { params, signal }),
    deleteBillingHistory: (params) =>
      axios.delete("/api/users/billing-history/rm", { params }),
    getHosts: (signal) => axios.get("/api/users/hosts", { signal }),
    deleteHost: (params) => axios.delete("/api/users/host/delete", { params }),
    getModems: (params, signal) =>
      axios.get("/api/users/modems", { params, signal }),
    finishedTour: (value) =>
      axios.patch(`/api/users/tour-completed?value=${value}`),
    updateBalance: (params) =>
      axios.post("/api/users/updateUserBalance", null, { params }),
    updateUser: (params) => axios.post("/api/users/save", params),
    adminGetAllPaginated: (params, signal) =>
      axios.post("/api/admin/users", params, { signal }),
    adminGetEntity: (params, signal) =>
      axios.get("/api/admin/users/by-id/expanded", { params, signal }),
    //user api
    getApiKey: (signal) => axios.get("/api/users/clientAuth", { signal }),
    updateApiKey: (params) => axios.post("/api/users/clientAuth", params),
    updateApiKeyIP: (params) =>
      axios.put("/api/users/clientAuth/ip", null, { params }),
  },
  // Tickets API
  tickets: {
    createFromForm: (params) => axios.post("/api/tickets/contactForm", params),
    createFromRequest: (params) =>
      axios.post("/api/tickets/connectRequest", params),
    adminGetAllPaginated: (params, signal) =>
      axios.post("/api/admin/tickets/pageable", params, { signal }),
    adminGetById: (id, signal) =>
      axios.get(`/api/admin/tickets/${id}`, { signal }),
    adminUpdate: (id, status) =>
      axios.patch(`/api/admin/tickets/${id}/status`, null, {
        params: { status },
      }),
  },
  // Countries API
  countries: {
    getAll: (signal) => axios.get("/api/countries", { signal }),
    update: (params) => axios.post("/api/countries/save", params),
    getEntity: (params) => axios.get("/api/countries/by-id", { params }),
  },
  // Billing Addresses API
  billingAddresses: {
    update: (params) => axios.put("/api/billingAddresses", params),
    adminGetByUserId: (params, signal) =>
      axios.get("/api/admin/billingAddress/byUser", { params, signal }),
    adminUpdate: (params) => axios.put("/api/admin/billingAddress", params),
  },
  // Meta Tags API
  metaTags: {
    adminGetHeader: (signal) =>
      axios.get("/api/admin/headerMetaTag", { signal }),
    adminGetBody: (signal) => axios.get("/api/admin/bodyMetaTag", { signal }),
    adminUpdateHeader: (params) =>
      axios.post("/api/admin/headerMetaTag", params),
    adminUpdateBody: (params) => axios.post("/api/admin/bodyMetaTag", params),
  },
  // User Change Email API
  changeEmail: {
    create: (params) => axios.post("/api/userMailChangeTokens", params),
    confirm: (token) => axios.get(`/api/userMailChangeTokens/confirm/${token}`),
    rollback: (token) =>
      axios.get(`/api/userMailChangeTokens/rollback/${token}`),
  },
  // Payments API
  payments: {
    getBaseHost: () => axios.get("/api/payments/baseHost"),
    getWebmoneyWalletId: (params) =>
      axios.get("/api/payments/webmoney/wallet", { params }),
    create: (params) => axios.post("/api/payments", params),
  },
  // Front API
  front: {
    getLendingContent: (params) => axios.post("/api/front", params),
    getSiteSettings: () => axios.get("/api/front/settings"),
  },
  // Shipments API
  shipments: {
    getRates: (params, signal) =>
      axios.post("/api/shipments/fetchRates", params, { signal }),
    adminGetRates: (params) =>
      axios.post("/api/admin/shipments/fetchRates", params),
  },
  // Telegram API
  telegram: {
    subscribe: () => axios.get("/api/telegram/subscribe"),
  },
  // Tax API
  tax: {
    calculate: (params, signal) =>
      axios.get("/api/tax/calculate", { params, signal }),
  },
  // Language API
  languages: {
    getAll: (signal) => axios.get("/api/languages", { signal }),
  },
  // Modem Traffic API
  modemTraffic: {
    reset: (params) => axios.post("/api/agr/tgr/modem/traffic/reset", params),
  },
  // Modem Reports API
  modemReports: {
    create: (params) => axios.post("/api/modemReports", params),
    adminGetAllPaginated: (params, signal) =>
      axios.post("/api/admin/modemReports", params, { signal }),
    adminGetCountOfNEW: (signal) =>
      axios.get("/api/admin/modemReports/count", { signal }),
    adminGetById: (id, signal) =>
      axios.get(`/api/admin/modemReports/${id}`, { signal }),
    adminUpdate: (params) => axios.put("/api/admin/modemReports", params),
  },
  // reCaptcha settings API
  reCaptcha: {
    adminGet: () => axios.get("/api/admin/captcha"),
    adminUpdate: (params) => axios.put("/api/admin/captcha", params),
  },
  // public API link
  publicAPI: {
    adminGet: () => axios.get("/api/settings/apiLink"),
    adminUpdate: (params) => axios.post("/api/admin/settings/apiLink", params),
  },
};
