export const BINDINGS = [
  {
    label: "AGGREGATOR",
    value: "AGGREGATOR"
  },
  {
    label: "CHECKOUTMODEM",
    value: "CHECKOUTMODEM"
  },
  {
    label: "HOST",
    value: "HOST"
  }
];
