import { AlertCircle } from "react-feather";
import { FormProvider, useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Alert
} from "reactstrap";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";

// ** Services
import { apiService } from "@services/ApiService";
// ** Custom Hooks & Utils
import { useLockBackground, useDispatchedActions } from "@hooks";
// ** Components
import { FormInput, Avatar } from "@components/app";
import { toast } from "react-toastify";

export const ModalReport = () => {
  // ** Hooks
  const { isDemo } = useOutletContext();
  const methods = useForm();
  // ** Dispatched Actions
  const { setModalReportOpen } = useDispatchedActions();
  // ** Redux state

  const { modalReportOpen, selectedModemsInfo } = useSelector(
    (state) => state.modals
  );
  const {
    hostModems: { activeHostId }
  } = useSelector((state) => state.content);

  // ** Functions
  const handlerCloseModal = () => setModalReportOpen("");

  const handleSave = (data) => {
    const params = {
      hostId: activeHostId,
      modemIds: selectedModemsInfo.map((modem) => modem.id),
      description: data.description,
      locale: "en"
    };
    apiService.modemReports
      .create(params)
      .then((res) => {
        if (res.status === 200 && res.data) {
          toast.success(`Report ${res.data.number} sent!`);
          setModalReportOpen(false);
        } else {
          toast.error("Error sending report!");
        }
      })
      .catch(() => {
        toast.error("Error sending report!");
      });
  };

  // ** Effects
  useLockBackground(!!modalReportOpen);

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={!!modalReportOpen}
      toggle={handlerCloseModal}
    >
      <ModalHeader className="d-flex" toggle={handlerCloseModal}>
        <Avatar
          color="light-warning"
          className="me-1"
          icon={<AlertCircle size={32} />}
        />
        Bug report to the manager
      </ModalHeader>
      {!isDemo ? (
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(handleSave)}>
            <ModalBody>
              <Alert color="warning">
                <h4 className="alert-heading">
                  Please describe the problem you encountered with the modem.
                </h4>
              </Alert>
              <FormGroup noMargin className="m-0">
                <Label for="description">Description:</Label>
                <FormInput
                  type="textarea"
                  rows={5}
                  name="description"
                  required
                  placeholder="Enter description"
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button outline color="success" type="submit">
                Send
              </Button>
            </ModalFooter>
          </Form>
        </FormProvider>
      ) : (
        <ModalBody>
          <Alert className="mb-1 mt-2" color="warning">
            <h4 className="alert-heading">
              Report is not available in demo mode
            </h4>
          </Alert>
        </ModalBody>
      )}
    </Modal>
  );
};
