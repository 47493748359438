import { Navigate } from "react-router-dom";

export const AdminHostsRoutes = [
  {
    path: "host/",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminHosts } = await import("@views/app/admin/host/");
          return {
            Component: AdminHosts
          };
        }
      },
      {
        path: "history/*",
        children: [
          {
            index: true,
            async lazy() {
              const { AdminHostsHistory } = await import(
                "@views/app/admin/host/history"
              );
              return {
                Component: AdminHostsHistory
              };
            }
          },
          {
            path: "archive/",
            async lazy() {
              const { AdminHostsHistory } = await import(
                "@views/app/admin/host/history"
              );
              return {
                Component: AdminHostsHistory
              };
            }
          },
          {
            path: "*",
            element: <Navigate to="./" replace={true} />
          }
        ]
      },
      {
        path: ":id/edit/",
        async lazy() {
          const { AdminHostEdit } = await import("@views/app/admin/host/edit");
          return {
            Component: AdminHostEdit
          };
        }
      },
      {
        path: ":id/edit/country/",
        async lazy() {
          const { AdminHostEditCountry } = await import(
            "@views/app/admin/host/edit/country"
          );
          return {
            Component: AdminHostEditCountry
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
