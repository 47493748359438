import { HelpCircle } from "react-feather";
import { Badge } from "reactstrap";
import { Tooltip } from "react-tooltip";
// ** Custom Hooks & Utils
import { fakeDataShow } from "@helpers";
// ** Constants
import { MODEM_TARIFF_COLORS } from "@constants";

const ModemRow = ({ row, admin }) => {
  // ** Functions
  const handlerClickToBadge = (e) => {
    e.preventDefault();
  };
  return (
    <div
      className={
        row?.paid || admin ? "overflow-hidden " : "unselectable blur-box"
      }
      style={{
        textOverflow: "ellipsis"
      }}
    >
      <div className="mb-50 text-wrap">
        <span className="fw-bolder me-50">
          № {fakeDataShow(row?.number, !row?.paid && !admin)}
        </span>
        {fakeDataShow(row?.model, !row?.paid && !admin)}
      </div>
      <Badge
        color={
          MODEM_TARIFF_COLORS.hasOwnProperty(
            row?.tariffInfo?.rateName.toUpperCase()
          )
            ? MODEM_TARIFF_COLORS[row?.tariffInfo?.rateName.toUpperCase()]
            : MODEM_TARIFF_COLORS.NO_DATA
        }
        href={row?.paid ? "#" : ""}
        onClick={handlerClickToBadge}
      >
        {row?.tariffInfo?.rateName || "no tariff"}
      </Badge>
    </div>
  );
};

export const staticModem = (admin = false) => {
  const name = () => (
    <div className="d-flex align-items-center">
      <div className="me-50">MODEM</div>
      <HelpCircle
        data-tooltip-id="modem-btn"
        size={20}
        className="outline-none"
      />
      <Tooltip
        id="modem-btn"
        style={{ zIndex: 9999 }}
        positionStrategy="fixed"
        place="bottom"
      >
        <div className="d-flex flex-column py-50">
          <span className="me-auto mb-50">
            <Badge color={MODEM_TARIFF_COLORS.BASIC} className="me-50">
              Basic
            </Badge>
            {"- basic tariff"}
          </span>
          <span className="me-auto mb-50">
            <Badge color={MODEM_TARIFF_COLORS.ADVANCED} className="me-50">
              Advanced
            </Badge>
            {"- advanced tariff"}
          </span>
          <span className="me-auto">
            <Badge color={MODEM_TARIFF_COLORS.NO_DATA} className="me-50">
              No tariff
            </Badge>
            {"- no tariff"}
          </span>
        </div>
      </Tooltip>
    </div>
  );

  return {
    id: "number",
    name: name(),
    // selector: "number",
    minWidth: "140px",
    key: "numder",
    style: {
      whiteSpace: "nowrap"
    },
    cell: (row) => <ModemRow row={row} admin={admin} />
  };
};
