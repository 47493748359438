import { ArrowLeft } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

export const GoBack = ({ text, url, clearFunk, ...rest }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    clearFunk?.();
    if (!url) {
      return navigate(-1);
    }
    if (url.includes("?")) {
      return navigate(url);
    }
    const checkEnd = url.endsWith("/");
    const checkSearch = sessionStorage.getItem(
      `searchParams-${url}${checkEnd ? "" : "/"}`
    );
    if (checkSearch) {
      sessionStorage.removeItem(`searchParams-${url}${checkEnd ? "" : "/"}`);
      return navigate(`${url}${checkEnd ? "" : "/"}${checkSearch}`);
    }
    // navigate(url ? url : -1);
    navigate(url);
  };

  return (
    <Button color="primary" {...rest} onClick={handleClick}>
      <ArrowLeft size={14} className="me-1" />
      {text ? text : "Go back"}
    </Button>
  );
};
