import { Button } from "@components/landing/ui";
import { useTranslation } from "@hooks";

export const TAB_KEYS = {
  TARIFF: "tariffs",
  DONGLE: "dongleKits"
};

export const Tabs = ({ activeTab, tabsHandler }) => {
  // *Hooks
  const { mainPage: { use } } = useTranslation();

  return (
    <ul className="landing-use__tabs">
      <li
        className={`landing-use__tab ${activeTab === TAB_KEYS.TARIFF ? "landing-use__tab--active" : ""}`}
      >
        <Button onClick={() => tabsHandler(TAB_KEYS.TARIFF)}>
          {use?.tariffs?.tab}
        </Button>
      </li>
      <li
        className={`landing-use__tab ${activeTab === TAB_KEYS.DONGLE ? "landing-use__tab--active" : ""}`}
      >
        <Button onClick={() => tabsHandler(TAB_KEYS.DONGLE)}>
          {use?.dongleKits?.tab}
        </Button>
      </li>
    </ul>
  );
};
