// eslint-disable-next-line import/named
import { Container } from "@components/landing/ui";
import { FooterBottom } from "./components/footerBottom/footerBottom";
import { FooterTop } from "./components/footerTop/footerTop";

import "./footer.scss";

export const Footer = () => (
  <footer className="landing-footer">
    <Container>
      <FooterTop />
    </Container>
    <Container>
      <FooterBottom />
    </Container>
  </footer>
);
