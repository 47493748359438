import { useState, useEffect } from "react";
import { AlertCircle } from "react-feather";
import {
  Button,
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Alert,
  Form
} from "reactstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FormProvider, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";

// ** Custom Hooks & Utils
import { currentLimitsValue, renderAccentNames } from "@helpers";
import { useDispatchedActions, useLockBackground } from "@hooks";
// ** Services
import { apiService } from "@services/ApiService";
// ** Components
import { BlurContent } from "@components/app";
import { LimitsFormFields } from "./components/LimitsFormFields";

export const ModalModemLimits = () => {
  // ** Dispatched Actions
  const { setModalModemLimitsOpen, setUpdateModems } = useDispatchedActions();
  // ** Hooks
  const { isDemo } = useOutletContext();
  const methods = useForm({
    defaultValues: { type: "MB", notification: false }
  });
  const formData = methods.watch();
  // ** Redux state
  const { selectedModemsInfo, modalModemLimitsOpen } = useSelector(
    (state) => state.modals
  );
  const { activeHostId } = useSelector((state) => state.content.hostModems);
  const { content } = useSelector((state) => state.demo);

  // ** Function to check modems
  const checkModems = (modems) => {
    const tempData = {
      ids: [],
      alowed: [],
      forbidden: []
    };
    modems.forEach((modem) => {
      tempData.ids.push(modem.id);
      if (modem.tariffInfo.authorization) {
        tempData.alowed.push(modem.number);
      } else {
        tempData.forbidden.push(modem.number);
      }
    });
    return tempData;
  };

  // ** State
  const [checkedModems] = useState(checkModems(selectedModemsInfo));
  const [modemsLimits, setModemsLimits] = useState([]);
  const [localLimits, setLocalLimits] = useState(null);
  const [userlogins, setUserLogins] = useState([]);
  const [demoUpdate, setDemoUpdate] = useState(false);

  // ** Functions
  const toggle = () => setModalModemLimitsOpen(!modalModemLimitsOpen);

  const getModemLimits = () => {
    if (!isDemo) {
      apiService.modems
        .getModemLimits({ modemId: checkedModems.ids })
        .then((req) => {
          if (req?.data) {
            setModemsLimits(req.data);
          }
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.log(error));
    } else {
      // demo logik start
      const activeHostModems = content.modems[activeHostId];
      const tempModems = activeHostModems.filter((item) =>
        checkedModems.ids.includes(item.id)
      );
      const tempLimits = tempModems.map((item) => item.limits);
      setModemsLimits(tempLimits);
      setDemoUpdate(true);
      // demo logik end
    }
  };

  const handlerSave = (data) => {
    const currentData = {
      speedLimit: currentLimitsValue(data.speed, data.speedCustom, "MB"),
      trafficLimit: currentLimitsValue(
        data.traffic,
        data.trafficCustom,
        data.type
      ),
      outOfLimitNotifications: data.notification
    };
    if (!isDemo) {
      const params = {
        modemIds: checkedModems.ids,
        ...currentData
      };
      apiService.modems.setLimits(params).then((req) => {
        if (req.request.status === 200) {
          toast.success("Successfully saved limit!");
          getModemLimits();
          setModalModemLimitsOpen(false);
        } else {
          toast.error("Error occured during saving.");
        }
      });
    } else {
      // demo logik start
      const activeHostModems = content.modems[activeHostId];
      const newModems = {
        ...content.modems,
        [activeHostId]: activeHostModems.map((item) => {
          if (checkedModems.ids.includes(item.id)) {
            const tempModemAuths = item.limits.modemAuths.map((auth) => ({
              ...auth,
              speedLimit: 0,
              trafficLimit: 0
            }));
            return {
              ...item,
              lastUpdate: new Date().getTime(),
              limits: {
                ...item.limits,
                ...currentData,
                logins: [],
                modemAuths: tempModemAuths,
                isModemLimitPresent: true
              }
            };
          } else return item;
        })
      };
      setUpdateModems(newModems);
      toast.success("Successfully saved limit!");
      setModalModemLimitsOpen(false);
      // demo logik end
    }
  };

  const blurContent = () => (
    <div className="text-center">
      To use additional features, you need to upgrade tariff
    </div>
  );

  // ** Effects
  useEffect(() => {
    getModemLimits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (modemsLimits.length === 1) {
      setLocalLimits(modemsLimits[0]);
    }
    const users = modemsLimits.reduce(
      (result, item) => [...result, ...item.logins],
      []
    );
    setUserLogins(users);
  }, [modemsLimits]);

  // demo logick update users after change
  useEffect(() => {
    if (isDemo && demoUpdate) {
      getModemLimits();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.modems]);
  // end demo

  useLockBackground(!!modalModemLimitsOpen);

  return (
    <Modal
      isOpen={modalModemLimitsOpen}
      className="modal-dialog-centered"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        <AlertCircle className="me-50" color="#ffb708" />
        Set limit
      </ModalHeader>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(handlerSave)}>
          <BlurContent
            blur={checkedModems?.alowed?.length === 0}
            content={blurContent}
          >
            <ModalBody>
              {modemsLimits?.length > 0 && userlogins.length > 0 && (
                <Alert className="mb-1 p-1 font-weight-bold" color="danger">
                  If you save limits to these modems, then will be delete limits
                  of next users:
                  {renderAccentNames(userlogins)}
                </Alert>
              )}

              <LimitsFormFields limits={localLimits} type={formData.type} />

              {checkedModems?.alowed?.length !== 0 &&
                checkedModems?.forbidden?.length !== 0 && (
                  <Alert color="warning" className="mt-1">
                    <div className="d-flex alert-body">
                      Limits cannot be added to the modems:
                      {renderAccentNames(checkedModems.forbidden)}
                    </div>
                  </Alert>
                )}
            </ModalBody>
          </BlurContent>
          <ModalFooter>
            <Button outline color="danger" onClick={toggle}>
              Cancel
            </Button>
            <Button outline color="success" type="submit">
              Save
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};
