import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IconSvg } from "@constants";

export const AppLogo = () => {
  // ** Redux State
  const { lang } = useSelector((state) => state.settings);

  return (
    <Link
      className="brand-logo d-flex align-items-center p-0 "
      to={`/${lang}/`}
    >
      {/* <img
        src="/Favicon-app-120px.svg"
        loading="lazy"
        alt={"logo"}
        width="42"
        height="42"
      /> */}
      <IconSvg tag="appLogo" className="me-1" width="42" height="42" />
      <h2 className="brand-text mb-0">PROXYSELLER</h2>
    </Link>
  );
};
