import { useState } from "react";
import { AlertCircle } from "react-feather";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import {
  Button,
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Row,
  Col
} from "reactstrap";
// ** Custom Hooks & Utils
import { useDispatchedActions, useLockBackground } from "@hooks";

// ** Constants
const colourOptions = [
  { value: "", label: "none" },
  { value: "5", label: "5 min" },
  { value: "10", label: "10 min" },
  { value: "20", label: "20 min" },
  { value: "30", label: "30 min" },
  { value: "45", label: "45 min" },
  { value: "60", label: "1 hour" },
  { value: "240", label: "4 hours" }
];

export const ModalRotation = () => {
  // ** Hooks
  const { isDemo } = useOutletContext();
  // ** Dispatched Actions
  const { setModalRotationOpen, fetchChangeRotation } = useDispatchedActions();
  // ** Redux state
  const { modalRotationOpen, selectedModemId } = useSelector(
    (state) => state.modals
  );
  // ** State
  const [checkedInput, setCheckedInput] = useState();
  const [chosenValue, setChosenValue] = useState("");

  // ** Functions
  const toggle = () => setModalRotationOpen(!modalRotationOpen);

  const onCheckedHandler = (e) => {
    if (e.target.type === "text") {
      e.target.value = e.target.value.replace(/[^0-9]/g, "");
      setChosenValue(e.target.value);

      if (checkedInput) {
        checkedInput.checked = false;
      }
    }
  };

  const saveHandler = () => {
    const params = { modemId: selectedModemId, rotation: chosenValue };
    fetchChangeRotation({ params, isDemo });
  };

  const onClickHandler = (e) => {
    setChosenValue(e);
    setCheckedInput(e);
  };

  // ** Effects
  useLockBackground(!!modalRotationOpen);

  return (
    <Modal
      isOpen={modalRotationOpen}
      className="modal-dialog-centered"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        <AlertCircle className="me-50" color="#ffb708" />
        Set time
      </ModalHeader>
      <ModalBody>
        <Row>
          {colourOptions.map(({ value, label }) => (
            <Col sm={3} md={3} lg={3} className={"p-50 col-6"} key={value}>
              <Button
                id={value}
                color="primary"
                className="w-100"
                outline={!(value === checkedInput)}
                onClick={() => onClickHandler(value)}
              >
                {label}
              </Button>
            </Col>
          ))}
          <Col sm={3} md={3} lg={3} className="col-6 p-50">
            <input
              className="form-control text-primary border-primary py-50 px-1"
              type="text"
              placeholder="Interval"
              name="rotation"
              onChange={(e) => onCheckedHandler(e)}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button outline color="danger" onClick={toggle}>
          Cancel
        </Button>
        <Button outline color="success" onClick={saveHandler}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
