import { useEffect, useState } from "react";
import { Badge } from "reactstrap";
import { useSelector } from "react-redux";
import { useSubscription } from "react-stomp-hooks";
import { useLoaderData } from "react-router-dom";

// ** Custom Hooks & Helpers
import {
  useDispatchedActions,
  useTranslation,
  useCheckDomain,
  useCurrentProfileData
} from "@hooks";
// import { useLoaderData } from "react-router-dom";

export const ModemUsageInfo = () => {
  // ** Dispatched Actions
  const { fetchModemUsageInfo } = useDispatchedActions();
  // ** Hooks
  const { isDemo } = useLoaderData();

  const { app } = useTranslation();
  const show = useCheckDomain(["localhost", "stage"]);
  // ** Redux state
  const { userProfile, content } = useCurrentProfileData(isDemo);
  const {
    modemUsageInfo: { data }
  } = useSelector((state) => state.navBar);

  // ** State
  const [trigerLoad, setTrigerLoad] = useState(true);

  // ** Functions
  const handlerTriger = () => setTrigerLoad((state) => !state);

  // ** Subscriptions
  useSubscription(
    isDemo ? [] : `/user/${userProfile?.data?.id}/modemUsage`,
    handlerTriger
  );

  // ** Effects
  useEffect(() => {
    const promise = fetchModemUsageInfo(isDemo);
    return () => promise?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigerLoad]);

  // demo logick update users after change
  useEffect(() => {
    if (isDemo) {
      handlerTriger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content?.modems]);
  // end demo

  return (
    <div className="d-block">
      {userProfile?.data?.isSuperUser && show ? (
        <>
          <h5 className="text-center mb-50">{app?.header?.statistics}:</h5>
          <Badge color="light-success" className="me-50">
            {app?.header?.on}: {data?.onlineModemsCount}
          </Badge>
          <Badge color="light-danger">
            {app?.header?.off}: {data?.offlineModemsCount}
          </Badge>
        </>
      ) : (
        <>
          <h5 className="text-center mb-50">Statistics:</h5>
          <Badge color="light-success" className="me-50">
            On: {data?.onlineModemsCount}
          </Badge>
          <Badge color="light-danger">Off: {data?.offlineModemsCount}</Badge>
        </>
      )}
    </div>
  );
};
