export const NOTIFICATION_TYPES = {
  SUCCESSFUL_MODEM_PAYMENT_RATE: [
    "$balance",
    "$modemList",
    "$checkoutLink",
    "$userName",
    "$totalAmount",
    "$modemCount",
    "$gotMoney"
  ],
  SUCCESSFUL_MODEM_PAYMENT_MONEY_USED_BALANCE: [
    "$balance",
    "$modemList",
    "$checkoutLink",
    "$userName",
    "$totalAmount",
    "$modemCount",
    "$usedBalance",
    "$paymentSystem"
  ],
  SUCCESSFUL_MODEM_PAYMENT_MONEY: [
    "$balance",
    "$modemList",
    "$checkoutLink",
    "$userName",
    "$totalAmount",
    "$modemCount",
    "$paymentSystem"
  ],
  SUCCESSFUL_MODEM_PAYMENT_BALANCE: [
    "$balance",
    "$modemList",
    "$checkoutLink",
    "$userName",
    "$totalAmount",
    "$modemCount",
    "$usedBalance"
  ],
  SUCCESSFUL_BALANCE_REPLENISHMENT: [
    "$balance",
    "$userName",
    "$amount",
    "$paymentSystem"
  ],
  SUCCESSFUL_BILLING: ["$totalAmount", "$hostName", "$modemList"],
  SUCCESSFUL_PAUSE: ["$hostName", "$modemNumber"],
  SUCCESSFUL_UNPAUSE: ["$hostName", "$modemNumber"],
  UNSUCCESSFUL_BILLING: ["$balance", "$modemNumber", "$hostName"],
  UNSUCCESSFUL_PAYMENT: [],
  UNSUCCESSFUL_PAUSE: ["$hostName", "$modemNumber"],
  UNSUCCESSFUL_UNPAUSE: ["$hostName", "$modemNumber"],
  DOWNLOAD_TRAFFIC_OUT_OF_LIMIT: ["$modemNumber", "$limit", "$lastDownload"],
  DOWNLOAD_TRAFFIC_RESET: ["$modemNumber", "$limit", "$lastDownload"],
  UPLOAD_TRAFFIC_OUT_OF_LIMIT: ["$modemNumber", "$limit", "$lastUpload"],
  UPLOAD_TRAFFIC_RESET: ["$modemNumber", "$limit", "$lastUpload"],
  REMINDER_WEEK: ["$modemNumber", "$hostName", "$modemEndDate"],
  REMINDER_THREE_DAYS: ["$modemNumber", "$hostName", "$modemEndDate"],
  REMINDER_ONE_DAY: ["$modemNumber", "$hostName", "$modemEndDate"],

  TEST_SINGLE_BOTH_TABLE: [
    "$num",
    "$word",
    "$modemNumber",
    "$modemAmount",
    "$hostName",
    "$hostAmount"
  ],
  TEST_SINGLE_TG_TABLE: [
    "$num",
    "$modemNumber",
    "$modemAmount",
    "$hostName",
    "$hostAmount"
  ],
  TEST_DOUBLE_TG_TABLE: [
    "$num",
    "$modemNumber",
    "$modemAmount",
    "$hostName",
    "$hostAmount"
  ],
  TEST_TRIPLE_TG_TABLE: [
    "$num",
    "$modemNumber",
    "$modemAmount",
    "$hostName",
    "$hostAmount"
  ],
  TEST_SINGLE_TABLE: ["$num", "$word"],
  TEST_DOUBLE_TABLE: ["$num", "$word"],
  TEST_TRIPLE_TABLE: ["$num", "$word"],

  EMAIL_CONFIRMATION: ["$name", "$url"],
  EMAIL_CHANGE_CONFIRMATION: ["$name", "$email", "$confirmationUrl"],
  EMAIL_CHANGE_OLD_EMAIL_ROLLBACK: ["$name", "$newEmail", "$rollbackUrl"],
  EMAIL_CHANGE_SUCCESSFUL_CHANGE: ["$name", "$newEmail"],
  PASSWORD_RECOVERY: ["$url"],
  SUCCESSFUL_ORDER_INVOICE: ["$firstName", "$lastName", "$countryName", "$state", "$city", "$postcode", "$totalPrice", "$dongleKitDescription", "$dongleKitPrice", "$orderNumber"],
  SUCCESSFUL_TICKET_CREATED: ["$name", "$number"],
  ADMIN_SUCCESSFUL_TICKET_CREATED: ["$userEmail", "$userName", "$ticketNumber", "$ticketText"],

  EASYPOST_TRACKER_CREATED: ["$status", "$trackingCode", "$statusDetail", "$estDeliveryDate", "$carrier"],
  EASYPOST_TRACKER_UPDATED: ["$status", "$trackingCode", "$statusDetail", "$estDeliveryDate", "$carrier", "$state", "$city"]
};
