import { isSSR } from "./isSSR.helper";

export const startCalendly = () => {
  if (isSSR()) return;
  if (!window.Calendly) return;
  window.Calendly.initBadgeWidget({
    url: "https://calendly.com/mobile-panel/30min",
    text: "Schedule time with me",
    color: "#4bc776",
    textColor: "#ffffff",
    branding: false
  });
};

export const removeCalendly = () => {
  if (isSSR()) return;
  if (window.Calendly) window.Calendly.destroyBadgeWidget();
};

export const loadCalendarScript = () => {
  if (isSSR()) {
    return;
  }
  const scriptId = "calendly-widget";
  let script = document.querySelector(`#${scriptId}`);

  if (script) {
    return startCalendly();
  }

  script = document.createElement("script");
  script.id = scriptId;
  script.type = "text/javascript";
  script.src = "https://assets.calendly.com/assets/external/widget.js";
  script.setAttribute("async", "true");
  // listen for load event and execute callback
  script.addEventListener("load", startCalendly);
  return document.body.append(script);
};
