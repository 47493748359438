import { useEffect, useState } from "react";
import { XCircle } from "react-feather";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { useOutletContext } from "react-router-dom";

// ** Custom Hooks & Utils
import { useDispatchedActions } from "@hooks";
// ** Services
import { apiService } from "@services/ApiService";
// ** Components
import { NoDataComponent, LoadingDataTableTwo } from "@components/app";

export const TableSms = ({ smsSource }) => {
  // ** Hooks
  const { isDemo } = useOutletContext();
  // ** Dispatched Actions
  const { setUpdateModems } = useDispatchedActions();
  // ** Redux state
  const { selectedModemId } = useSelector((state) => state.modals);
  const { activeHostId } = useSelector((state) => state.content.hostModems);
  const { content } = useSelector((state) => state.demo);

  // ** State
  const [smsList, setSmsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [demoUpdate, setDemoUpdate] = useState(false);

  const readSms = (smsSource, signal) => {
    setLoading(true);
    if (!isDemo) {
      if (selectedModemId && smsSource) {
        const params = { modemId: selectedModemId, smsSource };
        apiService.modems
          .getSmsRead(params, signal)
          .then((req) => {
            if (req.status === 200 && req.data) {
              setSmsList(req.data?.length ? req.data : []);
            }
          })
          .finally(() => setLoading(false));
      }
    } else {
      // demo logik start
      const activeHostModems = content.modems[activeHostId];
      const tempModem = activeHostModems.find(
        (item) => selectedModemId === item.id
      );
      setSmsList(tempModem?.sms?.[smsSource] || []);
      setLoading(false);
      setDemoUpdate(true);
      // demo logik end
    }
  };

  const smsDelete = (smsIndex) => {
    if (!isDemo) {
      const params = { modemId: selectedModemId, smsIndex };
      apiService.modems.deleteSms(params).then(() => {
        readSms(selectedModemId, smsSource);
        toast.success(`SMS ${smsIndex} is deleted!`);
      });
    } else {
      // demo logik start
      const activeHostModems = content.modems[activeHostId];
      const newModems = {
        ...content.modems,
        [activeHostId]: activeHostModems.map((item) => {
          if (selectedModemId === item.id) {
            const tempSms = item.sms[smsSource];
            return {
              ...item,
              sms: {
                ...item.sms,
                [smsSource]: tempSms.filter((sms) => sms.index !== smsIndex)
              }
            };
          } else return item;
        })
      };
      setUpdateModems(newModems);
      toast.success(`SMS ${smsIndex} is deleted!`);
      // demo logik end
    }
  };

  // ** Constants
  const columns = [
    {
      name: "Message Index",
      // selector: "index"
      cell: (row) => row?.index
    },
    {
      name: "Sender`s Number",
      // selector: "phone"
      cell: (row) => row?.phone
    },
    {
      name: "Message Date",
      cell: (row) =>
        isDemo ? (
          <Moment format="YYYY/MM/DD hh:mm:ss">{row?.date}</Moment>
        ) : (
          row?.date
        )
    },
    {
      name: "Message Content",
      minWidth: "300px",
      cell: (row) => (
        <p style={{ overflow: "auto", maxHeight: "100px" }}>{row?.sms}</p>
      )
    },
    {
      name: "Remove",
      cell: (row) =>
        row?.index ? (
          <button
            className="icon-wrapper actions-none"
            onClick={() => smsDelete(row?.index)}
          >
            <XCircle />
          </button>
        ) : null
    }
  ];

  // ** Effects
  useEffect(() => {
    const controller = new AbortController();
    if (demoUpdate) {
      setDemoUpdate(false);
    }
    readSms(smsSource, controller.signal);
    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smsSource]);

  // demo logick update users after change
  useEffect(() => {
    if (isDemo && demoUpdate) {
      readSms(smsSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.modems]);
  // end demo

  return (
    <DataTable
      className="react-dataTable"
      noHeader
      data={smsList?.length ? smsList : []}
      columns={columns}
      noDataComponent={<NoDataComponent />}
      progressComponent={<LoadingDataTableTwo rowNumber={5} />}
      progressPending={loading}
    />
  );
};
