import { useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Button } from "@components/landing/ui";
import { useCurrentSeo } from "@hooks";
import { WorkModal } from "./workModal";
import { ContentList } from "./contentList";

export const WorkContents = ({ activeTab }) => {
  // *Hooks
  const { work } = useCurrentSeo();

  // *State
  const [showModal, setShowModal] = useState(false);

  // *Handlers
  const modalHandler = () => setShowModal(!showModal);

  return (
    <ul className="landing-work__contents">
      {work?.length ? work.map((contentItem, index) => (
        <li
          key={index}
          className={`landing-work__content ${activeTab === index ? "landing-work__content--active" : ""}`}
        >
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={showModal}
              timeout={300}
              mountOnEnter
              unmountOnExit
              classNames="landing-work__content-inner"
            >
              <>
                <div
                  className={!showModal ? "landing-work__content-wrapper" : "landing-hidden"}
                >
                  <ContentList content={contentItem?.items} />
                  <Button onClick={modalHandler}>{contentItem?.btn}</Button>
                </div>
                <div className={showModal ? "landing-work__modal" : "landing-hidden"}>
                  <WorkModal
                    close={modalHandler}
                    info={contentItem?.modal}
                  />
                </div>
              </>
            </CSSTransition>
          </SwitchTransition>
        </li>
      )) : null}
    </ul>
  );
};
