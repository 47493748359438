import { Navigate } from "react-router-dom";

export const AdminCheckoutsRoutes = [
  {
    path: "checkout/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminCheckouts } = await import("@views/app/admin/checkout");
          return {
            Component: AdminCheckouts
          };
        }
      },
      {
        path: ":checkoutId/edit/",
        async lazy() {
          const { AdminCheckoutEdit } = await import(
            "@views/app/admin/checkout/edit"
          );
          return {
            Component: AdminCheckoutEdit
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
