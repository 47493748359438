/* eslint-disable import/named */
import { Button } from "@components/landing/ui";
import { useTranslation, useDispatchedActions } from "@hooks";

import Animation1 from "@assets/animations/gif-1.gif";
import Animation2 from "@assets/animations/gif-2.gif";
import Animation3 from "@assets/animations/gif-3.gif";
import Animation4 from "@assets/animations/gif-4.gif";
import Animation5 from "@assets/animations/gif-5.gif";
import { useEffect, useState } from "react";

const Animation = {
  "gif-1": Animation1,
  "gif-2": Animation2,
  "gif-3": Animation3,
  "gif-4": Animation4,
  "gif-5": Animation5
};

export const SlideContent = ({ info }) => {
  // **Props
  const { title, text, image } = info;

  // **Hooks
  const {
    mainPage: { get }
  } = useTranslation();
  const { setOpenModalFeatureSet } = useDispatchedActions();
  // ** local state
  const [showImage, setShowImage] = useState(false);
  // **Handlers
  const handlerOpenModalFeatureSet = () => setOpenModalFeatureSet(true);

  useEffect(() => {
    setShowImage(true);
  }, []);

  return (
    <>
      <div className="landing-get__item-info">
        <p className="landing-get__item-title">{title}</p>
        <p className="section__text">{text}</p>
        <Button
          className="d-none d-md-block"
          onClick={handlerOpenModalFeatureSet}
        >
          {get?.btn}
        </Button>
      </div>
      <div className="landing-get__item-img">
        <img
          src={showImage ? Animation[image] : ""}
          width="571"
          height="412"
          alt={"gif"}
          loading="lazy"
        />

        <Button className="d-md-none" onClick={handlerOpenModalFeatureSet}>
          {get?.btn}
        </Button>
      </div>
    </>
  );
};
