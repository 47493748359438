import { Navigate } from "react-router-dom";

export const DemoRoutes = [
  {
    path: "home/",
    async lazy() {
      const { Home } = await import("@views/app/Home/Home");
      return {
        Component: Home
      };
    }
  },
  {
    path: "knowledge-base/",
    async lazy() {
      const { KnowledgeBase } = await import(
        "@views/app/KnowledgeBase/KnowledgeBase"
      );
      return {
        Component: KnowledgeBase
      };
    }
  },
  {
    path: "checkout/:id",
    async lazy() {
      const { CheckOut } = await import("@views/app/CheckOut/CheckOut");
      return {
        Component: CheckOut
      };
    }
  },
  {
    path: "profile/:tab/",
    async lazy() {
      const { AccountSettings } = await import("@views/app/account-settings");
      return {
        Component: AccountSettings
      };
    }
  },
  {
    path: "profile/:tab/:id",
    async lazy() {
      const { AccountSettings } = await import("@views/app/account-settings");
      return {
        Component: AccountSettings
      };
    }
  },
  {
    path: "setup/",
    async lazy() {
      const { Setup } = await import("@views/app/Setup/Setup");
      return {
        Component: Setup
      };
    }
  },
  {
    path: "*", // redirect to home
    element: <Navigate to="./home/" replace={true} />
  }
];
