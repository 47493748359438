import { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import { Inbox, Box, Mail } from "react-feather";
import { useSelector } from "react-redux";
// ** Custom Hooks & Utils
import { useDispatchedActions, useLockBackground } from "@hooks";
// ** Components
import { TableSms } from "./components/TableSms";

const DataSorce = [
  { id: "inbox", name: "Inbox", icon: <Inbox /> },
  { id: "outbox", name: "Outbox", icon: <Box /> },
  { id: "draft", name: "Draft", icon: <Mail /> }
];

export const ModalReadSms = () => {
  // ** Dispatched Actions
  const { setModalReadSmsOpen } = useDispatchedActions();
  // ** Redux state
  const { modalReadSmsOpen } = useSelector((state) => state.modals);

  // ** State
  const [smsSource, setSmsSource] = useState("inbox");

  // ** Functions
  const toggle = (event) => {
    const { id } = event.currentTarget;
    if (smsSource !== id) {
      setSmsSource(id);
    }
  };

  const close = () => setModalReadSmsOpen(false);

  useLockBackground(!!modalReadSmsOpen);

  return (
    <div className="vertically-centered-modal">
      <Modal
        isOpen={modalReadSmsOpen}
        toggle={close}
        size={"xl"}
        className={"modal-dialog-centered mx-auto"}
      >
        <ModalHeader toggle={close}>Messages</ModalHeader>
        <ModalBody>
          <Nav tabs fill>
            {DataSorce.map((item) => (
              <NavItem key={item?.id}>
                <NavLink
                  active={smsSource === item?.id}
                  id={item?.id}
                  onClick={toggle}
                >
                  {item?.icon}
                  <span className="align-middle">{item?.name}</span>
                </NavLink>
              </NavItem>
            ))}
          </Nav>
         <TableSms smsSource={smsSource} />
        </ModalBody>
      </Modal>
    </div>
  );
};
