export const SPEED_LIMITS = [
    { key: "speed1", value: 1024 * 1024 * 5 },
    { key: "speed2", value: 1024 * 1024 * 50 },
    { key: "speed3", value: 1024 * 1024 * 100 },
    { key: "speed4", value: 1024 * 1024 * 200 }
  ];

export const TRAFFIC_LIMITS = [
    { key: "traffic1", value: 1024 * 1024 * 1024 },
    { key: "traffic2", value: 1024 * 1024 * 1024 * 2 },
    { key: "traffic3", value: 1024 * 1024 * 1024 * 3 },
    { key: "traffic4", value: 1024 * 1024 * 1024 * 4 }
  ];
