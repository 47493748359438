import { Col, Row } from "reactstrap";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
// ** Custom Hooks & Utils
import { currentLimitsShow } from "@helpers";
// ** Components
import { FormCheckBox, FormInput, FormRadio } from "@components/app";
// ** Constants
import { SPEED_LIMITS, TRAFFIC_LIMITS } from "@constants";

export const LimitsFormFields = ({ limits, type }) => {
  // ** Hooks
  const { setValue, getValues } = useFormContext();
  // ** State
  const [localType, setLocalType] = useState("MB");

  // ** Functions
  const setLimit = (limit, type, size) => {
    if (limit > 0) {
      const LIMITS = type === "speed" ? SPEED_LIMITS : TRAFFIC_LIMITS;
      if (LIMITS.find((speed) => speed.value === limit)) {
        setValue(type, limit);
      } else {
        const value = currentLimitsShow(limit, size);
        const current = value % 1 === 0 ? value : value.toFixed(2);
        setValue(`${type}Custom`, current);
      }
    }
  };

  // ** Effects
  useEffect(() => {
    setLimit(limits?.speedLimit, "speed", "MB");
    setLimit(limits?.trafficLimit, "traffic", type);

    if (limits?.outOfLimitNotifications) {
      setValue("notification", limits?.outOfLimitNotifications);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limits]);

  useEffect(() => {
    if (type !== localType) {
      setLocalType(type);
      const trafficCustom = getValues("trafficCustom");
      if (+trafficCustom > 0) {
        const value =
          type === "MB" ? +trafficCustom * 1024 : +trafficCustom / 1024;
        setValue("trafficCustom", value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <>
      <h5 className="mb-1">Select traffic measurement</h5>
      <FormRadio
        id="MB"
        name="type"
        label="MB"
        initValue="MB"
        className="mb-50"
      />
      <FormRadio id="GB" name="type" label="GB" initValue="GB" />

      <Row className="pt-1">
        <Col sm="6" className=" border-right-primary">
          <h5 className="mb-1 ">Speed limit</h5>

          {SPEED_LIMITS.map((item) => (
            <FormRadio
              className="mb-50"
              id={item.key}
              key={item.key}
              name="speed"
              clearOnEvent="speedCustom"
              label={`${currentLimitsShow(item.value, "MB")} ${"MB"}`}
              initValue={item.value}
            />
          ))}
          <FormInput
            placeholder="Custom value"
            name="speedCustom"
            clearOnEvent="speed"
            type="number"
            min={0}
            messageOff
          />
        </Col>
        <Col sm="6" className=" border-left-primary">
          <h5 className="mb-1">Traffic limit</h5>
          {TRAFFIC_LIMITS.map((item) => (
            <FormRadio
              className="mb-50"
              id={item.key}
              key={item.key}
              name="traffic"
              clearOnEvent="trafficCustom"
              label={`${currentLimitsShow(item.value, type)} ${type}`}
              initValue={item.value}
            />
          ))}
          <FormInput
            className="mb-1"
            placeholder="Custom value"
            name="trafficCustom"
            clearOnEvent="traffic"
            type="number"
            min={0}
            messageOff
          />
          <FormCheckBox
            name="notification"
            label="Notify if the limit is used up"
          />
        </Col>
      </Row>
    </>
  );
};
