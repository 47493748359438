import { useEffect, useState } from "react";

export const useCheckDomain = (names = []) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const { hostname, host } = window.location;
    if (!hostname || !host) return;

    if (!names.length) {
      setShow(true);
      return;
    }

    const name = names.find(
      (name) => hostname.includes(name) || host.includes(name)
    );

    setShow(!!name);
  }, [names]);

  return show;
};
