import { X } from "react-feather";
import { Button, Col, FormGroup, InputGroup, Label } from "reactstrap";

export const FieldContainer = ({ label, removeId, action, children }) => {
  // ** Function
  const handleRemoveValue = (event) => {
    const { id } = event?.currentTarget;
    if (!id) return;
    action?.([`${id.replace("remove-", "")}`]);
  };
  return (
    <FormGroup noMargin tag={Col} lg="4" md="6" className="mb-1">
      <Label for={removeId}>{label}:</Label>
      <InputGroup className="flex-nowrap">
        {children}

        <Button
          id={`remove-${removeId}`}
          color="danger"
          outline
          className="py-0"
          onClick={handleRemoveValue}
        >
          <X size={20} />
        </Button>
      </InputGroup>
    </FormGroup>
  );
};
