import { Navigate } from "react-router-dom";

export const AdminAggregatorRoutes = [
  {
    path: "aggregator/",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminAggregators } = await import(
            "@views/app/admin/aggregator/"
          );
          return {
            Component: AdminAggregators
          };
        }
      },
      {
        path: "settings/",
        async lazy() {
          const { AdminAggregatorSettings } = await import(
            "@views/app/admin/aggregator/vars"
          );
          return {
            Component: AdminAggregatorSettings
          };
        }
      },
      {
        path: "add/",
        async lazy() {
          const { AdminAggregatorAddEdit } = await import(
            "@views/app/admin/aggregator/AddEdit"
          );
          return {
            Component: AdminAggregatorAddEdit
          };
        }
      },
      {
        path: ":id/config/",
        async lazy() {
          const { AdminAggregatorConfig } = await import(
            "@views/app/admin/aggregator/config"
          );
          return {
            Component: AdminAggregatorConfig
          };
        }
      },
      {
        path: ":id/edit/",
        async lazy() {
          const { AdminAggregatorAddEdit } = await import(
            "@views/app/admin/aggregator/AddEdit"
          );
          return {
            Component: AdminAggregatorAddEdit
          };
        }
      },
      {
        path: "*", // This route should be at the end of the list
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
