import { Navigate } from "react-router-dom";
import { AdminLayout } from "../../layouts";
import { AdminAggregatorRoutes } from "./aggregator.routes";
import { AdminHostsRoutes } from "./hosts.routes";
import { AdminModemsRoutes } from "./modems.routes";
import { AdminTicketsRoutes } from "./tickets.routes";
import { AdminTariffsRoutes } from "./tariffs.routes";
import { AdminDongleKitsRoutes } from "./dongle-kit.routes";
import { AdminCheckoutsRoutes } from "./checkouts.routes";
import { AdminCheckoutModemsRoutes } from "./checkoutModems.routes";
import { AdminPaymentSystemsRoutes } from "./paymentSystems.routes";
import { AdminPaymentsRoutes } from "./payments.routes";
import { AdminUsersRoutes } from "./users.routes";
import { AdminNotificationTemplatesRoutes } from "./notificationTemplates.routes";
import { AdminAuthTokensRoutes } from "./authTokens.routes";
import { AdminProxyServersRoutes } from "./proxyServers.routes";
import { AdminCommandsRoutes } from "./commands.routes";
import { AdminScriptsRoutes } from "./scripts.routes";
import { AdminSetupRoutes } from "./setup.routes";
import { AdminKnowledgeBaseRoutes } from "./knowledgeBase.routes";
import { AdminLandingContentRoutes } from "./landingContent.routes";
import { AdminOrdersRoutes } from "./orders.routes";
import { AdminWarehouseRoutes } from "./warehouse.route";
import { AdminRobotsRoutes } from "./robots.routes";
import { AdminCustomMetaTagRoutes } from "./customMetaTag.routes";
import { AdminErrorReportRoutes } from "./errorReport.router";
import { AdminErrorReportsRoutes } from "./reports.routes";
import { AdminReCaptchaRoutes } from "./reCaptcha.routes";
import { AdminPublicApiLinkRoutes } from "./publicApiLink.routes";

export const AdminRoutesNew = [
  {
    path: "admin/*",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        async lazy() {
          const { AdminPage } = await import("@views/app/admin/main");
          return {
            Component: AdminPage
          };
        }
      },
      ...AdminAggregatorRoutes,
      ...AdminHostsRoutes,
      ...AdminModemsRoutes,
      ...AdminTicketsRoutes,
      ...AdminTariffsRoutes,
      ...AdminDongleKitsRoutes,
      ...AdminCheckoutsRoutes,
      ...AdminCheckoutModemsRoutes,
      ...AdminPaymentSystemsRoutes,
      ...AdminPaymentsRoutes,
      ...AdminUsersRoutes,
      ...AdminNotificationTemplatesRoutes,
      ...AdminAuthTokensRoutes,
      ...AdminProxyServersRoutes,
      ...AdminScriptsRoutes,
      ...AdminCommandsRoutes,
      ...AdminSetupRoutes,
      ...AdminKnowledgeBaseRoutes,
      ...AdminLandingContentRoutes,
      ...AdminOrdersRoutes,
      ...AdminWarehouseRoutes,
      ...AdminRobotsRoutes,
      ...AdminCustomMetaTagRoutes,
      ...AdminErrorReportRoutes,
      ...AdminErrorReportsRoutes,
      ...AdminReCaptchaRoutes,
      ...AdminPublicApiLinkRoutes,
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
