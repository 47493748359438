import { createSlice } from "@reduxjs/toolkit";

import { en, ru } from "@constants";

const initialState = {
  translation: {
    en,
    ru
  },
  langs: {
    data: [
      {
        alpha2: "RU",
        code: "ru",
        internationalName: "Russian",
        name: "Russian",
        originalName: "Russian"
      },
      {
        alpha2: "EN",
        code: "en",
        internationalName: "English",
        name: "English",
        originalName: "English"
      }
    ],
    isDataLoaded: false,
    isLoading: false,
    isFetchError: null
  },
  nearest: ["az", "hy", "be", "kk", "ky", "ru", "tg", "uz", "uk"]
};

const TranslationReducer = createSlice({
  name: "translation",
  initialState,
  reducers: {}
});

export const TranslationReducerActions = {
  ...TranslationReducer.actions
};

export default TranslationReducer.reducer;
