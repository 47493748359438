import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  CardTitle,
  CardText,
  Form,
  FormGroup,
  Label,
  Button
} from "reactstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
// ** Services
import { apiService } from "@services/ApiService";
// ** Custom Hooks & Utils
import { useDispatchedActions } from "@hooks";
import { clearBrowserCache } from "@helpers";
// ** Components
import {
  AppLogo,
  FormInput,
  FormPassword,
  FormCheckBox
} from "@components/app";
import { MetaDate } from "@components/Metadate";
import source from "@assets/images/pages/login-img.svg";

export const Login = () => {
  // ** Dispatched Actions
  const { fetchUserLogin, clearContentState, clearUserProfileError } =
    useDispatchedActions();
  // ** Hooks
  const methods = useForm({
    defaultValues: { username: "", password: "", "remember-me": false }
  });
  const { login, password } = methods.watch();
  const navigate = useNavigate();
  // ** Redux state
  const { auth, userProfile } = useSelector((state) => state.auth);
  // ** Local state
  const [isDefaultUserCreated, setIsDefaultUserCreated] = useState(true);

  // ** Functions
  const formSubmit = (data) => {
    if (isDefaultUserCreated) {
      fetchUserLogin(data);
    } else {
      apiService.users.createDefault(data).then(() => {
        setIsDefaultUserCreated(false);
      });
    }
  };

  // ** Effects
  useEffect(() => {
    if (localStorage.getItem("app_build") !== process.env.REACT_APP_GH_BUILD) {
      clearBrowserCache();
      localStorage.setItem("app_build", process.env.REACT_APP_GH_BUILD);
    }
    const controller = new AbortController();
    if (!auth?.isAuth) {
      apiService.users.checkDefault(controller.signal).then((req) => {
        setIsDefaultUserCreated(!!req?.data);
      });
    }
    const close = (event) => {
      if (event.keyCode === 13 && login && password) {
        methods.handleSubmit(formSubmit)();
      }
    };
    window.addEventListener("keydown", close);
    return () => {
      window.removeEventListener("keydown", close);
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auth?.isAuth) {
      clearContentState();
      navigate("/app/home/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isAuth]);

  useEffect(() => {
    if (userProfile?.isLoaded && userProfile?.fetchError) {
      methods.setError("username", {
        type: "manual",
        message: "Wrong login or password!"
      });
      methods.setError("password", {
        type: "manual",
        message: "Wrong login or password!"
      });
      clearUserProfileError();
      setTimeout(() => methods.clearErrors(), 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile?.isLoaded]);

  if (!auth?.isLoaded || auth?.isAuth) {
    return null;
  }

  return (
    <>
      <MetaDate app={true} />
      <div className="auth-wrapper auth-v2">
        <Row className="auth-inner m-0 p-0">
          <AppLogo />
          <Col
            className="d-none d-lg-flex align-items-center p-5"
            lg="8"
            sm="12"
          >
            <div className="w-100 d-lg-flex align-items-center justify-content-center px-5">
              <img className="img-fluid" src={source} alt="Login V2" />
            </div>
          </Col>
          <Col
            className="d-flex align-items-center auth-bg px-2 p-lg-5"
            lg="4"
            sm="12"
          >
            <Col className="px-xl-2 mx-auto" sm="8" md="6" lg="12">
              <CardTitle tag="h2" className="mb-1">
                Welcome! 👋
              </CardTitle>
              <CardText className="mb-2">
                Please sign-in to your account
              </CardText>
              <FormProvider {...methods}>
                <Form
                  className="auth-login-form"
                  onSubmit={methods.handleSubmit(formSubmit)}
                  noValidate
                >
                  <FormGroup noMargin className="mb-1">
                    <Label className="form-label" for="login-email">
                      Email
                    </Label>
                    <FormInput
                      type="email"
                      name="username"
                      placeholder="email@example.com"
                      messageOff
                      required
                      autoFocus
                      tabIndex={1}
                    />
                  </FormGroup>
                  <FormGroup noMargin className="mb-1">
                    <Label className="w-100 d-flex" for="login-password">
                      Password
                      <Link
                        className="ms-auto"
                        to="/app/auth/recovery/"
                        tabIndex={2}
                      >
                        Forgot Password?
                      </Link>
                    </Label>
                    <FormPassword
                      name="password"
                      placeholder="Password"
                      required
                      // autoFocus
                      tabIndex={1}
                    />
                  </FormGroup>
                  <FormCheckBox
                    name="remember-me"
                    label="Remember me"
                    tabIndex={1}
                  />
                  <Button color="primary" block type="submit" tabIndex={1}>
                    {isDefaultUserCreated ? "Sign in" : "Create user"}
                  </Button>
                </Form>
              </FormProvider>
              <CardText className="text-center mt-2 mb-1">
                New on our platform?
                <Link
                  className="ms-50"
                  to="/app/auth/registration"
                  tabIndex={1}
                >
                  Create an account
                </Link>
              </CardText>
            </Col>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Login;
