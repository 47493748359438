import { useState } from "react";
import { Button, Input } from "reactstrap";
import { Edit, UserX } from "react-feather";
import { Tooltip } from "react-tooltip";
// ** Custom Hooks & Utils
import { currentLimitsShow } from "@helpers";
// ** Constants
import { IconSvg } from "@constants";
// ** Components
import { CustomCopy } from "@components/app";
import { EditUser } from "./components/EditUser";

export const UsersListItem = (props) => {
  // ** Props
  const {
    user: { id, authType, login, password, ip, speedLimit, trafficLimit },
    selectedUsers,
    amountUsers,
    refreshUsers,
    setLimits,
    deleteUser,
    onSelectRow
  } = props;
  // ** State
  const [edit, setEdit] = useState(false);

  // ** Functions
  const handleChange = (e) => {
    const { checked } = e.target;
    onSelectRow(checked, id);
  };

  const correctLimits = (limit, speed) => {
    const typeShow = +limit / (1024 * 1024) >= 1024 ? "GB" : "MB";
    const value = currentLimitsShow(limit, typeShow);
    if (!value || value <= 0) return "unset";
    if (value % 1 === 0) {
      return `${value} ${typeShow === "GB" ? "gb" : "mb"}${speed ? "/s" : ""}`;
    } else if (value % 0.1 === 0) {
      return `${value.toFixed(1)} ${typeShow === "GB" ? "gb" : "mb"}${
        speed ? "/s" : ""
      }`;
    } else {
      return `${value.toFixed(2)} ${typeShow === "GB" ? "gb" : "mb"}${
        speed ? "/s" : ""
      }`;
    }
  };

  const currentLimits = () => {
    if (+speedLimit === 0 && +trafficLimit === 0) {
      return "No Limits";
    }
    return `max. ${correctLimits(speedLimit, true)}, max. ${correctLimits(
      trafficLimit
    )}`;
  };

  if (edit) {
    return (
      <EditUser
        user={props.user}
        refreshUsers={refreshUsers}
        setEdit={setEdit}
      />
    );
  }

  return (
    <div className="d-flex w-100 align-items-center py-75 border-bottom">
      <div className="ps-1 pe-75">
        <Input
          type="checkbox"
          id={`MUL_CH_BOX_${id}`}
          checked={selectedUsers.includes(id)}
          onChange={handleChange}
        />
      </div>
      {authType === "LOGIN" && (
        <>
          <small
            className="overflow-hidden px-50"
            style={{ minWidth: "100px" }}
          >
            {login}
          </small>
          <small
            className="overflow-hidden px-50"
            style={{ minWidth: "125px" }}
          >
            {password}
          </small>
        </>
      )}
      {authType === "IP" && (
        <small className="overflow-hidden  px-50" style={{ minWidth: "225px" }}>
          {ip}
        </small>
      )}
      <small className="overflow-hidden  px-50" style={{ minWidth: "170px" }}>
        {currentLimits()}
      </small>
      <div
        className="d-flex flex-wrap justify-content-end ms-auto me-25"
        style={{ minWidth: "95px" }}
      >
        <Button
          id={id}
          data-tooltip-id={`tooltip-${id}`}
          data-tooltip-content="Set user speed and traffic limits"
          color="flat-secondary"
          className="btn-icon p-25 border-0"
          onClick={setLimits}
        >
          <IconSvg tag="speedLimit" />
        </Button>
        <Button
          id={`Edit-${id}`}
          data-tooltip-id={`tooltip-${id}`}
          data-tooltip-content="Edit user auth params"
          color="flat-secondary "
          className="btn-icon p-25 border-0"
          onClick={() => setEdit(true)}
        >
          <Edit size={14} />
        </Button>
        <Button
          id={`Copy-${id}`}
          data-tooltip-id={`tooltip-${id}`}
          data-tooltip-content="Copy user auth params"
          color="flat-secondary"
          className="btn-icon p-25 border-0"
        >
          <CustomCopy
            text={authType === "LOGIN" ? `${login}:${password}` : `${ip}`}
            size={14}
          />
        </Button>
        {amountUsers > 1 && (
          <Button
            color="flat-secondary"
            id={id}
            data-tooltip-id={`tooltip-${id}`}
            data-tooltip-content="Delete user from this modem"
            className="btn-icon p-25 border-0"
            onClick={deleteUser}
          >
            <UserX
              className="modal-users-icon-delete"
              size={14}
              stroke="#ea5455"
            />
          </Button>
        )}
        <Tooltip id={`tooltip-${id}`} />
      </div>
    </div>
  );
};
