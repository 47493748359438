export const en = {
  app: {
    header: {
      balance: "Balance",
      statistics: "Statistics",
      on: "On",
      off: "Off"
    }
  },
  header: {
    ariaLabelMain: "Go to home page",
    altImgLogo: "Logo's company Proxy Seller",
    signIn: "Sign In",
    signUp: "Sign Up",
    demo: "Live demo"
  },
  nav: {
    terms: "Terms",
    faq: "FAQ",
    reviews: "Reviews",
    blog: "Blog",
    contact: "Contact"
  },
  mainPage: {
    hero: {
      btn: "Watch video",
      altImg: "Proxy seller proxy with laptop"
    },
    work: {
      btn: "How it works?",
      btnCloseAria: "Close description"
    },
    use: {
      title: "Use only what you need",
      allFunc: "All feature set",
      dongleKits: {
        tab: "No equipment",
        error:
          "A non-existent equipment package was selected, select another and try again",
        btn: "Buy"
      },
      sendRequest: {
        text: "I have equipment, can I connect?",
        btn: "Send request",
        modal: {
          title: "Equipment connection request",
          labelModel: "Modem model",
          placeholderModem: "Enter the modem model, each on a new line",
          labelUSB: "USB hub model:",
          btn: "Send"
        }
      },
      tariffs: {
        tab: "Have equipment",
        btn: "Sign up",
        btnIfAuth: "Choise",
        btnContact: "Contact us",
        enterprise: {
          publicName: "Enterprise",
          price: "0",
          advantages: [],
          description: "/1 modem /1 month"
        }
      },
      form: {
        title: "Order form",
        assept: "Accept",
        yourInfo: "Your Information",
        adressInfo: "Your Shipping & Billing Address",
        needHelp: "Manager's help in filling out the address",
        tooltipManager:
          "If you have difficulties filling out the address, you can choose this option and our manager will contact you to clarify the details.",
        submit: "Continue",
        paymentInfo: "Payment information",
        company: "Company name",
        person: "Person name",
        checkbox: "Use a different billing address (optional)",
        termOfUse: "Terms of use",
        agreeWith: "I agree with the ",
        and: " and ",
        termOfUseLink: "terms of use",
        privacyPolicy: "privacy policy",
        textBeforeLink:
          "If you have not placed an order or registered before, your account will be created automatically. You can do it manually through the",
        textLink: " registration form",
        lowBalance:
          "There are insufficient balance in your account please choose another payment system",
        continue: "Continue",
        placeholders: {
          search: "Search ...",
          firstName: "First name ",
          lastName: "Last name",
          companyName: "Company Name",
          vat: "VAT number (optional)",
          phone: "Phone number",
          email: "Email",
          password: "Password",
          howFind: "How did you hear about our company? ",
          shipment: "Send shipment to",
          country: "Country",
          region: "Region",
          city: "Town / City",
          street: "Street address",
          apartment: "Apartment, suite, unit etc",
          postCode: "Postcode / ZIP",
          notes: "Order notes (optional)",
          paymentSystems: "Payment system ",
          deliveryService: "Delivery Service",
          deliveryServiceNoFetchData: "Delivery service (fill in Your data)",
          noOptions: "Start typing",
          optionsNotFound: "Not found"
        }
      },
      orderInfo: {
        title: "Options",
        preiceDetail: {
          title: "Price detail",
          vat: "VAT",
          delivery: "Delivery",
          total: "Total price"
        }
      }
    },
    get: {
      title: "You get more than you pay",
      btn: "Whole feature set"
    },
    placeholders: {
      name: "Your name",
      email: "Your email address",
      message: "Your message"
    },
    errors: {
      requiredField: "This field is required",
      wrongAuthData: "Wrong password or email",
      wrongPass:
        "Length from 8 to 32 characters, must be one letter and one number",
      wrongFormatEmail: "Invalid mail format",
      wrongFormatPhone: "Invalid phone format",
      address: "Address is invalid",
      delivery: "Delivery address not found",
      unknownServerError: "Server error, try again later",
      captcha: "Reqwest error, try again later",
      privacyPolicy: "You must agree to the privacy policy"
    },
    ticketsMessage: {
      success: "Your ticket number:",
      error: "Ticket not sent, try again later",
      errorEmail: "Please enter a valid email address"
    },
    footer: {
      close: "Close",
      paymentList: [
        {
          id: 1,
          name: "paypal",
          alt: "PayPal"
        },
        {
          id: 2,
          name: "mastercard",
          alt: "Mastercard"
        },
        {
          id: 3,
          name: "bitcoin",
          alt: "Bitcoin"
        },
        {
          id: 4,
          name: "visa",
          alt: "Visa"
        },
        {
          id: 5,
          name: "ethereum",
          alt: "Ethereum"
        },
        {
          id: 6,
          name: "tether",
          alt: "Tether"
        }
      ],
      nav: {
        title: "Company"
      },
      copyright: "2022 © Proxyseller",
      links: [
        { title: "Cookie policy", key: "cookiePolicy" },
        { title: "Terms and Conditions", key: "offer" },
        { title: "Privacy policy", key: "privacyPolicy" }
      ]
    }
  }
};
