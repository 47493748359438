import { IconSvg } from "@constants";
import { useTranslation } from "@hooks";

export const WorkModal = ({ close, info }) => {
  // *Hooks
  const { work } = useTranslation();

  const handlerClose = () => close?.();

  return (
    <>
      <button
        className="landing-work__modal-close"
        aria-label={work?.btnCloseAria}
        onClick={handlerClose}
      />
      <div className="landing-work__modal-wrapper">
        <div className="landing-work__modal-img">
          <IconSvg tag="heroImg" />
        </div>
        <div className="landing-work__modal-info">
          <h2 className="landing-work__modal-title">{info?.title}</h2>
          <div className="landing-work__modal-text">
            {info?.text
              ? info?.text
                  .split("\n")
                  .map((item, index) => <p key={index}>{item}</p>)
              : null}
          </div>
        </div>
      </div>
    </>
  );
};
