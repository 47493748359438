import PerfectScrollbar from "react-perfect-scrollbar";
import { Check, AlertTriangle, User } from "react-feather";
import { Button, Badge, DropdownItem } from "reactstrap";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLoaderData } from "react-router-dom";

// ** Custom Hooks & Helpers
import { useDispatchedActions } from "@hooks";
// ** Services
import { apiService } from "@services/ApiService";
// ** Components
import { Avatar } from "@components/app";

// ** Constants
const AVATARS = {
  INFO: { icon: <User size={20} />, color: "light-info" },
  SUCCESS: { icon: <Check size={20} />, color: "light-success" },
  WARNING: { icon: <AlertTriangle size={20} />, color: "light-danger" }
};

export const NotificationMenuItems = ({ handlerTriger }) => {
  // ** Dispatched Actions
  const { demoReadAllNotifications } = useDispatchedActions();
  // ** Hooks
  const { isDemo } = useLoaderData();
  // ** Redux state
  const { notifications } = useSelector((state) => state.navBar);

  // ** States
  const [loading, setLoading] = useState(false);

  // функция с запросом на сервер о прочтении всех сообщений
  const readAllNotifications = () => {
    setLoading(true);
    const notificationsToMark = [];
    notifications?.data?.forEach(({ id }) => notificationsToMark.push(id));

    if (!isDemo) {
      apiService.notifications
        .markRead({ notificationsToMark })
        .then((data) => {
          if (!data.error && data.status === 200) {
            handlerTriger?.();
          }
        })
        .finally(() => setLoading(false));
    } else {
      demoReadAllNotifications();
      setLoading(false);
    }
  };

  return (
    <>
      <li className="dropdown-menu-header">
        <DropdownItem
          className="d-flex justify-content-between"
          tag="div"
          header
        >
          <h4 className="notification-title mb-0 me-auto">Notifications</h4>
          <Badge tag="div" color="primary" pill>
            {notifications?.count} new
          </Badge>
        </DropdownItem>
      </li>
      {notifications?.data?.length ? (
        <PerfectScrollbar
          component="li"
          style={{ maxHeight: "25rem" }}
          className="media-list scrollable-container"
          options={{
            wheelPropagation: false
          }}
        >
          {notifications?.data?.map((object) => {
            if (object?.isRead === false) {
              return (
                <div
                  key={object?.id}
                  className="d-flex align-items-center p-75 border-bottom"
                >
                  {object?.notificationType &&
                  AVATARS[object?.notificationType] ? (
                    <Avatar
                      className="me-1"
                      color={AVATARS[object?.notificationType].color}
                      icon={AVATARS[object?.notificationType].icon}
                    />
                  ) : null}
                  <p
                    className="m-0 small text-muted text-break"
                    dangerouslySetInnerHTML={{ __html: object?.text }}
                  ></p>
                </div>
              );
            }
          })}
        </PerfectScrollbar>
      ) : null}
      <li className="dropdown-menu-footer">
        <Button
          className="mb-1 w-100"
          color="primary"
          onClick={readAllNotifications}
          disabled={!notifications?.count || loading}
        >
          Read all notifications
        </Button>
      </li>
    </>
  );
};
