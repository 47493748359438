import "./button.scss";

export const Button = ({ loading, children, className, ...props }) => (
  <button className={`button ${className || ""}`} {...props}>
    {loading ? (
      <>
        <div className="circle" />
        <div className="circle" />
        <div className="circle" />
      </>
    ) : (
      children
    )}
  </button>
);
