import { CopyToClipboard } from "react-copy-to-clipboard";
import { Copy } from "react-feather";
import { toast } from "react-toastify";

export const CustomCopy = (props) => {
  // ** Props
  const { text, className = "", size = null } = props;
  // ** Function
  const handlerToast = () => toast.success("Copied To Clipboard !", { icon: <Copy /> });

  return (
    <CopyToClipboard
      className={`cursor-pointer flex-none ${className}`}
      text={text}
      onCopy={handlerToast}
    >
      {size ? <Copy size={size} /> : <code>{text}</code>}
    </CopyToClipboard>
  );
};
