import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ContentItemDongle } from "../contentItem/ContentItemDongle";

const breakpointsDongle = {
  320: {
    spaceBetween: 15,
    slidesPerView: 1.25
  },
  460: {
    spaceBetween: 30,
    slidesPerView: 1.5
  },
  580: {
    spaceBetween: 30,
    slidesPerView: 1.75
  },
  767: {
    spaceBetween: 30,
    slidesPerView: 2.5
  },
  992: {
    spaceBetween: 30,
    slidesPerView: 3.25
  },
  1200: {
    spaceBetween: 30,
    slidesPerView: 4
  }
};

export const SwiperDongle = ({ items }) => {
  // *Handlers
  const handlerSetActiveIndex = (el) => {
    el.activeIndex = 0;
    el.setProgress(0);
  };

  const handlerTouchStart = (_, e) => {
    if (e.target.tagName === "A") {
      e.target.click();
      e.stopPropagation();
    }
  };

  return (
    <Swiper
      wrapperTag="ul"
      watchOverflow
      navigation
      touchEventsTarget="container"
      slideToClickedSlide={true}
      modules={[Navigation]}
      className="landing-use__content-list"
      centerInsufficientSlides={true}
      breakpoints={breakpointsDongle}
      onUpdate={handlerSetActiveIndex}
      threshold={30}
      onTouchStart={handlerTouchStart}
    >
      {items?.length
        ? items.map((item, index) => (
            <SwiperSlide
              key={item.id}
              className={"landing-use__content-item"}
              tag="li"
            >
              <ContentItemDongle data={item} index={index + 1} />
            </SwiperSlide>
          ))
        : null}
    </Swiper>
  );
};
