import { RESPONSIBLE_TYPES } from "@constants";

export const currentResponsible = (row) => {
    if (row.responsibleType === RESPONSIBLE_TYPES.SYSTEM) {
      return "SYSTEM";
    }
    if (row.responsibleType === RESPONSIBLE_TYPES.USER) {
      return `User: ${row.responsibleUserEmail}`;
    }
    if (row.responsibleType === RESPONSIBLE_TYPES.PUBLIC_API) {
      return "PUBLIC API";
    }
    return "UNKNOWN";
  };
