import { useSelector } from "react-redux";
// ** Components
import {
  ModalHistoryIP,
  ModalUsers,
  ModalCommandLinks,
  ModalReadSms,
  ModalSendMessage,
  ModalRotation,
  ModalSimNumberEdit,
  ModalExportCV,
  ModalActionSelectedModems,
  ModalModemLimits,
  ModalUserLimits,
  ModalReport
} from "..";

export const Modals = () => {
  // ** Redux store
  const {
    modalHistoryIpOpen,
    modalExportCVOpen,
    modalUsersOpen,
    modalCommandLinksOpen,
    modalReadSmsOpen,
    modalSendSmsOpen,
    modalRotationOpen,
    modalSimNumberEditOpen,
    modalActionAllOpen,
    modalModemLimitsOpen,
    modalUserLimitsOpen,
    modalReportOpen
  } = useSelector((state) => state.modals);

  return (
    <>
      {modalHistoryIpOpen && <ModalHistoryIP />}
      {modalUsersOpen && <ModalUsers />}
      {modalCommandLinksOpen && <ModalCommandLinks />}
      {modalReadSmsOpen && <ModalReadSms />}
      {modalSendSmsOpen && <ModalSendMessage />}
      {modalRotationOpen && <ModalRotation />}
      {modalSimNumberEditOpen && <ModalSimNumberEdit />}
      {modalExportCVOpen && <ModalExportCV />}
      {modalActionAllOpen && <ModalActionSelectedModems />}
      {modalModemLimitsOpen && <ModalModemLimits />}
      {modalUserLimitsOpen && <ModalUserLimits />}
      {modalReportOpen && <ModalReport />}
    </>
  );
};
