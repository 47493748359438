export const ru = {
  app: {
    header: {
      balance: "Баланс",
      statistics: "Статистика",
      on: "Вкл",
      off: "Выкл"
    }
  },
  header: {
    ariaLabelMain: "Перейти на главную страницу",
    altImgLogo: "Логотип компании Proxy Seller",
    signIn: "Войти",
    signUp: "Регистрация",
    demo: "Демо"
  },
  nav: {
    terms: "Условия",
    faq: "FAQ",
    reviews: "Отзывы",
    blog: "Блог",
    contact: "Контакты"
  },
  mainPage: {
    hero: {
      btn: "Посмотреть видео",
      altImg: "Proxy seller прокси и ноутбук"
    },
    work: {
      btn: "Как это работает?",
      btnCloseAria: "Закрыть описание"
    },
    use: {
      title: "Используй только то, что необходимо",
      allFunc: "Весь набор функций",
      dongleKits: {
        tab: "Нет оборудования",
        error:
          "Был выбран несуществующий пакет оборудования, выберите другой и попробуйте снова",
        btn: "Купить"
      },
      tariffs: {
        tab: "Есть оборудование",
        btn: "Зарегистрироваться",
        btnIfAuth: "Выбрать",
        btnContact: "Связаться с нами",
        enterprise: {
          publicName: "Enterprise",
          price: "0",
          advantages: [],
          description: "/1 модем /1 месяц"
        }
      },
      sendRequest: {
        text: "Есть оборудование, смогу ли подключить?",
        btn: "Отправить запрос",
        modal: {
          title: "Запрос на подключение оборудования",
          labelModel: "Модель модемов",
          placeholderModem: "Введите модель модемов, каждый с новой строки",
          labelUSB: "Модель USB хабов:",
          btn: "Отправить"
        }
      },
      form: {
        title: "Форма заказа",
        assept: "Принять",
        yourInfo: "Информация о вас",
        adressInfo: "Ваш адрес доставки и выставления счетов",
        needHelp: "Помощь меджера в заполнении адреса",
        tooltipManager:
          "Если у вас возникли трудности с заполнением адреса, вы можете выбрать этот вариант и наш менеджер свяжется с вами для уточнения деталей.",
        submit: "Продолжить",
        paymentInfo: "Платежная информация",
        company: "Компании",
        person: "Клиента",
        checkbox: "Используйте другой адрес (опционально)",
        termOfUse: "Пользовательское соглашение",
        agreeWith: "Я согласен с ",
        and: " и ",
        termOfUseLink: "условиями использования",
        privacyPolicy: "политикой конфиденциальности",
        textBeforeLink:
          "Если Вы ранее не оформляли заказ и не регистрировались, Ваша учетная запись будет создана автоматически. Вы можете сделать это вручную через ",
        textLink: "форму регистрации",
        lowBalance:
          "На вашем счету недостаточно средств, пожалуйста выберите другой способ оплаты",
        continue: "Продолжить",
        placeholders: {
          search: "Поиск ...",
          firstName: "Имя",
          lastName: "Фамилия",
          companyName: "Название компании",
          vat: "VAT номер (опционально)",
          phone: "Номер телефона",
          email: "Почта",
          password: "Пароль",
          howFind: "Как вы узнали про нашу компанию?",
          shipment: "Отправить посылку по адресу",
          country: "Страна",
          region: "Область",
          city: "Город",
          apartment: "Квартира, офис",
          street: "Улица",
          postCode: "Почтовый индекс",
          notes: "Описание (опционально)",
          paymentSystems: "Платежная система",
          deliveryService: "Служба доставки",
          deliveryServiceNoFetchData: "Служба доставки (заполните ваши данные)",
          noOptions: "Начните вводить",
          optionsNotFound: "Не найдены"
        }
      },
      orderInfo: {
        title: "Опции",

        preiceDetail: {
          title: "Детали цены",
          vat: "НДС",
          delivery: "Доставка",
          total: "Итого"
        }
      }
    },
    get: {
      title: "Получаешь больше, чем платишь",
      btn: "Весь набор функций"
    },
    placeholders: {
      name: "Ваше имя",
      email: "Электронная почта",
      message: "Ваше сообщение"
    },
    errors: {
      requiredField: "Обязательное поле",
      wrongAuthData: "Неверный логин или пароль",
      wrongPass:
        "Длина от 8 до 32 сиволов, должна быть одна буква и одна цифра",
      wrongFormatEmail: "Неверный формат почты",
      wrongFormatPhone: "Неверный формат телефона",
      address: "Неверная улица",
      delivery: "Адрес доставки не найден",
      unknownServerError: "Ошибка сервера, попробуйте позже",
      captcha: "Ошибка запроса, попробуйте позже",
      privacyPolicy:
        "Для продолжения необходимо согласиться с политикой конфиденциальности"
    },
    ticketsMessage: {
      success: "Номер вашей заявки:",
      error: "Ошибка отправки заявки, пожалуйста попробуйте позже.",
      errorEmail: "Пожалуйста, введите существующий адрес электронной почты"
    },
    footer: {
      close: "Закрыть",
      paymentList: [
        {
          id: 1,
          name: "paypal",
          alt: "PayPal"
        },
        {
          id: 2,
          name: "mastercard",
          alt: "Mastercard"
        },
        {
          id: 3,
          name: "bitcoin",
          alt: "Bitcoin"
        },
        {
          id: 4,
          name: "visa",
          alt: "Visa"
        },
        {
          id: 5,
          name: "ethereum",
          alt: "Ethereum"
        },
        {
          id: 6,
          name: "tether",
          alt: "Tether"
        }
      ],
      nav: {
        title: "Компания"
      },
      copyright: "2022 © Proxyseller",
      links: [
        { title: "Политика cookie", key: "cookiePolicy" },
        { title: "Условия использования", key: "offer" },
        { title: "Политика конфиденциальности", key: "privacyPolicy" }
      ]
    }
  }
};
