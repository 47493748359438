import { Controller, useFormContext } from "react-hook-form";
// ** Custom Hooks & Utils
import { useTranslation } from "@hooks";
// ** Styles
import "./checkbox.scss";

export const Checkbox = ({ name, label, required, messageOff = false, radio }) => {
  // ** Hooks
  const { control } = useFormContext();
  const { mainPage } = useTranslation();

  // ** Functions
  const currentErrorMessage = (error) => {

    if (messageOff) return "";

    if (error?.type === "required") {
      return mainPage?.errors?.requiredField;
    }

    return error?.message;
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={false}
      rules={{ required: { value: required, message: mainPage?.errors?.requiredField } }}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <div className="landing-checkbox">
          <label className="landing-checkbox__option">
            <input
              ref={ref}
              id={name}
              className="landing-checkbox__input"
              type={!radio ? "checkbox" : "radio"}
              onChange={onChange}
              checked={value}
            />
            <span className="landing-checkbox__box"></span>
            {label && (
              <span className="landing-checkbox__label">
                {label}
                {required && (
                  <span className="landing-checkbox__label-required">*</span>
                )}
              </span>
            )}
          </label>
          {!messageOff && error?.type && (
            <p className="landing-form__item-error">{currentErrorMessage(error)}</p>
          )}
        </div>
      )}
    />
  );
};
