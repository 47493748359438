import { createSlice } from "@reduxjs/toolkit";
import {
  fetchChangeRotation,
  fetchSaveSimNumber,
  fetchActionAllModems,
  deleteModem
} from "./Modals.thunks";
const ModalsReducer = createSlice({
  name: "Modals",
  initialState: {
    modalHistoryIpOpen: false,
    modalExportCVOpen: false,
    modalUsersOpen: false,
    modalModemLimitsOpen: false,
    modalUserLimitsOpen: false,
    selectedUsersIds: [],
    reloadUserlist: false,
    modalUserModemLimitSet: false,
    selectedModemsInfo: [],
    modalDeleteModemOpen: false,
    modalCommandLinksOpen: false,
    modalRotationOpen: false,
    modalReportOpen: false,
    modalSimNumberEditOpen: false,
    modalReadSmsOpen: false,
    modalSendSmsOpen: false,
    modalActionAllOpen: "",
    selectedModemId: "",
    activeHostId: "",
    modalTourOpen: false,
    changeRotation: { isLoaded: false, fetchError: null },
    saveSimNumber: { isLoaded: false, fetchError: null }
  },
  reducers: {
    setModalHistoryIpOpen(state, action) {
      state.modalHistoryIpOpen = !!action.payload;
      state.selectedModemId = action.payload ? action.payload : "";
    },
    setModalExportCVOpen(state, action) {
      state.modalExportCVOpen = action.payload;
    },
    setModalUsersOpen(state, action) {
      state.modalUsersOpen = action.payload;
    },
    setModalModemLimitsOpen(state, action) {
      state.modalModemLimitsOpen = action.payload;
    },
    setModalReportOpen(state, action) {
      state.modalReportOpen = !!action.payload;
    },
    setModalUserLimitsOpen(state, action) {
      state.modalUserLimitsOpen = !!action.payload?.ids?.length;
      state.selectedUsersIds = action.payload?.ids || [];
      state.modalUserModemLimitSet = !!action.payload?.modemLimitSet;
      if (!action.payload?.ids) {
        state.reloadUserlist = !state.reloadUserlist;
      }
    },
    setSelectedModemsInfo(state, action) {
      state.selectedModemsInfo = action.payload;
    },
    setModalDeleteModemOpen(state, action) {
      state.modalDeleteModemOpen = !!action.payload;
      state.selectedModemId = action.payload ? action.payload : "";
    },
    setModalCommandLinksOpen(state, action) {
      state.modalCommandLinksOpen = !!action.payload;
      state.selectedModemId = action.payload ? action.payload : "";
    },
    setModalRotationOpen(state, action) {
      state.modalRotationOpen = !!action.payload;
      state.selectedModemId = action.payload ? action.payload : "";
    },
    setModalSimNumberEditOpen(state, action) {
      state.modalSimNumberEditOpen = !!action.payload;
      state.selectedModemId = action.payload ? action.payload : "";
    },
    setModalReadSmsOpen(state, action) {
      state.modalReadSmsOpen = !!action.payload;
      state.modalSendSmsOpen = false;
      state.selectedModemId = action.payload ? action.payload : "";
    },
    setModalSendSmsOpen(state, action) {
      state.modalSendSmsOpen = !!action.payload;
      state.selectedModemId = action.payload ? action.payload : "";
    },
    setModalTourOpen(state, action) {
      state.modalTourOpen = action.payload;
    },
    setModalActionAllOpen(state, action) {
      state.modalActionAllOpen = action?.payload ? action.payload : "";
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChangeRotation.pending, (state) => {
      state.changeRotation.isLoaded = false;
      state.changeRotation.fetchError = null;
    });
    builder.addCase(fetchChangeRotation.fulfilled, (state) => {
      state.changeRotation.isLoaded = true;
      state.selectedModemId = "";
      state.modalRotationOpen = false;
    });
    builder.addCase(fetchChangeRotation.rejected, (state, action) => {
      state.changeRotation.fetchError = !action.payload;
      state.changeRotation.isLoaded = true;
      state.selectedModemId = "";
      state.modalRotationOpen = false;
    });

    builder.addCase(fetchSaveSimNumber.pending, (state) => {
      state.saveSimNumber.isLoaded = false;
      state.saveSimNumber.fetchError = null;
    });
    builder.addCase(fetchSaveSimNumber.fulfilled, (state) => {
      state.saveSimNumber.isLoaded = true;
      state.selectedModemId = "";
      state.modalSimNumberEditOpen = false;
    });
    builder.addCase(fetchSaveSimNumber.rejected, (state, action) => {
      state.saveSimNumber.fetchError = !action.payload;
      state.saveSimNumber.isLoaded = true;
      state.selectedModemId = "";
      state.modalSimNumberEditOpen = false;
    });
  }
});

export const ModalsReducerActions = {
  ...ModalsReducer.actions,
  fetchChangeRotation,
  fetchSaveSimNumber,
  fetchActionAllModems,
  deleteModem
};

export default ModalsReducer.reducer;
