import { useLoaderData } from "react-router-dom";
// ** Custom Hooks & Helpers
import { useCurrentProfileData } from "@hooks";
// ** Components
import { HorizontalNavMenuLink } from "./HorizontalNavMenuLink";
import { HorizontalNavMenuGroup } from "./HorizontalNavMenuGroup";

export const HorizontalNavMenuItems = ({ items, ...rest }) => {
  // ** Hooks
  const { isDemo } = useLoaderData();
  // ** Redux State

  const {
    userProfile: {
      data: { isSuperUser, isAdmin }
    }
  } = useCurrentProfileData(isDemo);

  // check if items is an array and if it has length
  if (!items || !items?.length) return null;
  // filter items based on user role
  const filteredItems =
    isSuperUser || isAdmin ? items : items.filter((item) => !item.isForAdmin);

  return filteredItems?.length
    ? filteredItems.map((item) => {
        if (item?.children) {
          return (
            <HorizontalNavMenuGroup
              key={item.id || item.header}
              item={item}
              {...rest}
            />
          );
        }
        return (
          <HorizontalNavMenuLink
            key={item.id || item.header}
            item={item}
            {...rest}
          />
        );
      })
    : null;
};
