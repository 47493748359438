import { Navigate } from "react-router-dom";

export const AdminTicketsRoutes = [
  {
    path: "tickets/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminTickets } = await import(
            "@views/app/admin/tickets/list"
          );
          return {
            Component: AdminTickets
          };
        }
      },
      {
        path: ":ticketId/edit/",
        async lazy() {
          const { AdminTicketEdit } = await import(
            "@views/app/admin/tickets/edit"
          );
          return {
            Component: AdminTicketEdit
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
