import { createSlice } from "@reduxjs/toolkit";
import { fetchLandingContent } from "./Landing.thunks";

const LandingReducer = createSlice({
  name: "Landing",
  initialState: {
    mobileMenu: { isOpen: false },
    content: { data: null, isLoaded: false, fetchError: null },
    modalFeatureSet: { isOpen: false }
  },
  reducers: {
    toggleMobileMenu(state, action) {
      state.mobileMenu.isOpen = !!action.payload;
    },
    setSsrLendingContent(state, action) {
      state.content.data = {
        ...state.content.data,
        [action.payload.locale]: {
          ...action.payload.data,
          locale: action.payload.locale
        }
      };
      state.content.isLoaded = true;
    },
    setOpenModalFeatureSet(state, action) {
      state.modalFeatureSet.isOpen = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLandingContent.pending, (state) => {
      state.content.isLoaded = false;
      state.content.fetchError = null;
    });
    builder.addCase(fetchLandingContent.fulfilled, (state, action) => {
      state.content.data = {
        ...state.content.data,
        [action.payload.locale]: {
          ...action.payload.data,
          locale: action.payload.locale
        }
      };
      state.content.isLoaded = true;
    });
    builder.addCase(fetchLandingContent.rejected, (state, action) => {
      state.content.fetchError = action.payload;
      state.content.isLoaded = true;
    });
  }
});

export const LandingReducerActions = {
  ...LandingReducer.actions,
  fetchLandingContent
};

export default LandingReducer.reducer;
