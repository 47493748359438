import { Badge, Button } from "reactstrap";
// ** Custom Hooks & Utils
import { useDispatchedActions } from "@hooks";
import { fakeDataShow } from "@helpers";

const Provider = ({ row, admin }) => {
  // ** Dispatched Actions
  const { setModalSimNumberEditOpen } = useDispatchedActions();

  // ** Functions
  const hendlerOnClick = () => setModalSimNumberEditOpen(row?.id);

  return (
    <div
      className={`d-flex flex-column align-items-start
       ${row?.paid ? "" : "unselectable blur-box"}`}
    >
      <span className="mb-25">
        {fakeDataShow(row?.simProvider, !row?.paid && !admin)}
      </span>
      {row?.simNumberCustom ? (
        <span role="button" className="mb-25" onClick={hendlerOnClick}>
          {fakeDataShow(row?.simNumberCustom, !row?.paid && !admin)}
        </span>
      ) : (
        <Button
          className="p-0 border-0 mb-25"
          color="flat-warning"
          disabled={!row?.paid && !admin}
          onClick={hendlerOnClick}
        >
          <Badge className="py-25" style={{ top: 0 }} color="light-warning">
            {"Enter"}
          </Badge>
        </Button>
      )}
      <span>{fakeDataShow(row?.workMode, !row?.paid && !admin)}</span>
    </div>
  );
};

export const staticProvider = (admin = false) => ({
  id: "sim",
  name: "Provider",
  minWidth: "155px",
  key: "3",
  cell: (row) => <Provider row={row} admin={admin} />
});
