import Landing from "@views/landing/MainPage/Landing";
import { Navigate } from "react-router-dom";
import { LandingLayout } from "../layouts/LandingLayout";

export const landingRoutes = [
  {
    index: true,
    element: <Landing />,
    isSSR: true
  },
  {
    path: "order/:id",
    async lazy() {
      const { OrderPage } = await import("@views/landing/OrderPage/OrderPage");
      return {
        Component: OrderPage
      };
    }
  },
  {
    path: "*", // 404 page
    element: <Navigate to="./" replace={true} />
  }
];

export const LandingRoutesSSR = [
  {
    path: "/:lang/",
    element: <LandingLayout />,
    children: [
      {
        index: true,
        element: <Landing />
      },
      {
        path: "order/:id",
        async lazy() {
          const { OrderPage } = await import(
            "@views/landing/OrderPage/OrderPage"
          );
          return {
            Component: OrderPage
          };
        }
      }
    ],
    isSSR: true
  }
];
