import { Navigate } from "react-router-dom";

export const AdminAuthTokensRoutes = [
  {
    path: "auth-token/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminAuthTokens } = await import(
            "@views/app/admin/authToken/"
          );
          return {
            Component: AdminAuthTokens
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
