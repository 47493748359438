import { useCurrentSeo } from "@hooks";
import { IconSvg } from "@constants";

export const List = () => {
  // **Hooks
  const { support } = useCurrentSeo();

  return (
    <ul className="landing-support__list">
      {support?.items?.length
        ? support.items.map((item, index) => (
            <li className="landing-support__item" key={index}>
              <a className="landing-support__item-link" target={"_blank"} href={item?.link} rel="noreferrer">
                <div className="landing-support__item-img">
                  <IconSvg tag={item.value} />
                </div>
                <p>{item.title}</p>
              </a>
            </li>
          ))
        : null}
    </ul>
  );
};
