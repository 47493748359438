import { Link, useParams } from "react-router-dom";
import { useTranslation } from "@hooks";
import { IconSvg } from "@constants";

export const ContentItemDongle = ({ data, index }) => {
  // *Hooks
  const { lang } = useParams();
  const {
    mainPage: { use }
  } = useTranslation();

  return (
    <>
      <div className="landing-use__content-item-img">
        <IconSvg
          tag={`dongleKitModems${[1, 2, 3, 4].includes(index) ? index : "1"}`}
        />
        {index ? (
          <div className="landing-use__content-item-circle">
            X{data?.amount}
          </div>
        ) : null}
        {/* <div className="landing-use__content-item-circle">x{data?.amount}</div> */}
      </div>
      <div className="landing-use__content-item-title-inner">
        <p className="landing-use__content-item-title">{data?.name}</p>
      </div>
      <p className="landing-use__content-item-type">{data?.description}</p>
      <div className="landing-use__content-item-price">
        <div className="landing-use__content-item-price--old">
          {data?.oldPrice ? `$${data?.oldPrice}` : null}
        </div>
        <div className="landing-use__content-item-price--new">
          {data?.price ? `$${data?.price}` : null}
        </div>
      </div>
      <ul className="landing-use__content-item-list">
        {data?.advantages?.length
          ? data?.advantages.map((item, index) => <li key={index}>{item}</li>)
          : null}
      </ul>
      <Link
        className="landing-use__content-item__link"
        to={`/${lang}/order/${data?.id}/`}
      >
        {use?.dongleKits?.btn}
      </Link>
    </>
  );
};
