import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { TEXT_ACTIONS } from "@constants";
const SwalAct = withReactContent(Swal);

const getModemInfoHTML = (array, action) => {
  let htmlString = `<h4 class="mb-2"><strong class="text-center">
                      Fail to ${action} these modems
                  </strong></h4>`;

  array.map(({ number, reason }) => {
    const html = `
                  <div class="mb-1">
                      <div class="d-flex align-items-center justify-content-center mb-50">
                            <span class="me-75">Modem number:</span>
                          <strong>${number}</strong>
                      </div>
                      <div class="d-flex align-items-center justify-content-center mb-50">
                          <span class="me-75">Reason:</span>
                          <strong>${reason}</strong>
                      </div>
                  </div>`;

    htmlString += html;
  });

  return htmlString;
};

export const swalActModemInfo = (array, action) =>
  SwalAct.fire({
    icon: "error",
    html: getModemInfoHTML(array, TEXT_ACTIONS[action]?.error),
    showConfirmButton: false,
    timer: 10000
  });
