export const AdminReCaptchaRoutes = [
  {
    path: "re-captcha/edit/",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminReCaptha } = await import(
            "@views/app/admin/KeysFormServices/reCaptcha"
          );
          return {
            Component: AdminReCaptha
          };
        }
      }
    ]
  }
];
