export const isDifferentSerchParams = (arr1, arr2) => {
  if (!Array.isArray) {
    Array.isArray = function (arg) {
      return Object.prototype.toString.call(arg) === "[object Array]";
    };
  }

  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return true;
  }

  if (arr1.length !== arr2.length) {
    return true;
  }

  arr1.sort((a, b) => a.key.localeCompare(b.key));
  arr2.sort((a, b) => a.key.localeCompare(b.key));

  return arr1.some((item, index) => {
    if (item?.key !== arr2?.[index]?.key) {
      return true;
    }
    if (Array.isArray(item?.value)) {
      return item?.value.some((it) => !arr2?.[index]?.value?.includes(it));
    } else {
      return item?.value !== arr2?.[index]?.value;
    }
  });
};
