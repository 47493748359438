import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
// ** Custom Hooks & Utils
import { useDispatchedActions, useLockBackground } from "@hooks";
import { selectThemeColors } from "@helpers";
// ** Services
import { apiService } from "@services/ApiService";

// ** Constants
const fileFormats = [
  { value: "txt", label: "txt" },
  { value: "csv", label: "csv" },
  { value: "xlsx", label: "xlsx" }
];
const demoColumns = ["number", "ip", "use"];
const defoultFormat = "%number%,%ip%,%use%";

export const ModalExportCV = () => {
  // ** Dispatched Actions
  const { setModalExportCVOpen } = useDispatchedActions();
  // ** Redux state
  const { modalExportCVOpen } = useSelector((state) => state.modals);
  const { hostModems } = useSelector((state) => state.content);
  const {
    isDemo,
    content: { modems }
  } = useSelector((state) => state.demo);
  // ** State
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [paramsExport, setParamsExport] = useState([]);
  const [fileFormat, setFileFormat] = useState({
    value: "txt",
    label: "txt"
  });
  const [formatExportDownload, setFormatExportDownload] =
    useState(defoultFormat);

  // ** Functions
  const toggle = (e) => {
    if (e.target.tagName !== "INPUT" && e.target?.name !== "OPTION") {
      setDropdownOpen((prevState) => !prevState);
    }
  };
  const selectHandler = (data) => setFileFormat(data);
  const handlerFormatExportData = (e) => {
    if (formatExportDownload.includes(e.target.textContent)) {
      const temp = formatExportDownload
        .split(",")
        .filter((item) => item !== e.target.textContent);
      setFormatExportDownload(temp.join(","));
    } else {
      setFormatExportDownload((state) =>
        state ? [state, e.target.textContent].join(",") : e.target.textContent
      );
    }
  };

  const handlerIntup = (e) => setFileName(e.target.value);

  const onChangeHandler = (e) => {
    setFormatExportDownload(e.target.value);
  };

  const close = () => setModalExportCVOpen(false);

  const download = async () => {
    if (!formatExportDownload) {
      toast.error("You must specify the data pattern");
      return;
    }
    if (!isDemo) {
      try {
        const file = await apiService.modems.export({
          hostId: hostModems?.activeHostId,
          extension: fileFormat.value,
          fileName,
          format: formatExportDownload
        });
        if (file.status !== 200 || !file?.data) {
          throw "Error serwer respons";
        }

        const objectURL = URL.createObjectURL(file.data);
        const link = document.createElement("a");
        link.href = objectURL;
        link.setAttribute(
          "download",
          `${fileName || "modems"}.${fileFormat.value}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        toast.error(err);
      }
    } else {
      const activeHostModems = modems[hostModems?.activeHostId];
      const demoFormatExport = formatExportDownload
        .replace(/[%]/g, "")
        .split(",")
        .filter((item) => demoColumns.includes(item));
      let separator = ",";
      let hrefParam = "data:text/json;charset=utf-8";
      if (fileFormat.value !== "txt") {
        separator = ";";
        hrefParam = "data:text/csv;charset=utf-8";
      }
      const demoDataExport = activeHostModems.map((item) => {
        let result = "";
        demoFormatExport.forEach((key) => {
          result += `${item[key]}${separator}`;
        });

        return result.slice(0, -1);
      });

      const a = document.createElement("a");
      a.setAttribute(
        "href",
        `${hrefParam},${encodeURIComponent(demoDataExport.join("\n"))}`
      );
      a.setAttribute("download", `${fileName || "modems"}.${fileFormat.value}`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  // ** Effects
  useEffect(() => {
    apiService.modems.getExportFields().then((res) => {
      if (res?.status === 200 && res?.data) {
        setParamsExport(res.data);
      }
    });
  }, []);

  useLockBackground(!!modalExportCVOpen);

  return (
    <Modal
      isOpen={modalExportCVOpen}
      toggle={close}
      className="modal-dialog-centered"
      onClosed={() => setFileName("")}
    >
      <ModalHeader toggle={close}>Export To Excel</ModalHeader>
      <ModalBody>
        <FormGroup noMargin className="mb-1">
          <Input
            type="text"
            value={fileName}
            onChange={handlerIntup}
            placeholder="Enter File Name"
          />
        </FormGroup>
        <FormGroup noMargin className="mb-1">
          <Select
            theme={selectThemeColors}
            className="react-select"
            classNamePrefix="select"
            options={fileFormats}
            defaultValue={fileFormat}
            onChange={selectHandler}
          />
        </FormGroup>
        <Dropdown
          className="export__dropdown"
          isOpen={dropdownOpen}
          toggle={toggle}
        >
          <DropdownToggle
            outline
            color="primary"
            className="w-100 d-flex"
            style={{ height: "38px" }}
          >
            {formatExportDownload || "Edit format export data"}
          </DropdownToggle>
          <DropdownMenu className="w-100 " container="body">
            {paramsExport?.length &&
              paramsExport.map((item) => (
                <DropdownItem
                  key={item}
                  name="OPTION"
                  className="w-100"
                  onClick={handlerFormatExportData}
                >
                  {`%${item}%`}
                </DropdownItem>
              ))}
            <DropdownItem className="w-100">
              <Input
                type="text"
                placeholder="Own format"
                value={formatExportDownload}
                onChange={onChangeHandler}
              />
            </DropdownItem>
            <DropdownItem className="w-100">
              <Button color="primary">Confirm format export data</Button>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={download}>
          Export
        </Button>
        <Button color="flat-danger" onClick={close}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
