import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useParams } from "react-router-dom";
// ** Custom Hooks & Helpers
import { useDispatchedActions, useScrollReload } from "@hooks";
import { loadCalendarScript } from "@helpers";
// ** Components
import { Footer, Header } from "@components/landing/common/";
import { MetaDate } from "@components/Metadate";

import "@scss/landingIndex.scss";
import "@scss/settings/functions.scss";
import "@scss/settings/normalize.scss";
import "@scss/components/ui.scss";
import "@scss/libs/swiper.scss";

export const LandingLayout = () => {
  // **Hooks
  const { lang } = useParams();
  const { fetchLandingContent } = useDispatchedActions();

  // **Redux State
  const { content } = useSelector((state) => state.landing);
  const { translation } = useSelector((state) => state.translation);
  const settings = useSelector((state) => state.settings);

  // **Effects
  useEffect(() => {
    if (
      lang &&
      translation.hasOwnProperty(lang) &&
      (!content.data || !content.data[lang])
    ) {
      fetchLandingContent(lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    loadCalendarScript();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useRemovePreloader([content?.data]);
  useScrollReload();
  if (!lang || !translation.hasOwnProperty(lang)) {
    return <Navigate to={`/${settings?.lang || "en"}/`} />;
  }

  return (
    <>
      <MetaDate
        lang={lang}
        indexRobots={settings?.site?.data?.robots?.enabled}
      />
      <div className={"landing-wrapper"} style={{ display: "none" }}>
        <Header />
        <main id="main">
          <Outlet />
        </main>
        <Footer />
      </div>
    </>
  );
};
