import { Navigate } from "react-router-dom";

export const AdminPaymentSystemsRoutes = [
  {
    path: "payment-system/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminPaymentSystems } = await import(
            "@views/app/admin/paymentSystem/"
          );
          return { Component: AdminPaymentSystems };
        }
      },
      {
        path: "add/",
        async lazy() {
          const { AdminPaymentSystemAddEdit } = await import(
            "@views/app/admin/paymentSystem/addEdit"
          );
          return { Component: AdminPaymentSystemAddEdit };
        }
      },
      {
        path: ":paymentSystemId/edit/",
        async lazy() {
          const { AdminPaymentSystemAddEdit } = await import(
            "@views/app/admin/paymentSystem/addEdit"
          );
          return { Component: AdminPaymentSystemAddEdit };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
