import { useEffect, useState } from "react";

export const useWindowWidth = () => {
  // ** local State
  const [windowWidth, setWindowWidth] = useState(() => {
    try {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      return width;
    } catch (error) {
      return 0;
    }
  });

  // Handler to call on window resize
  const handleResize = () => {
    // Set window width/height to state
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    setWindowWidth(width);
  };
  useEffect(() => {
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowWidth;
};
