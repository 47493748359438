import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "@services/ApiService";
import { toast } from "react-toastify";
import { demoActionsAllModems, swalActModemInfo } from "@helpers";
import { TEXT_ACTIONS } from "@constants";
import { ContentReducerActions } from "../ContentReducer/Content.reducer";
import { DemoReducerActions } from "../DemoReducer/Demo.reducer";
import { ModalsReducerActions } from "../ModalsReducer/Modals.reducer";

export const fetchChangeRotation = createAsyncThunk(
  "Modals/fetchChangeRotation",
  async ({ params, isDemo }, { rejectWithValue, getState, dispatch }) => {
    if (isDemo) {
      const { demo, content } = getState();
      const { modems } = demo?.content;
      const { activeHostId } = content?.hostModems;
      const activeHostModems = modems?.[activeHostId];
      const newModems = {
        ...modems,
        [activeHostId]: activeHostModems.map((item) => {
          if (item.id === params.modemId) {
            return {
              ...item,
              lastUpdate: new Date().getTime(),
              rotationRate: params.rotation
            };
          } else return item;
        })
      };
      toast.success("Successfully changed rotation!");
      dispatch(DemoReducerActions.setUpdateModems(newModems));
      dispatch(ContentReducerActions.setReloadModemsTrigger());
      return true;
    }
    try {
      const response = await apiService.modems.changeRotationRate(params);
      if (response && response.status !== 200) {
        throw response;
      }
      toast.success("Successfully changed rotation!");
      dispatch(ContentReducerActions.setReloadModemsTrigger());
      return true;
    } catch (err) {
      toast.error("Error occurred during changing rotation!");
      return rejectWithValue(false);
    }
  }
);

export const fetchSaveSimNumber = createAsyncThunk(
  "Modals/fetchSaveSimNumber",
  async ({ params, isDemo }, { rejectWithValue, getState, dispatch }) => {
    if (isDemo) {
      const { demo, content } = getState();
      const { modems } = demo?.content;
      const { activeHostId } = content?.hostModems;
      const activeHostModems = modems[activeHostId];
      const newModems = {
        ...modems,
        [activeHostId]: activeHostModems.map((item) => {
          if (item.id === params.id) {
            return {
              ...item,
              lastUpdate: new Date().getTime(),
              simNumberCustom: params.simNumberCustom
            };
          } else return item;
        })
      };
      toast.success("Successfully saved number!");
      dispatch(DemoReducerActions.setUpdateModems(newModems));
      dispatch(ContentReducerActions.setReloadModemsTrigger());
      return true;
    }
    try {
      const response = await apiService.modems.setCustomSimNumber(params);
      if (response && response.status !== 200) {
        throw response;
      }
      toast.success("Successfully saved number!");
      dispatch(ContentReducerActions.setReloadModemsTrigger());
      return true;
    } catch (err) {
      toast.error("Error occurred during saving number!");
      return rejectWithValue(false);
    }
  }
);

export const fetchActionAllModems = createAsyncThunk(
  "Modals/fetchActionAllModems",
  async (isDemo, { rejectWithValue, getState, dispatch }) => {
    const { demo, content, modals } = getState();
    const action = modals?.modalActionAllOpen;
    const { activeHostId } = content.hostModems;
    const ids = modals?.selectedModemsInfo?.length
      ? modals?.selectedModemsInfo?.map((it) => it.id)
      : [];
    if (isDemo) {
      const activeHostModems = demo?.content?.modems?.[activeHostId];
      const { newModems, errors, success } = demoActionsAllModems(
        activeHostModems,
        ids,
        demo?.content?.modems,
        activeHostId,
        action
      );
      dispatch(DemoReducerActions.setUpdateModems(newModems));
      if (success?.length) {
        toast.success(
          `All modem(-s) successfully ${TEXT_ACTIONS[action]?.success}!`
        );
        if (action === "DELETE") {
          dispatch(ModalsReducerActions.setSelectedModemsInfo([]));
          dispatch(ContentReducerActions.clearTableSelect());
        }
      }
      if (errors?.length) {
        swalActModemInfo(errors, action);
      }
      return false;
    }
    try {
      const params = { modemIds: ids, action };
      const response = await apiService.modems.processById(params);

      if (response?.status !== 200) {
        throw response;
      }

      if (response?.data?.failedModems?.length) {
        swalActModemInfo(response?.data?.failedModems, action);
      } else {
        toast.success(
          `All modem(-s) successfully ${TEXT_ACTIONS[action]?.success}!`
        );
        if (action === "DELETE") {
          dispatch(ModalsReducerActions.setSelectedModemsInfo([]));
          dispatch(ContentReducerActions.clearTableSelect());
        }
      }

      dispatch(ContentReducerActions.setReloadModemsTrigger());
      return false;
    } catch (err) {
      toast.error("Modems acton failed");
      return rejectWithValue(true);
    }
  }
);

export const deleteModem = createAsyncThunk(
  "Modals/deteteModem",
  async (isDemo, { rejectWithValue, getState, dispatch }) => {
    const { demo, content, modals } = getState();
    const { activeHostId } = content.hostModems;
    const { selectedModemId } = modals;
    if (isDemo) {
      const { modems } = demo?.content;
      const newModems = {
        ...modems,
        [activeHostId]: modems[activeHostId].filter(
          (item) => item.id !== selectedModemId
        )
      };

      toast.success("Modem removed successfully");
      dispatch(DemoReducerActions.setUpdateModems(newModems));
      dispatch(ContentReducerActions.setReloadModemsTrigger());
      return false;
    }
    try {
      const response = await apiService.modems.deleteById({
        modemId: selectedModemId
      });

      if (response && response.status !== 200) {
        throw response;
      }
      dispatch(ContentReducerActions.setReloadModemsTrigger());
      toast.success("Modem removed successfully");
      return false;
    } catch (err) {
      toast.error("Modem remove failed");
      return rejectWithValue(true);
    }
  }
);
