import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// ** Custom Hooks & Utils
import { useDispatchedActions } from "@hooks";
import { demoCreateCheckOut } from "@helpers";
// ** Services
import { apiService } from "@services/ApiService";
// ** Styles
import "./staticBlur.scss";

const BlurRow = ({ row }) => {
  // ** Hooks
  const navigate = useNavigate();
  // ** Dispatched Actions
  const { demoSetCheckOut } = useDispatchedActions();
  // ** Redux State
  const { isDemo, content } = useSelector((state) => state.demo);
  const { hostModems } = useSelector((state) => state.content);

  // ** Functions
  const hendlerOnClick = () => {
    if (!isDemo) {
      apiService.checkouts.create([row.id]).then((req) => {
        if (req.data) {
          navigate(`/app/checkout/${req.data.id}`);
        }
      });
    } else if (content?.modems) {
      // demo logik start
      const checkOut = demoCreateCheckOut({
        ids: [row.id],
        modems: content?.modems[hostModems?.activeHostId]
      });
      demoSetCheckOut(checkOut);
      navigate(`/demo/checkout/${checkOut.key}`);
      // demo logik end
    }
  };

  return !row.paid ? (
    <div className="static-blur">
      <Button
        color="primary"
        onClick={hendlerOnClick}
        className="static-blur__button"
      >
        Set tariff
      </Button>
    </div>
  ) : null;
};

export const staticBlur = () => ({
  id: "blur",
  // selector: "blur",
  key: "14",
  width: "0px",
  name: null,
  style: {
    position: "static",
    padding: "0 !important"
  },
  cell: (row) => <BlurRow row={row} />
});
