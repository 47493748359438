import { Navigate } from "react-router-dom";

export const AdminRobotsRoutes = [
  {
    path: "robots/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminRobots } = await import("@views/app/admin/robots/");
          return {
            Component: AdminRobots
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
