import { createSlice } from "@reduxjs/toolkit";
import { isSSR, setCookie, getCookie } from "@helpers";
import { fetchSiteSettings } from "./Settings.thunks";
const initialLang = () => {
  const langs = ["en", "ru"];
  const ruCode = "ru";
  const cisCountries = ["az", "hy", "be", "kk", "ky", "ru", "tg", "uz", "uk"];
  const defaultLang = langs?.[0];
  if (!isSSR()) {
    const cookieLang = getCookie("lang");
    if (langs.includes(cookieLang)) {
      return cookieLang;
    }
    const mainLang = navigator.language || navigator.userLanguage;
    const otherLangs = navigator.languages || [];
    const browserLang = mainLang?.split("-")?.[0] || null;

    if (langs.includes(browserLang)) {
      return browserLang;
    }
    if (cisCountries.includes(browserLang)) {
      return ruCode;
    }
    if (otherLangs.length) {
      for (const lang of otherLangs) {
        if (langs.includes(lang)) {
          return lang;
        }
        if (cisCountries.includes(lang)) {
          return ruCode;
        }
      }
    }
    return defaultLang;
    // const lang = localStorage.getItem("lang");
    // const langs = ["en", "ru"];
    // const path = window.location.pathname;
    // const pathLang = path?.split("/")?.[1] || null;
    // if (langs.includes(pathLang)) {
    //   if (pathLang !== lang) {
    //     localStorage.setItem("lang", JSON.stringify(pathLang));
    //   }
    //   return pathLang;
    // }
    // if (langs.includes(JSON.parse(lang))) {
    //   return JSON.parse(lang);
    // }
    // localStorage.setItem("lang", JSON.stringify("en"));
  }
  return defaultLang;
};

const initialSettingsState = () => ({
  lang: initialLang(),
  site: {
    data: {
      captcha: {
        enabled: false,
        siteKeyV2: null,
        siteKeyV3: null
      },
      robots: {
        enabled: false
      },
      publicAPI: {
        url: null
      }
    },
    isLoaded: false,
    fetchError: null
  }
});

const SettingsReducer = createSlice({
  name: "Settings",
  initialState: initialSettingsState(),
  reducers: {
    setLang(state, action) {
      state.lang = action.payload;
      if (!isSSR()) {
        setCookie("lang", action.payload);
      }
    },
    setSiteSettingsSSR(state, action) {
      state.site.data = {
        captcha: {
          enabled: action.payload?.captcha?.captchaEnabled,
          siteKeyV2: action.payload?.captcha?.captchaV2,
          siteKeyV3: action.payload?.captcha?.captchaV3
        },
        robots: {
          enabled: action.payload?.robotsEnabled
        },
        publicAPI: {
          url: action.payload?.apiServiceLink
        }
      };
      state.site.isLoaded = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSiteSettings.pending, (state) => {
      state.site.fetchError = null;
      state.site.isLoaded = false;
    });
    builder.addCase(fetchSiteSettings.fulfilled, (state, action) => {
      state.site.data = {
        captcha: {
          enabled: action.payload?.captcha?.captchaEnabled,
          siteKeyV2: action.payload?.captcha?.captchaV2,
          siteKeyV3: action.payload?.captcha?.captchaV3
        },
        robots: {
          enabled: action.payload?.robotsEnabled
        },
        publicAPI: {
          url: action.payload?.apiServiceLink
        }
      };
      state.site.isLoaded = true;
    });
    builder.addCase(fetchSiteSettings.rejected, (state, action) => {
      state.site.fetchError = action.payload;
      state.site.isLoaded = true;
    });
  }
});

export const SettingsReducerActions = {
  ...SettingsReducer.actions,
  fetchSiteSettings
};

export default SettingsReducer.reducer;
