/* eslint-disable import/namespace */
import { Helmet } from "react-helmet-async";

export const MetaDate = ({
  lang = "en",
  title = "PROXYSELLER",
  description = "",
  app = false,
  indexRobots = false
}) => {
  // ** Constants
  const current = {
    title,
    description,
    lang,
    color: "#ffffff",
    icon: `/favicons/apple-touch-icon${app ? "-app" : ""}.png`,
    icon32: `/favicons/favicon${app ? "-app" : ""}-32x32.png`,
    icon16: `/favicons/favicon${app ? "-app" : ""}-16x16.png`,
    favicon: `/favicons/favicon${app ? "-app" : ""}.ico`,
    maskIcon: "/favicons/safari-pinned-tab.svg",
    robots: indexRobots ? "all" : "noindex, nofollow"
  };
  return (
    <Helmet>
      <html lang={current.lang} dir="ltr" />
      <base href="/" />
      <meta charSet="utf-8" />
      <meta name="theme-color" content={current.color} />
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      <title>{current.title}</title>
      <meta name="description" content={current.description} />
      <meta name="image" content={current.icon} />
      <meta itemProp="name" content={current.title} />
      <meta itemProp="description" content={current.description} />
      <meta itemProp="image" content={current.icon} />
      <meta property="og:locale" content={current.lang} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={current.title} />
      <meta property="og:description" content={current.description} />
      <meta property="og:image" content={current.icon} />
      <meta property="og:image:width" content="180" />
      <meta property="og:image:height" content="180" />
      <meta name="twitter:title" content={current.title} />
      <meta name="twitter:description" content={current.description} />
      <meta name="twitter:image" content={current.icon} />

      <meta name="robots" content={current.robots} />
      <meta name="msapplication-TileColor" content="#da532c" />
      <link rel="shortcut icon " href={current.favicon} />
      <link
        rel="manifest"
        crossOrigin="use-credentials"
        href={"/site.webmanifest"}
      />
      <link rel="mask-icon" href={current.maskIcon} color="#5bbad5" />
      <link rel="apple-touch-icon" sizes="180x180" href={current.icon} />
      <link rel="icon" type="image/png" sizes="32x32" href={current.icon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={current.icon16} />
      <script type="application/ld+json" src={"/script.application.json"} />
      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.5.1/dist/leaflet.css"
        crossOrigin=""
      />
      {/*<!-- Calendly badge widget begin --> */}
      <link
        href="https://assets.calendly.com/assets/external/widget.css"
        rel="stylesheet"
      />
    </Helmet>
  );
};
