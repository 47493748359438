import { useState } from "react";
import { useSelector } from "react-redux";
import { Navbar } from "reactstrap";
import { useLoaderData } from "react-router-dom";

// ** Components
import { NAVBAR_MENU, NAVBAR_MENU_DEMO } from "../navigation";
import { HorizontalNavMenuItems } from "./HorizontalNavMenuItems";

export const HorizontalMenu = () => {
  // ** Hooks
  const { isDemo } = useLoaderData();
  // ** Redux state
  const { skin } = useSelector((state) => state.theme);
  // ** States
  const [groupActive, setGroupActive] = useState([]);
  const [openDropdown, setOpenDropdown] = useState([]);

  return (
    <div className="horizontal-menu-wrapper">
      <Navbar
        tag="div"
        expand="sm"
        light={skin !== "dark"}
        dark={skin === "dark"}
        className={
          "header-navbar navbar-horizontal navbar-shadow menu-border floating-nav"
        }
      >
        <div className="navbar-container main-menu-content px-0">
          <ul className="nav navbar-nav" id="main-menu-navigation">
            <HorizontalNavMenuItems
              items={isDemo ? NAVBAR_MENU_DEMO : NAVBAR_MENU}
              groupActive={groupActive}
              openDropdown={openDropdown}
              setGroupActive={setGroupActive}
              setOpenDropdown={setOpenDropdown}
            />
          </ul>
        </div>
      </Navbar>
    </div>
  );
};
