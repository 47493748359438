import { Controller, useFormContext } from "react-hook-form";
import { FormGroup, Input, Label } from "reactstrap";

export const FormCheckBox = ({
  name,
  disabled = false,
  inverted = false,
  className = "",
  label = "",
  inline = false,
  ...rest
}) => {
  // ** Hooks
  const { control } = useFormContext();

  return (
    <FormGroup
      noMargin
      className={`mb-1 d-flex align-items-center ${className}`}
      inline={inline}
      check
    >
      <Controller
        name={name}
        control={control}
        defaultValue={false}
        render={({ field: { onChange, value, ref } }) => (
          <Input
            innerRef={ref}
            type="checkbox"
            id={name}
            onChange={onChange}
            checked={inverted ? !value : value}
            className="me-50"
            disabled={disabled}
            {...rest}
          />
        )}
      />
      {label && (
        <Label for={name} className="mb-0">
          {label}
        </Label>
      )}
    </FormGroup>
  );
};
