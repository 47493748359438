import { Navigate } from "react-router-dom";

export const AdminUsersRoutes = [
  {
    path: "users/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminUsers } = await import("@views/app/admin/users/list");
          return {
            Component: AdminUsers
          };
        }
      },
      {
        path: ":id/edit/:tab",
        async lazy() {
          const { AdminUserEdit } = await import("@views/app/admin/users/edit");
          return {
            Component: AdminUserEdit
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
