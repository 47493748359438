import { Controller, useFormContext } from "react-hook-form";
import Flatpickr from "react-flatpickr";
import { FormFeedback } from "reactstrap";

export const FormDate = ({
  name,
  required = false,
  readOnly = false,
  enableTime = false,
  mode = "single",
  errorText = ""
}) => {
  // ** Hooks
  const { control } = useFormContext();

  // ** Constants
  const MODES = ["single", "multiple", "range"];
  const options = {
    enableTime: !!enableTime,
    mode: MODES.includes(mode) ? mode : "single"
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      rules={{
        required: {
          value: required,
          message: errorText
        }
      }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <>
          <Flatpickr
            id={name}
            className={`form-control ${error?.type ? "is-invalid" : ""}`}
            disabled={readOnly}
            options={options}
            value={value}
            onChange={onChange}
          />
          {!!error?.message && <FormFeedback>{error.message}</FormFeedback>}
        </>
      )}
    />
  );
};
