import { Col, Row } from "reactstrap";

export const DescriptionlineCentered = ({ label, children }) => (
    <Row className="d-flex align-items-start mb-25">
      <Col sm={3} className="description-line__label">
        {label}:
      </Col>
      <Col sm={9} className="d-flex align-items-center justify-content-space-between">
        {children}
      </Col>
    </Row>
  );
