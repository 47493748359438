import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "@services/ApiService";

export const fetchLandingContent = createAsyncThunk(
  "Landing/fetchContent",
  async (locale, { rejectWithValue }) => {
    try {
      const response = await apiService.front.getLendingContent({
        languageCode: locale,
        metadata: []
    });

      if (response && response.status !== 200) {
        throw response;
      }
      return {
        data: {
          dongleKits: response?.data?.dongleKits,
          get: response?.data?.get,
          tariffs: response?.data?.rates,
          work: response?.data?.work,
          ...response?.data?.content
        },
        locale
      };
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);
