import { useState } from "react";
import { Container } from "@components/landing/ui";
import { WorkContents, WorkTabs } from "./components";

import "./work.scss";

export const Work = () => {
  // *State
  const [activeTab, setActiveTab] = useState(0);

  // *Handlers
  const tabsHandler = (index) => setActiveTab(index);

  return (
    <div className="landing-work">
      <Container>
        <WorkTabs activeTab={activeTab} tabsHandler={tabsHandler} />
        <WorkContents activeTab={activeTab} />
      </Container>
    </div>
  );
};
