import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import { FormFeedback, Input } from "reactstrap";

export const FormInput = ({
  type = "text",
  name,
  defaultValue = "",
  clearOnEvent = "",
  className = "",
  required = false,
  placeholder = "",
  errorText = "This field is required",
  errorIpText = "",
  readOnly = false,
  messageOff = false,
  notShowError = false,
  onlyLatinLanguage = false,
  integer = false,
  min,
  max,
  ...rest
}) => {
  // ** Hooks
  const { control, setValue } = useFormContext();

  // ** State
  const [showMessage, setShowMessage] = useState(false);

  // ** Functions
  const currentChangeHandler = (event, handlerChange) => {
    if (clearOnEvent) {
      setValue(clearOnEvent, null);
    }
    let { value } = event.target;
    const prevLength = value.length;
    if (onlyLatinLanguage) {
      value = value.replace(
        /[^a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g,
        ""
      );
      if (value.length !== prevLength && !showMessage) {
        toast.info(
          "Only latin characters are allowed in this field ( please try to change language )"
        );
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 5000);
      }
    }

    if (type === "tel") {
      value = value.replace(/[^0-9+]/g, "");
      if (value.length > 18) {
        value = value.slice(0, 18);
      }
    }
    if (type === "ip") {
      value = value.replace(/[^0-9.]/g, "");
      if (value.length > 15) {
        value = value.slice(0, 15);
      }
    }

    event.target.value = value;
    handlerChange?.(event);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required,
          message: messageOff ? "" : errorText
        },
        ...(type === "number" &&
          typeof min === "number" && {
            min: {
              value: min,
              message: messageOff ? "" : `Minimum value is ${min}`
            }
          }),
        ...(type === "number" &&
          typeof max === "number" && {
            max: {
              value: max,
              message: messageOff ? "" : `Maximum value is ${max}`
            }
          }),
        ...(type === "email" && {
          pattern: {
            value:
              /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,4}$/i,
            message: messageOff ? "" : "Wrong email format"
          }
        }),
        ...(type === "ip" && {
          pattern: {
            value:
              /^([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])$/,
            message: messageOff ? "" : errorIpText
          }
        }),
        ...(type === "tel" && {
          pattern: {
            value: /^(\+)?([0-9]){1,18}$/,
            message: messageOff ? "" : "Invalid phone number"
          }
        })
      }}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <>
          <Input
            {...field}
            id={name}
            value={field.value || ""}
            className={className}
            type={type === "ip" ? "text" : type}
            placeholder={placeholder}
            invalid={!!error?.type}
            onChange={(event) => currentChangeHandler(event, field.onChange)}
            onKeyDown={(e) => {
              if (type === "number" && integer && ",.".includes(e.key))
                // eslint-disable-next-line nonblock-statement-body-position
                e.preventDefault();
            }}
            readOnly={readOnly}
            {...rest}
          />
          {!!error?.message && !notShowError && (
            <FormFeedback>{error.message}</FormFeedback>
          )}
        </>
      )}
    />
  );
};
