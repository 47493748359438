/* eslint-disable import/no-named-as-default */
import { useState, useRef, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { Modal } from "@components/landing/common/modal/modal";
import { Button } from "@components/landing/ui";
import { useTranslation } from "@hooks";
import { Form } from "@components/landing/form";
import { apiService } from "@services/ApiService";

import "./SendRequest.scss";

export const SendRequest = () => {
  // *Hooks
  const recaptchaRef = useRef();
  const methods = useForm({
    defaultValues: { name: "", email: "", modemModel: "", usbHubModel: "" }
  });
  const {
    mainPage: {
      use: { sendRequest, form },
      placeholders,
      errors,
      ticketsMessage
    }
  } = useTranslation();

  // *Redux States
  const { userProfile } = useSelector((state) => state.auth);

  // *States
  const { site } = useSelector((state) => state.settings);
  const [submitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const hendlerOpenModal = () => setOpen(true);

  // *Functions
  const sendTicket = (captchaResponse = "none") => {
    setSubmitting(true);
    const data = methods.getValues();
    const params = { ...data, captchaResponse };

    apiService.tickets
      .createFromRequest(params)
      .then((res) => {
        if (res.status === 200) {
          toast.success(`${ticketsMessage?.success} ${res?.data?.number}`);
          methods.reset();
          setOpen(false);
        } else if (res?.response?.status === 412) {
          toast.error(errors?.captcha);
        } else if (
          res?.response?.status === 400 &&
          res?.response?.data === "EMAIL_IS_INVALID"
        ) {
          toast.error(ticketsMessage?.errorEmail);
        } else {
          toast.error(ticketsMessage?.error);
        }
      })
      .catch((error) => toast.error(error?.message || error))
      .finally(() => {
        setSubmitting(false);
        recaptchaRef?.current?.reset();
      });
  };

  const submit = () => {
    if (
      site?.data?.captcha?.enabled &&
      site?.data?.captcha?.siteKeyV2 &&
      recaptchaRef.current
    ) {
      recaptchaRef.current.execute();
    } else {
      sendTicket();
    }
  };

  useEffect(() => {
    if (userProfile?.data?.email) {
      methods.setValue("email", userProfile?.data?.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile?.data?.email]);

  return (
    <div className="landing__send-request__container">
      <p className="landing__send-request__text">{sendRequest?.text}</p>
      <Button onClick={hendlerOpenModal}>{sendRequest?.btn}</Button>
      <Modal
        visible={open}
        width="500"
        lite
        cancelHandler={setOpen}
        title={sendRequest?.modal?.title}
      >
        <FormProvider {...methods}>
          <form
            className="landing__send-request__form"
            onSubmit={methods.handleSubmit(submit)}
            noValidate
          >
            <div className="landing__send-request__form-inputs">
              <div className="landing__send-request__form-input">
                <Form.Input
                  name="name"
                  placeholder={placeholders?.name}
                  required
                />
              </div>
              <div className="landing__send-request__form-input">
                <Form.Input
                  name="email"
                  type="email"
                  placeholder={form?.placeholders?.email}
                  required
                />
              </div>
              <div className="landing__send-request__form-input">
                <Form.Input
                  name="modemModel"
                  type="textarea"
                  placeholder={sendRequest?.modal?.labelModel}
                  required
                />
              </div>
              <div className="landing__send-request__form-input">
                <Form.Input
                  name="usbHubModel"
                  type="textarea"
                  placeholder={sendRequest?.modal?.labelUSB}
                  required
                />
              </div>
            </div>
            {site?.data?.captcha?.enabled && site?.data?.captcha?.siteKeyV2 && (
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={site?.data?.captcha?.siteKeyV2}
                size="invisible"
                onChange={sendTicket}
              />
            )}
            <Button type="submit" disabled={submitting} loading={submitting}>
              {sendRequest?.modal?.btn}
            </Button>
          </form>
        </FormProvider>
      </Modal>
    </div>
  );
};
