export const currentLimitsShow = (limit, limitValue) => {
  if (limitValue === "MB") {
    return +limit / (1024 * 1024);
  }
  if (limitValue === "GB") {
    return +limit / (1024 * 1024 * 1024);
  }
};

export const currentLimitsValue = (data, customData, type) => {
  if (+customData) {
    if (type === "MB") {
      return +customData * 1024 * 1024;
    }
    if (type === "GB") {
      return +customData * 1024 * 1024 * 1024;
    }
  }

  if (+data) {
    return +data;
  }

  return 0;
};

