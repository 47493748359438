import { useTranslation, useCurrentSeo, useModal } from "@hooks";
import { Modal } from "@components/landing/common";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const FooterBottom = () => {
  // **Hooks
  const { mainPage: { footer } } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSeo = useCurrentSeo();
  const [modalToggler, modalActive, setModalActive] = useModal();

  // **State
  const [typeName, setTypeName] = useState("");

  // **Handlers
  const handlerOpenModal = (e) => {
    setTypeName(e.currentTarget.id.replace("footer-btn-", ""));
    modalToggler();
  };

  // **Effects
  useEffect(() => {
      const modal = searchParams.get("modal");
      // console.log(modal);
    if (modal) {
      if (currentSeo?.footer?.[modal]?.text) {
        // console.log("modal");
        setTypeName(modal);
        modalToggler();
      }
      setSearchParams({ });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, currentSeo]);

  return (
    <div className="landing-footer__bottom">
      <div className="landing-footer__bottom-copyright">
        {currentSeo?.footer?.copyright || footer.copyright}
      </div>
      <ul className="landing-footer__bottom-list">
        {footer?.links?.length &&
          footer.links.map((item) => (
            <li className="landing-footer__bottom-item" key={item.key}>
              <button
                id={`footer-btn-${item.key}`}
                onClick={handlerOpenModal}
                className="landing-footer__bottom-button"
              >
                {item.title}
              </button>
            </li>
          ))}
      </ul>
      <Modal
        visible={modalActive}
        width="800"
        cancelHandler={setModalActive}
        title={currentSeo?.footer?.[typeName]?.title || ""}
      >
        <div
          className="landing-modal__text landing-footer__modal-text"
          dangerouslySetInnerHTML={{
            __html: currentSeo?.footer?.[typeName]?.text
              ? currentSeo?.footer?.[typeName]?.text
                  .replace(
                    /<table/g,
                    "<div style='overflow-x: auto; width: 100%'><table"
                  )
                  .replace(/<\/table>/g, "</table></div>")
              : ""
          }}
        ></div>
      </Modal>
    </div>
  );
};
