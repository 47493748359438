import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ContentItemTariff } from "../contentItem/ContentItemTariff";

const breakpointsTariff = {
  320: {
    spaceBetween: 15,
    slidesPerView: 1.25
  },
  460: {
    spaceBetween: 30,
    slidesPerView: 1.25
  },
  580: {
    spaceBetween: 30,
    slidesPerView: 1.5
  },
  767: {
    spaceBetween: 30,
    slidesPerView: 2.25
  },
  992: {
    spaceBetween: 30,
    slidesPerView: 2.75
  },
  1200: {
    spaceBetween: 30,
    slidesPerView: 3
  }
};

export const SwiperTariff = ({ items }) => {
  // *Handlers
  const handlerSetActiveIndex = (el) => {
    el.activeIndex = 0;
    el.setProgress(0);
  };

  const handlerTouchStart = (_, e) => {
    if (e.target.tagName === "BUTTON") {
      e.target.click();
      e.stopPropagation();
    }
  };

  return (
    <Swiper
      wrapperTag="ul"
      watchOverflow
      navigation
      touchEventsTarget="container"
      slideToClickedSlide={true}
      modules={[Navigation]}
      className="landing-use__content-list"
      centerInsufficientSlides={true}
      breakpoints={breakpointsTariff}
      onUpdate={handlerSetActiveIndex}
      threshold={30}
      onTouchStart={handlerTouchStart}
    >
      {items?.length
        ? items.map((item) => (
            <SwiperSlide
              key={item.id}
              className={
                "landing-use__content-item landing-use__content-item-tariff"
              }
              tag="li"
            >
              <ContentItemTariff data={item} />
            </SwiperSlide>
          ))
        : null}
    </Swiper>
  );
};
