import { bindActionCreators } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
// ** Redux Actions
import { AllActions } from "../../redux/reducers/AllActions";

export const useDispatchedActions = () => {
  // ** Hooks
  const dispatch = useDispatch();

  return bindActionCreators(AllActions, dispatch);
};
