export const isSSR = () => {
  if (process.env.NODE_ENV === "test") {
    return true;
  } else {
    return !(
      typeof window !== "undefined" &&
      window.document &&
      window.document.createElement
    );
  }
};
