/* eslint-disable eqeqeq */
import { Controller, useFormContext } from "react-hook-form";
import { FormGroup, Input, Label } from "reactstrap";

export const FormRadio = ({
  id,
  name,
  clearOnEvent = "",
  initValue,
  label = "",
  disabled = false,
  className = "",
  inline = false,
  style = {},
  ...rest
}) => {
  // ** Hooks
  const { control, setValue } = useFormContext();

  return (
    <FormGroup
      noMargin
      className={`d-flex align-items-center ${className}`}
      check
      inline={inline}
      style={style}
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            type="radio"
            id={id}
            disabled={disabled}
            checked={initValue == value}
            onChange={(event) => {
              if (clearOnEvent) {
                setValue(clearOnEvent, null);
              }
              onChange(event);
            }}
            value={initValue}
            {...rest}
          />
        )}
      />
      <Label for={id} className="mb-0">
        {label}
      </Label>
    </FormGroup>
  );
};
