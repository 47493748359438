import { Suspense, useEffect, useState } from "react";
import { useLoaderData, Outlet } from "react-router-dom";
// ** Custom Hooks & Helpers
import { useCurrentTheme } from "@hooks";
import { removeCalendly } from "@helpers";

// ** Components
import { MetaDate } from "@components/Metadate";
// ** Styles
import "@scss/base/pages/page-misc.scss";
import "@scss/base/pages/page-auth.scss";

export const BlankLayout = () => {
  // ** Loader data
  const { isDemo } = useLoaderData();
  // **States
  const [isMounted, setIsMounted] = useState(false);

  // **Effects
  useEffect(() => {
    removeCalendly();

    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  // set current skin to body
  useCurrentTheme();

  // useRemovePreloader([isMounted]);
  if (!isMounted) {
    return null;
  }

  return (
    <>
      <MetaDate app={true} />
      <div className="blank-page">
        <div className="app-content content">
          <div className="content-wrapper">
            <div className="content-body">
              <Suspense>
                <Outlet context={{ isDemo }} />
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
