import { Modal, ModalHeader, ModalBody, Alert } from "reactstrap";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Moment from "react-moment";
import { useDispatchedActions, useLockBackground } from "@hooks";
import { apiService } from "@services/ApiService";
import { LoadingHistoryIp, PickerRange } from "@components/app";
import {
  CustomPagination,
  NoDataComponent
} from "@components/app/table-components";
import moment from "moment";

export const ModalHistoryIP = () => {
  //  ** Dispatched Actions
  const { setModalHistoryIpOpen } = useDispatchedActions();
  // ** Hooks
  const { isDemo } = useOutletContext();
  // ** Redux state
  const { selectedModemsInfo, modalHistoryIpOpen, selectedModemId } =
    useSelector((state) => state.modals);

  // ** Constants
  const checkIds = () => {
    if (selectedModemId && selectedModemId !== "all") {
      return selectedModemId;
    }
    if (selectedModemsInfo && selectedModemsInfo.length === 1) {
      return selectedModemsInfo[0].id;
    }
    return null;
  };
  const currentId = checkIds();
  const defaultTime = new Date();

  // ** State
  const [localTime, setLocalTime] = useState([
    new Date(defaultTime.setDate(defaultTime.getDate() - 6)),
    new Date()
  ]);
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
  const [paginationSizePage, setPaginationSizePage] = useState(10);
  const [paginationTotalElementPage, setPaginationTotalElementPage] =
    useState(0);

  // ** Functions
  const getHistoryIP = () => {
    if (currentId) {
      const timeStart = localTime[0]
        ? moment(localTime[0]).format("YYYY-MM-DD")
        : "";
      const timeEnd = localTime[1]
        ? moment(localTime[1]).format("YYYY-MM-DD")
        : "";
      const params = {
        modemId: currentId,
        from: timeStart,
        to: timeEnd,
        page: paginationCurrentPage - 1,
        size: paginationSizePage
      };
      apiService.modems.getIpHistory(params).then((req) => {
        if (req?.status === 200 && req?.data) {
          setHistoryData(req.data.content);
          setPaginationTotalElementPage(req.data.totalElements);
        }
        setLoading(false);
      });
    }
  };

  const close = () => setModalHistoryIpOpen(false);

  // ** Table columns
  const modemsHostStaticColumn = [
    {
      name: "Date",
      cell: (row) => (
        <Moment format="YYYY/MM/DD hh:mm:ss">{row?.createdDate}</Moment>
      )
    },
    {
      name: "IP",
      cell: (row) => row?.event?.newIp
    }
  ];

  // ** Effects
  useEffect(() => {
    if (localTime.length === 2 && !isDemo) {
      getHistoryIP();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localTime, paginationCurrentPage, paginationSizePage]);

  useLockBackground(!!modalHistoryIpOpen);

  return (
    <div className="vertically-centered-modal">
      <Modal
        isOpen={modalHistoryIpOpen}
        toggle={close}
        className={" modal-dialog-centered "}
        style={{ minWidth: 600 }}
      >
        <ModalHeader toggle={close}>History IP</ModalHeader>
        {!isDemo ? (
          <ModalBody>
            {currentId ? (
              <>
                <PickerRange
                  value={localTime}
                  onChange={(data) => setLocalTime(data)}
                />
                <DataTable
                  noHeader
                  pagination
                  paginationServer
                  paginationTotalRows={paginationTotalElementPage}
                  paginationDefaultPage={paginationCurrentPage}
                  paginationPerPage={paginationSizePage}
                  onChangePage={(page) => setPaginationCurrentPage(page)}
                  onChangeRowsPerPage={(size) => setPaginationSizePage(size)}
                  paginationComponent={CustomPagination}
                  progressComponent={<LoadingHistoryIp rowNumber={10} />}
                  noDataComponent={<NoDataComponent />}
                  progressPending={loading}
                  data={historyData}
                  columns={modemsHostStaticColumn}
                  className="react-dataTable react-dataTable__pagination"
                />
              </>
            ) : (
              <Alert className="mb-1 mt-2" color="warning">
                <h4 className="alert-heading">
                  You should choose only one modem to see history ip
                </h4>
              </Alert>
            )}
          </ModalBody>
        ) : (
          <ModalBody>
            <Alert className="mb-1 mt-2" color="primary">
              <h4 className="alert-heading">
                History IP work only in full version
              </h4>
            </Alert>
          </ModalBody>
        )}
      </Modal>
    </div>
  );
};
