import { Navigate } from "react-router-dom";

export const AdminErrorReportRoutes = [
  {
    path: "error-report/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminErrorReports } = await import(
            "@views/app/admin/errorReport/list"
          );
          return {
            Component: AdminErrorReports
          };
        }
      },
      {
        path: ":id/",
        async lazy() {
          const { AdminErrorReportInfo } = await import(
            "@views/app/admin/errorReport/info"
          );
          return {
            Component: AdminErrorReportInfo
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
