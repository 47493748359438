import { Navigate } from "react-router-dom";
import { AppLayout, BlankLayout, LandingLayout } from "../layouts";
import { AuthRoutes } from "./AuthRoutes";
import { AppRoutes } from "./AppRoutes";
import { DemoRoutes } from "./DemoRoutes";
import { landingRoutes } from "./LandingRoutes";
import { PaymentsRoutes } from "./PaymentsRoutes";

export const getRoutes = ({ lang }) => [
  ...PaymentsRoutes,
  {
    path: "/app/auth/*",
    loader: () => ({ isDemo: false }),
    element: <BlankLayout />,
    children: AuthRoutes
  },
  {
    path: "/app/*",
    loader: () => ({ isDemo: false }),
    element: <AppLayout />,
    children: AppRoutes
  },
  {
    path: "/demo/*",
    loader: () => ({ isDemo: true }),
    element: <AppLayout />,
    children: DemoRoutes
  },
  {
    path: "/:lang/*",
    element: <LandingLayout />,
    children: landingRoutes
  },
  {
    path: "*",
    element: <Navigate to={`/${lang}/`} />
  }
];
