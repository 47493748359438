
import { useCurrentSeo } from "@hooks";
import { FaqItem } from "./faqItem";

export const FaqList = ({ clicked, handleToggle }) => {
  // **Hooks
  const { faq } = useCurrentSeo();

  return (
    <ul className="landing-faq__list">
      {faq?.items.length ? faq.items.map((faqItem, index) => (
            <FaqItem
              key={index}
              info={faqItem}
              active={clicked === index}
              onToggle={() => handleToggle(index)}
            />
          ))
        : null}
    </ul>
  );
};
