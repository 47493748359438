import { useState } from "react";
import { Container } from "@components/landing/ui";
import { useTranslation } from "@hooks";
import { ContentSPA, Tabs, TAB_KEYS } from "./components";

import "./use.scss";

export const Use = () => {
  // *Hooks
  const {
    mainPage: { use }
  } = useTranslation();

  // *States
  const [activeTab, setActiveTab] = useState(TAB_KEYS.TARIFF);

  // *Handlers
  const tabsHandler = (index) => setActiveTab(index);

  return (
    <>
      <div className="landing-use">
        <Container>
          <div className="landing-use__top">
            <p className="section__title">{use?.title}</p>
            <Tabs activeTab={activeTab} tabsHandler={tabsHandler} />
          </div>
        </Container>
        <ContentSPA activeTab={activeTab} />
      </div>
    </>
  );
};
