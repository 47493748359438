export const PAYMENT_SYSTEM_INITIAL_VALUES = {
    code: "",
    currency: [],
    purposes: [],
    id: "",
    name: "",
    paymentSystemType: "",
    test: null,
    params: {}
  };
