import { Badge } from "reactstrap";
import { useLoaderData } from "react-router-dom";

// ** Custom Hooks & Helpers
import { useTranslation, useCheckDomain, useCurrentProfileData } from "@hooks";

export const BalanceUser = () => {
  // *Hooks
  const { isDemo } = useLoaderData();
  const { app } = useTranslation();
  const show = useCheckDomain(["localhost", "stage"]);
  // ** Redux Store
  const {
    userProfile: { data }
  } = useCurrentProfileData(isDemo);

  return (
    <div className="d-sm-flex flex-column align-items-center me-1 d-none">
      {data?.isSuperUser && show ? (
        <h5 className=" mb-50">{app?.header?.balance || ""}:</h5>
      ) : (
        <h5 className=" mb-50">Balance:</h5>
      )}
      <Badge color={"light-primary"}>
        {`$ ${data?.balance ? data?.balance?.toFixed(2) : 0}`}
      </Badge>
    </div>
  );
};
