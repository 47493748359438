export const getIcon = (tag) => {
  const icons = {
    // payment
    bitcoin: "/landing/payments/bitcoin.svg",
    ethereum: "/landing/payments/ethereum.svg",
    mastercard: "/landing/payments/mastercard.svg",
    paypal: "/landing/payments/paypal.svg",
    tether: "/landing/payments/tether.svg",
    visa: "/landing/payments/visa.svg",

    // support
    telegram: "/landing/support/telegram.svg",
    skype: "/landing/support/skype.svg",
    gmail: "/landing/support/gmail.svg",
    whatsapp: "/landing/support/whatsapp.svg",

    // getting
    imgGet: "/landing/getting/img@1x.svg",

    // hero
    imgHero: "/landing/hero/img@1x.svg",

    // header
    logo: "/landing/header/logo.svg",
  };

  if (icons.hasOwnProperty(tag)) {
    return icons[tag];
  }

  return "";
};
