import { Navigate } from "react-router-dom";

export const AdminDongleKitsRoutes = [
  {
    path: "dongle-kit/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminDongleKits } = await import(
            "@views/app/admin/DongleKits"
          );
          return {
            Component: AdminDongleKits
          };
        }
      },
      {
        path: "add/",
        async lazy() {
          const { AdminDongleKitAddEdit } = await import(
            "@views/app/admin/DongleKits/DongleKitAddEdit"
          );
          return {
            Component: AdminDongleKitAddEdit
          };
        }
      },
      {
        path: ":id/edit/",
        async lazy() {
          const { AdminDongleKitAddEdit } = await import(
            "@views/app/admin/DongleKits/DongleKitAddEdit"
          );
          return {
            Component: AdminDongleKitAddEdit
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
