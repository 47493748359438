// ** Custom Hooks & Utils
import { fakeDataShow } from "@helpers";
// ** Components
import { CustomCopy } from "@components/app";

export const staticProxy = (admin = false) => ({
  id: "proxy",
  name: "Proxy",
  // selector: "proxy",
  minWidth: "250px",
  key: "6",
  cell: (row) => (
    <div
      className={`text-end ${
        row?.paid || admin ? "" : "unselectable blur-box"
      }`}
    >
      <div>
        <span className="me-50">{`http: ${fakeDataShow(
          `${row?.aggregatorIp}:${row?.portHttp}`,
          !row?.paid && !admin
        )}`}</span>
        <CustomCopy
          text={fakeDataShow(
            `${row?.aggregatorIp}:${row?.portHttp}`,
            !row?.paid && !admin
          )}
          size={14}
        />
      </div>
      <div>
        <span className="me-50">
          {`socks: ${fakeDataShow(
            `${row?.aggregatorIp}:${row?.portSocks}`,
            !row?.paid && !admin
          )}`}
        </span>
        <CustomCopy
          text={fakeDataShow(
            `${row?.aggregatorIp}:${row?.portSocks}`,
            !row?.paid && !admin
          )}
          size={14}
        />
      </div>
    </div>
  )
});
