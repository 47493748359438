/* eslint-disable quotes */
/* eslint-disable camelcase */
import { Controller, useFormContext } from "react-hook-form";
import { Alert, FormGroup, Input } from "reactstrap";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { isSSR } from "@helpers";
import chalk from "chalk";
// ** Styles
import "./FormEditor.scss";
import "@scss/react/libs/editor/editor.scss";

let ReactQuill = null;
(async () => {
  try {
    const Quill = await import("react-quill");
    await import("react-quill/dist/quill.snow.css");
    ReactQuill = Quill.default;
  } catch (ignored) {
    const isServer = isSSR();
    // eslint-disable-next-line no-console
    console.log(
      chalk.yellow(
        `Error: ReactQuill import failed on ${isServer ? "server" : "client"} "FormEditor.jsx"`
      )
    );
  }
})();

export const FormEditor = ({ name, required }) => {
  // ** Hooks
  const { control } = useFormContext();
  // ** Redux State
  const { skin } = useSelector((state) => state.theme);
  // ** Local State
  const [useQuill, setUseQuill] = useState(false);

  // ** Function
  const handelEditorSwitch = () => setUseQuill((prev) => !prev);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],
      ["link", "image", "video", "formula"],
      // [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ align: [] }],
      ["clean"]
    ]
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: !!required }}
      defaultValue={""}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <>
          <div className="d-flex justify-content-center align-items-center mb-1">
            <span className="me-1">Use Tiny Editor </span>
            <FormGroup noMargin switch>
              <Input
                type="switch"
                role="switch"
                id={"editorSwitch"}
                onChange={handelEditorSwitch}
                checked={useQuill}
                className="me-1"
                // className={className}
                // label={"Use Quill Editor"}
              />
            </FormGroup>
            <span>Use Quill Editor</span>
          </div>
          {useQuill ? (
            <ReactQuill
              // theme="core"
              value={value}
              onChange={onChange}
              ref={ref}
              className={`quill-editor ${
                skin === "dark" ? "quill-editor__dark" : ""
              }`}
              modules={modules}
            />
          ) : (
            <>
              <Editor
                // apiKey="emjshh1tafcjgizkmk6eofcmmcxc2cmugajs9l2ordjyca64" // old test key
                apiKey="9o5g0gj4ymxhok1fi1rsb1jrzz4auc2denmx6gcolmxy4jw7"
                value={value}
                onInit={(evt, editor) => {
                  ref.current = editor;
                }}
                onEditorChange={onChange}
                init={{
                  selector: "textarea#full-featured-non-premium",
                  plugins:
                    "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                  imagetools_cors_hosts: ["picsum.photos"],
                  menubar: "file edit view insert format tools table help",
                  toolbar:
                    "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
                  toolbar_sticky: true,
                  autosave_ask_before_unload: true,
                  autosave_interval: "30s",
                  autosave_prefix: "{path}{query}-{id}-",
                  autosave_restore_when_empty: false,
                  autosave_retention: "2m",
                  image_advtab: true,
                  link_list: [
                    { title: "My page 1", value: "https://www.tiny.cloud" },
                    { title: "My page 2", value: "http://www.moxiecode.com" }
                  ],
                  image_list: [
                    { title: "My page 1", value: "https://www.tiny.cloud" },
                    { title: "My page 2", value: "http://www.moxiecode.com" }
                  ],
                  image_class_list: [
                    { title: "None", value: "" },
                    { title: "Some class", value: "class-name" }
                  ],
                  importcss_append: true,
                  file_picker_callback(callback, meta) {
                    /* Provide file and text for the link dialog */
                    if (meta.filetype === "file") {
                      callback("https://www.google.com/logos/google.jpg", {
                        text: "My text"
                      });
                    }

                    /* Provide image and alt text for the image dialog */
                    if (meta.filetype === "image") {
                      callback("https://www.google.com/logos/google.jpg", {
                        alt: "My alt text"
                      });
                    }

                    /* Provide alternative source and posted for the media dialog */
                    if (meta.filetype === "media") {
                      callback("movie.mp4", {
                        source2: "alt.ogg",
                        poster: "https://www.google.com/logos/google.jpg"
                      });
                    }
                  },
                  templates: [
                    {
                      title: "New Table",
                      description: "creates a new table",
                      content:
                        '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>'
                    },
                    {
                      title: "Starting my story",
                      description: "A cure for writers block",
                      content: "Once upon a time..."
                    },
                    {
                      title: "New list with dates",
                      description: "New List with dates",
                      content:
                        '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>'
                    }
                  ],
                  template_cdate_format:
                    "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
                  template_mdate_format:
                    "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
                  height: 300,
                  image_caption: true,
                  quickbars_selection_toolbar:
                    "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                  noneditable_noneditable_class: "mceNonEditable",
                  toolbar_mode: "sliding",
                  contextmenu: "link image imagetools table",
                  // skin: "dark",
                  content_css: skin === "dark" ? "dark" : ""
                }}
              />
              {error?.type && (
                <Alert className="mb-1 mt-2" color="warning">
                  <h4 className="alert-heading">You have not type text</h4>
                  <div className="alert-body">Please type text.</div>
                </Alert>
              )}
            </>
          )}
        </>
      )}
    />
  );
};
