import { Label } from "reactstrap";
import Flatpickr from "react-flatpickr";
// ** Styles
import "@scss/react/libs/flatpickr/flatpickr.scss";

export const PickerRange = ({ value, onChange }) => (
  <div className="d-flex align-items-center p-1">
    <Label for="range-picker" className="me-50">
      Date
    </Label>
    <Flatpickr
      value={value}
      id="range-picker"
      className="form-control"
      onChange={onChange}
      options={{
        mode: "range"
      }}
    />
  </div>
);
