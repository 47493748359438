import { useOutletContext, useParams, Link } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import {
  User,
  Lock,
  Bell,
  Mail,
  CreditCard,
  DollarSign,
  Server,
  Table,
  MapPin,
  Code
} from "react-feather";
// ** Constants
export const TABS = [
  {
    children: null,
    title: "General",
    tab: "general",
    icon: <User size={18} className="me-1 flex-none" />
  },
  {
    children: null,
    title: "Change Password",
    tab: "password",
    icon: <Lock size={18} className="me-1 flex-none" />
  },
  {
    children: null,
    title: "Change Email",
    tab: "email",
    icon: <Mail size={18} className="me-1 flex-none" />
  },
  {
    children: null,
    title: "Notifications",
    tab: "notifications",
    icon: <Bell size={18} className="me-1 flex-none" />
  },
  {
    children: null,
    title: "Payment",
    tab: "payment",
    icon: <CreditCard size={18} className="me-1 flex-none" />
  },
  {
    children: null,
    title: "Balance",
    tab: "balance",
    icon: <DollarSign size={18} className="me-1 flex-none" />
  },
  {
    children: [
      { title: "Domain-Edit", tab: "domain-edit" },
      { title: "Domain-Add", tab: "domain-add" }
    ],
    title: "Domains",
    tab: "domains",
    icon: <Server size={18} className="me-1 flex-none" />
  },
  {
    children: null,
    title: "Orders",
    tab: "orders",
    icon: <Table size={18} className="me-1 flex-none" />
  },
  {
    children: null,
    title: "Shipment & Billing",
    tab: "billing",
    icon: <MapPin size={18} className="me-1 flex-none" />
  },
  {
    children: null,
    title: "API",
    tab: "api",
    icon: <Code size={18} className="me-1 flex-none" />
  }
];

export const Tabs = () => {
  // ** Hooks
  const { isDemo } = useOutletContext();
  const { tab } = useParams();

  return (
    <Nav className="nav-left" pills vertical>
      {TABS.map((item, index) => (
        <NavItem key={index}>
          <NavLink
            tag={Link}
            active={
              tab === item.tab ||
              (item.children &&
                !!item.children.find((child) => child.tab === tab))
            }
            to={`/${isDemo ? "demo" : "app"}/profile/${item.tab}/`}
          >
            {item.icon}
            <span className="font-weight-bold text-wrap">{item.title}</span>
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};
