import { Inbox } from "react-feather";

export const NoDataComponent = ({ text, children }) => (
  <div className="text-center w-100 no-data-body text-secondary">
    {children ? (
      children
    ) : (
      <>
        <Inbox className="mb-1" size={75} strokeWidth={1}/>
        <p>{text && typeof text === "string" ? text : "No data found"}</p>
      </>
    )}
  </div>
);
