import { Controller, useFormContext } from "react-hook-form";
import { ReactCountryFlag } from "react-country-flag";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import { useState } from "react";
// ** Custom Hooks & Utils
import { IconSvg } from "@constants";
// ** Styles
import "./FormSelectV2.scss";

// ** Constants
const RENDER_TYPES = {
  COUNTRY: "COUNTRY",
  PHONE_PREFIX: "PHONE_PREFIX"
};

export const FormSelectV2 = ({
  name,
  placeholder = "Select ...",
  options = [],
  async = false,
  loadOptions = () => [],
  required = false,
  setSubValue = {},
  dropdownButtonStyles = {},
  dropdownMenuStyles = {},
  isMulti = false,
  isDisabled = false,
  isLoading = false,
  messageOff = false,
  errorText = "This field is required",
  handleChange,
  customStyles = {},
  ...rest
}) => {
  // ** Hooks
  const { control, setValue } = useFormContext();

  // ** State
  const [dropdowned, setDropdowned] = useState(false);

  // ** Functions
  const currentValue = (value) => {
    let currentValue;
    if (async) {
      currentValue = options.find((option) => value === option.value);
      if (currentValue) {
        return currentValue;
      } else if (value) {
        return { value, label: value };
      }
    }
    if (isMulti) {
      currentValue = options.filter((option) => value?.includes(option.value));
    } else {
      currentValue = options.find((option) => value === option.value);
    }
    if (currentValue) {
      return currentValue;
    }

    return null;
  };

  const handleChangeSelect = (data, formChange) => {
    if (isMulti) {
      formChange(data.map((item) => item.value));
    } else {
      formChange(data?.value);
    }
    if (setSubValue?.name && setSubValue?.optionFieldName) {
      setValue(
        setSubValue?.name,
        data.hasOwnProperty(setSubValue?.optionFieldName)
          ? data?.[setSubValue?.optionFieldName]
          : ""
      );
    }
    setDropdowned(false);
    handleChange?.(data);
  };

  const renderOption = (label, value) => (
    <span className="d-flex align-items-center w-100">
      <ReactCountryFlag
        className="country-flag flag-icon me-50 h-100"
        countryCode={value.toLowerCase()}
        svg
      />
      <span className="">{label}</span>
    </span>
  );

  const renderDropdownTarget = (data, error) => {
    const localData = {
      label: data?.label,
      value: data?.value,
      code: data?.code,
      renderType: data?.renderType
    };
    return (
      <button
        name={dropdowned ? "active" : "not-active"}
        className={`app-select__dropdown-btn 
         ${error?.type ? "app-select__dropdown-btn-invalid" : ""}
        }`}
        disabled={isDisabled}
        onClick={() => setDropdowned(true)}
        style={dropdownButtonStyles}
      >
        {localData?.value || localData?.label ? (
          <div className="app-select__dropdown-btn-text">
            {!RENDER_TYPES.hasOwnProperty(localData?.renderType) &&
            localData?.label
              ? localData?.label
              : null}
            {localData?.renderType === RENDER_TYPES.COUNTRY &&
              renderOption(localData?.label, localData?.code)}
            {localData?.renderType === RENDER_TYPES.PHONE_PREFIX &&
              renderOption(localData?.value.slice(0, -3), localData?.code)}
          </div>
        ) : (
          <div className="app-select__dropdown-btn-placeholder">
            {placeholder}
          </div>
        )}
        <div className="app-select__dropdown-btn-icon">
          <IconSvg tag="arrowDown" />
        </div>
      </button>
    );
  };

  const Dropdown = ({ children, isOpen, target, onClose }) => (
    <div className="app-select__dropdown">
      {target}
      {isOpen ? (
        <div className="app-select__dropdown-menu" style={dropdownMenuStyles}>
          {children}
        </div>
      ) : null}
      {isOpen ? (
        <div className="app-select__dropdown-blanket" onClick={onClose} />
      ) : null}
    </div>
  );
  const noOptionsMessage = () => "Start typing to search ...";

  const DropdownIndicator = () => (
    <div className="app-select__dropdown-indicator">
      <IconSvg tag="search" />
    </div>
  );

  const Option = (props) => {
    const { label, code, renderType } = props.data;

    if (!renderType || !RENDER_TYPES.hasOwnProperty(renderType)) {
      return <components.Option {...props} />;
    }

    if (renderType === RENDER_TYPES.COUNTRY) {
      return (
        <components.Option {...props}>
          {renderOption(label, code)}
        </components.Option>
      );
    }

    if (renderType === RENDER_TYPES.PHONE_PREFIX) {
      return (
        <components.Option {...props}>
          {renderOption(label, code)}
        </components.Option>
      );
    }

    return <components.Option {...props} />;
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      margin: 4
    }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
    ...customStyles
  };

  return name && control ? (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: required, message: messageOff ? "" : errorText }
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className="app-select">
          {/* <label className="landing-form__item-select__label"> */}
          <Dropdown
            isOpen={dropdowned}
            onClose={() => setDropdowned(false)}
            target={renderDropdownTarget(currentValue(value), error)}
          >
            {async ? (
              <AsyncSelect
                className={"react-select"}
                classNamePrefix="select"
                cacheOptions
                menuIsOpen
                autoFocus
                controlShouldRenderValue={false}
                tabSelectsValue={false}
                styles={selectStyles}
                components={{
                  Option,
                  DropdownIndicator,
                  IndicatorSeparator: () => null
                }}
                defaultOptions={options}
                placeholder={placeholder}
                loadOptions={loadOptions}
                noOptionsMessage={noOptionsMessage}
                onChange={(data) => handleChangeSelect(data, onChange)}
                value={
                  options?.find((item) => item?.value === value) || {
                    value,
                    label: value
                  }
                }
              />
            ) : (
              <Select
                className={"react-select"}
                classNamePrefix="select"
                options={options}
                menuIsOpen
                placeholder={"Search..."}
                backspaceRemovesValue={false}
                components={{
                  Option,
                  DropdownIndicator,
                  IndicatorSeparator: () => null
                }}
                value={currentValue(value)}
                isSearchable
                autoFocus
                styles={selectStyles}
                controlShouldRenderValue={false}
                tabSelectsValue={false}
                onChange={(data) => handleChangeSelect(data, onChange)}
                isDisabled={isDisabled}
                isLoading={isLoading}
                {...rest}
              />
            )}
          </Dropdown>
          {/* </label> */}
          {!!error?.message && (
            <div className="invalid-feedback d-block">{error?.message}</div>
          )}
        </div>
      )}
    />
  ) : null;
};
