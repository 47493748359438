import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const useCurrentSeo = () => {
  // ** Hooks
  const { lang } = useParams();

  // ** Redux State
  const { content } = useSelector((state) => state.landing);

  // ** local State
  const [currentSeo, setCurrentSeo] = useState(
    lang && content?.data?.[lang] ? content?.data?.[lang] : {}
  );

  // ** Effects
  useEffect(() => {
    if (
      content?.isLoaded &&
      content?.data?.[lang] &&
      currentSeo?.locale !== lang
    ) {
      setCurrentSeo(content?.data?.[lang]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, content?.isLoaded]);

  return currentSeo;
};
