/* eslint-disable no-empty */
/* eslint-disable class-methods-use-this */
import React from "react";
import axios from "axios";
import { clearBrowserCache } from "@helpers";
// import { getIcon } from "@helpers";

const getBrowserInfo = () => {
  const returnObj = {};
  const isSpa =
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement;
  returnObj.renderMode = isSpa ? "SPA" : "SSR";
  if (isSpa) {
    returnObj.url = window.location.href;
    returnObj.innerHeight = window.innerHeight;
    returnObj.innerWidth = window.innerWidth;
    const _location = {};
    const _navigator = {};
    const _screen = {};
    try {
      for (const i in window.navigator) {
        if (!(typeof window.navigator[i] === "function")) {
          _navigator[i] = window.navigator[i];
        }
      }
    } catch (ignored) {}
    try {
      for (const i in window.location) {
        if (!(typeof window.location[i] === "function")) {
          _location[i] = window.location[i];
        }
      }
    } catch (ignored) {}
    try {
      for (const i in window.screen) {
        if (!(typeof window.screen[i] === "function")) {
          _screen[i] = window.screen[i];
        }
      }
    } catch (ignored) {}
    returnObj.userAgent = window.navigator.userAgent;
    returnObj.navigator = _navigator;
    returnObj.location = _location;
    returnObj.screen = _screen;
    // clearBrowserCache();
  }
  return returnObj;
};

const fillErrorInReport = (error, report) => {
  report.errorName = error?.name;
  report.errorMessage = error?.message;
  report.errorStack = error?.stack;
};

const fillErrorInfoInReport = (errorInfo, report) => {
  report.errorComponentStack = errorInfo?.componentStack;
};

const fillExtendedFieldsInReport = (extendedFieldsObject, report) => {
  report.extendedFields = extendedFieldsObject;
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, reportId: null };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    try {
      if (error?.name === "ChunkLoadError") {
        if (
          typeof window !== "undefined" &&
          window.document &&
          window.document.createElement
        ) {
          const bSkip = localStorage.getItem("app-error-boundary-skip");
          if (!bSkip) {
            localStorage.setItem("app-error-boundary-skip", "ChunkLoadError");
            clearBrowserCache();
            window.location.reload();
          } else {
            localStorage.removeItem("app-error-boundary-skip");
          }
        }
      }
      const reportObject = getBrowserInfo();
      fillErrorInReport(error, reportObject);
      fillErrorInfoInReport(errorInfo, reportObject);
      fillExtendedFieldsInReport({}, reportObject);
      axios.post("/api/front/error/report", reportObject).then((req) => {
        if (req?.status === 200) {
          this.setState({ reportId: req?.data });
        }
      });
    } catch (er) {
      // eslint-disable-next-line no-console
      console.error("Error in error boundary: ", er);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            display: "flex",
            textAlign: "center",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh"
          }}
        >
          {/* <img src={getIcon("logo")} alt={"logo"} width="214" height="42" /> */}
          <h1 style={{ margin: "24px", fontSize: "42px", fontWeight: "bold" }}>
            PROXYSELLER
          </h1>
          <h1 style={{ margin: "24px" }}>Ooops, Something went wrong.</h1>
          <h1 style={{ margin: "24px" }}>
            If you see this page first time, please clear your browser cache and
            try again.
          </h1>
          <h1 style={{ margin: "24px" }}>
            {this.state.reportId
              ? `Report id: ${this.state.reportId}`
              : "Send report..."}
          </h1>
          {this.state.reportId && (
            <>
              <h1 style={{ margin: "24px" }}>
                We apologize, our specialists received your error!
              </h1>
              <h1 style={{ margin: "24px" }}>
                {"Do not worry, they will quickly subjugate everything now :)"}
              </h1>
            </>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
