export const NOTIFICATION_STATIC_PARAMS = {
    num: 123,
    balance: 4001,
    paymentSystem: "Stripe",
    modemList: "101, 102, 104, 108",
    checkoutLink: "https://stage.mobile.proxy-seller.com/checkout/6295bafe7894ee735928fe89",
    userName: "RobSil",
    totalAmount: 380,
    monthCount: 6,
    usedBalance: 70,
    gotMoney: 40,
    amount: 380,
    hostName: "Host Rapid",
    modemNumber: 104,
    modemCount: 4,
    limit: 14000,
    lastDownload: 580,
    lastUpload: 680,
    modemEndDate: new Date(),
    firstName: "Rob",
    lastName: "Sil",
    countryName: "Ukraine",
    state: "Kyiv City",
    city: "Kyiv",
    postcode: "10001",
    totalPrice: "759",
    dongleKitDescription: "some",
    dongleKitPrice: "some",
    orderNumber: "someordernumber"
};
