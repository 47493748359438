import { useState } from "react";
import { Button, Container } from "@components/landing/ui";
import { useTranslation, useCurrentSeo } from "@hooks";
import { IconSvg } from "@constants";
import { ModalYouTubePlayer } from "./components/ModalYouTubePlayer";

import "./hero.scss";

export const Hero = () => {
  // *Hooks
  const { mainPage } = useTranslation();
  const { hero } = useCurrentSeo();

  // *State
  const [show, setShow] = useState(false);

  // *Function
  const handlerShow = () => setShow((state) => !state);
  return (
    <section className="landing_hero">
      <Container>
        <div className="landing_hero__wrapper">
          <div className="landing_hero__info">
            <h1>
              {hero?.title
                ? hero?.title
                    ?.split(" ")
                    .map((item, index) =>
                      hero?.pointedWords?.includes(item) ? (
                        <i key={index}>{`${item} `}</i>
                      ) : (
                        `${item} `
                      )
                    )
                : null}
            </h1>
            <ul className="landing_hero__list">
              {hero?.items?.length
                ? hero?.items.map((item, index) => (
                    <li key={index} className="landing_hero__item">
                      {item}
                    </li>
                  ))
                : null}
            </ul>
            <p className="section__text">{hero?.text}</p>
            <Button onClick={handlerShow}>
              {mainPage?.hero?.btn} <IconSvg tag="btnPlay" />
            </Button>
          </div>
          <div className="landing_hero__img">
            <IconSvg tag="heroImg" />
          </div>
        </div>
      </Container>
      <ModalYouTubePlayer show={show} close={handlerShow} />
    </section>
  );
};
