import { useLocation } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { useState } from "react";
// ** Custom Hooks & Utils
import { isNavGroupActive } from "../../utils";
// ** Components
import { HorizontalNavMenuItems } from "./HorizontalNavMenuItems";

export const HorizontalNavMenuGroup = (props) => {
  // ** Props
  const {
    item,
    groupActive,
    openDropdown,
    setGroupActive,
    setOpenDropdown,
    isChild = false
  } = props;
  // ** Hooks
  const { pathname } = useLocation();
  // ** States
  const [clicked, setClicked] = useState(false);

  // ** Functions
  // ** On mouse enter push the ID to openDropdown array
  const handlerMouseEnter = () => {
    const arr = openDropdown;
    arr.push(item.id);
    setOpenDropdown([...arr]);
  };

  // ** On mouse leave remove the ID to openDropdown array
  const handlerMouseLeave = () => {
    const arr = openDropdown;
    arr.splice(arr.indexOf(item.id), 1);
    setOpenDropdown([...arr]);
  };

  const handlerMouseClick = () => {
    if (clicked) {
      handlerMouseLeave();
    } else {
      handlerMouseEnter();
    }
    setClicked((state) => !state);
  };

  return (
    <Dropdown
      tag="li"
      className={`${isChild ? "" : "nav-item"} ${
        isNavGroupActive(item.children, pathname) ||
        groupActive.includes(item.id)
          ? "sidebar-group-active active"
          : ""
      }`}
      isOpen={openDropdown.includes(item.id)}
      // toggle={handlerMouseEnter}
      toggle={handlerMouseClick}
      onMouseEnter={handlerMouseEnter}
      onMouseLeave={handlerMouseLeave}
    >
      <DropdownToggle
        tag={"a"}
        href="/"
        className={`dropdown-toggle d-flex align-items-center ${
          isChild ? "dropdown-item" : "nav-link"
        }`}
        onClick={(e) => e.preventDefault()}
      >
        {item.icon}
        <span>{item.title}</span>
      </DropdownToggle>
      <DropdownMenu
        container="body"
        tag="ul"
        className="dropdown-menu-group mt-50 media-list overflow-auto"
        // modifiers={menuModifiers}
      >
        <HorizontalNavMenuItems
          items={item.children}
          groupActive={groupActive}
          openDropdown={openDropdown}
          setGroupActive={setGroupActive}
          setOpenDropdown={setOpenDropdown}
          isChild={true}
        />
      </DropdownMenu>
    </Dropdown>
  );
};
