/* eslint-disable import/no-named-as-default */
import ReCAPTCHA from "react-google-recaptcha";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Form } from "@components/landing/form";
import { Button } from "@components/landing/ui";
import { apiService } from "@services/ApiService";
import { useTranslation, useCurrentSeo } from "@hooks";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";

export const FaqModal = ({ closeModal }) => {
  // **Refs
  const recaptchaRef = useRef();
  // **Hooks
  const methods = useForm();
  const {
    mainPage: { ticketsMessage, placeholders, errors }
  } = useTranslation();
  const { support } = useCurrentSeo();

  // **Redux State
  const { site } = useSelector((state) => state.settings);

  // **State
  const [submitting, setSubmitting] = useState(false);

  // **Functions
  const sendTicket = (captchaResponse = "none") => {
    setSubmitting(true);
    const data = methods.getValues();
    const params = { ...data, captchaResponse };

    apiService.tickets
      .createFromForm(params)
      .then((res) => {
        if (res.status === 200) {
          toast.success(`${ticketsMessage?.success} ${res?.data?.number}`);
          methods.reset();
          closeModal();
        } else if (res?.response?.status === 412) {
          toast.error(errors?.captcha);
        } else if (
          res?.response?.status === 400 &&
          res?.response?.data === "EMAIL_IS_INVALID"
        ) {
          toast.error(ticketsMessage?.errorEmail);
        } else {
          toast.error(ticketsMessage?.error);
        }
      })
      .catch((error) => toast.error(error?.message || error))
      .finally(() => {
        setSubmitting(false);
        recaptchaRef?.current?.reset();
      });
  };

  const submit = () => {
    if (
      site?.data?.captcha?.enabled &&
      site?.data?.captcha?.siteKeyV2 &&
      recaptchaRef.current
    ) {
      recaptchaRef.current.execute();
    } else {
      sendTicket();
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className="landing-modal__form"
        onSubmit={methods.handleSubmit(submit)}
        noValidate
      >
        <div className="landing-support__input">
          <Form.Input name="name" placeholder={placeholders?.name} required />
        </div>
        <div className="landing-support__input">
          <Form.Input
            name="email"
            type="email"
            placeholder={placeholders?.email}
            required
          />
        </div>
        <div className="landing-support__input">
          <Form.Input
            name="text"
            type="textarea"
            placeholder={placeholders?.message}
            required
          />
        </div>
        {site?.data?.captcha?.enabled && site?.data?.captcha?.siteKeyV2 && (
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={site?.data?.captcha?.siteKeyV2}
            size="invisible"
            onChange={sendTicket}
          />
        )}
        <Button type="submit" disabled={submitting} loading={submitting}>
          {support?.btn}
        </Button>
      </form>
    </FormProvider>
  );
};
