import { useEffect, useState } from "react";
import { useCurrentSeo, useTranslation } from "@hooks";
import { SendRequest } from "../SendRequest/SendRequest";
import { TAB_KEYS } from "../tabs/Tabs";
import { SwiperDongle } from "../SwiperContainer/SwiperDongle";
import { SwiperTariff } from "../SwiperContainer/SwiperTariff";

export const ContentSPA = ({ activeTab }) => {
  // *Hooks
  const currentSeo = useCurrentSeo();
  const { mainPage: { use: { tariffs: { enterprise } } } } = useTranslation();

  // *States
  const [localList, setLocalList] = useState([]);

  // *Effects
  useEffect(() => {
    if (currentSeo?.[activeTab]) {
      let list = [];
      if (activeTab === TAB_KEYS.TARIFF) {
        list = [...currentSeo[activeTab],
        {
          id: "enterprise",
          name: "enterprise",
          publicName: enterprise?.publicName,
          description: enterprise?.description,
          price: enterprise?.price,
          advantages: enterprise?.advantages
        }];
      } else {
        list = [...currentSeo[activeTab]];
      }
      setLocalList(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, currentSeo]);

  return localList?.length ? (
    <div className="landing-use__content">
      {activeTab === TAB_KEYS.TARIFF && <SwiperTariff items={localList} />}
      {activeTab === TAB_KEYS.DONGLE && <SwiperDongle items={localList} />}
      {activeTab === TAB_KEYS.DONGLE && <SendRequest />}
    </div>
  ) : null;
};
