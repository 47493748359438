export const DEMO_HOSTS = [
  {
    id: "testhost01",
    name: "Host 1",
    modemCount: 7,
    online: true
  },
  {
    id: "testhost02",
    name: "Host 2",
    modemCount: 5,
    online: true
  },
  {
    id: "testhost03",
    name: "Host 3",
    modemCount: 5,
    online: false
  }
];
