export const HostModemsColumnsSelector = [
  {
    name: "Modem",
    selector: "number"
  },
  {
    name: "Status",
    selector: "status"
  },
  {
    name: "Provider",
    selector: "sim"
  },
  {
    name: "Proxy",
    selector: "proxy"
  },
  {
    name: "Ip",
    selector: "ip"
  },
  {
    name: "Rotation",
    selector: "rotation"
  },
  {
    name: "Updated",
    selector: "lastUpdate"
  },
  {
    name: "Sms",
    selector: "sms"
  },
  {
    name: "Notes",
    selector: "notes"
  },
  {
    name: "Action",
    selector: "action"
  }
];
