import { useCurrentSeo } from "@hooks";
import { IconSvg } from "@constants";

export const SocialList = () => {
  // **Hooks
  const { footer } = useCurrentSeo();

  return (
    <ul className="landing-footer__social-list">
      {footer?.social?.items?.length
        ? footer.social.items.map((item, index) => (
            <li className="landing-footer__social-item" key={index}>
              <a className="landing-footer__social-link" target={"_blank"} href={item?.link} rel="noreferrer">
                <IconSvg tag={item?.value} />
              </a>
            </li>
          ))
        : null}
    </ul>
  );
};
