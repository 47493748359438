import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useCurrentTheme = () => {
  // ** Redux State
  const { skin } = useSelector((state) => state.theme);

  // ** Effects
  useEffect(() => {
    // ** Get Body Tag
    const element = window.document.body;

    // ** Define classnames for skins
    const classNames = { dark: "dark-layout" };

    // ** Remove all classes from Body on mount
    element.classList.remove(...element.classList);

    // ** If skin is not light add skin class
    if (classNames.hasOwnProperty(skin)) {
      element.classList.add(classNames[skin]);
    }
    return () => element.classList.remove(...element.classList);
  }, [skin]);
};
