import { createSlice } from "@reduxjs/toolkit";
import { isSSR } from "@helpers";
import {
  fetchHosts,
  fetchHostModems,
  fetchKnowladgeBase,
  fetchAgreements,
  fetchSeoEdit,
  renameHost
} from "./Content.thunks";

const initialContentState = () => {
  let rowsPerPage = null;
  let showedColumns = null;
  let currentPage = null;
  if (!isSSR()) {
    rowsPerPage = localStorage.getItem("homePageModemsTableRowsPerPage");
    showedColumns = localStorage.getItem("homePageModemsShowedColumns");
    currentPage = localStorage.getItem("homePageModemsTableCurrentPage");
  }

  return {
    hosts: { data: [], isLoaded: false, fetchError: null },
    hostModems: {
      data: [],
      reloadTrigger: false,
      activeHostId: null,
      activeHostModemsAmmout: 0,
      clearTableSelect: false,
      isLoaded: false,
      fetchError: null,
      rowsPerPage: rowsPerPage ? JSON.parse(rowsPerPage) : 10,
      currentPage: currentPage ? JSON.parse(currentPage) : 1,
      showedColumns: showedColumns
        ? JSON.parse(showedColumns)
        : {
            number: true,
            sim: true,
            ip: true,
            proxy: true,
            rotation: true,
            lastUpdate: true,
            status: true,
            sms: true,
            notes: true,
            action: true,
            blur: true,
            simNumCustom: true
          }
    },
    knowladgeBase: { data: [], isLoaded: false, fetchError: null },
    agreements: { data: {}, isLoaded: false, fetchError: null },
    seoEdit: { data: {}, languages: [], isLoaded: false, fetchError: null }
  };
};

const ContentReducer = createSlice({
  name: "Content",
  initialState: initialContentState(),
  reducers: {
    clearContentState(state) {
      const temp = initialContentState();
      state.hosts = temp.hosts;
      state.hostModems = temp.hostModems;
    },
    clearTableSelect(state) {
      state.hostModems.clearTableSelect = !state.hostModems.clearTableSelect;
    },
    setReloadModemsTrigger(state) {
      state.hostModems.reloadTrigger = !state.hostModems.reloadTrigger;
    },
    setRowsPerPage(state, action) {
      state.hostModems.rowsPerPage = action.payload;
      try {
        localStorage.setItem("homePageModemsTableRowsPerPage", action.payload);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    setCurrentPage(state, action) {
      state.hostModems.currentPage = action.payload;
      try {
        localStorage.setItem("homePageModemsTableCurrentPage", action.payload);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    setShowedColumns(state, action) {
      state.hostModems.showedColumns = action.payload;
      try {
        localStorage.setItem(
          "homePageModemsShowedColumns",
          JSON.stringify(action.payload)
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    setActiveHostId(state, action) {
      state.hostModems.activeHostId = action.payload;
      const host = state.hosts.data.find((item) => item.id === action.payload);
      state.hostModems.activeHostModemsAmmout = host?.modemCount || 0;
      state.hostModems.isLoaded = false;
      state.hostModems.clearTableSelect = !state.hostModems.clearTableSelect;
    },
    setHostModems(state, action) {
      state.hostModems.data = action.payload;
      state.hostModems.isLoaded = true;
    },
    demoSetHosts(state, action) {
      state.hosts.data = action.payload;
    },
    setLoadingHostModems(state) {
      state.hostModems.isLoaded = false;
    },
    demoSetHostModems(state, action) {
      state.hostModems.clearTableSelect = false;
      state.hostModems.data = action.payload;
      state.hostModems.isLoaded = true;
    },
    clearSeoEditData(state) {
      state.seoEdit.data = {};
      state.seoEdit.languages = [];
      state.seoEdit.isLoaded = false;
      state.seoEdit.fetchError = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHosts.pending, (state) => {
      // state.hosts.isLoaded = false;
      state.hosts.fetchError = null;
    });
    builder.addCase(fetchHosts.fulfilled, (state, action) => {
      state.hosts.data = action.payload.data;
      if (state.hostModems.activeHostId === null && action.payload.data?.[0]) {
        state.hostModems.activeHostId = action.payload.data[0].id;
        state.hostModems.activeHostModemsAmmout =
          action.payload.data[0].modemCount;
      }
      state.hosts.isLoaded = true;
    });
    builder.addCase(fetchHosts.rejected, (state, action) => {
      state.hosts.fetchError = action.payload;
      if (action?.error?.name !== "AbortError") {
        state.hosts.isLoaded = true;
      }
    });
    builder.addCase(fetchHostModems.pending, (state) => {
      // state.hostModems.isLoaded = false;
      state.hostModems.fetchError = null;
    });
    builder.addCase(fetchHostModems.fulfilled, (state, action) => {
      state.hostModems.clearTableSelect = false;
      state.hostModems.data = action.payload.data;
      state.hostModems.isLoaded = true;
    });
    builder.addCase(fetchHostModems.rejected, (state, action) => {
      state.hostModems.fetchError = action.payload;
      if (action?.error?.name !== "AbortError") {
        state.hostModems.isLoaded = true;
      }
    });
    builder.addCase(fetchKnowladgeBase.pending, (state) => {
      state.knowladgeBase.isLoaded = false;
      state.knowladgeBase.fetchError = null;
    });
    builder.addCase(fetchKnowladgeBase.fulfilled, (state, action) => {
      state.knowladgeBase.data = action.payload.data;
      state.knowladgeBase.isLoaded = true;
    });
    builder.addCase(fetchKnowladgeBase.rejected, (state, action) => {
      state.knowladgeBase.fetchError = action.payload;
      state.knowladgeBase.isLoaded = true;
    });
    builder.addCase(fetchAgreements.pending, (state) => {
      state.agreements.isLoaded = false;
      state.agreements.fetchError = null;
    });
    builder.addCase(fetchAgreements.fulfilled, (state, action) => {
      state.agreements.data = {
        ...state.agreements.data,
        [action.payload.locale]: action.payload.data
      };
      state.agreements.isLoaded = true;
    });
    builder.addCase(fetchAgreements.rejected, (state, action) => {
      state.agreements.fetchError = action.payload;
      state.agreements.isLoaded = true;
    });
    builder.addCase(fetchSeoEdit.pending, (state) => {
      state.seoEdit.isLoaded = false;
      state.seoEdit.fetchError = null;
    });
    builder.addCase(fetchSeoEdit.fulfilled, (state, action) => {
      state.seoEdit.data = action.payload.data;
      state.seoEdit.languages = action.payload.languages;
      state.seoEdit.isLoaded = true;
    });
    builder.addCase(fetchSeoEdit.rejected, (state, action) => {
      state.seoEdit.data = {};
      state.seoEdit.languages = [];
      state.seoEdit.fetchError = action.payload;
      state.seoEdit.isLoaded = true;
    });
  }
});

export const ContentReducerActions = {
  ...ContentReducer.actions,
  fetchHosts,
  fetchHostModems,
  fetchKnowladgeBase,
  fetchAgreements,
  fetchSeoEdit,
  renameHost
};
export default ContentReducer.reducer;
