import Login from "@views/app/authorization/Login";
import { Navigate } from "react-router-dom";

export const AuthRoutes = [
  {
    path: "login/",
    element: <Login />
  },
  {
    path: "recovery/:hash",
    async lazy() {
      const { ResetPassword } = await import(
        "@views/app/authorization/ResetPassword"
      );
      return {
        Component: ResetPassword
      };
    }
  },
  {
    path: "recovery/",
    async lazy() {
      const { PasswordRecovery } = await import(
        "@views/app/authorization/PasswordRecovery"
      );
      return {
        Component: PasswordRecovery
      };
    }
  },
  {
    path: "confirm-send",
    async lazy() {
      const { RecoveryEmailSent } = await import(
        "@views/app/authorization/RecoveryEmailSent"
      );
      return {
        Component: RecoveryEmailSent
      };
    }
  },
  {
    path: "registration",
    async lazy() {
      const { Register } = await import("@views/app/authorization/Register");
      return {
        Component: Register
      };
    }
  },
  {
    path: "email/:operation/:data",
    async lazy() {
      const { EmailOperations } = await import(
        "@views/app/authorization/EmailOperations"
      );
      return {
        Component: EmailOperations
      };
    }
  },
  {
    path: "register/success/:email",
    async lazy() {
      const { PostRegister } = await import(
        "@views/app/authorization/PostRegister"
      );
      return {
        Component: PostRegister
      };
    }
  },
  {
    path: "not-authorized",
    async lazy() {
      const { NotAuthorized } = await import(
        "@views/app/authorization/NotAuthorized"
      );
      return {
        Component: NotAuthorized
      };
    }
  },
  {
    path: "*", // redirect to login page
    element: <Navigate to="./login/" replace={true} />
  }
];
