import { AuthReducerActions } from "./AuthReducer/Auth.reducer";
import { ContentReducerActions } from "./ContentReducer/Content.reducer";
import { ModalsReducerActions } from "./ModalsReducer/Modals.reducer";
import { TranslationReducerActions } from "./TranslationReducer/Translation.reducer";
import { DemoReducerActions } from "./DemoReducer/Demo.reducer";
import { LandingReducerActions } from "./LandingReducer/Landing.reducer";
import { SettingsReducerActions } from "./SettingsReducer/Settings.reducer";
import { NavBarReducerActions } from "./NavBarReducer/NavBar.reducer";
import { ThemeReducerActions } from "./ThemeReducer/Theme.reducer";

export const AllActions = {
  ...AuthReducerActions,
  ...ContentReducerActions,
  ...ModalsReducerActions,
  ...TranslationReducerActions,
  ...DemoReducerActions,
  ...LandingReducerActions,
  ...SettingsReducerActions,
  ...NavBarReducerActions,
  ...ThemeReducerActions
};
