import { useEffect, useState } from "react";

import { useWindowWidth } from "./useWindowWidth";

export const useScrollBarWidth = () => {
  // ** local State
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const windowWidth = useWindowWidth();

  // ** Effects
  useEffect(() => {
    setScrollbarWidth(window.innerWidth - document.body.offsetWidth);
  }, [windowWidth]);

  return scrollbarWidth;
};
