import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "@services/ApiService";

export const fetchModemUsageInfo = createAsyncThunk(
  "NavBar/fetchModemUsageInfo",
  async (isDemo, { rejectWithValue, getState, signal }) => {
    if (isDemo) {
      const { demo } = getState();
      const { modems } = demo?.content;
      let onlineModemsCount = 0;
      let offlineModemsCount = 0;
      const keys = Object.keys(modems);
      keys.forEach((key) => {
        const temp = modems[key];
        temp.forEach((item) => {
          if (item?.use) {
            onlineModemsCount += 1;
          } else {
            offlineModemsCount += 1;
          }
        });
      });
      return { onlineModemsCount, offlineModemsCount };
    }
    try {
      const response = await apiService.modems.getStatus(signal);
      if (response?.status === 200) {
        return response.data;
      }
      throw response;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const fetchNotifications = createAsyncThunk(
  "NavBar/fetchNotifications",
  async (isDemo, { rejectWithValue, getState, signal }) => {
    if (isDemo) {
      const { demo } = getState();
      const { notifications } = demo?.content;
      return { data: notifications, count: notifications.length };
    }
    try {
      const response = await apiService.notifications.get(signal);
      if (response?.status === 200) {
        return { data: response.data, count: response.data.length };
      }
      throw response;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const fetchReportsForAdmin = createAsyncThunk(
  "NavBar/fetchReportsForAdmin",
  async (_, { rejectWithValue, signal }) => {
    try {
      const response = await apiService.modemReports.adminGetCountOfNEW(signal);
      if (response?.status === 200) {
        if (typeof response.data === "number") {
          return response.data;
        } else {
          return 0;
        }
      }
      throw response;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);
