export const AdminPublicApiLinkRoutes = [
  {
    path: "public-api-link/edit/",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminPublicApiLink } = await import(
            "@views/app/admin/KeysFormServices/PublicApiLink"
          );
          return {
            Component: AdminPublicApiLink
          };
        }
      }
    ]
  }
];
