import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Input } from "reactstrap";
// ** Custom Hooks & Utils
import { useDebounceValue, useDispatchedActions } from "@hooks";
// ** Services
import { apiService } from "@services/ApiService";

const NoteInput = ({ row, admin }) => {
  // ** Dispatched Actions
  const { setUpdateModems } = useDispatchedActions();
  // ** Redux State
  const { isDemo, content } = useSelector((state) => state.demo);
  const { activeHostId } = useSelector((state) => state.content.hostModems);
  // ** States
  const [value, setValue] = useState("");
  const [checkValue, setCheckValue] = useState("");
  const debounce = useDebounceValue(value, 1000);

  // ** Functions
  const onChangeNote = (e) => setValue(e.target.value);

  // ** Effects
  useEffect(() => {
    if (debounce !== checkValue) {
      if (!isDemo) {
        apiService.modems.setNote(debounce, row?.id).then((req) => {
          if (req?.data) {
            toast.success("Successfully saved note!");
            setCheckValue(debounce);
          } else {
            toast.error("Error save note!");
          }
        });
      } else {
        // demo logik start
        const activeHostModems = content.modems[activeHostId];
        const newModems = {
          ...content.modems,
          [activeHostId]: activeHostModems.map((item) => {
            if (row.id === item.id) {
              return {
                ...item,
                lastUpdate: new Date().getTime(),
                note: debounce
              };
            } else return item;
          })
        };
        setUpdateModems(newModems);
        toast.success("Successfully saved note!");
        // demo logik end
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce]);

  return (
    <Input
      onChange={onChangeNote}
      defaultValue={row?.paid ? row?.note : ""}
      type="textarea"
      rows="1"
      disabled={!row?.paid && !admin}
      style={{ resize: "none" }}
      className={`${!row?.paid && !admin ? "blur-box" : "form-control "}`}
    />
  );
};

export const staticNotes = (admin = false) => ({
  id: "notes",
  name: "Notes",
  // selector: "notes",
  minWidth: "250px",
  key: "12",
  cell: (row) => <NoteInput row={row} admin={admin} />
});
