import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// ** Custom Hooks & Utils
import { useCurrentSeo } from "@hooks";
// ** Components
import { MetaDate } from "@components/Metadate";
import { Crm } from "./components/crm/crm";
import { Faq } from "./components/faq/faq";
import { Get } from "./components/get/get";
import { Hero } from "./components/hero/hero";
import { Seo } from "./components/seo/seo";
import { Support } from "./components/support/support";
import { Use } from "./components/use/use";
import { Work } from "./components/work/work";

const Landing = () => {
  const { lang } = useParams();
  const currentSeo = useCurrentSeo();
  const { site } = useSelector((state) => state.settings);
  return (
    <>
      <MetaDate
        lang={lang}
        title={currentSeo?.metadata?.title}
        description={currentSeo?.metadata?.description}
        indexRobots={site?.data?.robots?.enabled}
      />
      <Hero />
      <Work />
      <Use />
      <Get />
      <Crm />
      <Faq />
      <Support />
      <Seo />
    </>
  );
};

export default Landing;
