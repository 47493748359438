import { Controller, useFormContext } from "react-hook-form";
import { useState } from "react";
import { ReactCountryFlag } from "react-country-flag";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
// ** Custom Hooks & Utils
import { IconSvg } from "@constants";
import { useTranslation } from "@hooks";
// ** Styles
import "./selectV2.scss";

// ** Constants
export const RENDER_TYPES = {
  COUNTRY: "COUNTRY",
  PHONE_PREFIX: "PHONE_PREFIX"
};

export const SelectPopout = ({
  name,
  label = "",
  placeholder = "",
  options = [],
  async = false,
  loadOptions = () => [],
  required = false,
  setSubValue = {},
  isMulti = false,
  isDisabled = false,
  isLoading = false,
  messageOff = false,
  handleChange,
  ...rest
}) => {
  // ** Hooks
  const { control, setValue } = useFormContext();
  const [dropdowned, setDropdowned] = useState(false);
  const { mainPage } = useTranslation();

  // ** Functions
  const currentValue = (value) => {
    let currentValue;
    if (async) {
      currentValue = options.find((option) => value === option.value);
      if (currentValue) {
        return currentValue;
      } else if (value) {
        return { value, label: value };
      }
    }
    if (isMulti) {
      currentValue = options.filter((option) => value?.includes(option.value));
    } else {
      currentValue = options.find((option) => value === option.value);
    }

    if (currentValue) {
      return currentValue;
    }

    return null;
  };

  const handleChangeSelect = (data, formChange) => {
    if (isMulti) {
      formChange(data.map((item) => item.value));
    } else {
      formChange(data?.value);
    }
    if (setSubValue?.name && setSubValue?.optionFieldName) {
      setValue(
        setSubValue?.name,
        data.hasOwnProperty(setSubValue?.optionFieldName)
          ? data?.[setSubValue?.optionFieldName]
          : ""
      );
    }
    setDropdowned(false);
    handleChange?.(data);
  };

  const renderOption = (label, value) => (
    <div className="d-flex align-items-center w-100">
      <ReactCountryFlag
        className="country-flag flag-icon me-50 h-100"
        countryCode={value.toLowerCase()}
        svg
        alt="flag icon"
      />
      <span className="country-name">{label}</span>
    </div>
  );
  const renderDropdownTarget = (data, error) => {
    const localData = {
      label: data?.label,
      value: data?.value,
      code: data?.code,
      renderType: data?.renderType
    };
    return (
      <button
        // name={dropdowned ? "active" : "not-active"}
        className={`landing-form__item-selectV2__dropdown-btn ${
          error?.type ? "landing-form__item-selectV2__dropdown-btn-error" : ""
        }`}
        onClick={() => setDropdowned(true)}
      >
        <span className="landing-form__item-selectV2__dropdown-btn-text">
          {!RENDER_TYPES.hasOwnProperty(localData?.renderType) &&
          localData?.label
            ? localData?.label
            : null}
          {localData?.renderType === RENDER_TYPES.COUNTRY &&
            renderOption(localData?.label, localData?.code)}
          {localData?.renderType === RENDER_TYPES.PHONE_PREFIX &&
            renderOption(localData?.value.slice(0, -3), localData?.code)}
        </span>
        <span className="landing-form__item-selectV2__dropdown-btn-icon">
          <IconSvg tag="arrowDown" />
        </span>
        {localData?.renderType !== RENDER_TYPES.PHONE_PREFIX && (
          <span
            className={`landing-form__item-selectV2__label-name ${
              data ? "landing-form__item-selectV2__label-name-active" : ""
            }
              ${
                isDisabled
                  ? "landing-form__item-selectV2__label-name-disabled"
                  : ""
              }
              `}
          >
            {label}
            {required && !name.toLowerCase().includes("prefix") && (
              <span className="landing-form__item-selectV2__label-name-required">
                *
              </span>
            )}
          </span>
        )}
      </button>
    );
  };

  const Dropdown = ({ children, isOpen, target, onClose }) => (
    <div className="select__dropdown">
      {target}
      {isOpen ? <div className="select__dropdown-menu">{children}</div> : null}
      {isOpen ? (
        <div className="select__dropdown-blanket" onClick={onClose} />
      ) : null}
    </div>
  );
  const noOptionsMessage = () => mainPage?.use?.form?.placeholders?.noOptions;
  const DropdownIndicator = () => (
    <div className="select__dropdown-indicator">
      <IconSvg tag="search" />
    </div>
  );

  const Option = (props) => {
    const { label, code, renderType } = props.data;

    if (!renderType || !RENDER_TYPES.hasOwnProperty(renderType)) {
      return <components.Option {...props} />;
    }

    if (renderType === RENDER_TYPES.COUNTRY) {
      return (
        <components.Option {...props}>
          {renderOption(label, code)}
        </components.Option>
      );
    }

    if (renderType === RENDER_TYPES.PHONE_PREFIX) {
      return (
        <components.Option {...props}>
          {renderOption(label, code)}
        </components.Option>
      );
    }

    return <components.Option {...props} />;
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      margin: 4
    }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" })
  };

  const currentErrorMessage = (error) => {
    if (messageOff) return "";

    if (error?.type === "required") {
      return mainPage?.errors?.requiredField;
    }

    return error?.message;
  };

  return name && control ? (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: required, message: mainPage?.errors?.requiredField }
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className="landing-form__item-selectV2">
          {/* <label className="landing-form__item-select__label"> */}
          <Dropdown
            isOpen={dropdowned}
            onClose={() => setDropdowned(false)}
            target={renderDropdownTarget(currentValue(value), error)}
          >
            {async ? (
              <AsyncSelect
                className={"landing__form__selectV2"}
                classNamePrefix="landing__form__selectV2"
                cacheOptions
                menuIsOpen
                autoFocus
                controlShouldRenderValue={false}
                tabSelectsValue={false}
                styles={selectStyles}
                components={{
                  Option,
                  DropdownIndicator,
                  IndicatorSeparator: () => null
                }}
                defaultOptions={options}
                placeholder={placeholder}
                loadOptions={loadOptions}
                noOptionsMessage={noOptionsMessage}
                onChange={(data) => handleChangeSelect(data, onChange)}
                value={
                  options?.find((item) => item?.value === value) || {
                    value,
                    label: value
                  }
                }
              />
            ) : (
              <Select
                className={`landing__form__selectV2 ${
                  error?.type ? "landing__form__selectV2-error" : ""
                }`}
                menuIsOpen
                classNamePrefix="landing__form__selectV2"
                options={options}
                placeholder={placeholder}
                backspaceRemovesValue={false}
                components={{
                  Option,
                  DropdownIndicator,
                  IndicatorSeparator: () => null
                }}
                value={currentValue(value)}
                isSearchable
                autoFocus
                styles={selectStyles}
                controlShouldRenderValue={false}
                tabSelectsValue={false}
                onChange={(data) => handleChangeSelect(data, onChange)}
                isDisabled={isDisabled}
                isLoading={isLoading}
                {...rest}
              />
            )}
          </Dropdown>
          {/* </label> */}
          {!messageOff && error?.type && (
            <p className="landing-form__item-error">
              {currentErrorMessage(error)}
            </p>
          )}
        </div>
      )}
    />
  ) : null;
};
