import { Navigate } from "react-router-dom";

export const AdminLandingContentRoutes = [
  {
    path: "landing-content/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminLandingContent } = await import(
            "@views/app/admin/landingContent/"
          );
          return {
            Component: AdminLandingContent
          };
        }
      },
      {
        path: ":tab/:id?",
        async lazy() {
          const { AdminLandingContent } = await import(
            "@views/app/admin/landingContent/"
          );
          return {
            Component: AdminLandingContent
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
