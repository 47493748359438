import { useEffect, useState} from "react";
import { useScrollBarWidth } from "./useScrollBarWidth";

export const useModal = () => {
  // ** local State
  const [modalActive, setModalActive] = useState(false);
  const scrollbarWidth = useScrollBarWidth();

  // ** Functions
  const modalToggler = () => {
    setModalActive(true);
  };

  // ** Effects
  useEffect(() => {
    if (modalActive) {
      document.documentElement.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      document.documentElement.style.paddingRight = "";
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalActive]);

  return [modalToggler, modalActive, setModalActive];
};
