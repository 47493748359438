export const BlurContent = (props) => {
  // ** Props
  const { children, className, style, blur, content, degree } = props;

  // ** Constants
  const componentClassName = className ? className : "";
  let componentStyle = { position: "relative" };
  if (typeof style === "object") {
    componentStyle = { ...componentStyle, ...style };
  }
  const blurStyle = {
    filter: `blur(${degree ? degree : 5}px)`,
    pointerEvents: "none",
    userSelect: "none"
  };
  const contentStyle = {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1
  };

  const Content = () => {
    if (typeof content === "function") {
      return <div style={contentStyle}>{content()}</div>;
    } else {
      return <div style={contentStyle}>{content}</div>;
    }
  };

  return (
    <div className={componentClassName} style={componentStyle}>
      {blur && content && <Content />}
      <div style={blur ? blurStyle : {}}>{children}</div>
    </div>
  );
};
