import {
  Archive,
  AtSign,
  Book,
  CheckSquare,
  Clock,
  Cloud,
  Code,
  DollarSign,
  FileText,
  Folder,
  FolderPlus,
  HardDrive,
  Home,
  Inbox,
  Key,
  Layers,
  Link,
  List,
  Mail,
  Plus,
  Radio,
  Server,
  Settings,
  ShoppingBag,
  Terminal,
  Users
} from "react-feather";

export const NETWORK_HARDWARE = [
  {
    id: "aggregators",
    title: "Aggregators",
    showFor: ["ADMIN", "SUPERUSER"],
    icon: <Server />,
    path: "/app/admin/aggregator/",
    children: [
      {
        id: "aggregator-list",
        title: "List",
        showFor: ["ADMIN", "SUPERUSER"],
        path: "/app/admin/aggregator/",
        icon: <List />
      },
      {
        id: "aggregator-add",
        title: "Add",
        showFor: ["ADMIN", "SUPERUSER"],
        path: "/app/admin/aggregator/add/",
        icon: <Plus />
      },
      {
        id: "aggregator-settings",
        title: "Settings",
        showFor: ["ADMIN", "SUPERUSER"],
        path: "/app/admin/aggregator/settings/",
        icon: <Settings />
      }
    ]
  },
  {
    id: "hosts",
    title: "Hosts",
    showFor: ["ADMIN", "SUPERUSER"],
    icon: <HardDrive />,
    path: "/app/admin/host/",
    children: [
      {
        id: "host-list",
        title: "List",
        showFor: ["ADMIN", "SUPERUSER"],
        path: "/app/admin/host/",
        icon: <List />
      },
      {
        id: "host-history",
        title: "History",
        showFor: ["ADMIN", "SUPERUSER"],
        path: "/app/admin/host/history/",
        icon: <Archive />
      }
    ]
  },
  {
    id: "modems",
    title: "Modems",
    showFor: ["ADMIN", "SUPERUSER"],
    icon: <Radio />,
    path: "/app/admin/modem/",
    children: [
      {
        id: "modem-list",
        title: "List",
        showFor: ["ADMIN", "SUPERUSER"],
        path: "/app/admin/modem/",
        icon: <List />
      },
      {
        id: "modem-history",
        title: "History",
        showFor: ["ADMIN", "SUPERUSER"],
        path: "/app/admin/modem/history/",
        icon: <Archive />
      },
      {
        id: "modem-history-settings",
        title: "History settings",
        showFor: ["ADMIN", "SUPERUSER"],
        path: "/app/admin/modem/history/settings/",
        icon: <Settings />
      }
    ]
  },

  {
    id: "proxy-servers",
    title: "Proxy servers",
    showFor: ["ADMIN", "SUPERUSER"],
    path: "/app/admin/proxy-server/",
    icon: <Cloud />,
    children: [
      {
        id: "proxy-server-list",
        title: "List",
        showFor: ["ADMIN", "SUPERUSER"],
        path: "/app/admin/proxy-server/",
        icon: <List />
      },
      {
        id: "proxy-server-add",
        title: "Add",
        showFor: ["ADMIN", "SUPERUSER"],
        path: "/app/admin/proxy-server/add/",
        icon: <Plus />
      }
    ]
  },
  {
    id: "scripts",
    title: "Scripts",
    showFor: ["ADMIN", "SUPERUSER"],
    icon: <Code />,
    path: "/app/admin/script/",
    children: [
      {
        id: "script-list",
        title: "List",
        showFor: ["ADMIN", "SUPERUSER"],
        path: "/app/admin/script/",
        icon: <List />
      },
      {
        id: "script-runtime",
        title: "Runtime",
        showFor: ["ADMIN", "SUPERUSER"],
        path: "/app/admin/script/run/",
        icon: <Clock />
      }
    ]
  }
];

export const USERS_AND_ACTIVITY = [
  {
    id: "users",
    title: "Users",
    showFor: ["ADMIN", "SUPERUSER"],
    path: "/app/admin/users/",
    icon: <Users />
  },
  {
    id: "orders",
    title: "Orders",
    showFor: ["ADMIN", "SUPERUSER"],
    path: "/app/admin/orders/",
    icon: <ShoppingBag />
  },
  {
    id: "payments",
    title: "Payments",
    showFor: ["ADMIN", "SUPERUSER"],
    path: "/app/admin/payments/",
    icon: <FileText />
  },
  {
    id: "checkouts",
    title: "Checkouts",
    showFor: ["ADMIN", "SUPERUSER"],
    path: "/app/admin/checkout/",
    icon: <Folder />
  },
  {
    id: "modem-checkouts",
    title: "Modem checkouts",
    showFor: ["ADMIN", "SUPERUSER"],
    path: "/app/admin/checkout-modem/",
    icon: <FolderPlus />
  },
  {
    id: "reports",
    title: "Reports",
    showFor: ["ADMIN", "SUPERUSER"],
    path: "/app/admin/reports/",
    icon: <Inbox />
  },
  {
    id: "tickets",
    title: "Tickets",
    showFor: ["ADMIN", "SUPERUSER"],
    path: "/app/admin/tickets/",
    icon: <AtSign />
  },
  {
    id: "auth-tokens",
    title: "Auth tokens",
    showFor: ["ADMIN", "SUPERUSER"],
    path: "/app/admin/auth-token/",
    icon: <FileText />
  }
];

export const SITE_SETTINGS = [
  {
    id: "tariffs",
    title: "Tariffs",
    showFor: ["SUPERUSER"],
    path: "/app/admin/tariff/",
    icon: <CheckSquare />
  },
  {
    id: "dongle-kits",
    title: "Dongle kits",
    showFor: ["SUPERUSER"],
    path: "/app/admin/dongle-kit/",
    icon: <Layers />
  },
  {
    id: "payment-systems",
    title: "Payment systems",
    showFor: ["SUPERUSER"],
    path: "/app/admin/payment-system/",
    icon: <DollarSign />
  },
  {
    id: "warehouses",
    title: "Warehouses",
    showFor: ["SUPERUSER"],
    path: "/app/admin/warehouse/",
    icon: <Home />
  },
  {
    id: "content-seo",
    title: "Content & SEO",
    showFor: ["SUPERUSER"],
    icon: <Book />,
    children: [
      {
        id: "knowledge-base",
        title: "Knowledge base",
        showFor: ["SUPERUSER"],
        path: "/app/admin/knowledge-base/",
        icon: <Book />
      },
      {
        id: "landing-content",
        title: "Landing content",
        showFor: ["SUPERUSER"],
        path: "/app/admin/landing-content/",
        icon: <Book />
      },
      {
        id: "setup-instructions",
        title: "Setup instructions",
        showFor: ["SUPERUSER"],
        path: "/app/admin/setup/",
        icon: <Book />
      }
    ]
  },
  {
    id: "notification-templates",
    title: "Notification templates",
    showFor: ["SUPERUSER"],
    path: "/app/admin/notification-templates/",
    icon: <Mail />
  },
  {
    id: "tags-and-robots",
    title: "Tags & robots",
    showFor: ["SUPERUSER"],
    icon: <Code />,
    children: [
      {
        id: "robots",
        title: "Robots",
        showFor: ["ADMIN", "SUPERUSER"],
        path: "/app/admin/robots/",
        icon: <Code />
      },
      {
        id: "header-meta-tags",
        title: "Header meta tags",
        showFor: ["ADMIN", "SUPERUSER"],
        path: "/app/admin/custom-meta-tag/header/",
        icon: <Code />
      },
      {
        id: "body-meta-tags",
        title: "Body meta tags",
        showFor: ["ADMIN", "SUPERUSER"],
        path: "/app/admin/custom-meta-tag/body/",
        icon: <Code />
      }
    ]
  },
  {
    id: "keys-from-services",
    title: "Keys & settings from services",
    showFor: ["SUPERUSER"],
    icon: <Key />,
    children: [
      {
        id: "reCaptcha",
        title: "reCaptcha",
        showFor: ["SUPERUSER"],
        path: "/app/admin/re-captcha/edit/",
        icon: <Key />
      },
      {
        id: "public-api-link",
        title: "Public API link",
        showFor: ["SUPERUSER"],
        path: "/app/admin/public-api-link/edit/",
        icon: <Link />
      }
    ]
  },

  {
    id: "error-reports",
    title: "Error reports",
    showFor: ["SUPERUSER"],
    path: "/app/admin/error-report/",
    icon: <Terminal />
  }
];
