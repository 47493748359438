import { Navigate } from "react-router-dom";

export const AdminProxyServersRoutes = [
  {
    path: "proxy-server/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminProxyServers } = await import(
            "@views/app/admin/proxyServer/list"
          );
          return {
            Component: AdminProxyServers
          };
        }
      },
      {
        path: "add/",
        async lazy() {
          const { AdminProxyServerAddEdit } = await import(
            "@views/app/admin/proxyServer/addEdit"
          );
          return {
            Component: AdminProxyServerAddEdit
          };
        }
      },
      {
        path: "edit/:proxyServerId/",
        async lazy() {
          const { AdminProxyServerAddEdit } = await import(
            "@views/app/admin/proxyServer/addEdit"
          );
          return {
            Component: AdminProxyServerAddEdit
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
