import { Controller, useFormContext } from "react-hook-form";
import { useState } from "react";
import { Eye, EyeOff } from "react-feather";
import { FormFeedback, Input, InputGroup, InputGroupText } from "reactstrap";

export const FormPassword = ({
  name,
  required = false,
  errorText = "",
  placeholder = "",
  validate = false,
  visible = false,
  iconSize = 14,
  ...rest
}) => {
  // ** Hooks
  const { control } = useFormContext();

  // ** State
  const [inputVisibility, setInputVisibility] = useState(visible);

  // ** Functions
  // ** Renders Icon Based On Visibility
  const renderIcon = () => {
    const size = +iconSize ? iconSize : 14;

    if (inputVisibility === false) {
      return <Eye size={size} />;
    } else {
      return <EyeOff size={size} />;
    }
  };

  const toggleInputVisibility = () => setInputVisibility(!inputVisibility);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required,
          message: errorText
        },
        ...(validate
          ? {
              minLength: {
                value: 8,
                message: "Min length is 8"
              },
              maxLength: {
                value: 32,
                message: "Max length is 32"
              },
              validate: (value) =>
                /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,32}$/.test(
                  value
                ) || "Password must contain at least one letter and one number"
            }
          : {})
      }}
      defaultValue={""}
      render={({ field, fieldState: { error } }) => (
        <>
          <InputGroup className="input-group-merge">
            <Input
              {...field}
              id={name}
              type={inputVisibility === false ? "password" : "text"}
              placeholder={placeholder ? placeholder : "············"}
              invalid={!!error?.type}
              {...rest}
            />

            <InputGroupText
              className="cursor-pointer"
              onClick={toggleInputVisibility}
            >
              {renderIcon()}
            </InputGroupText>
          </InputGroup>
          {!!error?.message && (
            <FormFeedback className="d-block">{error.message}</FormFeedback>
          )}
        </>
      )}
    />
  );
};
