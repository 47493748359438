import {
  ChevronDown,
  ChevronUp,
  ChevronsDown,
  ChevronsUp
} from "react-feather";
import { Button } from "reactstrap";

export const ButtonsChangeIndex = ({ id, changeIndex, disableButton }) => {
  // ** Constants
  const BUTTONS_CHENGE_INDEX = [
    { name: "START", icon: <ChevronsUp /> },
    { name: "DECREMENT", icon: <ChevronUp /> },
    { name: "INCREMENT", icon: <ChevronDown /> },
    { name: "END", icon: <ChevronsDown /> }
  ];

  return (
    <>
      {BUTTONS_CHENGE_INDEX.map((item) => (
        <div key={item?.name} className="rounded-circle overflow-hidden">
          <Button
            className="btn-icon rounded-circle p-25 border-0"
            color="flat-primary"
            disabled={disableButton}
            name={item?.name}
            id={id}
            onClick={changeIndex}
          >
            {item?.icon}
          </Button>
        </div>
      ))}
    </>
  );
};
