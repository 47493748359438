import { AlertCircle } from "react-feather";
import { Button, Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
// ** Custom Hooks & Utils
import { useLockBackground, useDispatchedActions } from "@hooks";
// ** Components
import { Avatar } from "@components/app";
// ** Constants
import { TEXT_ACTIONS } from "@constants";

export const ModalActionSelectedModems = () => {
  // ** Hooks
  const { isDemo } = useOutletContext();
  // ** Dispatched Actions
  const { fetchActionAllModems, setModalActionAllOpen } =
    useDispatchedActions();
  // ** Redux state
  const { selectedModemsInfo, modalActionAllOpen } = useSelector(
    (state) => state.modals
  );

  // ** Functions
  const handlerCloseModal = () => setModalActionAllOpen("");
  const handlerAction = () => {
    fetchActionAllModems(isDemo);
    setModalActionAllOpen("");
  };

  // ** Effects
  useLockBackground(!!modalActionAllOpen);

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={!!modalActionAllOpen}
      toggle={handlerCloseModal}
    >
      <ModalHeader className="d-flex" toggle={handlerCloseModal}>
        <Avatar
          color="light-warning"
          className="me-1"
          icon={<AlertCircle size={32} />}
        />
        {TEXT_ACTIONS?.[modalActionAllOpen]?.title}
      </ModalHeader>
      <ModalBody>
        {" "}
        <span>
          Modem(s)
          <span className="font-weight-bold mx-25">
            {selectedModemsInfo.map((item) => item.number).join(",")}
          </span>
          will be {TEXT_ACTIONS?.[modalActionAllOpen]?.body}
        </span>
      </ModalBody>
      <ModalFooter>
        <Button outline color="danger" onClick={handlerCloseModal}>
          Cancel
        </Button>
        <Button outline color="success" onClick={handlerAction}>
          Accept
        </Button>
      </ModalFooter>
    </Modal>
  );
};
