import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
// ** Custom Hooks & Utils
import { search, getAllParents } from "../../utils";
// ** Components
import { NAVBAR_MENU } from "../navigation";

export const HorizontalNavMenuLink = ({
  item,
  setOpenDropdown,
  setGroupActive,
  isChild
}) => {
  // ** Hooks
  const { pathname } = useLocation();

  // ** Functions
  // ** Get parents of current items
  const searchParents = (navigation, currentURL) => {
    const parents = search(navigation, currentURL); // Search for parent object
    const allParents = getAllParents(parents, "id"); // Parents Object to Parents Array
    allParents.pop();
    return allParents;
  };

  // ** Remove all items from OpenDropdown array
  const resetOpenDropdowns = () => setOpenDropdown([]);

  // ** Effects
  // ** On mount update active group array
  useEffect(() => {
    if (pathname === item.navLink) {
      if (isChild) {
        const arr = searchParents(NAVBAR_MENU, pathname);
        setGroupActive([...arr]);
      } else {
        setGroupActive([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <li
      className={`nav-item ${item.disabled ? "disabled" : ""} ${
        // item.navLink === pathname ? "active" : ""  //  original
        pathname.includes(item.navLink) ? "active" : "" //  modified
      }`}
    >
      <NavLink
        className={`d-flex align-items-center ${
          isChild ? "dropdown-item" : "nav-link"
        }`}
        target={item.newTab ? "_blank" : null}
        to={item.navLink || "/"}
        onClick={resetOpenDropdowns}
      >
        {item.icon}
        <span>{item.title}</span>
      </NavLink>
    </li>
  );
};
