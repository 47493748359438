import { Navigate } from "react-router-dom";

export const AdminCommandsRoutes = [
  {
    path: "command/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminCommands } = await import("@views/app/admin/command/");
          return {
            Component: AdminCommands
          };
        }
      },
      {
        path: ":id/add/", // id is a script id
        async lazy() {
          const { AdminCommandAdd } = await import(
            "@views/app/admin/command/add"
          );
          return {
            Component: AdminCommandAdd
          };
        }
      },
      {
        path: ":id/edit/",
        async lazy() {
          const { AdminCommandEdit } = await import(
            "@views/app/admin/command/edit"
          );
          return {
            Component: AdminCommandEdit
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
