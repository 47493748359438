import { useCurrentSeo } from "@hooks";
import { Logo, Lang } from "@components/landing/ui";
import { Nav } from "../nav/nav";
import { Payment } from "../payment/payment";
import { SocialList } from "../socialList/socialList";

export const FooterTop = () => {
  // **Hooks
  const { footer } = useCurrentSeo();

  return (
    <div className="landing-footer__top">
      <div className="landing-footer__top-left">
        <Logo />
        <Payment />
      </div>
      <Nav />
      <div className="landing-footer__top-right">
        <p className="landing-footer__title">
          {footer?.social?.title}
        </p>
        <div className="landing-footer__inner">
          <SocialList />
          <Lang fullName />
        </div>
      </div>
    </div>
  );
};
