import { createSlice } from "@reduxjs/toolkit";
import { isSSR } from "@helpers";

const initialThemeState = () => {
  let skin = null;
  if (!isSSR()) {
    const isDarkTheme = window?.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    const defaultTheme = isDarkTheme ? "dark" : "light";
    const storageTheme = localStorage.getItem("skin")
      ? JSON.parse(localStorage.getItem("skin"))
      : null;
    skin =
      storageTheme && ["light", "dark"].includes(storageTheme)
        ? storageTheme
        : defaultTheme;
  }
  return {
    skin: skin || "light"
  };
};

const ThemeReducer = createSlice({
  name: "Theme",
  initialState: initialThemeState(),
  reducers: {
    setThemeSkin(state, action) {
      state.skin = action.payload;
      if (!isSSR()) {
        localStorage.setItem("skin", JSON.stringify(action.payload));
        document.documentElement.setAttribute("data-skin", action.payload);
      }
    }
  }
});

export const ThemeReducerActions = {
  ...ThemeReducer.actions
};

export default ThemeReducer.reducer;
