import { useTranslation } from "@hooks";
import { Link, useParams } from "react-router-dom";

export const Nav = () => {
  // **Hooks
  const { lang } = useParams();
  const { nav, mainPage: { footer } } = useTranslation();

  // **Handlers
  const menuHandler = (event) => {
    const id = event.target.id.replace("footer-nav-", "");
    if (id) {
      setTimeout(() => {
        const el = document.getElementById(id);
        if (el) {
          el.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        event.target.blur();
      }, 100);
    }
  };

  return (
    <div className="landing-footer__top-center">
      <p className="landing-footer__title">{footer?.nav?.title}</p>
      <ul className="landing-footer__nav-list">
        <li className="landing-footer__nav-item">
          <Link
            to={`/${lang}/`}
            id="footer-nav-faq"
            className="landing-footer__nav-link"
            onClick={menuHandler}
          >
            {nav?.faq}
          </Link>
        </li>
        <li className="landing-footer__nav-item">
          <a
            href="https://proxy-seller.com/otzyvy/"
            className="landing-footer__nav-link"
          >
            {nav?.reviews}
          </a>
        </li>
        <li className="landing-footer__nav-item">
          <a
            href="https://proxy-seller.com/blog/"
            className="landing-footer__nav-link"
          >
            {nav?.blog}
          </a>
        </li>
        <li className="landing-footer__nav-item">
          <Link
            to={`/${lang}/`}
            id="footer-nav-support"
            className="landing-footer__nav-link"
            onClick={menuHandler}
          >
            {nav?.contact}
          </Link>
        </li>
      </ul>
    </div>
  );
};
