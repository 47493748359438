export const PAYMENT_TYPES = [
  { value: "QIWI", label: "QIWI" },
  { value: "STRIPE", label: "STRIPE" },
  { value: "INTERKASSA", label: "INTERKASSA" },
  { value: "WEBMONEY", label: "WEBMONEY" },
  { value: "BINANCE", label: "BINANCE" },
  { value: "PADDLE", label: "PADDLE" },
  { value: "PERFECT_MONEY", label: "PERFECT_MONEY" },
  { value: "COINBASE", label: "COINBASE" },
  { value: "PAYSSION", label: "PAYSSION" },
  { value: "CRYPTOCLOUD", label: "CRYPTOCLOUD" },
  { value: "SOLIDGATE", label: "SOLIDGATE" },
  { value: "CRYPTOMUS", label: "CRYPTOMUS" },
  { value: "SYSTEM", label: "SYSTEM" }
];

export const PAYMENT_TYPE_PARAMS = {
  QIWI: ["QIWI_SECRET_KEY", "QIWI_PUBLIC_KEY"],
  STRIPE: ["STRIPE_ACCOUNT_KEY", "STRIPE_SECRET_KEY", "STRIPE_PUBLIC_KEY"],
  INTERKASSA: ["INTERKASSA_WALLET_ID"],
  WEBMONEY: ["WEBMONEY_WALLET_ID"],
  BINANCE: ["BINANCE_PUBLIC_API_KEY", "BINANCE_SECRET_API_KEY"],
  PADDLE: [
    "PADDLE_VENDOR_ID",
    "PADDLE_VENDOR_AUTH_CODE",
    "PADDLE_CREATE_PAYMENT_URL",
    " PADDLE_PUBLIC_KEY"
  ],
  PERFECT_MONEY: ["PERFECT_MONEY_WALLET_ID", "PERFECT_MONEY_SECRET_KEY"],
  COINBASE: [
    "COINBASE_API_KEY",
    "COINBASE_API_VERSION",
    "COINBASE_CREATE_TYPE",
    "COINBASE_WEBHOOK_SECRET"
  ],
  PAYSSION: [
    "PAYSSION_API_KEY",
    "PAYSSION_PAYMENT_METHOD",
    "PAYSSION_PAYMENT_URL",
    "PAYSSION_SECRET_KEY"
  ],
  CRYPTOCLOUD: [
    "CRYPTOCLOUD_API_KEY",
    "CRYPTOCLOUD_SHOP_ID",
    "CRYPTOCLOUD_CREATE_PAYMENT_URL"
  ],
  SOLIDGATE: ["SOLIDGATE_PUBLIC_KEY", "SOLIDGATE_SECRET_KEY"],
  CRYPTOMUS: ["CRYPTOMUS_API_KEY", "CRYPTOMUS_MERCHANT_ID"],
  SYSTEM: []
};
