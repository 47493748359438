import { DEMO_SMS } from "./sms.demo";

const randomIP = () => {
  const random = () => Math.floor(Math.random() * 256);
  return `${random()}.${random()}.${random()}.${random()}`;
};

const randomPort = () => Math.floor(Math.random() * (99999 - 100)) + 100;

const genImey = () =>
  Array(8)
    .fill("0123456789")
    .map((x) => x[Math.floor(Math.random() * x.length)])
    .join("");

const time = new Date().getTime();
const aggregatorIp = randomIP();

export const DEMO_MODEMS = {
  testhost01: [
    {
      id: "testmodem01",
      number: 101,
      ip: randomIP(),
      ipVia: randomIP(),
      portHttp: randomPort(),
      portSocks: randomPort(),
      imei: genImey(),
      note: null,
      authLogin: "",
      authPass: "",
      aggregatorIp,
      status: "ONLINE",
      warnings: [],
      simProvider: "Kyivstar",
      simNumber: "0678385153",
      simNumberCustom: "",
      model: "WRT3844",
      lastUpdate: time,
      workMode: "5G",
      rotationRate: null,
      city: "МОЛДОВА city",
      country: "МОЛДОВА",
      use: false,
      paid: true,
      paymentExtension: false,
      tariffInfo: {
        id: "demotariff2",
        rateName: "advanced",
        price: "50",
        currency: "USD",
        authorization: true,
        downloadLimit: true,
        trafficLimit: true
      },
      limits: {
        logins: [],
        speedLimit: 0,
        trafficLimit: 0,
        isModemLimitPresent: false,
        modemAuths: [
          {
            id: "demoUser001",
            authOwner: "MODEM",
            modemId: "testmodem01",
            ip: "",
            authType: "LOGIN",
            login: "demoUser",
            password: "qaz123wsx",
            isActive: true,
            speedLimit: 0,
            trafficLimit: 0
          },
          {
            id: "demoUser002",
            authOwner: "MODEM",
            modemId: "testmodem01",
            ip: "",
            authType: "LOGIN",
            login: "demoAdmin",
            password: "qwe423ssd",
            isActive: true,
            speedLimit: 0,
            trafficLimit: 0
          },
          {
            id: "demoUser003",
            authOwner: "MODEM",
            modemId: "testmodem01",
            ip: "",
            authType: "LOGIN",
            login: "tester",
            password: "tyr882sd223",
            isActive: true,
            speedLimit: 0,
            trafficLimit: 0
          }
        ]
      },
      sms: DEMO_SMS,
      isPauseable: false,
      isContinueable: false,
      usbPort: null,
      tariffDays: 31,
      buyDate: time,
      activeTime: time
    },
    {
      id: "testmodem02",
      number: 102,
      ip: randomIP(),
      ipVia: randomIP(),
      portHttp: randomPort(),
      portSocks: randomPort(),
      imei: genImey(),
      note: null,
      authLogin: "",
      authPass: "",
      aggregatorIp,
      status: "ONLINE",
      warnings: [],
      simProvider: "Life",
      simNumber: "0638378177",
      simNumberCustom: "",
      model: "FPT3446",
      lastUpdate: time,
      workMode: "LTE",
      rotationRate: null,
      city: "ЕГИПЕТ city",
      country: "ЕГИПЕТ",
      use: true,
      paid: true,
      paymentExtension: false,
      tariffInfo: {
        id: "demotariff1",
        rateName: "basic",
        price: "20",
        currency: "USD",
        authorization: false,
        downloadLimit: true,
        trafficLimit: true
      },
      limits: {
        logins: [],
        speedLimit: 0,
        trafficLimit: 0,
        isModemLimitPresent: false,
        modemAuths: []
      },
      sms: DEMO_SMS,
      isPauseable: true,
      isContinueable: false,
      usbPort: null,
      tariffDays: 31,
      buyDate: time,
      activeTime: time
    },
    {
      id: "testmodem03",
      number: 103,
      ip: randomIP(),
      ipVia: randomIP(),
      portHttp: randomPort(),
      portSocks: randomPort(),
      imei: genImey(),
      note: null,
      authLogin: "",
      authPass: "",
      aggregatorIp,
      status: "DOWN",
      warnings: [],
      simProvider: "Kyivstar",
      simNumber: "0674020291",
      simNumberCustom: "",
      model: "FML5190",
      lastUpdate: time,
      workMode: "5G",
      rotationRate: null,
      city: "ПОЛЬША city",
      country: "ПОЛЬША",
      use: false,
      paid: false,
      paymentExtension: false,
      tariffInfo: {
        id: null,
        rateName: "",
        price: "",
        currency: "",
        authorization: false,
        downloadLimit: false,
        trafficLimit: false
      },
      limits: {
        logins: [],
        speedLimit: 0,
        trafficLimit: 0,
        isModemLimitPresent: false,
        modemAuths: []
      },
      sms: DEMO_SMS,
      isPauseable: false,
      isContinueable: false,
      usbPort: null,
      tariffDays: null,
      buyDate: null,
      activeTime: null
    },
    {
      id: "testmodem04",
      number: 104,
      ip: randomIP(),
      ipVia: randomIP(),
      portHttp: randomPort(),
      portSocks: randomPort(),
      imei: genImey(),
      note: null,
      authLogin: "",
      authPass: "",
      aggregatorIp,
      status: "DOWN",
      warnings: [],
      simProvider: "Vodafone",
      simNumber: "0508367465",
      simNumberCustom: "",
      model: "TWL9764",
      lastUpdate: time,
      workMode: "LTE",
      rotationRate: null,
      city: "МЕКСИКА city",
      country: "МЕКСИКА",
      use: false,
      paid: false,
      paymentExtension: false,
      tariffInfo: {
        id: null,
        rateName: "",
        price: "",
        currency: "",
        authorization: false,
        downloadLimit: false,
        trafficLimit: false
      },
      limits: {
        logins: [],
        speedLimit: 0,
        trafficLimit: 0,
        isModemLimitPresent: false,
        modemAuths: []
      },
      sms: DEMO_SMS,
      isPauseable: false,
      isContinueable: false,
      usbPort: null,
      tariffDays: null,
      buyDate: null,
      activeTime: null
    },
    {
      id: "testmodem05",
      number: 105,
      ip: randomIP(),
      ipVia: randomIP(),
      portHttp: randomPort(),
      portSocks: randomPort(),
      imei: genImey(),
      note: null,
      authLogin: "",
      authPass: "",
      aggregatorIp,
      status: "DOWN",
      warnings: [],
      simProvider: "Kyivstar",
      simNumber: "0672030397",
      simNumberCustom: "",
      model: "QWP2635",
      lastUpdate: time,
      workMode: "6G",
      rotationRate: null,
      city: "СЕЙШЕЛЫ city",
      country: "СЕЙШЕЛЫ",
      use: false,
      paid: false,
      paymentExtension: false,
      tariffInfo: {
        id: null,
        rateName: "",
        price: "",
        currency: "",
        authorization: false,
        downloadLimit: false,
        trafficLimit: false
      },
      limits: {
        logins: [],
        speedLimit: 0,
        trafficLimit: 0,
        isModemLimitPresent: false,
        modemAuths: []
      },
      sms: DEMO_SMS,
      isPauseable: false,
      isContinueable: false,
      usbPort: null,
      tariffDays: null,
      buyDate: null,
      activeTime: null
    },
    {
      id: "testmodem06",
      number: 106,
      ip: randomIP(),
      ipVia: randomIP(),
      portHttp: randomPort(),
      portSocks: randomPort(),
      imei: genImey(),
      note: null,
      authLogin: "",
      authPass: "",
      aggregatorIp,
      status: "DOWN",
      warnings: [],
      simProvider: "Vodafone",
      simNumber: "0509376910",
      simNumberCustom: "",
      model: "LMT2042",
      lastUpdate: time,
      workMode: "3G",
      rotationRate: null,
      city: "АРМЕНИЯ city",
      country: "АРМЕНИЯ",
      use: false,
      paid: false,
      paymentExtension: false,
      tariffInfo: {
        id: null,
        rateName: "",
        price: "",
        currency: "",
        authorization: false,
        downloadLimit: false,
        trafficLimit: false
      },
      limits: {
        logins: [],
        speedLimit: 0,
        trafficLimit: 0,
        isModemLimitPresent: false,
        modemAuths: []
      },
      sms: DEMO_SMS,
      isPauseable: false,
      isContinueable: false,
      usbPort: null,
      tariffDays: null,
      buyDate: null,
      activeTime: null
    },
    {
      id: "testmodem07",
      number: 107,
      ip: randomIP(),
      ipVia: randomIP(),
      portHttp: randomPort(),
      portSocks: randomPort(),
      imei: genImey(),
      note: null,
      authLogin: "",
      authPass: "",
      aggregatorIp,
      status: "DOWN",
      warnings: [],
      simProvider: "Life",
      simNumber: "0636383436",
      simNumberCustom: "",
      model: "VJD3799",
      lastUpdate: time,
      workMode: "6G",
      rotationRate: null,
      city: "ЧЕХИЯ city",
      country: "ЧЕХИЯ",
      use: false,
      paid: false,
      paymentExtension: false,
      tariffInfo: {
        id: null,
        rateName: "",
        price: "",
        currency: "",
        authorization: false,
        downloadLimit: false,
        trafficLimit: false
      },
      limits: {
        logins: [],
        speedLimit: 0,
        trafficLimit: 0,
        isModemLimitPresent: false,
        modemAuths: []
      },
      sms: DEMO_SMS,
      isPauseable: false,
      isContinueable: false,
      usbPort: null,
      tariffDays: null,
      buyDate: null,
      activeTime: null
    }
  ],
  testhost02: [
    {
      id: "testmodem08",
      number: 113,
      ip: randomIP(),
      ipVia: randomIP(),
      portHttp: randomPort(),
      portSocks: randomPort(),
      imei: genImey(),
      note: null,
      authLogin: "",
      authPass: "",
      aggregatorIp,
      status: "DOWN",
      warnings: [],
      simProvider: "Life",
      simNumber: "0635191276",
      simNumberCustom: "",
      model: "URR4389",
      lastUpdate: time,
      workMode: "5G",
      rotationRate: null,
      city: "США city",
      country: "США",
      use: false,
      paid: false,
      paymentExtension: false,
      tariffInfo: {
        id: null,
        rateName: "",
        price: "",
        currency: "",
        authorization: false,
        downloadLimit: false,
        trafficLimit: false
      },
      limits: {
        logins: [],
        speedLimit: 0,
        trafficLimit: 0
      },
      sms: DEMO_SMS,
      isPauseable: false,
      isContinueable: false,
      usbPort: null,
      tariffDays: null,
      buyDate: null,
      activeTime: null
    },
    {
      id: "testmodem09",
      number: 114,
      ip: randomIP(),
      ipVia: randomIP(),
      portHttp: randomPort(),
      portSocks: randomPort(),
      imei: genImey(),
      note: null,
      authLogin: "",
      authPass: "",
      aggregatorIp,
      status: "DOWN",
      warnings: [],
      simProvider: "Vodafone",
      simNumber: "0507278607",
      simNumberCustom: "",
      model: "BRV6981",
      lastUpdate: time,
      workMode: "3G",
      rotationRate: null,
      city: "МОЛДОВА city",
      country: "МОЛДОВА",
      use: false,
      paid: false,
      paymentExtension: false,
      tariffInfo: {
        id: null,
        rateName: "",
        price: "",
        currency: "",
        authorization: false,
        downloadLimit: false,
        trafficLimit: false
      },
      limits: {
        logins: [],
        speedLimit: 0,
        trafficLimit: 0
      },
      sms: DEMO_SMS,
      isPauseable: false,
      isContinueable: false,
      usbPort: null,
      tariffDays: null,
      buyDate: null,
      activeTime: null
    },
    {
      id: "testmodem10",
      number: 115,
      ip: randomIP(),
      ipVia: randomIP(),
      portHttp: randomPort(),
      portSocks: randomPort(),
      imei: genImey(),
      note: null,
      authLogin: "",
      authPass: "",
      aggregatorIp,
      status: "DOWN",
      warnings: [],
      simProvider: "Vodafone",
      simNumber: "0504026285",
      simNumberCustom: "",
      model: "PVZ1936",
      lastUpdate: time,
      workMode: "LTE",
      rotationRate: null,
      city: "ИТАЛИЯ city",
      country: "ИТАЛИЯ",
      use: false,
      paid: false,
      paymentExtension: false,
      tariffInfo: {
        id: null,
        rateName: "",
        price: "",
        currency: "",
        authorization: false,
        downloadLimit: false,
        trafficLimit: false
      },
      limits: {
        logins: [],
        speedLimit: 0,
        trafficLimit: 0
      },
      sms: DEMO_SMS,
      isPauseable: false,
      isContinueable: false,
      usbPort: null,
      tariffDays: null,
      buyDate: null,
      activeTime: null
    },
    {
      id: "testmodem11",
      number: 116,
      ip: randomIP(),
      ipVia: randomIP(),
      portHttp: randomPort(),
      portSocks: randomPort(),
      imei: genImey(),
      note: null,
      authLogin: "",
      authPass: "",
      aggregatorIp,
      status: "DOWN",
      warnings: [],
      simProvider: "Vodafone",
      simNumber: "0503852951",
      simNumberCustom: "",
      model: "RXV9541",
      lastUpdate: time,
      workMode: "3G",
      rotationRate: null,
      city: "ШВЕЦИЯ city",
      country: "ШВЕЦИЯ",
      use: false,
      paid: false,
      paymentExtension: false,
      tariffInfo: {
        id: null,
        rateName: "",
        price: "",
        currency: "",
        authorization: false,
        downloadLimit: false,
        trafficLimit: false
      },
      limits: {
        logins: [],
        speedLimit: 0,
        trafficLimit: 0
      },
      sms: DEMO_SMS,
      isPauseable: false,
      isContinueable: false,
      usbPort: null,
      tariffDays: null,
      buyDate: null,
      activeTime: null
    },
    {
      id: "testmodem12",
      number: 117,
      ip: randomIP(),
      ipVia: randomIP(),
      portHttp: randomPort(),
      portSocks: randomPort(),
      imei: genImey(),
      note: null,
      authLogin: "",
      authPass: "",
      aggregatorIp,
      status: "DOWN",
      warnings: [],
      simProvider: "Kyivstar",
      simNumber: "0677097015",
      simNumberCustom: "",
      model: "BQV9746",
      lastUpdate: time,
      workMode: "6G",
      rotationRate: null,
      city: "ШВЕЦИЯ city",
      country: "ШВЕЦИЯ",
      use: false,
      paid: false,
      paymentExtension: false,
      tariffInfo: {
        id: null,
        rateName: "",
        price: "",
        currency: "",
        authorization: false,
        downloadLimit: false,
        trafficLimit: false
      },
      limits: {
        logins: [],
        speedLimit: 0,
        trafficLimit: 0
      },
      sms: DEMO_SMS,
      isPauseable: false,
      isContinueable: false,
      usbPort: null,
      tariffDays: null,
      buyDate: null,
      activeTime: null
    }
  ],
  testhost03: [
    {
      id: "testmodem13",
      number: 108,
      ip: randomIP(),
      ipVia: randomIP(),
      portHttp: randomPort(),
      portSocks: "2087",
      imei: genImey(),
      note: null,
      authLogin: "",
      authPass: "",
      aggregatorIp,
      status: "OFFLINE",
      warnings: [],
      simProvider: "Life",
      simNumber: "0636972955",
      simNumberCustom: "",
      model: "WKP4945",
      lastUpdate: time,
      workMode: "6G",
      rotationRate: null,
      city: "ГЕРМАНИЯ city",
      country: "ГЕРМАНИЯ",
      use: false,
      paid: false,
      paymentExtension: false,
      tariffInfo: {
        id: null,
        rateName: "",
        price: "",
        currency: "",
        authorization: false,
        downloadLimit: false,
        trafficLimit: false
      },
      limits: {
        logins: [],
        speedLimit: 0,
        trafficLimit: 0
      },
      sms: DEMO_SMS,
      isPauseable: false,
      isContinueable: false,
      usbPort: null,
      tariffDays: null,
      buyDate: null,
      activeTime: null
    },
    {
      id: "testmodem14",
      number: 109,
      ip: randomIP(),
      ipVia: randomIP(),
      portHttp: randomPort(),
      portSocks: randomPort(),
      imei: genImey(),
      note: null,
      authLogin: "",
      authPass: "",
      aggregatorIp,
      status: "DOWN",
      warnings: [],
      simProvider: "Vodafone",
      simNumber: "0509336589",
      simNumberCustom: "",
      model: "MEU9092",
      lastUpdate: time,
      workMode: "5G",
      rotationRate: null,
      city: "БРАЗИЛИЯ city",
      country: "БРАЗИЛИЯ",
      use: false,
      paid: false,
      paymentExtension: false,
      tariffInfo: {
        id: null,
        rateName: "",
        price: "",
        currency: "",
        authorization: false,
        downloadLimit: false,
        trafficLimit: false
      },
      limits: {
        logins: [],
        speedLimit: 0,
        trafficLimit: 0
      },
      sms: DEMO_SMS,
      isPauseable: false,
      isContinueable: false,
      usbPort: null,
      tariffDays: null,
      buyDate: null,
      activeTime: null
    },
    {
      id: "testmodem15",
      number: 110,
      ip: randomIP(),
      ipVia: randomIP(),
      portHttp: randomPort(),
      portSocks: randomPort(),
      imei: genImey(),
      note: null,
      authLogin: "",
      authPass: "",
      aggregatorIp,
      status: "DOWN",
      warnings: [],
      simProvider: "Kyivstar",
      simNumber: "0676132944",
      simNumberCustom: "",
      model: "DJK6630",
      lastUpdate: time,
      workMode: "5G",
      rotationRate: null,
      city: "ТУРЦИЯ city",
      country: "ТУРЦИЯ",
      use: false,
      paid: false,
      paymentExtension: false,
      tariffInfo: {
        id: null,
        rateName: "",
        price: "",
        currency: "",
        authorization: false,
        downloadLimit: false,
        trafficLimit: false
      },
      limits: {
        logins: [],
        speedLimit: 0,
        trafficLimit: 0
      },
      sms: DEMO_SMS,
      isPauseable: false,
      isContinueable: false,
      usbPort: null,
      tariffDays: null,
      buyDate: null,
      activeTime: null
    },
    {
      id: "testmodem16",
      number: 111,
      ip: randomIP(),
      ipVia: randomIP(),
      portHttp: randomPort(),
      portSocks: randomPort(),
      imei: genImey(),
      note: null,
      authLogin: "",
      authPass: "",
      aggregatorIp,
      status: "DOWN",
      warnings: [],
      simProvider: "Life",
      simNumber: "0639530476",
      simNumberCustom: "",
      model: "BOJ5403",
      lastUpdate: time,
      workMode: "3G",
      rotationRate: null,
      city: "СИНГАПУР city",
      country: "СИНГАПУР",
      use: false,
      paid: false,
      paymentExtension: false,
      tariffInfo: {
        id: null,
        rateName: "",
        price: "",
        currency: "",
        authorization: false,
        downloadLimit: false,
        trafficLimit: false
      },
      limits: {
        logins: [],
        speedLimit: 0,
        trafficLimit: 0
      },
      sms: DEMO_SMS,
      isPauseable: false,
      isContinueable: false,
      usbPort: null,
      tariffDays: null,
      buyDate: null,
      activeTime: null
    },
    {
      id: "testmodem17",
      number: 112,
      ip: randomIP(),
      ipVia: randomIP(),
      portHttp: randomPort(),
      portSocks: randomPort(),
      imei: genImey(),
      note: null,
      authLogin: "",
      authPass: "",
      aggregatorIp,
      status: "DOWN",
      warnings: [],
      simProvider: "Vodafone",
      simNumber: "0501992406",
      simNumberCustom: "",
      model: "JSG2488",
      lastUpdate: time,
      workMode: "3G",
      rotationRate: null,
      city: "КАНАДА city",
      country: "КАНАДА",
      use: false,
      paid: false,
      paymentExtension: false,
      tariffInfo: {
        id: null,
        rateName: "",
        price: "",
        currency: "",
        authorization: false,
        downloadLimit: false,
        trafficLimit: false
      },
      limits: {
        logins: [],
        speedLimit: 0,
        trafficLimit: 0
      },
      sms: DEMO_SMS,
      isPauseable: false,
      isContinueable: false,
      usbPort: null,
      tariffDays: null,
      buyDate: null,
      activeTime: null
    }
  ]
};
