import { createSlice } from "@reduxjs/toolkit";
import {
  DEMO_HOSTS,
  DEMO_MODEMS,
  DEMO_NOTIFICATIONS,
  DEMO_PROFILE,
  DEMO_TARIFFS
} from "@constants";

const initialDemoState = () => ({
  needCleanDemoData: false,
  auth: { isAuth: true, isLoaded: true, fetchError: null },
  userProfile: {
    data: DEMO_PROFILE,
    isLoaded: true,
    fetchError: null,
    tourSkip: false
  },
  userDomains: { data: [], isLoaded: true, fetchError: null },
  content: {
    hosts: DEMO_HOSTS,
    notifications: DEMO_NOTIFICATIONS,
    rotateStartTime: new Date().getTime(),
    modems: DEMO_MODEMS,
    checkOut: {},
    tariffs: DEMO_TARIFFS
  }
});

const DemoReducer = createSlice({
  name: "Demo",
  initialState: initialDemoState(),
  reducers: {
    setCleanState(state) {
      state.needCleanDemoData = false;
    },
    demoRenameHost(state, action) {
      state.content.hosts = action.payload;
    },
    setUpdateModems(state, action) {
      state.content.modems = action.payload;
    },
    demoSetCheckOut(state, action) {
      state.content.checkOut = { [action.payload.key]: action.payload.data };
    },
    demoSetNotifications(state, action) {
      state.content.notifications = [
        ...state.content.notifications,
        action.payload
      ];
    },
    demoReadAllNotifications(state) {
      state.content.notifications = [];
    },
    demoSetProfile(state, action) {
      state.userProfile.data = action.payload;
    }
  }
});

export const DemoReducerActions = {
  ...DemoReducer.actions
};
export default DemoReducer.reducer;
