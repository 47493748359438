import ReactPaginate from "react-paginate";
import Select from "react-select";
import { useRef, useState, useEffect } from "react";
// ** Custom Hooks & Utils
import { useBreakpoints } from "@hooks";

// ** Constants
const TABLE_ROW_SIZES = [10, 20, 30, 40, 50, 100];

export const CustomPagination = (props) => {
  // ** Props
  const {
    currentPage,
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage
  } = props;
  // ** Refs
  const ref = useRef(null);
  // ** Hooks
  const breakpoints = useBreakpoints();
  // ** Local State
  const [rage, setRage] = useState(2);
  // ** Constants
  const sizes = TABLE_ROW_SIZES.filter((item) => item <= rowCount + 10);

  // ** Function
  const calcDisplayItemsOfTabl = (totalElements, Size, Page) => {
    if (totalElements === 0) {
      return "0-0 of 0";
    }
    if (totalElements <= Size) {
      return `1-${totalElements} of ${totalElements}`;
    }
    const tempCalc = Size * Page;
    if (Page === 1) {
      return `1-${tempCalc}  of ${totalElements}`;
    }

    if (tempCalc <= totalElements) {
      return `${tempCalc - Size + 1}-${tempCalc}  of ${totalElements}`;
    }

    return `${tempCalc - Size + 1}-${totalElements}  of ${totalElements}`;
  };

  const handlerMouseEnter = () => ref.current.focus();

  const handlerMouseLeave = () => ref.current.blur();

  const handlerChangeRowsPerPage = (data) => {
    onChangeRowsPerPage?.(data?.value);
  };

  useEffect(() => {
    const tempCalc = rowCount / rowsPerPage;
    let newRage = 2;
    if (!breakpoints.includes("sm")) {
      newRage = 1;
    }
    if (tempCalc >= 1000) {
      if (!breakpoints.includes("md")) {
        newRage = 1;
      }
      if (!breakpoints.includes("sm")) {
        newRage = 0;
      }
    }
    if (newRage !== rage) {
      setRage(newRage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowCount, rowsPerPage, breakpoints.length]);

  return (
    <div className="pagination-s p-50 d-flex flex-column align-items-end flex-md-row flex-md-wrap justify-content-end align-items-md-center">
      <div className="d-flex flex-column flex-sm-row align-items-end align-items-sm-center m-50">
        <div className="d-flex align-items-center mb-1 mb-sm-0 me-sm-1">
          Rows per page:
          <div
            className="ms-50 row-select__container"
            onMouseEnter={handlerMouseEnter}
            onMouseLeave={handlerMouseLeave}
          >
            <Select
              ref={ref}
              menuPlacement="top"
              className="react-select  row-select"
              classNamePrefix="select"
              inputProps={{ readOnly: true }}
              isSearchable={false}
              openMenuOnFocus={true}
              blurInputOnSelect={true}
              defaultValue={{ value: rowsPerPage, label: rowsPerPage }}
              options={sizes.map((option) => ({
                value: option,
                label: option
              }))}
              onChange={handlerChangeRowsPerPage}
            />
          </div>
        </div>
        <span>
          {calcDisplayItemsOfTabl(rowCount, rowsPerPage, +currentPage)}
        </span>
      </div>
      <ReactPaginate
        previousLabel=""
        nextLabel=""
        forcePage={currentPage - 1}
        onPageChange={(data) => {
          onChangePage(data.selected + 1);
        }}
        pageCount={Math.ceil(rowCount / rowsPerPage)}
        breakLabel="..."
        pageRangeDisplayed={rage}
        marginPagesDisplayed={rage}
        activeClassName="pagination-s-active"
        pageClassName="pagination-s-item"
        nextLinkClassName="page-link"
        nextClassName="page-item next-item"
        previousClassName="page-item prev-item"
        previousLinkClassName="page-link"
        pageLinkClassName="pagination-s-link"
        breakClassName="pagination-s-item"
        breakLinkClassName="pagination-s-link"
        containerClassName="pagination pagination-s react-paginate separated-pagination justify-content-end m-50"
      />
    </div>
  );
};
