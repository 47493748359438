import { Container } from "@components/landing/ui";
import { useCurrentSeo } from "@hooks";
import { RawHTML } from "@components/app";

import "./seo.scss";

export const Seo = () => {
  // **Hooks
  const { seo } = useCurrentSeo();

  return (
    <section className="landing-seo">
      <Container>
        <h2 className={"landing-seo__title"} >{seo?.title}</h2>
        <RawHTML className="section__subtitle">{seo?.text}</RawHTML>
      </Container>
    </section>
  );
};
