import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Archive, FileText, MoreVertical } from "react-feather";
// ** Custom Hooks & Utils
import { useDispatchedActions } from "@hooks";

const Sms = (props) => {
  const { row, admin } = props;
  const { setModalReadSmsOpen, setModalSendSmsOpen } = useDispatchedActions();

  return (
    <UncontrolledButtonDropdown direction="right">
      <DropdownToggle
        className={`btn-icon rounded-circle ${
          !row?.paid && !admin ? "blur-box" : ""
        }`}
        color="dark"
        outline
        disabled={!row?.paid && !admin}
      >
        <MoreVertical size={20} />
      </DropdownToggle>
      <DropdownMenu container="body">
        <DropdownItem
          className="d-flex w-100"
          onClick={() => setModalReadSmsOpen(row?.id)}
        >
          <FileText className="me-50" size={20} />
          <span>Read SMS</span>
        </DropdownItem>
        <DropdownItem
          className="d-flex w-100"
          onClick={() => setModalSendSmsOpen(row?.id)}
        >
          <Archive className="me-50" size={20} />
          <span>Send SMS</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export const staticSms = (admin = false) => ({
  id: "sms",
  name: "Sms",
  // selector: "sms",
  minWidth: "70px",
  key: "11",
  cell: (row) => <Sms row={row} admin={admin} />
});
