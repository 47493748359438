import { useEffect, useState } from "react";
import { Link, useNavigate, useLoaderData } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from "reactstrap";
import { Power } from "react-feather";
import { useSelector } from "react-redux";

// ** Custom Hooks & Helpers
import { useDispatchedActions, useCurrentProfileData } from "@hooks";
// ** Components
import { Avatar } from "@components/app";
import { TABS } from "@views/app/account-settings/components/Tabs";
// ** Services
import { apiService } from "@services/ApiService";

export const UserDropdown = () => {
  // ** Hooks
  const { isDemo } = useLoaderData();
  const navigate = useNavigate();
  // **Dispatched Actions
  const { clearAuthData } = useDispatchedActions();
  // ** Redux state
  const settings = useSelector((state) => state.settings);
  const { userProfile, auth } = useCurrentProfileData(isDemo);

  // ** State
  const [userData, setUserData] = useState(null);

  // ** Functions
  const logout = () => {
    if (!isDemo) {
      apiService.auth.logout().then((res) => {
        if (res.status === 200) {
          clearAuthData();
          navigate("/app/auth/login/");
        }
      });
    } else navigate(settings?.lang ? `/${settings.lang}/` : "/");
  };

  // ** Effects
  useEffect(() => {
    if (!isDemo) {
      if (auth?.isAuth && userProfile?.isLoaded) {
        setUserData(userProfile?.data);
      }
    } else {
      setUserData(userProfile?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isAuth, userProfile?.isLoaded, userProfile?.data]);

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name font-weight-bold">{userData?.name}</span>
          <span className="user-status">{userData?.email}</span>
        </div>
        <Avatar
          color="primary"
          content={userData?.name?.slice(0, 2)?.toUpperCase() || "U"}
        />
      </DropdownToggle>
      <DropdownMenu end container="body" className="z-index-1000">
        {TABS &&
          TABS.map((item) => (
            <DropdownItem
              key={item?.tab}
              tag={Link}
              to={`/${isDemo ? "demo" : "app"}/profile/${item.tab}/`}
            >
              {item.icon}
              <span className="align-middle">{item.title}</span>
            </DropdownItem>
          ))}
        <DropdownItem className="w-100" onClick={logout}>
          <Power size={14} className="me-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
