export const TEXT_ACTIONS = {
    PAUSE: {
      title: "Put the modem on pause?",
      body: "paused",
      error: "suspend",
      success: "paused"
    },
    CONTINUE: {
      title: "Start modems?",
      body: "started",
      error: "extend",
      success: "unpaused"
    },
    DELETE: {
      title: "Removal",
      body: "deleted",
      error: "delete",
      success: "deleted"
    }
  };
