import { combineReducers, configureStore } from "@reduxjs/toolkit";

import AuthReducer from "./reducers/AuthReducer/Auth.reducer";
import ContentReducer from "./reducers/ContentReducer/Content.reducer";
import ModalsReducer from "./reducers/ModalsReducer/Modals.reducer";
import TranslationReducer from "./reducers/TranslationReducer/Translation.reducer";
import DemoReducer from "./reducers/DemoReducer/Demo.reducer";
import LandingReducer from "./reducers/LandingReducer/Landing.reducer";
import SettingsReducer from "./reducers/SettingsReducer/Settings.reducer";
import NavBarReducer from "./reducers/NavBarReducer/NavBar.reducer";
import ThemeReducer from "./reducers/ThemeReducer/Theme.reducer";

const rootReducer = combineReducers({
  auth: AuthReducer,
  navBar: NavBarReducer,
  content: ContentReducer,
  modals: ModalsReducer,
  translation: TranslationReducer,
  demo: DemoReducer,
  landing: LandingReducer,
  settings: SettingsReducer,
  theme: ThemeReducer
});

export const setupStore = (preloadedState) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types
          ignoredPaths: ["translation", "demo", "content"]
        }
      }),
    devTools: process.env.NODE_ENV === "development",
    preloadedState
  });
