export const correctPrefixNumber = (data) => {
  if (!data) {
    return "+";
  }

  if (data?.length === 1) {
    return `+${data[0]}`;
  }

  const temp = data.map((item) => item.toString());

  let i = 1;
  let test = temp[0].slice(0, i);
  while (
    temp.every((item) => item.slice(0, i) === test) &&
    i <= temp[0].length
  ) {
    i += 1;
    test = temp[0].slice(0, i);
  }

  return `+${test.slice(0, -1)}`;
};
