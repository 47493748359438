import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { mergeDeep } from "../helpers";

export const useTranslation = () => {
  // **Props
  const { lang } = useParams();
  // ** Redux State
  const settings = useSelector((state) => state.settings);
  const { translation } = useSelector((state) => state.translation);

  // const currentTranslation =
  //   lang && translation?.hasOwnProperty(lang)
  //     ? translation[lang]
  //     : translation["en"];
  let currentTranslation = {};
  switch (true) {
    case lang && translation?.hasOwnProperty(lang):
      currentTranslation = translation[lang];
      break;
    case settings?.lang && translation?.hasOwnProperty(settings?.lang):
      currentTranslation = translation[settings?.lang];
      break;
    default:
      currentTranslation = translation["en"];
  }

  const defaultTranslation = translation["en"];

  return mergeDeep({}, defaultTranslation, currentTranslation);
};
