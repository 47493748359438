const time = new Date().getTime();
export const DEMO_SMS = {
  inbox: [
    {
      index: 3,
      phone: "DemoOperator",
      date: time,
      sms: "Please refill your account."
    },
    {
      index: 2,
      phone: "DemoOperator",
      date: time - 60000,
      sms: "You activete your number."
    },
    {
      index: 1,
      phone: "DemoOperator",
      date: time - 240000,
      sms: "Thanks for your choice, please send any message to 1234 for activete your number."
    }
  ],
  outbox: [
    {
      index: 1,
      phone: "1234",
      date: time - 120000,
      sms: "activete"
    }
  ],
  draft: []
};
