import { Menu } from "react-feather";
import { NavItem, NavLink, Navbar } from "reactstrap";
import { useSelector } from "react-redux";
// ** Custom Hooks & Utils
import { useDispatchedActions, useCheckDomain } from "@hooks";
// ** Components
import {
  UserDropdown,
  ModemUsageInfo,
  BalanceUser,
  ThemeToggler,
  NotificationDropdown,
  SwaggerLink,
  ReportsForAdmin,
  LangDropdown
} from "./components";

export const NavbarComponent = ({ isVertical }) => {
  // **Dispatched Actions
  const { setMenuVisibility } = useDispatchedActions();
  // ** Hooks
  const isLocalhost = useCheckDomain(["localhost"]);
  const isStage = useCheckDomain(["stage"]);

  // **Redux State
  const { skin } = useSelector((state) => state.theme);
  const { data } = useSelector((state) => state.auth.userProfile);

  // ** Functions
  const hadlerMenuOpen = () => setMenuVisibility(true);

  return (
    <Navbar
      expand="lg"
      light={skin !== "dark"}
      dark={skin === "dark"}
      color={"none"}
      className={
        isVertical
          ? "header-navbar navbar align-items-center floating-nav navbar-shadow"
          : "header-navbar navbar-fixed align-items-center navbar-shadow navbar-brand-center"
      }
    >
      <div className="navbar-container d-flex content px-0 px-xl-1">
        <ul className="navbar-nav d-xl-none d-flex align-items-center me-1">
          <NavItem className="mobile-menu">
            <NavLink
              tag="button"
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={hadlerMenuOpen}
            >
              <Menu className="ficon" />
            </NavLink>
          </NavItem>
        </ul>
        <div className="d-flex align-items-center">
          <BalanceUser />
          <ModemUsageInfo />
        </div>
        {data?.isSuperUser && isLocalhost && <SwaggerLink />}
        <ul className="nav navbar-nav align-items-center ms-auto">
          {data?.isSuperUser && (isLocalhost || isStage) && <LangDropdown />}
          <ThemeToggler />
          {(data?.isSuperUser || data?.isAdmin) && <ReportsForAdmin />}
          <NotificationDropdown />
          <UserDropdown />
        </ul>
      </div>
    </Navbar>
  );
};
