import { useCurrentSeo } from "@hooks";

export const WorkTabs = ({ activeTab, tabsHandler }) => {

  const { work } = useCurrentSeo();
  return (
    <ul className="landing-work__tabs">
      {work?.length ? work.map((item, index) => (
        <li
          key={index}
          className={`landing-work__tab ${activeTab === index ? "landing-work__tab--active" : ""}`}
        >
          <button onClick={() => tabsHandler(index)}>{item.title}</button>
        </li>
      )) : null}
    </ul>
  );
};
