import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useCurrentSeo } from "@hooks";
import { SlideContent } from "../slideContent/slideContent";
// let Pagination = null;
// let Swiper = null;
// let SwiperSlide = null;
// (async () => {
//   try {
//     const MODULES = await import("swiper/modules");
//     const SWIPER = await import("swiper/react");
//     Pagination = MODULES.Pagination;
//     Swiper = SWIPER.Swiper;
//     SwiperSlide = SWIPER.SwiperSlide;
//   } catch (ignored) {
//     // eslint-disable-next-line no-console
//     console.warn("Swiper, Error in SliderSPA.jsx: ", ignored);
//   }
// })();

export const SliderSPA = ({ direction, heightAuto }) => {
  // **Hooks
  const { get } = useCurrentSeo();

  return (
    <Swiper
      direction={direction}
      spaceBetween={30}
      slidesPerView={1}
      wrapperTag="ul"
      pagination={{ clickable: true }}
      initialSlide={1}
      loop
      threshold={30}
      modules={[Pagination]}
      className="landing-get__list"
    >
      {get?.length
        ? get.map((item) => (
            <SwiperSlide
              key={item.id}
              className="landing-get__item"
              tag="li"
              style={heightAuto ? { height: "auto" } : {}}
            >
              <SlideContent info={item} />
            </SwiperSlide>
          ))
        : null}
    </Swiper>
  );
};
