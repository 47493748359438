export const fakeDataShow = (string, check = false) => {
  if (!string || !check) return string;

  if (typeof string !== "string" && typeof string !== "number") return string;

  const temp = string.toString();
  const fake = ["!", "?", "#", "$", "%", "&"];
  const array = temp.split("");
  const fakeDataArray = array.map((item) => {
    if (item.match(/[a-z]/i) || item.match(/[0-9]/i)) {
      return fake[Math.floor(Math.random() * fake.length)];
    } else {
      return item;
    }
  });
  return fakeDataArray.join("");
};
