import { useNavigate } from "react-router-dom";
import { Button } from "@components/landing/ui";
import { useDispatchedActions, useTranslation } from "@hooks";
import { IconSvg } from "@constants";
import { useSelector } from "react-redux";

const TRARIFFS = {
  BASIC: "basic",
  ADVANSED: "advanced",
  ENTERPRISE: "enterprise"
};

export const ContentItemTariff = ({ data }) => {
  // *Hooks
  const navigate = useNavigate();
  const {
    mainPage: { use }
  } = useTranslation();
  const { setOpenModalFeatureSet } = useDispatchedActions();

  // *Redux States
  const { auth } = useSelector((state) => state.auth);

  // *Handlers
  const handlerOpenModalFeatureSet = () => setOpenModalFeatureSet(true);

  const handlerGoSignUp = () => {
    if (auth?.isAuth) {
      navigate("/app/home/");
    } else {
      navigate("/app/auth/registration/");
    }
  };

  const handlerContactUs = (event) => {
    const id = event.target.id.replace("btn-", "");
    if (id) {
      setTimeout(() => {
        const el = document.getElementById(id);
        if (el) {
          el.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        event.target.blur();
      }, 100);
    }
  };

  return (
    <>
      <div className="tariff-info-box">
        {data?.name && (
          <div className="tariff-info-box-image">
            <IconSvg
              tag={
                data.name.toLowerCase() === TRARIFFS.ADVANSED
                  ? TRARIFFS.ADVANSED
                  : TRARIFFS.BASIC
              }
            />
          </div>
        )}
        <div
          className={`landing-use__content-item-tariff-title ${data?.name === TRARIFFS.ENTERPRISE ? "landing-use__content-item-tariff-title-enterprise" : ""}`}
        >
          {data?.publicName}
        </div>
        {data?.name !== TRARIFFS.ENTERPRISE ? (
          <div className="landing-use__content-item-tariff-price">
            <div className="landing-use__content-item-tariff-price-amount">
              {data?.price ? `$${data?.price}` : null}
            </div>
            <div className="landing-use__content-item-tariff-price-period">
              {data?.description}
            </div>
          </div>
        ) : null}
        <ul className="landing-use__content-item-tariff-list">
          {data?.advantages?.length
            ? data?.advantages.map((item, index) => <li key={index}>{item}</li>)
            : null}
        </ul>
        <div className="landing-use__content-item-tariff-all-func">
          {data?.name === TRARIFFS.ADVANSED && (
            <button
              className="landing-use__content-item-tariff-all-func-btn"
              onClick={handlerOpenModalFeatureSet}
            >
              {use?.allFunc}
            </button>
          )}
        </div>
      </div>
      {data?.name === TRARIFFS.ENTERPRISE ? (
        <Button id="btn-support" onClick={handlerContactUs}>
          {use?.tariffs?.btnContact}
        </Button>
      ) : (
        <Button onClick={handlerGoSignUp}>
          {auth?.isAuth ? use?.tariffs?.btnIfAuth : use?.tariffs?.btn}
        </Button>
      )}
    </>
  );
};
