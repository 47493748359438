export const MODEM_TARIFF_COLORS = {
  BASIC: "warning",
  ADVANCED: "primary",
  ENTERPRISE: "info",
  NO_DATA: "secondary"
};

export const MODEM_STATUS_COLORS = {
  ONLINE: "success",
  OFFLINE: "secondary",
  DOWN: "danger",
  REBOOT: "warning",
  NO_DATA: "secondary"
};
