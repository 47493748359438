import { AlertCircle } from "react-feather";
import { Button, Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap";
// ** Custom Hooks & Utils
import { useLockBackground } from "@hooks";
// ** Constants
import { Avatar } from "@components/app";

export const ModalConfirmAction = (props) => {
  // ** Props
  const {
    isOpen,
    close,
    action,
    title,
    body,
    cancelBtn,
    confirmBtn,
    cancelBtnHidden = false
  } = props;

  // ** Functions
  const handlerToggle = () => close?.();
  const handlerAction = () => {
    action?.();
    close?.();
  };

  // ** Effects
  useLockBackground(!!isOpen);

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={!!isOpen}
      toggle={handlerToggle}
    >
      <ModalHeader className="d-flex" toggle={handlerToggle}>
        <Avatar
          color="light-warning"
          className="me-1"
          icon={<AlertCircle size={32} />}
        />
        {title ? title : "Confirmation"}
      </ModalHeader>
      <ModalBody>{body ? body : "Do you confirm action?"}</ModalBody>
      <ModalFooter>
        {!cancelBtnHidden && (
          <Button outline color="danger" onClick={handlerToggle}>
            {cancelBtn ? cancelBtn : "No"}
          </Button>
        )}
        <Button outline color="success" onClick={handlerAction}>
          {confirmBtn ? confirmBtn : "Yes"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
