import { MoreVertical, Trash2, Power } from "react-feather";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// ** Custom Hooks & Utils
import { useDispatchedActions } from "@hooks";
import { demoCreateCheckOut } from "@helpers";
// ** Services
import { apiService } from "@services/ApiService";

const Actions = ({ row, admin }) => {
  // ** Hooks
  const navigate = useNavigate();
  // ** Dispatched Actions
  const { setModalDeleteModemOpen, demoSetCheckOut } = useDispatchedActions();
  // ** Redux state
  const { isDemo, content } = useSelector((state) => state.demo);
  const { activeHostId } = useSelector((state) => state.content.hostModems);

  // ** Functions
  const handleChangePlan = (mId) => {
    if (!isDemo) {
      apiService.checkouts.create([mId]).then((req) => {
        if (req.data) {
          navigate(`/app/checkout/${req.data.id}`);
        }
      });
    } else if (content?.modems) {
      const checkOut = demoCreateCheckOut({
        ids: [mId],
        modems: content?.modems[activeHostId]
      });
      demoSetCheckOut(checkOut);
      navigate(`/demo/checkout/${checkOut.key}`);
    }
  };

  return (
    <UncontrolledButtonDropdown direction="left">
      <DropdownToggle
        className={`btn-icon rounded-circle ${
          !row?.paid && !admin ? "blur-box" : ""
        }`}
        color="dark"
        outline
        disabled={!row?.paid && !admin}
      >
        <MoreVertical size={20} />
      </DropdownToggle>
      <DropdownMenu container="body">
        <DropdownItem
          className="d-flex w-100 align-items-center"
          onClick={() => handleChangePlan(row.id)}
        >
          <Power size={20} className="me-50" />
          <span>Change plan</span>
        </DropdownItem>
        <DropdownItem
          className="d-flex w-100 align-items-center"
          onClick={() => setModalDeleteModemOpen(row?.id)}
        >
          <Trash2 size={20} className="me-50" />
          <span>Delete</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export const staticAction = (admin = false) => ({
  id: "action",
  name: "Action",
  // selector: "action",
  key: "13",
  cell: (row) => <Actions row={row} admin={admin} />
});
