import { useEffect } from "react";
import { Outlet, Navigate, useLoaderData } from "react-router-dom";
import { useSelector } from "react-redux";
import { ArrowUp } from "react-feather";
import ScrollToTop from "react-scroll-to-top";
// ** Custom Hooks & Helpers
import {
  useCurrentTheme,
  useDispatchedActions,
  // useRemovePreloader,
  useBreakpoints,
  useCurrentProfileData
} from "@hooks";
import { removeCalendly, isSSR } from "@helpers";
import chalk from "chalk";
// ** Components
import { MetaDate } from "@components/Metadate";

import {
  Footer,
  HorizontalMenu,
  NavbarComponent,
  VerticalMenu
} from "./components";

// ** Styles
import "@scss/base/core/menu/menu-types/horizontal-menu.scss";
import "@scss/base/core/menu/menu-types/vertical-menu.scss";
import "@scss/base/core/menu/menu-types/vertical-overlay-menu.scss";

(async () => {
  try {
    await import("animate.css/animate.css");
  } catch (ignored) {
    const isServer = isSSR();
    // eslint-disable-next-line no-console
    console.log(
      chalk.yellow(
        `Error: Animate CSS import failed on ${isServer ? "server" : "client"} "AppLayout.jsx"`
      )
    );
  }
})();

export const AppLayout = () => {
  // ** Hooks
  const { isDemo } = useLoaderData();
  const breakpoints = useBreakpoints();
  const isVertical = !breakpoints.includes("xl");
  // **Redux store
  const { auth, userProfile } = useCurrentProfileData(isDemo);
  const { menuVisibility } = useSelector((state) => state.navBar.layout);
  const {
    auth: { isAuth }
  } = useSelector((state) => state.auth);
  // ** Redux State
  const demo = useSelector((state) => state.demo);
  // **Dispatched Actions
  const {
    fetchUserProfile,
    fetchUserDomains,
    setCleanState,
    clearContentState
  } = useDispatchedActions();

  // **Effects
  useEffect(() => {
    removeCalendly();
    if (!isDemo && isAuth) {
      if (!userProfile?.isLoaded) {
        fetchUserProfile();
      }
      // console.log("fetchUserDomains");
      fetchUserDomains();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, isDemo]);

  // set current skin to body
  useCurrentTheme();

  // ** Effects
  useEffect(() => {
    if (!isDemo && demo.needCleanDemoData) {
      clearContentState();
      setCleanState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDemo]);

  // useRemovePreloader([auth?.isLoaded]);
  if (!auth?.isLoaded) {
    return null;
  }

  if (!auth?.isAuth) {
    return <Navigate to={"/app/auth/login/"} />;
  }

  return (
    <>
      <MetaDate app={true} />
      <div
        className={`wrapper navbar-floating footer-static ${
          isVertical
            ? ` vertical-layout  vertical-overlay-menu ${
                menuVisibility ? "menu-open" : "menu-hide"
              }`
            : " horizontal-layout horizontal-menu  menu-expanded"
        }`}
      >
        <NavbarComponent isVertical={isVertical} />
        {isVertical ? <VerticalMenu /> : <HorizontalMenu />}
        <div
          className={`app-content content ${
            menuVisibility ? "overflow-hidden" : ""
          }`}
        >
          <div className="content-overlay" />
          <div className="header-navbar-shadow" />
          <div className={"content-wrapper animate__animated animate__fadeIn"}>
            <Outlet context={{ isDemo }} />
          </div>
        </div>
        <footer className={"footer footer-light footer-static"}>
          <Footer />
        </footer>
        <ScrollToTop
          smooth
          top={300}
          className="btn btn-primary btn-icon"
          component={<ArrowUp size={14} />}
        />
      </div>
    </>
  );
};
