import { useEffect, useState } from "react";

export const useLockBackground = (lock) => {
  // ** local State
  const [checkLock, setCheckLock] = useState(lock);
  // ** Effects
  useEffect(() => {
    if (checkLock !== lock) {
      if (lock) {
        const currentScroll =
          window.scrollY || document.documentElement.scrollTop;
        const scrollbarWidth =
          window.innerWidth - document.documentElement.clientWidth;
        document.body.classList.add("lock");
        document.body.style.top = `-${currentScroll}px`;
        document.body.style.paddingRight = `${scrollbarWidth}px`;
        document.body.style.overflow = "hidden";
      } else {
        const scrollY = document.body?.style?.top?.slice(1, -2) || 0;
        document.body.style.paddingRight = "";
        document.body.style.top = "";
        document.body.classList.remove("lock");
        document.body.style.overflow = "";
        window.scrollTo(0, scrollY);
      }
      setCheckLock(lock);
    }
    return () => {
      document.body.style.paddingRight = "";
      document.body.style.top = "";
      document.body.classList.remove("lock");
      document.body.style.overflow = "";
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lock]);
};
