import { UserX } from "react-feather";
import { Button, Input } from "reactstrap";
import { Tooltip } from "react-tooltip";
// ** Constants
import { IconSvg } from "@constants";

export const HeaderList = (props) => {
  // ** Props
  const { allSelect, setAllSelect, selectedUsers, deleteUsers, setLimits } =
    props;

  return (
    <div
      className={
        "d-flex font-weight-bold py-75 border-top border-bottom bg-table-header"
      }
    >
      <div className="ps-1 pe-75">
        <Input
          onChange={setAllSelect}
          checked={allSelect}
          id="select-all"
          type="checkbox"
        />
      </div>
      <div className="px-50" style={{ minWidth: "100px" }}>
        LOGIN
      </div>
      <div className="px-50" style={{ minWidth: "125px" }}>
        PASSWORD
      </div>
      <div className="px-50" style={{ minWidth: "170px" }}>
        SPEED/TARIFFIC
      </div>
      {selectedUsers?.length ? (
        <div className="d-flex ms-auto me-25">
          <Button
            color="flat-secondary"
            data-tooltip-id="modal-users-tooltip-head"
            data-tooltip-content="Set selected users speed and traffic limits"
            className="btn-icon px-25 py-0 border-0"
            onClick={setLimits}
          >
            <IconSvg tag="speedLimit" />
          </Button>
          {!allSelect && selectedUsers?.length && (
            <Button
              color="flat-danger"
              data-tooltip-id="modal-users-tooltip-head"
              data-tooltip-content="Delete selected users from this modem"
              className="btn-icon px-25 py-0 border-0"
              onClick={deleteUsers}
            >
              <UserX size={14} />
            </Button>
          )}
          <Tooltip id="modal-users-tooltip-head" />
        </div>
      ) : null}
    </div>
  );
};
