import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatchedActions } from "@hooks";

import "./dropdown.scss";

export const Dropdown = ({ children }) => {
  // **Dispatch
  const { toggleMobileMenu } = useDispatchedActions();
  // **Redux State
  const { isOpen } = useSelector((state) => state.landing.mobileMenu);

  // **Handlers
  const handlerOverlay = () => {
    toggleMobileMenu(!isOpen);
  };

  // **Effects
  useEffect(() => {
    const main = document.getElementById("main");
    if (isOpen) {
      const currentScroll =
        window.scrollY || document.documentElement.scrollTop;
      document
        .querySelector(".landing-dropdown")
        .classList.add("landing-dropdown--active");
      document.body.classList.add("lock");
      document.body.style.top = `-${currentScroll}px`;
      // document.body.style.paddingRight = `${scrollbarWidth}px`;
      document.body.style.overflow = "hidden";
      main.style.paddingRight = "12px";
    } else {
      const scrollY = document.body?.style?.top?.slice(1, -2) || 0;
      document
        .querySelector(".landing-dropdown")
        .classList.remove("landing-dropdown--active");
      // document.body.style.paddingRight = "";
      document.body.style.top = "";
      document.body.classList.remove("lock");
      document.body.style.overflow = "";
      main.style.paddingRight = "0px";
      window.scrollTo(0, scrollY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    document
      .querySelector(".landing-dropdown")
      .classList.remove("landing-dropdown--active");
    return () => {
      document.body.classList.remove("lock");
      document.documentElement.style.overflow = "";
      document.body.style.paddingRight = "";
      document.body.style.top = "";
    };
  }, []);

  return (
    <>
      <div className="landing-dropdown" style={{ display: "none" }}>
        {children}
      </div>
      <div className="landing-dropdown__overlay" onClick={handlerOverlay} />
    </>
  );
};
