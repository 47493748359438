import { Users } from "react-feather";
import {
  Button,
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
  CardText
} from "reactstrap";
import { useSelector } from "react-redux";
import { useState } from "react";
// ** Custom Hooks & Utils
import { useDispatchedActions, useLockBackground } from "@hooks";
// ** Components
import { AddNewUser } from "./components/AddNewUser";
import { UsersList } from "./components/UsersList/UsersList";
// ** Styles
import "./ModalUsers.scss";

export const ModalUsers = () => {
  // ** Dispatch
  const { setModalUsersOpen } = useDispatchedActions();
  // ** Redux state
  const { selectedModemsInfo, modalUsersOpen } = useSelector(
    (state) => state.modals
  );
  // ** State
  const [trigerReload, setTrigerReload] = useState(false);

  // ** Functions
  const handlerCloseModal = () => setModalUsersOpen(!modalUsersOpen);
  const handlerReload = () => setTrigerReload((state) => !state);

  // ** Effects
  useLockBackground(!!modalUsersOpen);

  return (
    <>
      <Modal
        isOpen={modalUsersOpen}
        className="modal-dialog-centered modal__users__custom"
        toggle={handlerCloseModal}
        // size="lg"
      >
        <ModalHeader toggle={handlerCloseModal}>
          <Users className="me-50" color="#ffb708" />
          Users
        </ModalHeader>
        <ModalBody>
          <CardText className="text-uppercase font-weight-bold">
            {selectedModemsInfo.length === 1
              ? `Users modems №${selectedModemsInfo[0].number}`
              : "User modems"}
          </CardText>
          <AddNewUser
            reloadUsers={handlerReload}
            handlerCloseModal={handlerCloseModal}
          />
          <hr></hr>
          {selectedModemsInfo?.length > 1 ? (
            "A new user will be installed on the selected modems"
          ) : (
            <UsersList trigerReload={trigerReload} />
          )}
        </ModalBody>

        <ModalFooter>
          <Button outline color="secondary" onClick={handlerCloseModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
