import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
// ** Custom Hooks & Utils
import { useDispatchedActions, useLockBackground } from "@hooks";

export const ModalAlert = ({ modalText, closeModal }) => {
  // ** Dispatched Actions
  const { setModalSendSmsOpen } = useDispatchedActions();

  // ** Functions
  const hanlerCloseModal = () => {
    closeModal?.();
    setModalSendSmsOpen(false);
  };

  // ** Effects
  useLockBackground(!!modalText);

  return (
    <Modal
      isOpen={modalText !== ""}
      className="modal-dialog-centered"
      modalClassName={`modal-${modalText === "ERROR" ? "warning" : "info"}`}
      key={`modal-response-${modalText === "ERROR" ? "warning" : "message"}`}
    >
      <ModalHeader>{modalText === "ERROR" ? "Warning" : "Info"}</ModalHeader>
      <ModalBody>
        {modalText === "ERROR" ?
          "Error sending request! The aggregator, host, or modem may be down..." : modalText}
      </ModalBody>
      <ModalFooter>
        <Button
          color={modalText === "ERROR" ? "warning" : "info"}
          onClick={hanlerCloseModal}
        >
          Accept
        </Button>
      </ModalFooter>
    </Modal>
  );
};
