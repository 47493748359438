import { Button } from "reactstrap";

export const SwaggerLink = () => {
  // ** functions
  const openSwagger = () =>
    window.open("/swagger/api-service/swagger-ui/index.html", "_blank");

  return (
    <Button
      className="ms-2 align-self-center d-sm-block d-none"
      color="success"
      outline
      onClick={openSwagger}
    >
      Swagger
    </Button>
  );
};
