import { Navigate } from "react-router-dom";

export const AdminScriptsRoutes = [
  {
    path: "script/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminScripts } = await import("@views/app/admin/script/list");
          return {
            Component: AdminScripts
          };
        }
      },
      {
        path: "add/",
        async lazy() {
          const { AdminScriptAdd } = await import(
            "@views/app/admin/script/add"
          );
          return {
            Component: AdminScriptAdd
          };
        }
      },
      {
        path: ":id/edit/",
        async lazy() {
          const { AdminScriptEdit } = await import(
            "@views/app/admin/script/edit"
          );
          return {
            Component: AdminScriptEdit
          };
        }
      },
      {
        path: "run/*",
        children: [
          {
            index: true,
            async lazy() {
              const { AdminScriptRuns } = await import(
                "@views/app/admin/run/list"
              );
              return {
                Component: AdminScriptRuns
              };
            }
          },
          {
            path: ":id/logs/",
            async lazy() {
              const { AdminScriptRunLogs } = await import(
                "@views/app/admin/run/logs"
              );
              return {
                Component: AdminScriptRunLogs
              };
            }
          },
          {
            path: "*",
            element: <Navigate to="./" replace={true} />
          }
        ]
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
