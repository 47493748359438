import { Link } from "react-router-dom";
import { useTranslation } from "@hooks";
import { useSelector } from "react-redux";

import "./sign.scss";

export const Sign = () => {
  // **Hooks
  const { header } = useTranslation();
  const { auth } = useSelector((state) => state.auth);

  return (
    <>
      <ul className="landing-sign__list">
        <li className="landing-sign__item">
          <Link className="landing-sign__link" to={auth?.isLoaded && auth?.isAuth ? "/app/home/" : "/app/auth/login/"}>
            {header?.signIn}
          </Link>
        </li>
        <li className="landing-sign__item">
          <Link className="landing-sign__link" to="/app/auth/registration/">
            {header?.signUp}
          </Link>
        </li>
      </ul>
    </>
  );
};
