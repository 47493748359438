import { Navigate } from "react-router-dom";
import { AdminRoutesNew } from "./adminRoutes/index";

export const AppRoutes = [
  {
    path: "home/",
    async lazy() {
      const { Home } = await import("@views/app/Home/Home");
      return {
        Component: Home
      };
    }
  },
  {
    path: "knowledge-base/",
    async lazy() {
      const { KnowledgeBase } = await import(
        "@views/app/KnowledgeBase/KnowledgeBase"
      );
      return {
        Component: KnowledgeBase
      };
    }
  },
  {
    path: "checkout/:id",
    async lazy() {
      const { CheckOut } = await import("@views/app/CheckOut/CheckOut");
      return {
        Component: CheckOut
      };
    }
  },
  {
    path: "profile/:tab/:id?",
    async lazy() {
      const { AccountSettings } = await import("@views/app/account-settings");
      return {
        Component: AccountSettings
      };
    }
  },
  {
    path: "setup/",
    async lazy() {
      const { Setup } = await import("@views/app/Setup/Setup");
      return {
        Component: Setup
      };
    }
  },
  ...AdminRoutesNew,
  {
    path: "*", // 404 page
    element: <Navigate to="/app/home/" replace={true} />
  }
];
