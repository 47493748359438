import { AlertCircle } from "react-feather";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Badge, NavItem, NavLink } from "reactstrap";
import { useSubscription } from "react-stomp-hooks";
// ** Custom Hooks & Utils
import { useDispatchedActions } from "@hooks";
// ** Components
// import sound from "@assets/report-sound.wav";

export const ReportsForAdmin = () => {
  // ** Hooks
  const navigate = useNavigate();
  // ** Dispatched Actions
  const { fetchReportsForAdmin } = useDispatchedActions();
  // ** Redux state
  const { count } = useSelector((state) => state.navBar.reportsForAdmin);
  // ** Local state
  const [triggerLoad, setTriggerLoad] = useState(true);

  // ** Functions
  const handlerTriger = () => setTriggerLoad((state) => !state);

  const openReportsPage = (event) => {
    event.preventDefault();
    navigate("/app/admin/reports");
  };

  // ** Subscriptions
  useSubscription("/admin/modemReports", handlerTriger);
  useSubscription("/admin/modemReports/changed", handlerTriger);

  // ** Effects
  useEffect(() => {
    const promise = fetchReportsForAdmin();
    return () => promise?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerLoad]);

  return (
    <NavItem className="d-block px-50">
      <NavLink className="nav-link-style p-0" onClick={openReportsPage}>
        {count > 0 ? (
          <>
            <AlertCircle className="text-danger" size={21} />
            <Badge pill color="danger" className="badge-up">
              {count}
            </Badge>
          </>
        ) : (
          <AlertCircle className="ficon" />
        )}
      </NavLink>
    </NavItem>
  );
};
