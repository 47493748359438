import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Row, Badge, Col } from "reactstrap";
import { useOutletContext } from "react-router-dom";

// ** Custom Hooks & Utils
import { useDispatchedActions } from "@hooks";
import { demoModemChangeStatus, randomIP } from "@helpers";
// ** Services
import { apiService } from "@services/ApiService";

const Name = () => {
  // ** Hooks
  const { isDemo } = useOutletContext();
  // ** Dispatched Actions
  const { setUpdateModems } = useDispatchedActions();
  // ** Redux state
  const { activeHostId } = useSelector((state) => state.content.hostModems);
  const { modems } = useSelector((state) => state.demo.content);
  // ** States
  const [fetching, setFetching] = useState(false);

  // ** Functions
  const onClickChangeAll = () => {
    setFetching(true);
    if (!isDemo) {
      apiService.modems
        .updateAllByHostId({ hostId: activeHostId })
        .then((res) => {
          if (res) {
            toast.success("Modems will be rebooted soon!");
          }
        })
        .catch(() => {
          toast.error("Error!");
        })
        .finally(() => setFetching(false));
    } else {
      // demo logik start
      toast.success("Modems will be rebooted soon!");
      const activeHostModems = modems[activeHostId];
      const newModemsRebut = {
        ...modems,
        [activeHostId]: activeHostModems.map((item) => ({
          ...item,
          lastUpdate: new Date().getTime(),
          status: demoModemChangeStatus(item?.status, "REBOOT")
        }))
      };
      const newModemsOnline = {
        ...modems,
        [activeHostId]: activeHostModems.map((item) => ({
          ...item,
          lastUpdate: new Date().getTime(),
          ip: randomIP(),
          status: demoModemChangeStatus(item?.status, "ONLINE")
        }))
      };
      setFetching(false);
      setTimeout(() => {
        setUpdateModems(newModemsRebut);
      }, 3000);

      setTimeout(() => {
        setUpdateModems(newModemsOnline);
      }, 8000);
      // demo logik end
    }
  };

  return (
    <div className="d-flex align-items-center">
      <span className="me-50">Rotation</span>
      <Button
        color="danger"
        disabled={fetching}
        size="sm"
        onClick={onClickChangeAll}
      >
        Change all
      </Button>
    </div>
  );
};

export const Rotation = ({ row, admin }) => {
  // ** Hooks
  const { isDemo } = useOutletContext();
  const { setModalCommandLinksOpen, setModalRotationOpen, setUpdateModems } =
    useDispatchedActions();
  // ** Redux state
  const { activeHostId } = useSelector((state) => state.content.hostModems);
  const { modems } = useSelector((state) => state.demo.content);
  const [isSubmit, setIsSubmit] = useState(false);
  const handlerModemChangeIp = (modemId) => {
    setIsSubmit(true);
    if (!isDemo) {
      apiService.modems
        .updateModemById({ modemId })
        .then((res) => {
          if (res.data.inf.success) {
            toast.success("Modem IP will be replaced soon!");
          } else {
            toast.error(
              "Failed to change modem IP. The host, aggregator, or modem may not be active."
            );
          }
          setIsSubmit(false);
        })
        .catch(() => {
          toast.error("Error changing modem IP!");
          setIsSubmit(false);
        });
    } else {
      // demo logik start
      toast.success("Modem IP will be replaced soon!");
      const activeHostModems = modems[activeHostId];
      const newModemsRebut = {
        ...modems,
        [activeHostId]: activeHostModems.map((item) => {
          if (item.id === modemId) {
            return {
              ...item,
              lastUpdate: new Date().getTime(),
              status: demoModemChangeStatus(item?.status, "REBOOT")
            };
          } else return item;
        })
      };
      const newModemsOnline = {
        ...modems,
        [activeHostId]: activeHostModems.map((item) => {
          if (item.id === modemId) {
            return {
              ...item,
              lastUpdate: new Date().getTime(),
              ip: randomIP(),
              status: demoModemChangeStatus(item?.status, "ONLINE")
            };
          } else return item;
        })
      };
      setTimeout(() => {
        setUpdateModems(newModemsRebut);
      }, 3000);

      setTimeout(() => {
        setUpdateModems(newModemsOnline);
        setIsSubmit(false);
      }, 8000);
      // demo logik end
    }
  };
  return (
    <Row
      className={`w-100 justify-content-between flex-nowrap ${
        !row?.paid && !admin ? "blur-box" : ""
      }`}
    >
      <Col className="d-flex align-items-center ps-0">
        <Button
          color="primary"
          outline
          style={{ minWidth: "95px" }}
          disabled={!row?.paid && !admin}
          onClick={() => setModalRotationOpen(row?.id)}
        >
          {row?.rotationRate ? `${row.rotationRate} min` : "Set"}
        </Button>
      </Col>
      <Col className="pe-0">
        <Button
          className="m-0 p-0 mb-25 border-0"
          color="flat-danger"
          disabled={isSubmit || (!row?.paid && !admin)}
          onClick={() => handlerModemChangeIp(row?.id)}
        >
          <Badge color="light-danger">Change ip</Badge>
        </Button>
        <Button
          className="m-0 p-0 border-0"
          color="flat-success"
          disabled={!row?.paid && !admin}
          onClick={() => setModalCommandLinksOpen(row?.id)}
        >
          <Badge color="light-success">Links</Badge>
        </Button>
      </Col>
    </Row>
  );
};

export const staticRotation = (admin = false) => ({
  id: "rotation",
  name: <Name />,
  // selector: "rotation",
  minWidth: "225px",
  key: "7",

  cell: (row) => <Rotation row={row} admin={admin} />
});
