import { Moon, Sun } from "react-feather";
import { useSelector } from "react-redux";
import { NavItem } from "reactstrap";
import { NavLink, useLoaderData } from "react-router-dom";
import { useEffect, useState } from "react";
// ** Custom Hooks & Helpers
import { useDispatchedActions } from "@hooks";

export const ThemeToggler = () => {
  // ** Dispatched Actions
  const { isDemo } = useLoaderData();
  const { setThemeSkin, updateProfileInterface } = useDispatchedActions();
  // ** Redux State
  const { skin } = useSelector((state) => state.theme);
  const [localSkin, setLocalSkin] = useState(skin);
  // ** Functions
  const handlerChangeTheme = (e) => {
    e.preventDefault();
    if (skin === "light") {
      setThemeSkin("dark");
    } else {
      setThemeSkin("light");
    }
  };

  useEffect(() => {
    let promise;
    if (skin !== localSkin) {
      const params = { theme: skin };
      promise = updateProfileInterface({ params, isDemo });
      setLocalSkin(skin);
    }
    return () => promise?.abort?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skin]);

  return (
    <NavItem className="d-block px-50">
      <NavLink className="nav-link-style" onClick={handlerChangeTheme}>
        {skin === "dark" ? (
          <Sun className="ficon" />
        ) : (
          <Moon className="ficon" />
        )}
      </NavLink>
    </NavItem>
  );
};
