import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useOutletContext } from "react-router-dom";

// ** Services
import { apiService } from "@services/ApiService";
// ** Custom Hooks & Utils
import { useDispatchedActions } from "@hooks";
import { ModalConfirmAction } from "@components/app";
// ** Components
import { UsersListItem } from "./components/UsersListItem/UsersListItem";
import { HeaderList } from "./components/HeaderList";

export const UsersList = ({ trigerReload }) => {
  // ** Hooks
  const { isDemo } = useOutletContext();
  // ** Dispatched Actions
  const { setUpdateModems, setModalUserLimitsOpen } = useDispatchedActions();
  // ** Redux state
  const { selectedModemsInfo, reloadUserlist } = useSelector(
    (state) => state.modals
  );
  const { activeHostId } = useSelector((state) => state.content.hostModems);
  const { content } = useSelector((state) => state.demo);
  // ** State
  const [deleteUserIds, setDeleteUserIds] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isModemsLimitsSet, setIsModemsLimitsSet] = useState(false);
  const [demoUpdate, setDemoUpdate] = useState(false);

  // ** Functions
  const getUsersByModemsId = () => {
    setIsModemsLimitsSet(false);
    selectedModemsInfo.forEach((modem) => {
      if (!isDemo) {
        apiService.modemAuth.getWithLimits({ id: modem?.id }).then((req) => {
          if (req && req?.data?.modemAuths) {
            if (req?.data?.isModemLimitPresent) {
              setIsModemsLimitsSet(true);
            }
            setUsers(
              req.data.modemAuths.map((item) => ({ ...item, enabled: false }))
            );
          }
        });
      } else {
        // demo logik start
        const activeHostModems = content.modems[activeHostId];
        const tempModem = activeHostModems.find((item) => item.id === modem.id);
        if (tempModem.limits.isModemLimitPresent) {
          setIsModemsLimitsSet(true);
        }
        setUsers(
          tempModem.limits.modemAuths.map((item) => ({
            ...item,
            enabled: false
          }))
        );
        setDemoUpdate(true);

        // demo logik end
      }
    });
    setSelectedUsers([]);
  };

  const onSelectRow = (checked, id) => {
    if (checked) {
      setSelectedUsers((state) => [...state, id]);
    } else {
      setSelectedUsers((state) => state.filter((item) => item !== id));
    }
  };

  // select all checkbox
  const selectAllCheckbox = ({ target: { checked } }) => {
    setSelectedUsers(checked ? users.map((item) => item?.id) : []);
  };

  // handler delete users
  const handlerDeleteUsers = () => setDeleteUserIds(selectedUsers);

  const handlerDeleteOneUser = (event) => {
    const { id } = event.currentTarget;
    setDeleteUserIds([id]);
  };

  const handlerCloseModalDeleteUser = () => setDeleteUserIds(null);

  const deleteUsers = () => {
    if (!isDemo) {
      deleteUserIds.forEach((id, index) => {
        apiService.modemAuth.deleteById({ id }).then(() => {
          if (index + 1 === deleteUserIds.length) {
            getUsersByModemsId();
            toast.success("Deleted successfully");
          }
        });
      });
    } else {
      // demo logik start
      const activeHostModems = content.modems[activeHostId];
      const newModems = {
        ...content.modems,
        [activeHostId]: activeHostModems.map((item) => {
          if (
            item.limits.modemAuths.find((auth) =>
              deleteUserIds.includes(auth.id)
            )
          ) {
            const tempLogins = [];
            const tempModemAuths = item.limits.modemAuths.filter((auth) => {
              if (!deleteUserIds.includes(auth.id)) {
                if (auth.speedLimit > 0 || auth.trafficLimit > 0) {
                  tempLogins.push(
                    auth.authType === "IP" ? auth.ip : auth.login
                  );
                }
                return auth;
              }
            });
            return {
              ...item,
              lastUpdate: new Date().getTime(),
              limits: {
                ...item.limits,
                logins: tempLogins,
                modemAuths: tempModemAuths
              }
            };
          } else return item;
        })
      };
      setUpdateModems(newModems);
      toast.success("Deleted successfully");
      // demo logik end
    }
  };

  //
  const handlerSetUsersLimits = () =>
    setModalUserLimitsOpen({
      modemLimitSet: isModemsLimitsSet,
      ids: selectedUsers
    });

  const handlerSetOneUserLimits = (event) => {
    const { id } = event.currentTarget;
    setModalUserLimitsOpen({ modemLimitSet: isModemsLimitsSet, ids: [id] });
  };

  // ** Effects
  useEffect(() => {
    // setModemsState(selectedModemsInfo);
    getUsersByModemsId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModemsInfo, trigerReload, reloadUserlist]);

  // demo logick update users after change
  useEffect(() => {
    if (isDemo && demoUpdate) {
      getUsersByModemsId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.modems]);
  // end demo

  return (
    <div className="overflow-auto">
      <div style={{ minWidth: "560px" }}>
        <HeaderList
          allSelect={
            selectedUsers?.length && selectedUsers?.length === users?.length
          }
          setAllSelect={selectAllCheckbox}
          selectedUsers={selectedUsers}
          deleteUsers={handlerDeleteUsers}
          setLimits={handlerSetUsersLimits}
        />

        {users?.length ? (
          <div>
            {users.map((user) => (
              <div key={user?.id}>
                <UsersListItem
                  user={user}
                  selectedUsers={selectedUsers}
                  amountUsers={users.length}
                  refreshUsers={getUsersByModemsId}
                  setLimits={handlerSetOneUserLimits}
                  deleteUser={handlerDeleteOneUser}
                  onSelectRow={onSelectRow}
                />
              </div>
            ))}
          </div>
        ) : (
          "There are no users on this modem"
        )}
        <ModalConfirmAction
          isOpen={!!deleteUserIds?.length}
          close={handlerCloseModalDeleteUser}
          action={deleteUsers}
          title="Delete user(s)"
          body="Are you sure you want to delete user(s)?"
        />
      </div>
    </div>
  );
};
