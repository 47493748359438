import { useState } from "react";
import { Modal } from "@components/landing/common";
import { Button, Container } from "@components/landing/ui";
import { useModal, useCurrentSeo } from "@hooks";
import { FaqList } from "./components/faqList";
import { FaqModal } from "./components/faqModal";

import "./faq.scss";

export const Faq = () => {
  // **Hooks
  const { faq } = useCurrentSeo();

  // **State
  const [clicked, setClicked] = useState(0);

  // **Handlers
  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  const [modalToggler, modalActive, setModalActive] = useModal();

  return (
    <>
      <div className="landing-faq" id="faq" >
        <Container>
          <div className="landing-faq__wrapper">
            <div className="landing-faq__info">
              <p className="section__title">{faq?.title}</p>
              <p className="section__text landing-faq__text">
                {faq?.text}
              </p>
              <Button onClick={modalToggler}>{faq?.button}</Button>
            </div>
            <FaqList clicked={clicked} handleToggle={handleToggle} />
          </div>
        </Container>
      </div>
      <Modal
        visible={modalActive}
        width="370"
        lite
        cancelHandler={setModalActive}
        title={faq?.modal?.title}
      >
        <FaqModal closeModal={setModalActive} />
      </Modal>
    </>
  );
};
