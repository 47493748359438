import { useState, useRef, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { useLoaderData } from "react-router-dom";

// ** Custom Hooks & Helpers
import { useDispatchedActions } from "@hooks";
// ** Components
import { NAVBAR_MENU, NAVBAR_MENU_DEMO } from "../navigation";
import { VerticalMenuHeader } from "./VerticalMenuHeader";
import { VerticalNavMenuItems } from "./VerticalNavMenuItems";

export const VerticalMenu = () => {
  // ** Ref
  const shadowRef = useRef(null);
  // ** Hooks
  const { isDemo } = useLoaderData();
  // **Dispatched Actions
  const { setMenuVisibility } = useDispatchedActions();

  // **Redux State
  const { skin } = useSelector((state) => state.theme);
  const { menuVisibility } = useSelector((state) => state.navBar.layout);

  // ** States
  const [groupOpen, setGroupOpen] = useState([]);
  const [groupActive, setGroupActive] = useState([]);

  // ** Functions
  // ** Scroll Menu
  const scrollMenu = (container) => {
    if (shadowRef && container.scrollTop > 0) {
      if (!shadowRef.current.classList.contains("d-block")) {
        shadowRef.current.classList.add("d-block");
      }
    } else if (shadowRef.current.classList.contains("d-block")) {
      shadowRef.current.classList.remove("d-block");
    }
  };

  const handlerCloseMenu = () => setMenuVisibility(false);

  // ** Effects
  useEffect(() => {
    if (menuVisibility) {
      setMenuVisibility(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <div
        className={`main-menu menu-fixed menu-accordion menu-shadow expanded ${
          skin === "dark" ? "menu-dark" : "menu-light"
        }`}
      >
        {/* Vertical Menu Header */}
        <VerticalMenuHeader setGroupOpen={setGroupOpen} />
        {/* Vertical Menu Header Shadow */}
        <div className="shadow-bottom" ref={shadowRef}></div>
        {/* Perfect Scrollbar */}
        <PerfectScrollbar
          className="main-menu-content"
          options={{ wheelPropagation: false }}
          onScrollY={(container) => scrollMenu(container)}
        >
          <ul className="navigation navigation-main">
            <VerticalNavMenuItems
              items={isDemo ? NAVBAR_MENU_DEMO : NAVBAR_MENU}
              groupActive={groupActive}
              setGroupActive={setGroupActive}
              groupOpen={groupOpen}
              setGroupOpen={setGroupOpen}
            />
          </ul>
        </PerfectScrollbar>
      </div>
      {/* Vertical Nav Menu Overlay */}
      <div
        className={`sidenav-overlay ${menuVisibility ? "show" : ""}`}
        onClick={handlerCloseMenu}
      />
    </>
  );
};
