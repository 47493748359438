import { useEffect, useRef, useState } from "react";

export const FaqItem = ({ info, onToggle, active }) => {
  // **Ref
  const answerRef = useRef();

  // **State
  const [height, setHeight] = useState(0);

  // **Effects
  useEffect(() => {
    setHeight(answerRef.current.scrollHeight);
  }, [active]);

  return (
    <li
      className={
        active
          ? "landing-faq__item landing-faq__item--active"
          : "landing-faq__item"
      }
    >
      <div className="landing-faq__item-head" onClick={onToggle}>
        {info.question}
        <div className="landing-faq__item-icon"></div>
      </div>
      <div
        ref={answerRef}
        className="landing-faq__item-body"
        style={active ? { maxHeight: height } : { maxHeight: "0px" }}
      >
        {info.answer}
      </div>
    </li>
  );
};
