import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useCurrentSeo } from "@hooks";

const crmBreakpoints = {
  320: {
    spaceBetween: 15,
    slidesPerView: 1.25
  },
  460: {
    spaceBetween: 15,
    slidesPerView: 1.5
  },
  767: {
    spaceBetween: 30,
    slidesPerView: 2.5
  },
  992: {
    spaceBetween: 15,
    slidesPerView: 3.25
  },
  1200: {
    spaceBetween: 30,
    slidesPerView: 4
  }
};

export const CRMSlider = ({ className }) => {
  // *Hooks
  const { crm } = useCurrentSeo();

  return (
    <div
      className={`landing-crm__slider ${className || ""}`}
      style={{ display: "none" }}
    >
      <Swiper
        spaceBetween={30}
        slidesPerView={4}
        wrapperTag="ul"
        watchOverflow
        navigation
        loop
        touchEventsTarget="container"
        modules={[Navigation]}
        breakpoints={crmBreakpoints}
      >
        {crm?.items?.length
          ? crm?.items.map((item, index) => (
              <SwiperSlide
                className="landing-crm__item"
                tag="li"
                key={index}
                style={{ height: "auto" }}
              >
                <h3 className="landing-crm__item-title">{item.title}</h3>
                <div className="landing-crm__item-text">
                  <p>{item.text}</p>
                </div>
              </SwiperSlide>
            ))
          : null}
      </Swiper>
    </div>
  );
};
