import { Link, matchPath, useLocation, useParams } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
// ** Components
import {
  NETWORK_HARDWARE,
  SITE_SETTINGS,
  USERS_AND_ACTIVITY
} from "../../../views/app/admin/main/adminLinks";

export const AdminBreadcrumbs = () => {
  // ** Hooks
  const { pathname } = useLocation();
  const paramsUrl = useParams();
  // ** Constants
  const arrayPath = pathname.split("/");
  const adminLinks = [
    ...NETWORK_HARDWARE,
    ...SITE_SETTINGS,
    ...USERS_AND_ACTIVITY
  ];

  let parent = null;
  // try to find parent
  if (arrayPath[3]) {
    parent = adminLinks.find((item) => {
      if (item?.path && item?.path.includes(arrayPath[3])) return true;
      if (
        item?.children?.length > 0 &&
        item?.children?.find((child) => child?.path.includes(arrayPath[3]))
      ) {
        return true;
      }
      return false;
    });
  }

  let active = null;
  // try to find active if parent has children
  if (parent && parent?.children?.length > 0) {
    active = parent?.children?.find((child) => {
      if (!child?.path) return false;
      if (child?.path === pathname) return true;
      if (Object.keys(paramsUrl).length > 0 && !paramsUrl.id) {
        return pathname.includes(child.path);
      }
      return matchPath(child.path, pathname);
    });
  }

  if (
    parent &&
    (active?.path === parent?.path ||
      (!active &&
        (parent?.path === pathname ||
          parent?.path === `${pathname}/` ||
          `${parent?.path}/` === pathname)))
  ) {
    active = parent;
    parent = null;
  }
  if (parent && !parent?.path) parent = null;
  // try to identify active if parent has no children
  if (parent && arrayPath[4] && !active) {
    switch (true) {
      case pathname.includes("edit"):
        active = { title: "Edit" };
        break;
      case pathname.includes("add"):
        active = { title: "Add" };
        break;
      case pathname.includes("archive"):
        active = { title: "History Archive" };
        break;
      case pathname.includes("history"):
        active = { title: "History" };
        break;
      case pathname.includes("logs"):
        active = { title: "Logs" };
        break;
      case pathname.includes("settings"):
        active = { title: "Settings" };
        break;
      case pathname.includes("config"):
        active = { title: "Config" };
        break;
      default:
        active = null;
    }
  }

  return (
    <div className="content-header row">
      <div className="content-header-left col-md-12 col-12 mb-2">
        <div className="row breadcrumbs-top">
          <div className="col-12">
            <h2 className="content-header-title float-start mb-0">Admin</h2>
            <div className="breadcrumb-wrapper vs-breadcrumbs d-sm-block d-none col-12">
              <Breadcrumb>
                <BreadcrumbItem tag="li">
                  <Link to="/app/admin/">Panel</Link>
                </BreadcrumbItem>
                {parent ? (
                  <BreadcrumbItem tag="li" className="text-primary">
                    <Link to={parent?.path}>{parent?.title}</Link>
                  </BreadcrumbItem>
                ) : null}
                {active ? (
                  <BreadcrumbItem tag="li" active>
                    {active?.title}
                  </BreadcrumbItem>
                ) : null}
              </Breadcrumb>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
