import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
// ** Custom Hooks & Utils
import { useDispatchedActions, useBreakpoints } from "@hooks";
// ** Styles
import "./lang.scss";

export const Lang = ({ fullName = false }) => {
  // **Hooks
  const { lang } = useParams();
  const { pathname } = useLocation();
  const breakpoints = useBreakpoints();
  // ** Dispatched Actions
  const { toggleMobileMenu, setLang } = useDispatchedActions();

  // **Redux state
  const { langs } = useSelector((state) => state.translation);
  const { isOpen } = useSelector((state) => state.landing.mobileMenu);
  const settings = useSelector((state) => state.settings);

  // ** State
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  // ** Functions
  const convertPathname = (newLang) => pathname.replace(lang, newLang);

  // ** Constants
  const langOptions = langs.data.map((item) => ({
    value: item.code,
    label: fullName ? item.internationalName : item.code.toUpperCase()
  }));

  // ** Effects
  useEffect(() => {
    if (lang && ["en", "ru"].includes(lang) && settings.lang !== lang) {
      setLang(lang);
    }
    if (!breakpoints.includes("xl")) {
      if (isOpen) {
        toggleMobileMenu(!isOpen);
      }
      document
        .querySelector(".landing-dropdown")
        .classList.remove("landing-dropdown--active");
      document
        .querySelector(".landing-burger__btn")
        .classList.remove("landing-burger__btn--active");
      document.body.classList.remove("lock");
      document.documentElement.style.overflow = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <div className="landing-lang" onMouseEnter={() => setDropdownIsOpen(true)}>
      <button
        className="landing-lang__current"
        style={{
          "--arrow": "url(/landing/ui/arrow.svg)"
        }}
        onFocus={() => setDropdownIsOpen(true)}
      >
        <span>{langOptions.find((item) => item.value === lang)?.label}</span>
      </button>
      {dropdownIsOpen && (
        <ul className="landing-lang__list">
          {langOptions.map((item) => (
            <li key={item?.value} className="landing-lang__item">
              <Link
                className={`landing-lang__link ${
                  item?.value === lang ? "landing-lang__link--active" : ""
                }`}
                to={convertPathname(item?.value.toLowerCase())}
                onClick={() => setDropdownIsOpen(false)}
              >
                {item?.label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
