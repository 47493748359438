export const renderAccentNames = (names) => {
  if (names?.length) {
    return names.map((item, index) => (
      <span key={index} className="ms-25">
        <strong>{item}</strong>
        {index + 1 === names.length ? "." : ","}
      </span>
    ));
  }
  return null;
};
