import { useEffect } from "react";
import { NavLink, useLoaderData } from "react-router-dom";
import { useSelector } from "react-redux";
import { X } from "react-feather";

// ** Custom Hooks & Helpers
import { useDispatchedActions } from "@hooks";

export const VerticalMenuHeader = (props) => {
  // ** Props
  const { setGroupOpen } = props;
  // ** Hooks
  const { isDemo } = useLoaderData();
  // ** Dispatched Actions
  const { setMenuVisibility } = useDispatchedActions();

  // ** Redux store
  const {
    layout: { menuVisibility }
  } = useSelector((state) => state.navBar);

  // ** Effects
  // ** Reset open group
  useEffect(() => {
    if (!menuVisibility) setGroupOpen([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuVisibility]);

  return (
    <div className="navbar-header ps-1">
      <ul className="nav navbar-nav flex-row">
        <li className="nav-item me-auto">
          <NavLink
            to={isDemo ? "/demo/home/" : "/app/auth/login/"}
            className="navbar-brand d-flex mt-1 me-0"
          >
            <img
              src="/favicons/Favicon-app-120px.svg"
              loading="lazy"
              alt={"logo"}
              width="36"
              height="36"
            />
            <h2 className="brand-text ps-50">PROXYSELLER</h2>
          </NavLink>
        </li>
        <li className="nav-item nav-toggle">
          <div className="nav-link modern-nav-toggle cursor-pointer">
            <X
              onClick={() => setMenuVisibility(false)}
              className="toggle-icon icon-x d-block d-xl-none"
              size={20}
            />
          </div>
        </li>
      </ul>
    </div>
  );
};
