import { useEffect, useState } from "react";

import { createPortal } from "react-dom";
// Libs
import { Transition } from "react-transition-group";
import { useScrollBarWidth } from "@hooks";
import "./modal.scss";

export const Modal = (props) => {
  // **Props
  const {
    visible,
    title,
    animationDuration = 300,
    width = 800,
    cancelHandler,
    lite = false,
    children
  } = props;

  // **Hooks
  const scrollbarWidth = useScrollBarWidth();
  // ** Local State
  const [show, setShow] = useState(false);

  // **Constants
  const styles = {
    "--duration": `${animationDuration}ms`,
    "--width": `${width}px`
  };

  // **Handlers
  const closeModal = () => cancelHandler(false);

  const modalWrapper = () => (
    <div className="landing-modal__wrapper">
      <div
        className={`landing-modal__content ${title ? "landing-modal__content--has-title" : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        {title && <div className="landing-modal__content-title">{title}</div>}
        <div className="landing-modal__content-body">{children}</div>
        <button
          className="landing-modal__close"
          aria-label="Закрыть модальное окно"
          onClick={closeModal}
        ></button>
      </div>
    </div>
  );

  // **Effects
  // Inserting and deleting modal
  useEffect(() => {
    if (!show) {
      setShow(true);
    }
    const modalElement = document.createElement("div");
    if (visible) {
      const currentScroll =
        window.scrollY || document.documentElement.scrollTop;
      document.body.classList.add("lock");
      document.body.appendChild(modalElement);
      document.body.style.top = `-${currentScroll}px`;
      document.body.style.paddingRight = `${scrollbarWidth}px`;
      document.body.style.overflow = "hidden";
      return () => {
        const scrollY = document.body?.style?.top?.slice(1, -2) || 0;
        document.body.classList.remove("lock");
        document.body.removeChild(modalElement);
        document.body.style.paddingRight = "";
        document.body.style.top = "";
        document.body.classList.remove("lock");
        document.body.style.overflow = "";
        window.scrollTo(0, scrollY);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  if (!show) {
    return <></>;
  }

  if (lite) {
    return (
      <div
        style={styles}
        className={`landing-modal landing-modal-lite ${visible ? "showModal" : ""}`}
        onClick={closeModal}
      >
        {modalWrapper()}
      </div>
    );
  }

  return createPortal(
    <Transition
      in={visible}
      timeout={animationDuration}
      mountOnEnter
      unmountOnExit
    >
      {(state) => (
        <div
          style={styles}
          className={`landing-modal ${state}`}
          onClick={closeModal}
        >
          {modalWrapper()}
        </div>
      )}
    </Transition>,
    document.body
  );
};
