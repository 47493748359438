import { Navigate } from "react-router-dom";

export const AdminErrorReportsRoutes = [
  {
    path: "reports/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminReports } = await import(
            "@views/app/admin/reports/list"
          );
          return {
            Component: AdminReports
          };
        }
      },
      {
        path: ":id/edit",
        async lazy() {
          const { AdminReportEdit } = await import(
            "@views/app/admin/reports/edit"
          );
          return {
            Component: AdminReportEdit
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
