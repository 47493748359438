import { Navigate } from "react-router-dom";

export const AdminPaymentsRoutes = [
  {
    path: "payments/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminPayments } = await import(
            "@views/app/admin/payments/list"
          );
          return {
            Component: AdminPayments
          };
        }
      },
      {
        path: ":paymentId/edit/",
        async lazy() {
          const { AdminPaymentEdit } = await import(
            "@views/app/admin/payments/edit"
          );
          return {
            Component: AdminPaymentEdit
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
