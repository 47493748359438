import { Controller, useFormContext } from "react-hook-form";
import { ReactCountryFlag } from "react-country-flag";
import Select, { components } from "react-select";
// ** Custom Hooks & Utils
import { useTranslation } from "@hooks";
// ** Styles
import "./select.scss";

// ** Constants
export const RENDER_TYPES = {
  COUNTRY: "COUNTRY",
  PHONE_PREFIX: "PHONE_PREFIX"
};

export const SelectForm = ({
  name,
  placeholder = "",
  options,
  required = false,
  isMulti = false,
  isDisabled = false,
  isLoading = false,
  messageOff = false,
  handleChange,
  ...rest
}) => {
  // ** Hooks
  const { control } = useFormContext();
  const { mainPage } = useTranslation();

  // ** Functions
  const currentValue = (value) => {
    let currentValue;
    if (isMulti) {
      currentValue = options.filter((option) => value?.includes(option.value));
    } else {
      currentValue = options.find((option) => value === option.value);
    }
    if (currentValue) {
      return currentValue;
    }

    return null;
  };

  const handleChangeSelect = (data, formChange) => {
    if (isMulti) {
      formChange(data.map((item) => item.value));
    } else {
      formChange(data?.value);
    }
    handleChange?.(data);
  };

  const renderOption = (label, value) => (
    <span className="d-flex align-items-center">
      <ReactCountryFlag
        className="country-flag flag-icon me-50 h-100"
        countryCode={value.toLowerCase()}
        svg
      />
      {label}
    </span>
  );

  const Option = (props) => {
    const { label, code, renderType } = props.data;

    if (!renderType || !RENDER_TYPES.hasOwnProperty(renderType)) {
      return <components.Option {...props} />;
    }

    if (renderType === RENDER_TYPES.COUNTRY) {
      return (
        <components.Option {...props}>
          {renderOption(label, code)}
        </components.Option>
      );
    }

    if (renderType === RENDER_TYPES.PHONE_PREFIX) {
      return (
        <components.Option {...props}>
          {renderOption(label, code)}
        </components.Option>
      );
    }

    return <components.Option {...props} />;
  };

  const SingleValue = ({ children, ...props }) => {
    const { label, code, value, renderType } = props.data;

    if (!renderType || !RENDER_TYPES.hasOwnProperty(renderType)) {
      return (
        <components.SingleValue {...props}>{children}</components.SingleValue>
      );
    }

    if (renderType === RENDER_TYPES.COUNTRY) {
      return (
        <components.SingleValue {...props}>
          {renderOption(label, code)}
        </components.SingleValue>
      );
    }

    if (renderType === RENDER_TYPES.PHONE_PREFIX) {
      return (
        <components.SingleValue {...props}>
          {renderOption(value, code)}
        </components.SingleValue>
      );
    }

    return (
      <components.SingleValue {...props}>{children}</components.SingleValue>
    );
  };

  const currentErrorMessage = (error) => {
    if (messageOff) return "";

    if (error?.type === "required") {
      return mainPage?.errors?.requiredField;
    }

    return error?.message;
  };

  return name && control ? (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: required, message: mainPage?.errors?.requiredField }
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className="landing-form__item-select">
          <label className="landing-form__item-select__label">
            <Select
              className={`landing__form__select ${
                error?.type ? "landing__form__select-error" : ""
              }`}
              classNamePrefix="landing__form__select"
              options={options}
              // menuIsOpen
              placeholder={placeholder}
              components={{ Option, SingleValue }}
              value={currentValue(value)}
              onChange={(data) => handleChangeSelect(data, onChange)}
              isDisabled={isDisabled}
              isLoading={isLoading}
              {...rest}
            />
            <span
              className={`landing-form__item-select__label-name ${
                currentValue(value)
                  ? "landing-form__item-select__label-name-active"
                  : ""
              }
              ${
                isDisabled
                  ? "landing-form__item-select__label-name-disabled"
                  : ""
              }
              `}
            >
              {placeholder}
              {required && (
                <span className="landing-form__item-select__label-name-required">
                  *
                </span>
              )}
            </span>
          </label>
          {!messageOff && error?.type && (
            <p className="landing-form__item-error">
              {currentErrorMessage(error)}
            </p>
          )}
        </div>
      )}
    />
  ) : null;
};
