/* eslint-disable no-console */
import { useEffect, useRef } from "react";
import { StompSessionProvider } from "react-stomp-hooks";
// ** Custom Hooks & Utils
import { useDispatchedActions } from "@hooks";
// ** Components
import { useSelector } from "react-redux";

export const App = ({ provider: RouterProvider, routerProps }) => {
  // **Hooks
  const refFetchControl = useRef(true);
  // ** Dispatched Actions
  const { fetchUserAuth, fetchSiteSettings, fetchAgreements } =
    useDispatchedActions();
  const { site } = useSelector((state) => state.settings);
  // **Effects
  useEffect(() => {
    if (!refFetchControl.current) return;
    refFetchControl.current = false;
    fetchUserAuth();
    if (!site?.isLoaded) {
      fetchSiteSettings();
    }
    fetchAgreements("en");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <StompSessionProvider
      url={"/api/websocket/"}
      reconnectDelay={process.env.NODE_ENV === "development" ? 0 : 3000}
    >
      <RouterProvider {...routerProps} />
    </StompSessionProvider>
  );
};

export default App;
