import { useEffect, useState } from "react";
const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
};

export const useBreakpoints = () => {
  // ** local State
  const [currentBreakpoints, setCurrentBreakpoints] = useState(() => {
    try {
      const res = Object.keys(breakpoints).filter(
        (breakpoint) => window.innerWidth >= breakpoints[breakpoint]
      );
      return res;
    } catch (ignored) {
      return ["xs", "sm", "md", "lg", "xl", "xxl"];
    }
  });
  // ** Effects
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const newBreakpoint = Object.keys(breakpoints).filter(
        (breakpoint) => windowWidth >= breakpoints[breakpoint]
      );
      if (newBreakpoint.length !== currentBreakpoints.length) {
        setCurrentBreakpoints(newBreakpoint);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBreakpoints.length]);

  return currentBreakpoints;
};
