import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { Card, CardBody, Col, Row } from "reactstrap";

import "./skeleton.styles";

export const LoadingModemLinks = () => {
  // ** Redux State
  const { skin } = useSelector((state) => state.theme);

  // ** Conctants
  const darkColors =
    skin === "dark" ? { baseColor: "#242b3d", highlightColor: "#343d55" } : {};

  return (
    <>
      <Row className="mb-1">
        {new Array(3).fill(1).map((it, i) => (
          <Col key={`button-shimmer-${i}`}>
            <Skeleton height={40} {...darkColors} />
          </Col>
        ))}
      </Row>
      <Row className="mb-1">
        <Col>
          <Skeleton height={38} {...darkColors} />
        </Col>
      </Row>
      {new Array(3).fill(1).map((it, i) => (
        <Row key={`load-modem-link-${i}`}>
          <Col>
            <Card className="bg-transparent border-primary shadow-none">
              <CardBody className="p-1">
                <div className="mb-50">
                  <Skeleton width={150} height={20} {...darkColors} />
                </div>
                <div className="row align-items-center">
                  <div className="col-11">
                    <Skeleton height={38} {...darkColors} />
                  </div>
                  <div className="col-1 ps-0 d-flex justify-content-end">
                    <Skeleton width={25} height={25} {...darkColors} />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ))}
    </>
  );
};
