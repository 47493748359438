import { useTranslation } from "@hooks";

import { getIcon } from "@helpers";

export const Payment = () => {
  const { mainPage: { footer } } = useTranslation();

  return (
    <ul className="landing-footer__payment-list">
      {footer?.paymentList?.length && footer.paymentList.map((payment) => (
        <li className="landing-footer__payment-item" key={payment.id}>
          <img
            src={getIcon(payment.name)}
            width="93"
            height="48"
            alt={payment.alt}
            loading="lazy"
          />
        </li>
      ))}
    </ul>
  );
};
