import { Clock, HelpCircle } from "react-feather";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Badge } from "reactstrap";
import { Tooltip } from "react-tooltip";
import { useOutletContext } from "react-router-dom";

// ** Services
import { apiService } from "@services/ApiService";

// ** Constants
import { MODEM_STATUS_COLORS } from "@constants";

const RenderStatus = ({ row, admin }) => {
  // ** Hooks
  const { isDemo } = useOutletContext();
  // ** Redux State
  const { data } = useSelector((state) => state.auth.userProfile);

  // ** Functions
  const handlerClick = (e, row) => {
    e.preventDefault();
    if (!isDemo) {
      apiService.modems.refreshModem({ modemId: row.id }).then((req) => {
        if (req.status === 200) {
          toast.success("Modem sync successful");
        } else toast.error("Modem sync failed");
      });
    }
  };

  return (
    <div
      className={`d-flex flex-column ${
        row?.paid || admin ? "" : "unselectable blur-box"
      }`}
    >
      <div>
        <span
          className={`bullet bullet-sm bullet-${
            row?.use ? "success" : "danger"
          } me-50`}
        ></span>
        {data?.roles && data.roles.includes("TESTER") ? (
          <Badge
            href="#"
            color={
              MODEM_STATUS_COLORS.hasOwnProperty(row?.status)
                ? MODEM_STATUS_COLORS[row?.status]
                : MODEM_STATUS_COLORS.NO_DATA
            }
            onClick={(e) => handlerClick(e, row)}
          >
            {row?.status}
            {row?.status === "DOWN" &&
            row?.warnings?.length &&
            row?.warnings.includes("STUCK_IN_DOWN") ? (
              <Clock
                className="ms-50"
                style={{
                  width: "10px",
                  height: "11px"
                }}
              />
            ) : null}
          </Badge>
        ) : (
          <Badge
            color={
              MODEM_STATUS_COLORS.hasOwnProperty(row?.status)
                ? MODEM_STATUS_COLORS[row?.status]
                : MODEM_STATUS_COLORS.NO_DATA
            }
          >
            {row?.status}
            {row?.status === "DOWN" &&
            row?.warnings?.length &&
            row?.warnings.includes("STUCK_IN_DOWN") ? (
              <Clock
                className="ms-50"
                style={{
                  width: "10px",
                  height: "11px"
                }}
              />
            ) : null}
          </Badge>
        )}
      </div>
      {row?.warnings?.length && row?.warnings.includes("PRIVACY_POLICY") ? (
        <Badge color="warning" className="mt-50">
          APROVE POLICI
        </Badge>
      ) : null}
    </div>
  );
};

export const staticStatus = (admin = false) => {
  const name = () => (
    <div className="d-flex align-items-center">
      <span className="me-50">Status</span>
      <HelpCircle
        data-tooltip-id="status-btn"
        size={20}
        className="outline-none"
      />
      <Tooltip
        id="status-btn"
        style={{ zIndex: 9999 }}
        positionStrategy="fixed"
        place="bottom"
      >
        <div className="d-flex flex-column py-50">
          <span className="me-auto mb-50">
            <Badge color={MODEM_STATUS_COLORS.ONLINE} className="me-50">
              ONLINE
            </Badge>
            {"- ready to share internet"}
          </span>
          <span className="me-auto mb-50">
            <Badge color={MODEM_STATUS_COLORS.OFFLINE} className="me-50">
              OFFLINE
            </Badge>
            {"- modem is on, everything works except internet connection"}
          </span>
          <span className="me-auto mb-50">
            <Badge color={MODEM_STATUS_COLORS.DOWN} className="me-50">
              DOWN
            </Badge>
            {"- modem is disabled or not working properly"}
          </span>
          <span className="me-auto mb-50">
            <Badge color={MODEM_STATUS_COLORS.DOWN} className="me-50">
              DOWN
              <Clock
                className="ms-50"
                style={{
                  width: "10px",
                  height: "11px"
                }}
              />
            </Badge>
            {"- modem is disabled or not working properly more than 5 minutes"}
          </span>
          <div className=" w-100 border border-secondary mb-50" />
          <span className="me-auto mb-50">
            <span className="bullet bullet-sm bullet-success me-50"></span>
            {"- internet sharing allowed"}
          </span>
          <span className="me-auto">
            <span className="bullet bullet-sm bullet-danger me-50"></span>
            {"- internet sharing is prohibited"}
          </span>
          <div className=" w-100 border border-secondary mb-50" />
          <span className="me-auto mb-50">
            <Badge color={"warning"} className="me-50">
              APROVE POLICI
            </Badge>
            {"- modem need approve policy for internet sharing"}
          </span>
        </div>
      </Tooltip>
    </div>
  );

  return {
    id: "status",
    name: name(),
    // selector: "status",
    minWidth: "130px",
    key: "status",
    cell: (row) => <RenderStatus row={row} admin={admin} />
  };
};
