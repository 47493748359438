import { Navigate } from "react-router-dom";

export const AdminOrdersRoutes = [
  {
    path: "orders/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminOrders } = await import("@views/app/admin/orders/list");
          return {
            Component: AdminOrders
          };
        }
      },
      {
        path: ":orderId/edit/:tab?",
        async lazy() {
          const { AdminOrderEdit } = await import(
            "@views/app/admin/orders/edit"
          );
          return {
            Component: AdminOrderEdit
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
