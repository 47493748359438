import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// ** Custom Hooks & Utils
import { useTranslation } from "@hooks";
import { getIcon } from "@helpers";
// ** Styles
import "./logo.scss";

export const Logo = () => {
  // ** Hooks
  const { lang } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { header } = useTranslation();

  // ** Functions
  const handleClick = (e) => {
    e.preventDefault();
    if (location.pathname !== `/${lang}/`) {
     navigate(`/${lang}/`);
    }
  };
  return (
    <Link to={`/${lang}/`} className="landing-logo" aria-label={header?.ariaLabelMain} onClick={handleClick} >
      <img
        src={getIcon("logo")}
        alt={header?.altImgLogo}
        width="214"
        height="42"
      />
    </Link>
  );
};
