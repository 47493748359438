import { useEffect } from "react";

export const useScrollReload = () => {
  // ** Functions
  const saveScrollPosition = () => {
    sessionStorage.setItem("location", window.location.href); // save current url
    sessionStorage.setItem("scrollX", window.scrollX);
    sessionStorage.setItem("scrollY", window.scrollY);
  };

  // ** Effects
  useEffect(() => {
    const location = sessionStorage.getItem("location"); // get current url
    if (location) {
      if (location === window.location.href) {
        // check if current url === saved url
        const scrollX = sessionStorage.getItem("scrollX")
          ? JSON.parse(sessionStorage.getItem("scrollX"))
          : 0;
        const scrollY = sessionStorage.getItem("scrollY")
          ? JSON.parse(sessionStorage.getItem("scrollY"))
          : 0;
        setTimeout(() => {
          window.scrollTo(scrollX, scrollY);
        }, 0); // scroll to last position
      }
      sessionStorage.removeItem("location"); // remove saved url
      sessionStorage.removeItem("scrollX"); // remove saved scrollX
      sessionStorage.removeItem("scrollY"); // remove saved scrollY
    }

    window.addEventListener("beforeunload", saveScrollPosition);
    return () => {
      window.removeEventListener("beforeunload", saveScrollPosition);
    };
  }, []);
};
