import { Container } from "@components/landing/ui";
import { useCurrentSeo } from "@hooks";
import { CRMList } from "./components/crmList";
import { CRMSlider } from "./components/slider/crmSlider";

import "./crm.scss";

export const Crm = () => {
  // *Hooks
  const { crm } = useCurrentSeo();
  return (
    <section className="landing-crm">
      <Container>
        <p className="section__title landing-crm__title">{crm?.title}</p>
        <CRMList className="d-none d-lg-grid" />
      </Container>
      <CRMSlider />
    </section>
  );
};
