import { Home, Square, Book, Grid } from "react-feather";

export const NAVBAR_MENU = [
  {
    id: "home",
    title: "Home",
    icon: <Home size={20} />,
    navLink: "/app/home/"
  },
  {
    id: "setup",
    title: "Setup",
    icon: <Square size={20} />,
    navLink: "/app/setup/"
  },
  {
    id: "knowledgeBase",
    title: "Knowledge Base",
    icon: <Book size={20} />,
    navLink: "/app/knowledge-base/"
  },
  {
    id: "admin",
    title: "Admin",
    icon: <Grid size={20} />,
    navLink: "/app/admin/",
    isForAdmin: true
  }
];

export const NAVBAR_MENU_DEMO = [
  {
    id: "home",
    title: "Home",
    icon: <Home size={20} />,
    navLink: "/demo/home/"
  },
  {
    id: "setup",
    title: "Setup",
    icon: <Square size={20} />,
    navLink: "/demo/setup/"
  },
  {
    id: "knowledgeBase",
    title: "Knowledge Base",
    icon: <Book size={20} />,
    navLink: "/demo/knowledge-base/"
  }
];
