import { Navigate } from "react-router-dom";

export const AdminTariffsRoutes = [
  {
    path: "tariff/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminTariffs } = await import("@views/app/admin/Tariffs");
          return {
            Component: AdminTariffs
          };
        }
      },
      {
        path: "add/",
        async lazy() {
          const { AdminTariffAdd } = await import(
            "@views/app/admin/Tariffs/add"
          );
          return {
            Component: AdminTariffAdd
          };
        }
      },
      {
        path: ":tariffId/edit/",
        async lazy() {
          const { AdminTariffEdit } = await import(
            "@views/app/admin/Tariffs/edit"
          );
          return {
            Component: AdminTariffEdit
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
