/* eslint-disable import/named */
import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useBreakpoints, useTranslation } from "@hooks";
import { Container, Logo, Lang } from "@components/landing/ui";

import { Burger } from "./components/burger/burger";
import { Dropdown } from "./components/dropdown/dropdown";
import { Menu } from "./components/menu/menu";
import { Sign } from "./components/sign/sign";

import "./header.scss";

export const Header = () => {
  // **Hooks
  const { lang } = useParams();
  const { header } = useTranslation();
  const breakpoints = useBreakpoints();

  // **Redux State
  const { isOpen } = useSelector((state) => state.landing.mobileMenu);

  // **Local State
  const [scroll, setScroll] = useState(0);
  const [scrollCheck, setScrollCheck] = useState(0);
  const [headerClasses, setHeaderClasses] = useState("landing-header");

  // **Memo
  const render = (classes) => (
    <header className={classes}>
      <Container>
        <div className="landing-header__wrapper">
          <Logo />
          <Menu />
          <Link
            className="landing-header__btn"
            to="/demo/home/"
            target={"_blank"}
          >
            {header?.demo}
          </Link>
          <Sign />
          <Lang />
          <Burger />
        </div>
        <Dropdown>
          <Menu />
          <Link
            className="landing-header__btn"
            to="/demo/home/"
            target={"_blank"}
          >
            {header?.demo}
          </Link>
          <Sign />
          <Lang />
        </Dropdown>
      </Container>
    </header>
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const headerMemo = useMemo(
    () => render(headerClasses),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [headerClasses, lang]
  );

  // **Functions
  const checkClasses = (classes) => classes.includes("hidden");

  const handleScroll = () => {
    const currentScroll = window.scrollY || document.documentElement.scrollTop;
    setScroll(currentScroll);
    if (window.location.hash) {
      window.history.replaceState(null, null, " ");
    }
  };

  const checkScroll = (scroll) => {
    if (!breakpoints.includes("xl")) {
      if (
        (scrollCheck > scroll || scroll <= 100) &&
        checkClasses(headerClasses)
      ) {
        setHeaderClasses("landing-header");
        setScrollCheck(scroll);
        return;
      }
      if (
        scrollCheck < scroll &&
        !checkClasses(headerClasses) &&
        !isOpen &&
        scrollCheck > 100
      ) {
        setHeaderClasses("landing-header landing-header-hidden");
        setScrollCheck(scroll);
        return;
      }
    }
    if (breakpoints.includes("xl") && checkClasses(headerClasses)) {
      setHeaderClasses("landing-header");
    }
    setScrollCheck(scroll);
  };

  // **Use Effects
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    checkScroll(scroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroll, breakpoints.length]);
  return headerMemo;
};
