import { createSlice } from "@reduxjs/toolkit";
import {
  fetchUserLogin,
  fetchUserProfile,
  fetchUserDomains,
  fetchUserAuth,
  updateProfileInterface
} from "./Auth.thunks";

const initialUserState = () => ({
  auth: { isAuth: false, isLoaded: false, fetchError: null },
  userProfile: {
    data: {},
    isLoaded: false,
    fetchError: null,
    tourSkip: false
  },
  userDomains: { data: [], isLoaded: false, fetchError: null },
  redirect: ""
});

const AuthReducer = createSlice({
  name: "Auth",
  initialState: initialUserState(),
  reducers: {
    setUserTourStatus(state, action) {
      state.userProfile.data.tourCompleted = action.payload;
    },
    setUserProfile(state, action) {
      state.userProfile.data = action.payload;
    },
    setTourSkip(state, action) {
      state.userProfile.tourSkip = action.payload;
    },
    clearUserProfileError(state) {
      state.userProfile.fetchError = null;
    },
    clearAuthData(state) {
      state.userProfile.data = {};
      state.userProfile.isLoaded = false;
      state.auth.isAuth = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserAuth.pending, (state) => {
      state.auth.fetchError = null;
      state.auth.isLoaded = false;
    });
    builder.addCase(fetchUserAuth.fulfilled, (state, action) => {
      state.userProfile.data = action.payload.data;
      state.auth.isAuth = action.payload.auth;
      state.auth.isLoaded = true;
      state.userProfile.isLoaded = !!action.payload.data?.id;
    });
    builder.addCase(fetchUserAuth.rejected, (state, action) => {
      state.auth.fetchError = action.payload;
      state.auth.isLoaded = true;
    });
    builder.addCase(fetchUserLogin.pending, (state) => {
      state.userProfile.fetchError = null;
      state.userProfile.isLoaded = false;
    });
    builder.addCase(fetchUserLogin.fulfilled, (state, action) => {
      state.userProfile.data = action.payload;
      state.userProfile.isLoaded = true;
      state.auth.isAuth = true;
    });
    builder.addCase(fetchUserLogin.rejected, (state, action) => {
      state.userProfile.fetchError = action.payload;
      state.userProfile.isLoaded = true;
    });
    builder.addCase(fetchUserProfile.pending, (state) => {
      state.userProfile.fetchError = null;
      state.userProfile.isLoaded = false;
    });
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.userProfile.data = action.payload;
      state.userProfile.isLoaded = true;
    });
    builder.addCase(fetchUserProfile.rejected, (state, action) => {
      state.userProfile.fetchError = action.payload;
      state.userProfile.isLoaded = true;
    });
    builder.addCase(fetchUserDomains.pending, (state) => {
      state.userDomains.fetchError = null;
      state.userDomains.isLoaded = false;
    });
    builder.addCase(fetchUserDomains.fulfilled, (state, action) => {
      state.userDomains.data = action.payload;
      state.userDomains.isLoaded = true;
    });
    builder.addCase(fetchUserDomains.rejected, (state, action) => {
      state.userDomains.fetchError = action.payload;
      state.userDomains.isLoaded = true;
    });
    // builder.addCase(updateProfileInterface.pending, (state) => {});
    builder.addCase(updateProfileInterface.fulfilled, (state, action) => {
      state.userProfile.data = { ...state.userProfile.data, ...action.payload };
    });
    // builder.addCase(updateProfileInterface.rejected, (state, action) => {});
  }
});

export const AuthReducerActions = {
  ...AuthReducer.actions,
  fetchUserLogin,
  fetchUserProfile,
  fetchUserDomains,
  fetchUserAuth,
  updateProfileInterface
};

export default AuthReducer.reducer;
