import { Plus, X } from "react-feather";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Button, FormGroup, Input, InputGroup } from "reactstrap";

export const FormList = ({ name }) => {
  // ** Hooks
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });

  const handlerAddNew = () => append("");

  return (
    <FormGroup noMargin className="mb-1">
      <ul>
        {fields.map((item, index) => (
          <li key={item.id}>
            <InputGroup className="d-flex align-items-center mb-1">
              <Controller
                name={`${name}.${index}`}
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <Input {...field} type="text" invalid={!!error?.type} />
                )}
              />

              <Button
                color="danger"
                style={{ zIndex: 0 }}
                onClick={() => remove(index)}
                outline
              >
                <X size={14} className="me-50" />
                <span>Delete</span>
              </Button>
            </InputGroup>
          </li>
        ))}
      </ul>
      <Button
        className="btn-icon"
        color="primary"
        block
        outline
        onClick={handlerAddNew}
      >
        <Plus size={14} />
        <span className="align-middle ms-25">Add New List Item</span>
      </Button>
    </FormGroup>
  );
};
