import { Link, useLocation } from "react-router-dom";
import { Collapse, Badge } from "reactstrap";
// ** Custom Hooks & Utils
import { isNavGroupActive, getAllParents } from "../../utils";
// ** Components
import { VerticalNavMenuItems } from "./VerticalNavMenuItems";

export const VerticalNavMenuGroup = ({
  item,
  groupActive,
  setGroupActive,
  groupOpen,
  setGroupOpen,
  parentItem
}) => {
  // ** Hooks
  const { pathname } = useLocation();

  // ** Functions
  // ** Toggles Open Group
  const toggleOpenGroup = (item, parentItem) => {
    let openArr = groupOpen;
    let allParents;

    if (parentItem) {
      allParents = getAllParents(parentItem, "id");
      allParents.pop();
    }

    // ** If user clicked on menu group inside already opened group i.g. when user click on blog group inside pages group
    if (groupOpen && allParents && groupOpen[0] === allParents[0]) {
      if (groupOpen.includes(item)) {
        openArr.splice(openArr.indexOf(item), 1);
      } else {
        openArr.push(item);
      }
    } else {
      openArr = [];
      if (!groupOpen.includes(item)) {
        openArr.push(item);
      }
    }

    // ** Set Open Group
    setGroupOpen([...openArr]);
  };

  // ** Toggle Active Group
  const toggleActiveGroup = (item, parentItem) => {
    let activeArr = groupActive;
    let allParents;

    if (parentItem) {
      allParents = getAllParents(parentItem, "id");
      activeArr = allParents;
    } else if (activeArr.includes(item)) {
      activeArr.splice(activeArr.indexOf(item), 1);
    } else {
      activeArr.push(item);
    }

    // ** Set open group removing any activegroup item present in opengroup state
    const openArr = groupOpen.filter((val) => !activeArr.includes(val));
    setGroupOpen([...openArr]);

    // **  Set Active Group
    setGroupActive([...activeArr]);
  };

  // ** On Group Item Click
  const onCollapseClick = (e) => {
    if (
      (groupActive && groupActive.includes(item.id)) ||
      isNavGroupActive(item.children, pathname)
    ) {
      toggleActiveGroup(item.id);
    } else {
      toggleOpenGroup(item.id, parentItem);
    }

    e.preventDefault();
  };

  return (
    <li
      className={`nav-item has-sub ${
        groupActive.includes(item.id) ? " open" : ""
      }
      ${groupOpen.includes(item.id) ? " sidebar-group-active" : ""}`}
    >
      <Link
        className="d-flex align-items-center"
        to="/app/auth/login/"
        onClick={onCollapseClick}
      >
        {item.icon}
        <span className="menu-title text-truncate">{item.title}</span>

        {item.badge && item.badgeText ? (
          <Badge className="ms-auto me-1" color={item.badge} pill>
            {item.badgeText}
          </Badge>
        ) : null}
      </Link>

      {/* Render Child Recursively Through VerticalNavMenuItems Component */}
      <ul className="menu-content">
        <Collapse
          isOpen={
            (groupActive && groupActive.includes(item.id)) ||
            (groupOpen && groupOpen.includes(item.id))
          }
        >
          <VerticalNavMenuItems
            items={item.children}
            groupActive={groupActive}
            setGroupActive={setGroupActive}
            groupOpen={groupOpen}
            setGroupOpen={setGroupOpen}
            toggleActiveGroup={toggleActiveGroup}
            parentItem={item}
          />
        </Collapse>
      </ul>
    </li>
  );
};
