import { Navigate } from "react-router-dom";

export const AdminModemsRoutes = [
  {
    path: "modem/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminModems } = await import("@views/app/admin/modem");
          return {
            Component: AdminModems
          };
        }
      },
      {
        path: "history/*",
        children: [
          {
            index: true,
            async lazy() {
              const { AdminModemsHistory } = await import(
                "@views/app/admin/modem/history"
              );
              return {
                Component: AdminModemsHistory
              };
            }
          },
          {
            path: "archive/",
            async lazy() {
              const { AdminModemsHistory } = await import(
                "@views/app/admin/modem/history"
              );
              return {
                Component: AdminModemsHistory
              };
            }
          },
          {
            path: "settings/",
            async lazy() {
              const { AdminModemsHistorySettings } = await import(
                "@views/app/admin/modem/history/vars"
              );
              return {
                Component: AdminModemsHistorySettings
              };
            }
          },
          {
            path: "*",
            element: <Navigate to="./" replace={true} />
          }
        ]
      },
      {
        path: ":id/edit/",
        async lazy() {
          const { AdminModemEdit } = await import(
            "@views/app/admin/modem/edit"
          );
          return {
            Component: AdminModemEdit
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
