export const MODEM_HISTORY_EVENT_TYPES = {
  DELETE_MODEM_AUTH: "DELETE_MODEM_AUTH",
  ADD_MODEM_AUTH: "ADD_MODEM_AUTH",
  CHANGE_MODEM_AUTH: "CHANGE_MODEM_AUTH",
  CHANGE_MODEM_LIMIT: "CHANGE_MODEM_LIMIT",
  CHANGE_MODEM_AUTH_LIMIT: "CHANGE_MODEM_AUTH_LIMIT",
  CHANGE_MODEM_IP: "CHANGE_MODEM_IP",
  CHANGE_MODEM_SIM_NUMBER: "CHANGE_MODEM_SIM_NUMBER",
  CHANGE_MODEM_SIM_NUMBER_CUSTOM: "CHANGE_MODEM_SIM_NUMBER_CUSTOM",
  CHANGE_MODEM_STATUS: "CHANGE_MODEM_STATUS",
  CHANGE_MODEM_USE: "CHANGE_MODEM_USE",
  CHANGE_MODEM_ROTATION: "CHANGE_MODEM_ROTATION",
  DELETE_MODEM_ROTATION: "DELETE_MODEM_ROTATION",
  CHANGE_MODEM_RATE: "CHANGE_MODEM_RATE",
  REBOOT_MODEM: "REBOOT_MODEM",
  DELETE_MODEM: "DELETE_MODEM",
  PAUSE_MODEM: "PAUSE_MODEM",
  UNPAUSE_MODEM: "UNPAUSE_MODEM"
};

export const MODEM_HISTORY_EVENT_TYPES_LIST = Object.values(
  MODEM_HISTORY_EVENT_TYPES
).map((value) => ({ value, label: value }));
