import { BlankLayout } from "../layouts";

export const PaymentsRoutes = [
  {
    path: "/app/payments/",
    loader: () => ({ isDemo: false }),
    element: <BlankLayout />,
    children: [
      {
        path: "failure/",
        async lazy() {
          const { PaymentFailure } = await import(
            "@views/app/payment/PaymentFailure"
          );
          return {
            Component: PaymentFailure
          };
        }
      },
      {
        path: "success/",
        async lazy() {
          const { PaymentSuccess } = await import(
            "@views/app/payment/PaymentSuccess"
          );
          return {
            Component: PaymentSuccess
          };
        }
      },
      {
        path: "webmoney/",
        async lazy() {
          const { WebMoneyMain } = await import(
            "@views/app/payment/WebMoneyMain"
          );
          return {
            Component: WebMoneyMain
          };
        }
      }
    ]
  },
  {
    path: "/demo/payments/",
    loader: () => ({ isDemo: true }),
    element: <BlankLayout />,
    children: [
      {
        path: "success/",
        async lazy() {
          const { PaymentSuccess } = await import(
            "@views/app/payment/PaymentSuccess"
          );
          return {
            Component: PaymentSuccess
          };
        }
      }
    ]
  }
];
