import { Row, Button, Card, Form } from "reactstrap";
import { Filter } from "react-feather";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
// ** Components
import { AppCollapse } from "@components/app";

export const FilterFormContainer = ({ removeValues, children }) => {
  // ** Hooks
  const methods = useForm();
  const [searchParams, setSearchParams] = useSearchParams();

  // ** Functions
  const handleSearch = (data) => {
    const row = searchParams.get("row");
    const page = searchParams.get("page");
    const search = new URLSearchParams();
    for (const [key, value] of Object.entries(data)) {
      if (value) {
        search.append(key, value);
      }
    }
    if (row) search.append("row", row);
    if (page) search.append("page", page);
    setSearchParams?.(search);
  };

  const handleReset = (data) => {
    const nullData = {};
    for (const [key] of Object.entries(data)) {
      nullData[key] = null;
    }
    methods.reset(nullData);
    const search = new URLSearchParams();
    const row = searchParams.get("row");
    const page = searchParams.get("page");
    if (row) search.append("row", row);
    if (page) search.append("page", page);
    setSearchParams?.(search);
  };

  // ** Constants
  const data = [
    {
      title: "Filters",
      content: (
        <FormProvider {...methods}>
          <Form
            onSubmit={methods.handleSubmit(handleSearch)}
            onReset={methods.handleSubmit(handleReset)}
          >
            <Row className="mb-1">{children}</Row>
            <div className="d-flex justify-content-end">
              <Button
                className="btn-icon me-1"
                outline
                color="primary"
                type="submit"
              >
                <Filter size={18} className="me-50" />
                Filter
              </Button>
              <Button color="danger" outline type="reset">
                Clear all
              </Button>
            </div>
          </Form>
        </FormProvider>
      )
    }
  ];
  // ** Effects
  useEffect(() => {
    if (searchParams.size > 0) {
      const params = {};
      for (const [key, value] of searchParams.entries()) {
        if (key !== "page" && key !== "row") {
          if (value.split(",").length > 1) {
            params[key] = value.split(",");
          } else {
            params[key] = value;
          }
        }
      }
      methods.reset(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  // ** Effects
  useEffect(() => {
    if (removeValues && removeValues?.length > 0) {
      removeValues.forEach((item) => {
        methods.setValue(item, null);
        searchParams.delete(item);
        setSearchParams(searchParams);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeValues]);

  return (
    <Card>
      <AppCollapse data={data} toggle={false} />
    </Card>
  );
};
