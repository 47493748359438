export const DEMO_TARIFFS = [
    {
      "id": "demotariff1",
      "createdDate": "2021-12-02T15:02:16.357+00:00",
      "lastModifiedDate": "2022-12-02T15:17:33.384+00:00",
      "version": 96,
      "name": "basic",
      "publicName": "Basic",
      "price": 20,
      "currency": "USD",
      "authorization": false,
      "trafficLimit": true,
      "downloadLimit": true,
      "restrictionIds": [
        "61f02519a449be7ce4c40e96",
        "638a1642b7ec8e02d903f93c"
      ]
    },
    {
      "id": "demotariff2",
      "createdDate": "2021-12-02T15:02:16.392+00:00",
      "lastModifiedDate": "2022-07-19T14:52:51.248+00:00",
      "version": 10,
      "name": "advanced",
      "publicName": "Advanced",
      "price": 50,
      "currency": "USD",
      "authorization": true,
      "trafficLimit": true,
      "downloadLimit": true,
      "restrictionIds": []
    }
  ];
