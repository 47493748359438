
export const isNavLinkActive = (link, currentURL) => currentURL === link;
/**
 * Check if nav group is
 * @param {Array} children Group children
 */

export const isNavGroupActive = (children, currentURL) => children.some((child) => {
    // If child have children => It's group => Go deeper(recursive)
    if (child.children) {
      return isNavGroupActive(child.children, currentURL);
    }
    // else it's link => Check for matched Route
    return isNavLinkActive(child.navLink, currentURL);
  });

/**
 * Search for parent object
 * @param {Array} navigation Group children
 * @param {string} currentURL current URL
 */

export const search = (navigation, currentURL) => {
  let result;
  navigation.some((child) => {
    let children;
    // If child have children => It's group => Go deeper(recursive)
    if (
      child.children &&
      (children = search(child.children, currentURL))
    ) {
      return (result = {
        id: child.id,
        children
      });
    }

    // else it's link => Check for matched Route
    if (isNavLinkActive(child.navLink, currentURL)) {
      return (result = {
        id: child.id
      });
    }
  });
  return result;
};

/**
 * Loop through nested object
 * @param {object} obj nested object
 */
export const getAllParents = (obj, match) => {
  const res = [];
  const recurse = (obj) => {
    for (const key in obj) {
      const value = obj[key];
      if (value) {
        if (value && typeof value === "object") {
          recurse(value, key);
        } else if (key === match) {
            res.push(value);
          }
      }
    }
  };
  recurse(obj);
  return res;
};
