import { Navigate } from "react-router-dom";

export const AdminSetupRoutes = [
  {
    path: "setup/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminSetup } = await import("@views/app/admin/setup");
          return {
            Component: AdminSetup
          };
        }
      },
      {
        path: "add/",
        async lazy() {
          const { AdminSetupAddEdit } = await import(
            "@views/app/admin/setup/addEdit"
          );
          return {
            Component: AdminSetupAddEdit
          };
        }
      },
      {
        path: ":setupId/edit/",
        async lazy() {
          const { AdminSetupAddEdit } = await import(
            "@views/app/admin/setup/addEdit"
          );
          return {
            Component: AdminSetupAddEdit
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
