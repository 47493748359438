import { Navigate } from "react-router-dom";

export const AdminNotificationTemplatesRoutes = [
  {
    path: "notification-templates/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminNotificationTemplates } = await import(
            "@views/app/admin/notificationTemplates/"
          );
          return {
            Component: AdminNotificationTemplates
          };
        }
      },
      {
        path: "add/",
        async lazy() {
          const { AdminNotificationTemplateAdd } = await import(
            "@views/app/admin/notificationTemplates/add"
          );
          return {
            Component: AdminNotificationTemplateAdd
          };
        }
      },
      {
        path: ":id/edit/",
        async lazy() {
          const { AdminNotificationTemplateEdit } = await import(
            "@views/app/admin/notificationTemplates/edit"
          );
          return {
            Component: AdminNotificationTemplateEdit
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
