import { useEffect, useState } from "react";
import { ReactCountryFlag } from "react-country-flag";
import { useSelector } from "react-redux";
import { useLoaderData } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";

// ** Custom Hooks & Utils
import { useDispatchedActions } from "@hooks";
export const LangDropdown = () => {
  // ** Dispatched Actions
  const { isDemo } = useLoaderData();
  const { setLang, updateProfileInterface } = useDispatchedActions();
  // **Redux state
  const { langs } = useSelector((state) => state.translation);
  const settings = useSelector((state) => state.settings);
  // ** State
  const [localLang, setLocalLang] = useState(settings.lang);
  const [currentLang, setCurrentLang] = useState("");

  // ** Function to switch Language
  const handleLangUpdate = (e, lang) => {
    e.preventDefault();
    const tempLang = langs.data.find((item) => item.code === lang);
    if (settings.lang === lang || !tempLang) return;

    setCurrentLang(tempLang);
    setLang(lang);
  };

  useEffect(() => {
    setCurrentLang(langs.data.find((item) => item?.code === settings.lang));
    let promise;
    if (settings.lang !== localLang) {
      const params = { locale: settings.lang };
      promise = updateProfileInterface({ params, isDemo });
      setLocalLang(settings.lang);
    }
    return () => promise?.abort?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.lang]);

  return (
    <UncontrolledDropdown
      // href="/"
      tag="li"
      className="dropdown-language nav-item"
    >
      <DropdownToggle
        // href="/"
        tag="a"
        className="nav-link d-flex align-items-center"
        onClick={(e) => e.preventDefault()}
      >
        <ReactCountryFlag
          className="country-flag flag-icon m-0"
          countryCode={currentLang?.code === "en" ? "us" : currentLang?.code}
          svg
        />
        <span className="selected-language ms-25">
          {currentLang?.internationalName}
        </span>
      </DropdownToggle>
      <DropdownMenu className="mt-0" end>
        {langs?.data.map((item) => (
          <DropdownItem
            className="d-flex align-items-center"
            href="/"
            tag="a"
            key={item.code}
            onClick={(e) => handleLangUpdate(e, item.code)}
          >
            <ReactCountryFlag
              className="country-flag"
              countryCode={item.code === "en" ? "us" : item.code}
              svg
            />
            <span className="ms-1">{item.internationalName}</span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
