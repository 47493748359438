import { createSlice } from "@reduxjs/toolkit";
import {
  fetchModemUsageInfo,
  fetchNotifications,
  fetchReportsForAdmin
} from "./NavBar.thunks";

const initialUserState = () => ({
  layout: { menuVisibility: false },
  modemUsageInfo: {
    data: {
      onlineModemsCount: 0,
      offlineModemsCount: 0
    },
    isLoaded: false,
    fetchError: null
  },
  notifications: {
    data: [],
    count: 0,
    isLoaded: false,
    fetchError: null
  },
  reportsForAdmin: {
    count: 0,
    isLoaded: false,
    fetchError: null
  }
});

const NavBarReducer = createSlice({
  name: "NavBar",
  initialState: initialUserState(),
  reducers: {
    setMenuVisibility(state, action) {
      state.layout.menuVisibility = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchModemUsageInfo.pending, (state) => {
      state.modemUsageInfo.fetchError = null;
      state.modemUsageInfo.isLoaded = false;
    });
    builder.addCase(fetchModemUsageInfo.fulfilled, (state, action) => {
      state.modemUsageInfo.data = action.payload;
      state.modemUsageInfo.isLoaded = true;
    });
    builder.addCase(fetchModemUsageInfo.rejected, (state, action) => {
      state.modemUsageInfo.fetchError = action.payload;
      state.modemUsageInfo.isLoaded = true;
    });
    builder.addCase(fetchNotifications.pending, (state) => {
      state.notifications.fetchError = null;
      state.notifications.isLoaded = false;
    });
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      state.notifications.data = action.payload.data;
      state.notifications.count = action.payload.count;
      state.notifications.isLoaded = true;
    });
    builder.addCase(fetchNotifications.rejected, (state, action) => {
      state.notifications.fetchError = action.payload;
      state.notifications.isLoaded = true;
    });
    builder.addCase(fetchReportsForAdmin.pending, (state) => {
      state.reportsForAdmin.fetchError = null;
      state.reportsForAdmin.isLoaded = false;
    });
    builder.addCase(fetchReportsForAdmin.fulfilled, (state, action) => {
      state.reportsForAdmin.count = action.payload;
      state.reportsForAdmin.isLoaded = true;
    });
    builder.addCase(fetchReportsForAdmin.rejected, (state, action) => {
      state.reportsForAdmin.fetchError = action.payload;
      state.reportsForAdmin.isLoaded = true;
    });
  }
});

export const NavBarReducerActions = {
  ...NavBarReducer.actions,
  fetchModemUsageInfo,
  fetchNotifications,
  fetchReportsForAdmin
};

export default NavBarReducer.reducer;
