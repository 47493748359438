import { useCurrentSeo } from "@hooks";

export const CRMList = ({ className }) => {
  const currentSeo = useCurrentSeo();
  return (
    <ul className={`landing-crm__list ${className || ""}`}>
      {currentSeo?.crm?.items &&
        currentSeo?.crm?.items.map((item, index) => (
          <li className="landing-crm__item" key={index}>
            <p className="landing-crm__item-title">{item.title}</p>
            <p className="landing-crm__item-text">{item.text}</p>
          </li>
        ))}
    </ul>
  );
};
