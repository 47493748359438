import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Spinner,
  Form
} from "reactstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";

// ** Services
import { apiService } from "@services/ApiService";
// ** Custom Hooks & Utils
import { useDispatchedActions, useLockBackground } from "@hooks";
// ** Components
import { FormInput } from "@components/app";
import { ModalAlert } from "./ModalAlert";

export const ModalSendMessage = () => {
  // ** Hooks
  const { isDemo } = useOutletContext();
  const methods = useForm({ defaultValues: { to: "", text: "" } });
  const { setModalReadSmsOpen, setModalSendSmsOpen, setUpdateModems } =
    useDispatchedActions();
  // ** Redux state
  const { selectedModemId, modalSendSmsOpen } = useSelector(
    (state) => state.modals
  );
  const { activeHostId } = useSelector((state) => state.content.hostModems);
  const { content } = useSelector((state) => state.demo);
  // ** State
  const [sendMessageButtonDisabled, setSendMessageButtonDisabled] =
    useState(false);
  const [modalText, setModalText] = useState("");

  // ** Functions
  const toggle = () => setModalSendSmsOpen(false);

  const handleViewSms = () => setModalReadSmsOpen(selectedModemId);

  const handlerOnSmsSend = (data) => {
    setSendMessageButtonDisabled(true);

    if (!isDemo) {
      const params = {
        modemId: selectedModemId,
        to: data?.to ? data.to : null,
        text: data.text
      };
      apiService.modems
        .sendSms(params)
        .then((res) => {
          if (res.data && !data?.to) {
            if (!res.data.response.inf.success) {
              setModalText("ERROR");
            } else if (res.data.response.inf.ussdret) {
              setModalText(res.data.response.inf.ussdret);
            }
            setSendMessageButtonDisabled(false);
          } else if (res.data && data?.to) {
            setModalText(
              res.data.response.inf.ok
                ? "Message was sent successfully"
                : "ERROR"
            );
            setSendMessageButtonDisabled(false);
          } else {
            toast.error("Error, no data!");
            setSendMessageButtonDisabled(false);
          }
        })
        .catch(() => {
          setSendMessageButtonDisabled(false);
          toast.error("Message/USSD code NOT sent!");
        });
    } else {
      // demo logik start
      const check = [];
      const activeHostModems = content.modems[activeHostId];
      const newModems = {
        ...content.modems,
        [activeHostId]: activeHostModems.map((item) => {
          if (selectedModemId === item.id) {
            if (item.status === "ONLINE") {
              const tempSms = item.sms.outbox;
              return {
                ...item,
                sms: {
                  ...item.sms,
                  outbox: [
                    {
                      index: tempSms.length + 1,
                      phone: data.to,
                      date: new Date().getTime(),
                      sms: data.text
                    },
                    ...tempSms
                  ]
                }
              };
            } else {
              check.push(item.id);
              return item;
            }
          } else return item;
        })
      };
      if (check.length > 0) {
        setModalText("ERROR");
      } else {
        setUpdateModems(newModems);
        setModalText("Message was sent successfully");
      }

      setSendMessageButtonDisabled(false);
      // demo logik end
    }
  };

  // ** Effects
  useLockBackground(!!modalSendSmsOpen);

  return (
    <div className="basic-modal">
      <Modal
        isOpen={modalSendSmsOpen}
        toggle={toggle}
        className={"modal-dialog-centered"}
      >
        <ModalHeader toggle={toggle}>Send message or USSD code</ModalHeader>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(handlerOnSmsSend)}>
            <ModalBody>
              <FormGroup noMargin className="mb-1">
                <Label for="recipientNumber">Recipient number:</Label>
                <FormInput
                  name="to"
                  type="tel"
                  placeholder="Recipient number"
                />
              </FormGroup>
              <FormGroup noMargin className="mb-1">
                <Label>Message/USSD Code:</Label>
                <FormInput
                  name="text"
                  type="textarea"
                  rows="5"
                  required
                  errorText="Fill up the text field"
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Close
              </Button>
              <Button color="success" onClick={handleViewSms}>
                View Sms
              </Button>
              <Button
                disabled={sendMessageButtonDisabled}
                color="primary"
                type="submit"
              >
                {sendMessageButtonDisabled ? (
                  <div>
                    <Spinner color="light" size="sm" />
                    <span className="ms-50">Sending message...</span>
                  </div>
                ) : (
                  "Send message"
                )}
              </Button>
            </ModalFooter>
          </Form>
        </FormProvider>

        <ModalAlert modalText={modalText} closeModal={() => setModalText("")} />
      </Modal>
    </div>
  );
};
