import { useEffect, useState } from "react";
import { Plus } from "react-feather";
import {
  Button,
  ButtonGroup,
  Form,
  Col,
  Row,
  Alert,
  FormGroup
} from "reactstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FormProvider, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";

// ** Services
import { apiService } from "@services/ApiService";
// ** Custom Hooks & Utils
import { useDispatchedActions } from "@hooks";
import { demoGenId } from "@helpers";
// ** Components
import { FormInput } from "@components/app";

// ** Constants
const AUTH_TYPES = { LOGIN: "LOGIN", IP: "IP" };

export const AddNewUser = (props) => {
  // ** Props
  const { reloadUsers, handlerCloseModal } = props;
  // ** Hooks
  const { isDemo } = useOutletContext();
  const methods = useForm({
    defaultValues: { login: "", password: "", ip: "" }
  });
  // ** Dispatched Actions
  const { setUpdateModems } = useDispatchedActions();
  // ** Redux state
  const { selectedModemsInfo } = useSelector((state) => state.modals);
  const { activeHostId } = useSelector((state) => state.content.hostModems);
  const { content } = useSelector((state) => state.demo);
  // ** State
  const [authType, setAuthType] = useState(AUTH_TYPES.LOGIN);
  const [awaitAdd, setAwaitAdd] = useState(false);
  //   const [allowedModems, setAllowedModems] = useState([]);
  const [forbiddenModems, setForbiddenModems] = useState([]);

  // ** Functions
  const hendlerSetAuthType = (event) => {
    const { name } = event.currentTarget;
    setAuthType(name);
  };

  const addNewUser = (data) => {
    setAwaitAdd(true);
    const ids = {};
    selectedModemsInfo.forEach((modem) => {
      ids[modem.id] = true;
    });

    if (!isDemo) {
      const params = {
        authOwner: "MODEM",
        ownerId: ids,
        authType,
        ip: authType === AUTH_TYPES.IP ? data.ip : "",
        login: authType === AUTH_TYPES.LOGIN ? data.login : "",
        password: authType === AUTH_TYPES.LOGIN ? data.password : ""
      };
      apiService.modemAuth
        .addNewUser(params)
        .then((req) => {
          if (req) {
            if (req.status === 200) {
              toast.success("Successfully added!");
              if (Object.keys(params.ownerId).length > 1) {
                handlerCloseModal?.();
              } else {
                reloadUsers?.();
              }
            } else if (
              req.response.status === 400 &&
              req.response.data === "INVALID_SYMBOLS_IN_INPUT"
            ) {
              toast.error(
                "Login and password must contain only latin letters, numbers and any special characters"
              );
            } else {
              const modemNumbers = [];
              Object.values(JSON.parse(req.request.response)).forEach((pair) =>
                modemNumbers.push(pair.first)
              );
              const result = modemNumbers.join(", ");
              toast.error(`Wrong modems: ${result}`);
            }
          }
        })
        .finally(() => {
          setAwaitAdd(false);
        });
    } else {
      // demo logik start
      const activeHostModems = content.modems[activeHostId];
      const modemsIds = selectedModemsInfo.map((item) => item.id);
      const newModems = {
        ...content.modems,
        [activeHostId]: activeHostModems.map((item) => {
          if (modemsIds.includes(item.id)) {
            const tempModemAuth = {
              id: demoGenId(7),
              authOwner: "MODEM",
              modemId: item.id,
              authType,
              ip: authType === AUTH_TYPES.IP ? data.ip : "",
              login: authType === AUTH_TYPES.LOGIN ? data.login : "",
              password: authType === AUTH_TYPES.LOGIN ? data.password : "",
              isActive: true,
              speedLimit: 0,
              trafficLimit: 0
            };
            return {
              ...item,
              lastUpdate: new Date().getTime(),
              limits: {
                ...item.limits,
                modemAuths: [...item.limits.modemAuths, tempModemAuth]
              }
            };
          } else return item;
        })
      };
      setUpdateModems(newModems);
      setAwaitAdd(false);
      // demo logik end
    }
    methods.reset();
  };

  // ** Effects
  useEffect(() => {
    if (selectedModemsInfo?.length) {
      setForbiddenModems(
        selectedModemsInfo.filter((m) => !m.tariffInfo.authorization)
      );
    }
  }, [selectedModemsInfo]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(addNewUser)}>
        {authType === AUTH_TYPES.LOGIN && (
          <Row>
            <FormGroup noMargin tag={Col} sm={6} className="mb-1">
              <FormInput
                name="login"
                placeholder="Login"
                required
                onlyLatinLanguage
              />
            </FormGroup>
            <FormGroup noMargin tag={Col} sm={6} className="mb-1">
              <FormInput
                name="password"
                placeholder="Password"
                required
                onlyLatinLanguage
              />
            </FormGroup>
          </Row>
        )}
        {authType === AUTH_TYPES.IP && (
          <Row>
            <FormGroup noMargin tag={Col} md={12} className="mb-1">
              <FormInput
                name="ip"
                type="ip"
                errorIpText="Invalid IP"
                placeholder="IP"
                required
              />
            </FormGroup>
          </Row>
        )}
        <Row className="justify-content-between px-1">
          <Col className="col-6 p-0">
            <Button
              disabled={awaitAdd}
              color="primary"
              type="submit"
              className="d-flex align-items-center "
            >
              <Plus size={15} className="me-50 flex-none" />
              Add New
            </Button>
          </Col>
          <Col className="col-6 p-0 d-flex justify-content-end">
            <ButtonGroup color="primary" type="submit">
              <Button
                color="primary"
                name={AUTH_TYPES.LOGIN}
                onClick={hendlerSetAuthType}
                active={authType === AUTH_TYPES.LOGIN}
              >
                Login
              </Button>
              <Button
                color="primary"
                name={AUTH_TYPES.IP}
                onClick={hendlerSetAuthType}
                active={authType === AUTH_TYPES.IP}
              >
                IP
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        {forbiddenModems.length !== 0 && (
          <Alert color="warning" className="mt-1">
            <h4 className="d-flex alert-heading">
              <span className="me-25">{"Modems: "}</span>
              <div>
                {forbiddenModems.map((item, index) => (
                  <div key={index}>
                    {index > 0 && <span className="me-25">,</span>}
                    <span className="font-weight-bold">{item.number}</span>
                  </div>
                ))}
              </div>
              <span className="ms-25">{"may have only one users."}</span>
            </h4>
            <div className="alert-body">
              <span>{"If modems have user, User will be not add."}</span>
            </div>
          </Alert>
        )}
      </Form>
    </FormProvider>
  );
};
