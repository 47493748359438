import { DEMO_PAYMENT_HISTORY } from "./payment.history.demo";
import { DEMO_ORDERS } from "./orders.demo";

export const DEMO_PROFILE = {
  id: "123456789",
  name: "Demo",
  picture: null,
  email: "demo@demo.demo",
  roles: ["ADMIN"],
  billingAddress: [
    // {
    //     id: "6411eddd33444",
    //     details: {
    //         countryCodeAlpha2: "PL",
    //         city: "Opole",
    //         postcode: "45-061",
    //         address: "Katowicka 52",
    //         apartment: "21",
    //         state: "Opole Voivodeship",
    //         companyName: "",
    //         recipient: "PERSON",
    //         vatNumber: "",
    //         firstName: "Yang",
    //         lastName: "Nowak",
    //         phoneNumber: "+48790000000",
    //         phoneNumberPrefix: "+48"
    //     },
    //     isMain: true
    // }
],
  emailConfirmed: true,
  useAllModemAuth: false,
  modemAuthLogin: "demoLogin",
  modemAuthPass: "demoPassword",
  balance: 300,
  tourCompleted: true,
  deleteOldDuplicates: true,
  isSuperUser: false,
  isAdmin: false,
  notificationsSettings: {
    chatId: null,
    telegramNotifications: false,
    telegramSettings: {
      balanceMessage: false,
      paymentRemindMessage: false,
      pauseMessage: false,
      modemDisconnectMessage: false,
      trafficMessage: false
    },
    emailNotifications: true,
    emailSettings: {
      balanceMessage: false,
      paymentRemindMessage: false,
      pauseMessage: false,
      modemDisconnectMessage: false,
      trafficMessage: false
    }
  },
  paymentHistory: DEMO_PAYMENT_HISTORY,
  orders: DEMO_ORDERS
};
