import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "@services/ApiService";

export const fetchSiteSettings = createAsyncThunk(
  "Settings/fetchSiteSettings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiService.front.getSiteSettings();
      if (response && response?.status === 200 && response?.data) {
        return response?.data;
      }
      throw response;
    } catch (error) {
      return rejectWithValue(false);
    }
  }
);
