import { Modal } from "@components/landing/common/modal/modal";
import { useDebounceValue, useWindowWidth } from "@hooks";
import { useEffect, useRef } from "react";

export const ModalYouTubePlayer = ({ show = false, close }) => {
    // *Hooks
    const ref = useRef();
    const wSize = useWindowWidth();
    const debounceSize = useDebounceValue(wSize, 500);

    // *Function
    const handlerShow = () => close?.();

    // *Effect
    useEffect(() => {
        if (ref?.current) {
            const { width } = ref.current?.getBoundingClientRect();
            ref.current.height = width / 1.777;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceSize, ref?.current]);

    return <Modal visible={show} width={1200} cancelHandler={handlerShow}>
        <iframe
            ref={ref}
            style={{ objectFit: "contain" }}
            width="100%"
            height="640"
            src="https://www.youtube.com/embed/_d8hzlWYCcQ?autoplay=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay;   fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        />
    </Modal>;
};
