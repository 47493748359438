/* eslint-disable import/no-named-as-default */
import ReCAPTCHA from "react-google-recaptcha";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Checkbox } from "@components/landing/form/checkbox/checkbox";
import { Form } from "@components/landing/form";
import { Button, Container } from "@components/landing/ui";
import { apiService } from "@services/ApiService";
import { useRef, useState } from "react";
import { useTranslation, useCurrentSeo } from "@hooks";
import { useSelector } from "react-redux";
import { List } from "./components/list";
import "./support.scss";

export const Support = () => {
  // **Refs
  const recaptchaRef = useRef();

  // **Hooks
  const methods = useForm();
  const {
    mainPage: { ticketsMessage, placeholders, errors }
  } = useTranslation();
  const { support } = useCurrentSeo();

  // **Redux State
  const { site } = useSelector((state) => state.settings);

  // **State
  const [submitting, setSubmitting] = useState(false);

  // **Functions
  const sendTicket = (captchaResponse = "none") => {
    setSubmitting(true);
    const data = methods.getValues();
    const params = {
      name: data?.supportFormName,
      email: data?.supportFormEmail,
      text: data?.supportFormText,
      checkbox: data?.checkbox,
      captchaResponse
    };

    apiService.tickets
      .createFromForm(params)
      .then((res) => {
        if (res.status === 200) {
          toast.success(`${ticketsMessage?.success} ${res?.data?.number}`);
          methods.reset();
        } else if (res?.response?.status === 412) {
          toast.error(errors?.captcha);
        } else if (
          res?.response?.status === 400 &&
          res?.response?.data === "EMAIL_IS_INVALID"
        ) {
          toast.error(ticketsMessage?.errorEmail);
        } else {
          toast.error(ticketsMessage?.error);
        }
      })
      .catch((error) => toast.error(error?.message || error))
      .finally(() => {
        setSubmitting(false);
        recaptchaRef?.current?.reset();
      });
  };

  const submit = () => {
    if (
      site?.data?.captcha?.enabled &&
      site?.data?.captcha?.siteKeyV2 &&
      recaptchaRef.current
    ) {
      recaptchaRef.current.execute();
    } else {
      sendTicket();
    }
  };

  return (
    <>
      <div className="landing-support" id="support">
        <Container>
          <div className="landing-support__wrapper">
            <div className="landing-support__info">
              <p className="section__title">{support?.title}</p>

              <p className="landing-support__text">{support?.text}</p>

              <List />
            </div>
            <FormProvider {...methods}>
              <form
                className="landing-support__form"
                onSubmit={methods.handleSubmit(submit)}
                noValidate
              >
                <div className="landing-support__input">
                  <Form.Input
                    name="supportFormName"
                    placeholder={placeholders?.name}
                    required
                  />
                </div>
                <div className="landing-support__input">
                  <Form.Input
                    name="supportFormEmail"
                    type="email"
                    placeholder={placeholders?.email}
                    required
                  />
                </div>
                <div className="landing-support__input landing-support__input_textarea">
                  <Form.Input
                    name="supportFormText"
                    type="textarea"
                    placeholder={placeholders?.message}
                    required
                  />
                </div>
                <div className="landing-support__checkbox">
                  <Checkbox
                    name="checkbox"
                    label={support?.checkbox}
                    required
                  />
                </div>
                {site?.data?.captcha?.enabled &&
                  site?.data?.captcha?.siteKeyV2 && (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={site?.data?.captcha?.siteKeyV2}
                      size="invisible"
                      onChange={sendTicket}
                    />
                  )}
                <Button
                  type="submit"
                  loading={submitting}
                  disabled={submitting}
                >
                  {support?.btn}
                </Button>
              </form>
            </FormProvider>
          </div>
        </Container>
      </div>
    </>
  );
};
