import { useTranslation, useDispatchedActions } from "@hooks";

import "./menu.scss";
import { Link, useParams } from "react-router-dom";

export const Menu = () => {
  // **Hooks
  const { lang } = useParams();
  const { nav } = useTranslation();

  // **Dispatch
  const { toggleMobileMenu } = useDispatchedActions();

  // **Handlers
  const menuHandler = (event) => {
    const id = event.target.id.replace("heared-nav-", "");
    toggleMobileMenu(false);
    if (id) {
      setTimeout(() => {
        const el = document.getElementById(id);
        if (el) {
          el.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        event.target.blur();
      }, 100);
    }
  };

  return (
    <>
      <nav className="landing-menu">
        <ul className="landing-menu__list">
          <li className="landing-menu__item">
            <Link
              to={`/${lang}/`}
              id="heared-nav-faq"
              className="landing-menu__link"
              onClick={menuHandler}
            >
              {nav?.faq}
            </Link>
          </li>
          <li className="landing-menu__item">
            <a
              href="https://proxy-seller.com/otzyvy/"
              className="landing-menu__link"
            >
              {nav?.reviews}
            </a>
          </li>
          <li className="landing-menu__item">
            <a
              href="https://proxy-seller.com/blog/"
              className="landing-menu__link"
            >
              {nav?.blog}
            </a>
          </li>
          <li className="landing-menu__item">
            <Link
              to={`/${lang}/`}
              id="heared-nav-support"
              className="landing-menu__link"
              onClick={menuHandler}
            >
              {nav?.contact}
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};
