import { Bell } from "react-feather";
import {
  Badge,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSubscription } from "react-stomp-hooks";
import { useLoaderData } from "react-router-dom";

// ** Custom Hooks & Helpers
import { useDispatchedActions, useCurrentProfileData } from "@hooks";
// ** Components
import { NotificationMenuItems } from "./components/NotificationMenuItems";

export const NotificationDropdown = () => {
  // ** Dispatched Actions
  const { fetchNotifications } = useDispatchedActions();
  // ** Hooks
  const { isDemo } = useLoaderData();
  // ** Redux state
  const { userProfile, content } = useCurrentProfileData(isDemo);
  const { notifications } = useSelector((state) => state.navBar);

  // ** States
  const [trigerLoad, setTrigerLoad] = useState(true);

  // ** Functions
  const handlerTriger = () => setTrigerLoad((state) => !state);

  // ** Subscriptions
  useSubscription(
    isDemo ? [] : [`/user/${userProfile?.data?.id}/notifications`],
    handlerTriger
  );

  // ** Effects
  useEffect(() => {
    const promise = fetchNotifications(isDemo);
    return () => promise.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigerLoad]);

  useEffect(() => {
    if (isDemo) {
      handlerTriger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content?.notifications]);

  return (
    <UncontrolledDropdown tag="li" className="dropdown-notification nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link cursor-pointer"
        onClick={(e) => e.preventDefault()}
      >
        <Bell className="ficon" />
        {notifications?.count !== 0 && (
          <Badge
            pill
            color="danger"
            className="badge-up d-flex align-items-center"
          >
            {notifications?.count}
          </Badge>
        )}
      </DropdownToggle>
      <DropdownMenu tag="ul" className="dropdown-menu-media mt-0 p-0" end>
        <NotificationMenuItems handlerTriger={handlerTriger} />
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
