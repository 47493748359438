import { Card, CardBody, CardTitle, Input } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
// ** Components
import { CustomCopy } from "@components/app";

// ** Utils
const addhttps = (domain, isTls) => {
  let tempDomain = domain;
  if (!/^(f|ht)tps?:\/\//i.test(domain)) {
    tempDomain = isTls ? `https://${domain}` : `http://${domain}`;
  }
  return tempDomain;
};

export const LinkDomain = ({ title, domain, link, isTls }) => {
  // ** Redux state
  const { skin } = useSelector((state) => state.theme);
  // ** State
  const [darkColors, setDarkColors] = useState({});

  // ** Effects
  useEffect(() => {
    setDarkColors(
      skin === "dark" ? { baseColor: "#242b3d", highlightColor: "#343d55" } : {}
    );
  }, [skin]);

  return (
    <Card className="bg-transparent border-primary shadow-none">
      <CardBody className="p-1">
        <CardTitle className=" mb-50" tag="h2">
          {title}
        </CardTitle>
        {link ? (
          <div className={"d-flex align-items-center justify-content-between"}>
            <Input
              readOnly
              value={`${addhttps(domain, isTls)}${link}`}
              className="me-50"
            />
            <CustomCopy text={`${addhttps(domain, isTls)}${link}`} size={26} />
          </div>
        ) : (
          <CardBody className="p-0">
            <div className="row align-items-center ">
              <div className="col-11" style={{ maxHeight: 38 }}>
                <Skeleton height={38} {...darkColors} />
              </div>
              <div className="col-1 ps-0 d-flex justify-content-end">
                <Skeleton width={25} height={25} {...darkColors} />
              </div>
            </div>
          </CardBody>
        )}
      </CardBody>
    </Card>
  );
};
