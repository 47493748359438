import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { useOutletContext } from "react-router-dom";

// ** Custom Hooks & Utils
import { useDispatchedActions } from "@hooks";
import { fakeDataShow } from "@helpers";
// ** Services
import { apiService } from "@services/ApiService";
// ** Components
import { CustomCopy } from "@components/app";

const RenderIP = ({ row, admin }) => {
  // ** Hooks
  const { isDemo } = useOutletContext();
  // ** Dispatched Actions
  const { setHostModems } = useDispatchedActions();
  // ** Redux State
  const { data } = useSelector((state) => state.auth.userProfile);
  const { hostModems } = useSelector((state) => state.content);
  // ** States
  const [fetching, setFetching] = useState(false);

  // ** Functions
  const handlerSynchronizeIP = (e) => {
    // e.preventDefault();
    setFetching(true);
    if (!isDemo) {
      const modemId = e.currentTarget?.name;
      apiService.modems
        .synchronizeIp({ modemId })
        .then((req) => {
          if (req.status === 200) {
            let check = false;
            const modems = hostModems.data.map((item) => {
              if (item.id === row.id) {
                check = true;
                return { ...item, ip: req.data };
              } else return item;
            });
            if (check) {
              setHostModems(modems);
            }
            toast.success("Ip sync successfully");
          } else toast.error("Ip sync failed");
        })
        .finally(() => setFetching(false));
    }
  };

  return data?.roles && data.roles.includes("TESTER") ? (
    <div className={`d-flex ${row?.paid ? "" : "unselectable"}`}>
      <Button
        className="p-0 me-50"
        color="flat-secondary"
        name={row?.id}
        disabled={fetching}
        onClick={handlerSynchronizeIP}
      >
        {row?.ip || "No Ip"}
      </Button>
      {row?.ip && <CustomCopy text={row?.ip} size={14} />}
    </div>
  ) : (
    <div
      className={`d-flex  ${row?.paid || admin ? "" : "unselectable blur-box"}`}
    >
      <span className="p-0 me-50 color-secondary">
        {fakeDataShow(row?.ip, !row?.paid && !admin) || "No Ip"}
      </span>
      {row?.ip && (
        <CustomCopy
          text={fakeDataShow(row?.ip, !row?.paid && !admin)}
          size={14}
        />
      )}
    </div>
  );
};

export const staticIp = (admin = false) => ({
  id: "ip",
  name: "Ip",
  // selector: "ip",
  minWidth: "160px",
  key: "ip",
  cell: (row) => <RenderIP row={row} admin={admin} />
});
