const time = new Date().getTime();

export const DEMO_PAYMENT_HISTORY = [
    {
        id: "demoPayment02",
        createdDate: time,
        lastModifiedDate: time,
        purpose: "MODEM",
        method: "BALANCE",
        action: "PAYMENT",
        status: "CONFIRMED",
        amount: 50,
        usedBalance: true,
        balanceChanges: 50,
        balanceBefore: 350,
        balanceAfter: 300,
        adminUserId: null,
        paymentSystemName: "Balance",
        aggregatorId: null,
        aggregatorName: null,
        hostId: null,
        hostName: null,
        hostNumber: null,
        modemId: null,
        modemNumber: null,
        paymentStamp: null,
        currencyId: "usd",
        currencyCode: "USD",
        checkoutId: "",
        checkoutModemsIds: [],
        refundCheckoutModemId: null
      },
  {
    id: "demoPayment01",
    createdDate: time,
    lastModifiedDate: time,
    purpose: "MODEM",
    method: "BALANCE",
    action: "PAYMENT",
    status: "CONFIRMED",
    amount: 20,
    usedBalance: true,
    balanceChanges: 20,
    balanceBefore: 370,
    balanceAfter: 350,
    adminUserId: null,
    paymentSystemName: "Balance",
    aggregatorId: null,
    aggregatorName: null,
    hostId: null,
    hostName: null,
    hostNumber: null,
    modemId: null,
    modemNumber: null,
    paymentStamp: null,
    currencyId: "usd",
    currencyCode: "USD",
    checkoutId: "",
    checkoutModemsIds: [],
    refundCheckoutModemId: null
  },
  {
    id: "demoPayment00",
    createdDate: time,
    lastModifiedDate: time,
    purpose: "USER",
    method: "PAYMENT_SYSTEM",
    action: "REPLENISHMENT",
    status: "CONFIRMED",
    amount: 370,
    usedBalance: true,
    balanceChanges: 370,
    balanceBefore: 0,
    balanceAfter: 370,
    adminUserId: null,
    paymentSystemName: "Demo Payment",
    aggregatorId: null,
    aggregatorName: null,
    hostId: null,
    hostName: null,
    hostNumber: null,
    modemId: null,
    modemNumber: null,
    paymentStamp: null,
    currencyId: null,
    currencyCode: "USD",
    checkoutId: null,
    checkoutModemsIds: [],
    refundCheckoutModemId: null
  }
];
