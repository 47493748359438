import { useSelector } from "react-redux";
import { Container } from "@components/landing/ui";
import { useDispatchedActions, useTranslation, useCurrentSeo } from "@hooks";
import { Modal } from "@components/landing/common/modal/modal";
import { SliderSPA } from "./components/sliderSPA/sliderSPA";

import "./get.scss";

export const Get = () => {
  // **Hooks
  const {
    mainPage: { get }
  } = useTranslation();
  const { featureSet } = useCurrentSeo();
  const { setOpenModalFeatureSet } = useDispatchedActions();
  // **Redux State
  const { modalFeatureSet } = useSelector((state) => state.landing);

  // **Handlers
  const handlerCloseModal = () => setOpenModalFeatureSet(false);

  return (
    <div className="landing-get">
      <Container>
        <p className="section__title landing-get__title">{get?.title}</p>
        <div className="d-none d-md-block">
          <SliderSPA direction={"vertical"} />
        </div>
        <div className="d-block d-md-none" style={{ display: "none" }}>
          <SliderSPA direction={"horizontal"} heightAuto />
        </div>
      </Container>
      <Modal
        visible={modalFeatureSet?.isOpen}
        width="800"
        cancelHandler={handlerCloseModal}
        title={featureSet?.modalTitle}
      >
        <div className="landing-modal__text">
          <p>{featureSet?.modalTextTop}</p>
        </div>
        <ul className="landing-modal__list">
          {featureSet?.items?.length
            ? featureSet?.items.map((item, index) => (
                <li key={index}>{item}</li>
              ))
            : null}
          {/* {featureSet?.modalType}: */}
        </ul>
        <div className="landing-modal__text">
          <p>{featureSet?.modalTextBottom}</p>
        </div>
      </Modal>
    </div>
  );
};
