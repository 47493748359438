/* eslint-disable no-undef */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "@services/ApiService";
import { toast } from "react-toastify";
import { DemoReducerActions } from "../DemoReducer/Demo.reducer";
import { ContentReducerActions } from "./Content.reducer";

export const fetchHostModems = createAsyncThunk(
  "Content/fetchHostModems",
  async ({ params, isDemo }, { rejectWithValue, getState, signal }) => {
    if (isDemo) {
      const { content } = getState().demo;
      const response = new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            data: content?.modems?.[params?.id] || [],
            activeHostId: params.id
          });
        }, 1000);
      });
      return response;
    }
    try {
      const response = await apiService.users.getModems(params, signal);

      if (response && response.status !== 200) {
        throw response;
      }

      return { data: response.data, activeHostId: params.id };
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const fetchHosts = createAsyncThunk(
  "Content/fetchHosts",
  async (isDemo = false, { rejectWithValue, getState, signal }) => {
    if (isDemo) {
      const { demo } = getState();
      return { data: demo?.content?.hosts };
    }
    try {
      const response = await apiService.users.getHosts(signal);

      if (response && response.status !== 200) {
        throw response;
      }

      return { data: response.data };
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const renameHost = createAsyncThunk(
  "Content/renameHost",
  async ({ params, isDemo }, { rejectWithValue, getState, dispatch }) => {
    if (isDemo) {
      const { demo } = getState();
      const tempHosts = demo?.content?.hosts.map((item) =>
        item.id === params.hostId ? { ...item, name: params.newName } : item
      );
      dispatch(DemoReducerActions.demoRenameHost(tempHosts));
      dispatch(ContentReducerActions.demoSetHosts(tempHosts));
      toast.success("Host name changed successfully");
      return true;
    }
    try {
      const response = await apiService.hosts.changeName(params);

      if (response?.status === 200) {
        toast.success("Host name changed successfully");
        dispatch(fetchHosts());
      } else {
        toast.error("Host name change failed");
        throw response;
      }
      return true;
    } catch (err) {
      return rejectWithValue(false);
    }
  }
);

export const fetchKnowladgeBase = createAsyncThunk(
  "Content/fetchKnowladgeBase",
  async (params, { rejectWithValue, signal }) => {
    try {
      const response = await apiService.knowledgeBaseCollections.getAll(
        params,
        signal
      );

      if (response && response.status !== 200) {
        throw response;
      }

      return {
        data: response.data.filter((item) => item.knowledgeBases.length > 0),
        locale: params.locale
      };
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const fetchAgreements = createAsyncThunk(
  "Content/fetchAgreements",
  async (locale, { rejectWithValue }) => {
    try {
      const response = await apiService.languageContent.getAgreements(locale);

      if (response && response.status !== 200) {
        throw response;
      }
      return { data: response.data, locale };
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const fetchSeoEdit = createAsyncThunk(
  "Content/fetchSeoEdit",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiService.languageContent.adminGetAll();

      if (response && response.status !== 200) {
        throw response;
      }
      const languages = response.data.map((item) => ({
        value: item.id,
        label: item.languageName
      }));

      return { data: response.data, languages };
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);
