import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

import "./skeleton.styles";

export const LoadingDataTableTwo = ({ rowNumber = 10 }) => {
  // ** Redux State
  const { skin } = useSelector((state) => state.theme);

  // ** Conctants
  const darkColors =
    skin === "dark" ? { baseColor: "#242b3d", highlightColor: "#343d55" } : {};

  return (
    <div className="d-flex flex-column w-100 loading-data-body">
      {new Array(rowNumber).fill(1).map((_, index) => (
        <div
          className="d-flex justify-content-between border-bottom"
          key={index}
        >
          <div className="px-1 py-75 d-flex align-items-center ">
            <Skeleton width={120} height={15} {...darkColors} />
          </div>
          <div className="px-1 py-75 d-flex align-items-center ">
            <Skeleton width={80} height={15} {...darkColors} />
          </div>
          <div className="px-1 py-75 d-flex align-items-center ">
            <Skeleton width={40} height={15} {...darkColors} />
          </div>
          <div className="px-1 py-75 d-flex align-items-center ">
            <Skeleton width={110} height={15} {...darkColors} />
          </div>
          <div className="px-1 py-75 d-flex align-items-center ">
            <Skeleton width={110} height={15} {...darkColors} />
          </div>
          <div className="px-1 py-75 d-flex align-items-center ">
            <Skeleton width={80} height={15} {...darkColors} />
          </div>
        </div>
      ))}
    </div>
  );
};
