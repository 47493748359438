import { FormProvider, useForm } from "react-hook-form";

import { Checkbox } from "./checkbox/checkbox";
import { Input } from "./input/input";
import { SelectPopout } from "./selectV2/selectV2";
import { SelectForm } from "./select/select";

export const Form = ({ className, funcSubmit, children }) => {
  const methods = useForm({
    mode: "onChange"
  });

  const submit = (data) => {
    funcSubmit?.(data);
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <form
        className={className}
        onSubmit={methods.handleSubmit(submit)}
      >
        {children}
      </form>
    </FormProvider>
  );
};

Form.Input = Input;
Form.Checkbox = Checkbox;
Form.SelectForm = SelectForm;
Form.SelectPopout = SelectPopout;
