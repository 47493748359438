import { Navigate } from "react-router-dom";

export const AdminCheckoutModemsRoutes = [
  {
    path: "checkout-modem/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminCheckoutModems } = await import(
            "@views/app/admin/checkoutModems"
          );
          return {
            Component: AdminCheckoutModems
          };
        }
      },
      {
        path: ":id/edit/",
        async lazy() {
          const { AdminCheckoutModemEdit } = await import(
            "@views/app/admin/checkoutModems/edit"
          );
          return {
            Component: AdminCheckoutModemEdit
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
