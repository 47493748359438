import { Navigate } from "react-router-dom";

export const AdminKnowledgeBaseRoutes = [
  {
    path: "knowledge-base/*",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminKnowledgeBase } = await import(
            "@views/app/admin/knowledgeBase/"
          );
          return {
            Component: AdminKnowledgeBase
          };
        }
      },
      {
        path: "collection/add/",
        async lazy() {
          const { AdminKnowledgeBaseCollectionAdd } = await import(
            "@views/app/admin/knowledgeBase/addCollection"
          );
          return {
            Component: AdminKnowledgeBaseCollectionAdd
          };
        }
      },
      {
        path: "collection/:collectionId/edit/",
        async lazy() {
          const { AdminKnowledgeBaseCollectionEdit } = await import(
            "@views/app/admin/knowledgeBase/editCollection"
          );
          return {
            Component: AdminKnowledgeBaseCollectionEdit
          };
        }
      },
      {
        path: "item/add/",
        async lazy() {
          const { AdminKnowledgeBaseItemAdd } = await import(
            "@views/app/admin/knowledgeBase/add"
          );
          return {
            Component: AdminKnowledgeBaseItemAdd
          };
        }
      },
      {
        path: "item/:itemId/edit/",
        async lazy() {
          const { AdminKnowledgeBaseItemEdit } = await import(
            "@views/app/admin/knowledgeBase/edit"
          );
          return {
            Component: AdminKnowledgeBaseItemEdit
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
