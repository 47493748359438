import { Navigate } from "react-router-dom";

export const AdminCustomMetaTagRoutes = [
  {
    path: "custom-meta-tag/",
    children: [
      {
        index: true,
        async lazy() {
          const { AdminCustomMetaTag } = await import(
            "@views/app/admin/customMeta/"
          );
          return {
            Component: AdminCustomMetaTag
          };
        }
      },
      {
        path: ":type?",
        async lazy() {
          const { AdminCustomMetaTag } = await import(
            "@views/app/admin/customMeta"
          );
          return {
            Component: AdminCustomMetaTag
          };
        }
      },
      {
        path: "*",
        element: <Navigate to="./" replace={true} />
      }
    ]
  }
];
