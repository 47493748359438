const time = new Date().getTime();

export const DEMO_ORDERS = [
  {
    id: "demoOrder02",
    createdDate: time,
    number: "010243020",
    status: "PAID",
    price: 499,
    delivery: "USPS",
    paymentMethod: "PAYMENT_SYSTEM",
    deliveryPrice: 59.88,
    tax: 0.0,
    trackingCode: "CM290072180US",
    dongleKitAmount: 5,
    dongleKitName: "5-Dongle Kit"
  },
  {
    id: "demoOrder01",
    createdDate: time,
    number: "010243620",
    status: "SENT",
    price: 699,
    delivery: "USPS",
    paymentMethod: "PAYMENT_SYSTEM",
    deliveryPrice: 79.88,
    tax: 0.0,
    trackingCode: "CM290074530US",
    dongleKitAmount: 10,
    dongleKitName: "10-Dongle Kit"
  },
  {
    id: "demoOrder00",
    createdDate: time,
    number: "019243020",
    status: "DELIVERED",
    price: 999,
    delivery: "USPS",
    paymentMethod: "PAYMENT_SYSTEM",
    deliveryPrice: 99.88,
    tax: 0.0,
    trackingCode: "CM223072180US",
    dongleKitAmount: 20,
    dongleKitName: "20-Dongle Kit"
  }
];
