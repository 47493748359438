import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { useLocation, useSearchParams } from "react-router-dom";
// ** Custom Hooks & Utils
import { isDifferentSerchParams } from "@helpers";
// ** Components
import { LoadingDataTableTwo } from "@components/app";
import {
  CustomPagination,
  NoDataComponent
} from "@components/app/table-components";

export const TableWithFetchPaginationData = ({
  columns = [],
  sort = {},
  searchFunc,
  outsideTriger = null,
  apiFetchFunc
}) => {
  // ** Hooks
  const { pathname, search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const row = searchParams.get("row");
  const page = searchParams.get("page");
  // ** Local State
  const [content, setContent] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [checkSearch, setCheckSearch] = useState([]);
  const [loading, setLoading] = useState(true);

  // ** Functions
  const handlerSizePage = (size) => {
    if (size > 10) {
      searchParams.set("row", size);
      setSearchParams?.(searchParams);
    } else {
      searchParams.delete("row");
      setSearchParams?.(searchParams);
    }
  };

  const handlerCurrentPage = (page) => {
    if (page > 1) {
      searchParams.set("page", page);
      setSearchParams?.(searchParams);
    } else {
      searchParams.delete("page");
      setSearchParams?.(searchParams);
    }
  };

  const currentLoadingNumber = () => {
    const currentPage = page ? +page : 1;
    const sizePage = row ? +row : 10;
    if (totalElements === 0) {
      return sizePage;
    }
    if (totalElements < sizePage) {
      return totalElements;
    }
    if (currentPage * sizePage > totalElements) {
      const rest = totalElements - (currentPage - 1) * sizePage;
      return rest < 0 ? 10 : rest;
    }
    return sizePage;
  };

  // ** Effects
  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    const params = {
      page: page ? +page - 1 : 0,
      size: row ? +row : 10,
      sort,
      search: checkSearch
    };
    apiFetchFunc?.(params, controller.signal)
      .then((req) => {
        if (req?.status === 200 && req?.data) {
          setContent(req.data?.content || []);
          setTotalElements(req.data.totalElements || 0);
        }
      })
      .catch(() => {
        toast.error("Error");
      })
      .finally(() => {
        if (!controller.signal.aborted) {
          setLoading(false);
        }
      });
    return () => controller?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outsideTriger, checkSearch]);

  useEffect(() => {
    if (searchParams.size > 0) {
      const params = {};
      const pageSise = {};
      for (const [key, value] of searchParams.entries()) {
        if (key !== "page" && key !== "row") {
          if (value.split(",").length > 1) {
            params[key] = value.split(",");
          } else if (key === "countries") {
            params[key] = value.split(",");
          } else {
            params[key] = value;
          }
        } else {
          pageSise[key] = value;
        }
      }
      const temp = searchFunc?.(params) || [];
      if (isDifferentSerchParams(temp, checkSearch) && pageSise.page) {
        searchParams.delete("page");
        setSearchParams?.(searchParams);
      } else {
        setCheckSearch(temp);
      }
      sessionStorage.setItem(`searchParams-${pathname}`, search);
    } else {
      const temp = searchFunc?.({}) || [];
      setCheckSearch(temp);
      const checkEnd = pathname.endsWith("/");
      sessionStorage.removeItem(
        `searchParams-${pathname}${checkEnd ? "" : "/"}`
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <DataTable
      noHeader
      pagination
      data={content}
      columns={columns || []}
      className="react-dataTable react-dataTable__pagination"
      paginationServer
      progressPending={loading}
      progressComponent={
        <LoadingDataTableTwo rowNumber={currentLoadingNumber()} />
      }
      paginationTotalRows={totalElements}
      paginationDefaultPage={page ? +page : 1}
      paginationPerPage={row ? +row : 10}
      onChangePage={handlerCurrentPage}
      onChangeRowsPerPage={handlerSizePage}
      paginationComponent={CustomPagination}
      noDataComponent={<NoDataComponent />}
    />
  );
};
