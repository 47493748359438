/* eslint-disable no-console */

/**
 * Clear browser cache
 */
export const clearBrowserCache = () => {
  try {
    console.log("Clear browser cache....");
    if ("caches" in window) {
      caches.keys().then((names) => {
        if (names.length > 0) {
          names.forEach((name) => {
            caches.delete(name).then((response) => {
              console.log(`Cache file '${name}' is ${response ? "deleted" : "not deleted!"}`);
            }).catch((error) => {
              console.error("browser cache: ERROR", error);
            });
          });
        } else {
          console.log("No find caches for remove!");
        }
        // names.forEach((name) => {
        //   console.log(`Delete file: ${name}`);
        //   caches.delete(name);
        // });
      });
    } else {
      console.warn("No find caches in window!", window);
    }
  } catch (error) {
    console.warn("Error on browser clear cache!", error);
  }
};
