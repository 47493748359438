import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Badge } from "reactstrap";
// ** Custom Hooks & Utils
import { search, getAllParents } from "../../utils";
// ** Components
import { NAVBAR_MENU } from "../navigation";

export const VerticalNavMenuLink = (props) => {
  // ** Props
  const { item, setGroupActive, setGroupOpen, toggleActiveGroup, parentItem } =
    props;
  // ** Hooks
  const { pathname } = useLocation();

  // ** Functions
  // ** Search for current item parents
  const searchParents = (navigation, currentURL) => {
    const parents = search(navigation, currentURL); // Search for parent object
    const allParents = getAllParents(parents, "id"); // Parents Object to Parents Array
    return allParents;
  };

  // ** URL Vars
  const resetActiveGroup = (navLink) => {
    const parents = search(NAVBAR_MENU, navLink);
    toggleActiveGroup(item.id, parents);
  };

  // ** Reset Active & Open Group Arrays
  const resetActiveAndOpenGroups = () => {
    setGroupActive([]);
    setGroupOpen([]);
  };

  // Click on Link
  const handlerNavActions = () => {
    if (parentItem) {
      resetActiveGroup(item.navLink);
    } else resetActiveAndOpenGroups();
  };

  // ** Effects
  // ** Checks url & updates active item
  useEffect(() => {
    if (item.navLink === pathname) {
      if (parentItem) {
        const arr = searchParents(NAVBAR_MENU, pathname);
        setGroupActive([...arr]);
      } else {
        setGroupActive([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <li
      className={`${!item.children ? "nav-item" : ""} ${
        item.disabled ? "disabled" : ""
        // } ${item.navLink === pathname ? "active" : ""}`} // Original
      } ${pathname.includes(item.navLink) ? "active" : ""}`} // Modified
    >
      <NavLink
        className="d-flex align-items-center"
        target={item.newTab ? "_blank" : null}
        to={item.navLink || "#"}
        onClick={handlerNavActions}
      >
        {item.icon}
        <span className="menu-item text-truncate">{item.title}</span>

        {item.badge && item.badgeText ? (
          <Badge className="ms-auto me-1" color={item.badge} pill>
            {item.badgeText}
          </Badge>
        ) : null}
      </NavLink>
    </li>
  );
};
