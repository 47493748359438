/* eslint-disable import/namespace */
/* eslint-disable quotes */
import { createRoot, hydrateRoot } from "react-dom/client";
// import { StrictMode } from "react";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
// import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
// ** Ripple Button
import { Button } from "reactstrap";
import { RippleButton } from "@components/app";
// ** Service & Utils
import { apiService } from "@services/ApiService";
// ** Config & Store
import { StrictMode } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { setupStore } from "./redux/store";
// ** Error Boundary
import ErrorBoundary from "./components/ErrorBoundary";

Button.Ripple = RippleButton;

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@scss/react/libs/toastify/toastify.scss";

// ** Core styles
import "@assets/fonts/iconfont.css";
import "@scss/core.scss";
import "./index.scss";
import "@scss/style.scss";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import { App } from "./App";
import { getRoutes } from "./routes";

const rootEl = document.getElementById("root");

const optionRender = (store = {}) => {
  const newStore = setupStore(store);
  const { lang } = newStore.getState().settings;
  const router = createBrowserRouter(getRoutes({ lang }));

  const renderAPP = (
    <StrictMode>
      <ErrorBoundary>
        <Provider store={newStore}>
          <HelmetProvider>
            <App provider={RouterProvider} routerProps={{ router }} />
            <ToastContainer
              transition={Slide}
              position="top-right"
              autoClose={3000}
              newestOnTop
              hideProgressBar
              closeButton={false}
            />
          </HelmetProvider>
        </Provider>
      </ErrorBoundary>
    </StrictMode>
  );

  if (rootEl.hasChildNodes()) {
    // console.log("hydrateRoot");
    return hydrateRoot(rootEl, renderAPP);
  } else {
    // console.log("createRoot");
    const root = createRoot(rootEl);
    return root.render(renderAPP);
  }
};

const initStore = async () => {
  try {
    const { content } = document.querySelector('meta[name="ssr-content-id"]');
    if (content) {
      const req = await apiService.ssr.cache(content);
      if (req.data.state) {
        return optionRender(req.data?.state || {});
      }
    }
    return optionRender(window.__PRELOADED_STATE__);
  } catch (ignore) {
    return optionRender(window.__PRELOADED_STATE__);
  } finally {
    delete window.__PRELOADED_STATE__;
    document.getElementById("preloaded-state")?.remove();
  }
};

initStore();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
