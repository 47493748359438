export const ContentList = ({ content }) => (
  <ul className="landing-work__content-list">
    {content?.length ? content.map((item, index) => (
      <li key={index} className="landing-work__content-item">
        <p className="landing-work__content-title">{item.title}</p>
        <p >{item.text}</p>
      </li>
    )) : null}
  </ul>
);
