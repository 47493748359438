import { ReactCountryFlag } from "react-country-flag";
import { Controller, useFormContext } from "react-hook-form";
import Select, { components } from "react-select";
// ** Custom Hooks & Utils
import { selectThemeColors } from "@helpers";
import { FormFeedback } from "reactstrap";

// ** Constants
export const RENDER_TYPES = {
  COUNTRY: "COUNTRY",
  PHONE_PREFIX: "PHONE_PREFIX"
};

export const FormSelect = (props) => {
  // ** Props
  const {
    name,
    options,
    required = false,
    isMulti = false,
    errorText = "This field is required",
    messageOff = false,
    placeholder = "Select ...",
    customStyles = {},
    notShowError = false,
    ...rest
  } = props;

  // ** Hooks
  const { control } = useFormContext();

  // ** Functions
  const currentValue = (value) => {
    let currentValue;
    if (isMulti) {
      const arrayValue = typeof value === "string" ? [value] : value;
      currentValue = options.filter((option) =>
        arrayValue?.find((it) => it === option.value)
      );
    } else {
      currentValue = options.find((option) => value === option.value);
    }
    return currentValue ? currentValue : null;
  };

  const renderOption = (label, value) => (
    <span className="d-flex align-items-center">
      <ReactCountryFlag
        className="country-flag flag-icon me-50 h-100"
        countryCode={value.toLowerCase()}
        svg
      />
      {label}
    </span>
  );

  const Option = (props) => {
    const { label, code, renderType } = props.data;

    if (!renderType || !RENDER_TYPES.hasOwnProperty(renderType)) {
      return <components.Option {...props} />;
    }

    if (renderType === RENDER_TYPES.COUNTRY) {
      return (
        <components.Option {...props}>
          {renderOption(label, code)}
        </components.Option>
      );
    }

    if (renderType === RENDER_TYPES.PHONE_PREFIX) {
      return (
        <components.Option {...props}>
          {renderOption(label, code)}
        </components.Option>
      );
    }

    return <components.Option {...props} />;
  };

  const SingleValue = ({ children, ...props }) => {
    const { label, code, value, renderType } = props.data;

    if (!renderType || !RENDER_TYPES.hasOwnProperty(renderType)) {
      return (
        <components.SingleValue {...props}>{children}</components.SingleValue>
      );
    }

    if (renderType === RENDER_TYPES.COUNTRY) {
      return (
        <components.SingleValue {...props}>
          {renderOption(label, code)}
        </components.SingleValue>
      );
    }

    if (renderType === RENDER_TYPES.PHONE_PREFIX) {
      return (
        <components.SingleValue {...props}>
          {renderOption(value, code)}
        </components.SingleValue>
      );
    }

    return (
      <components.SingleValue {...props}>{children}</components.SingleValue>
    );
  };

  const combainedStyles = {
    menu: (styles) => ({ ...styles, zIndex: 9999 }),
    ...customStyles
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: required, message: messageOff ? "" : errorText }
      }}
      defaultValue={null}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <Select
            theme={selectThemeColors}
            isMulti={isMulti}
            className={`react-select ${error?.type ? "is-invalid" : ""}`}
            classNamePrefix="select"
            options={options}
            components={{ Option, SingleValue }}
            placeholder={placeholder}
            onChange={(data) =>
              isMulti
                ? onChange(data.map((it) => it.value))
                : onChange(data.value)
            }
            value={currentValue(value)}
            {...rest}
            styles={combainedStyles}
          />
          {!!error?.message && !notShowError && (
            <FormFeedback>{error.message}</FormFeedback>
          )}
        </>
      )}
    />
  );
};
