import Moment from "react-moment";

export const staticUpdated = (admin = false) => ({
  id: "lastUpdate",
  name: "Updated",
  // selector: "lastUpdate",
  minWidth: "120px",
  key: "10",
  cell: (row) =>
    row?.lastUpdate && (
      <div
        className={`d-flex ${
          row?.paid || admin ? "" : "unselectable blur-box"
        }`}
      >
        <Moment format="YYYY/MM/DD hh:mm:ss">{row?.lastUpdate}</Moment>
      </div>
    )
});
