/* eslint-disable no-undef */
import { useState, useEffect } from "react";
import { AlertCircle } from "react-feather";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Alert,
  Form
} from "reactstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";

// ** Custom Hooks & Utils
import { currentLimitsValue, renderAccentNames } from "@helpers";
import { useDispatchedActions, useLockBackground } from "@hooks";
// ** Services
import { apiService } from "@services/ApiService";
// ** Components
import { LimitsFormFields } from "./components/LimitsFormFields";

export const ModalUserLimits = () => {
  // ** Dispatched Actions
  const { setUpdateModems, setModalUserLimitsOpen } = useDispatchedActions();

  // ** Hooks
  const { isDemo } = useOutletContext();
  const methods = useForm({
    defaultValues: { type: "MB", notification: false }
  });
  const formData = methods.watch();
  // ** Redux state
  const { activeHostId } = useSelector((state) => state.content.hostModems);
  const { content } = useSelector((state) => state.demo);
  const {
    selectedModemsInfo,
    modalUserLimitsOpen,
    selectedUsersIds,
    modalUserModemLimitSet
  } = useSelector((state) => state.modals);
  // ** State
  const [userLimits, setUserLimits] = useState(null);

  // ** Functions
  const handlerSave = (data) => {
    const params = {
      ownerIds: selectedUsersIds,
      modemId: selectedModemsInfo[0].id,
      speedLimit: currentLimitsValue(data.speed, data.speedCustom, "MB"),
      trafficLimit: currentLimitsValue(
        data.traffic,
        data.trafficCustom,
        data.type
      ),
      outOfLimitNotifications: data.notification
    };
    if (!isDemo) {
      apiService.proxyLimits.updateLimitsBatch(params).then((req) => {
        if (req.request.status >= 200 && req.request.status < 300) {
          if (req.data.failedModemAuths.length === 0) {
            toast.success("Successfully saved limit!");
            setModalUserLimitsOpen(false);
          } else {
            req.data.failedModemAuths.forEach((it) => {
              toast.error(
                `Error occurred! Login: ${it.login}. Reason: ${it.reason}`
              );
            });
          }
        } else {
          toast.error("Error occured during saving.");
          toast.error(req.response.data);
        }
      });
    } else {
      // demo logik start
      const activeHostModems = content.modems[activeHostId];
      const newModems = {
        ...content.modems,
        [activeHostId]: activeHostModems.map((item) => {
          if (item.id === params.modemId) {
            const tempLogins = [];
            const tempModemAuths = item.limits.modemAuths.map((auth) => {
              if (params.ownerIds.includes(auth.id)) {
                tempLogins.push(auth.authType === "IP" ? auth.ip : auth.login);
                return {
                  ...auth,
                  speedLimit: params.speedLimit,
                  trafficLimit: params.trafficLimit
                };
              } else return auth;
            });
            return {
              ...item,
              lastUpdate: new Date().getTime(),
              limits: {
                ...item.limits,
                speedLimit: 0,
                trafficLimit: 0,
                isModemLimitPresent: false,
                logins: tempLogins,
                modemAuths: tempModemAuths
              }
            };
          } else return item;
        })
      };
      setUpdateModems(newModems);
      setModalUserLimitsOpen(false);
      // demo logik end
    }
  };

  const close = () => setModalUserLimitsOpen(false);

  // ** Effects
  useEffect(() => {
    if (selectedUsersIds?.length === 1) {
      if (!isDemo) {
        apiService.proxyLimits
          .getUserLimits(selectedUsersIds[0], selectedModemsInfo[0].id)
          .then((req) => {
            if (req?.data) {
              setUserLimits(req.data);
            }
          });
      } else {
        // demo logik start
        const activeHostModems = content.modems[activeHostId];
        const tempModem = activeHostModems.find(
          (item) => item.id === selectedModemsInfo[0].id
        );
        const tempLimit = tempModem.limits.modemAuths.find(
          (item) => item.id === selectedUsersIds[0]
        );
        setUserLimits(tempLimit);
        // demo logik end
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLockBackground(!!modalUserLimitsOpen);

  return (
    <Modal
      isOpen={modalUserLimitsOpen}
      className="modal-dialog-centered"
      toggle={close}
    >
      <ModalHeader toggle={close}>
        <AlertCircle className="me-50" color="#ffb708" />
        Set User Limit
      </ModalHeader>
      <FormProvider {...methods}>
        {" "}
        <Form onSubmit={methods.handleSubmit(handlerSave)}>
          <ModalBody>
            {modalUserModemLimitSet && (
              <Alert className=" p-1 font-weight-bold" color="danger">
                If you save limits to this user, then will be delete limits
                modem №{renderAccentNames([selectedModemsInfo[0].number])}
              </Alert>
            )}
            <LimitsFormFields limits={userLimits} type={formData?.type} />
          </ModalBody>
          <ModalFooter>
            <Button outline color="danger" onClick={close}>
              Cancel
            </Button>
            <Button outline color="success" type="submit">
              Save
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};
